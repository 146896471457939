import React, {useContext} from "react";
import {useHistory} from "react-router-dom"
import {Button, Card} from "rt-design-system-backup"
import {Col, Row} from "antd"
import SVG from 'react-inlinesvg'
import RioLogo from "../../../assets/RioLogo.svg"
import NexTradeLogo from "../../../assets/NexTrade_Colour_logo_Vector_LIGHT_BG.png"
import NexTradeTextLogo from "../../../assets/NT_word_navy.png"
import NexTradeLogoSVG from "../../../assets/NexTrade_Colour_logo_Vector_LIGHT_BG.svg"
import NexTradeTextSVG from "../../../assets/NexTrade_WORD_logo_Vector_DARKBLUE.svg"

import "./style.scss"
import AppContext from "../AppContext";


export const NoPermissionsForm = () => {

    let history = useHistory();
    const {refreshUser} = useContext(AppContext);

    const handleRedirect = () => {
        localStorage.clear();
        refreshUser();
        history.push('/login')
    };

    return (
        <div style={{display: "block", marginTop: "36px"}}>
            <img src={NexTradeLogo}     width={75}  style={{display: "block", marginLeft: "auto", marginRight: "auto", marginBottom: "20px"}}></img>
            <SVG width={150} src={NexTradeTextSVG} style={{display: "block", marginLeft: "auto", marginRight: "auto", marginBottom: "30px"}}/>
        <h4 style={{fontSize: "24px", fontWeight: 700}}>Registration required</h4>
        <div className="rt-no-permissions-message">
            <p className="rt-no-permissions-message-p">
                Your credentials have been successfully verified.
                However, you are not currently registered on the NexTrade Platform.
                To register as a user and start using the NexTrade Platform, please email <a
                href="mailto:CommercialDigitalNexTradeTeam@riotinto.com">CommercialDigitalNexTradeTeam@riotinto.com</a>
            </p>
        </div>
        <Button onClick={handleRedirect} large className="login-btn" intent='primary'
        style={{display: "block", 
                marginLeft: "auto", 
                marginRight: "auto",
                width: "fit-content",
                borderRadius: "100px",
                lineHeight: "0px"
            }}>
            Back to Sign In
        </Button>
    </div>
    )};

export const NoPermissionsContainer = () => {
    return <div className="rt-login" style={{padding: "40px"}}>
        <Row align="middle" className="rt-login-row">
            <Col span={6} xs={{span: 24, offset: 0}} lg={{span: 10, offset: 7}}>
                <div className="rt-login-container">
                    <div className="rt-login-logo">
                        <SVG src={RioLogo} style={{padding: "20px"}}/>
                    </div>
                    <Card elevation={3}style={{padding: "64px", paddingTop: "32px"}}>
                        <NoPermissionsForm/>
                    </Card>
                </div>
            </Col>
        </Row>
    </div>
};


export const NoPermissions = () => {
    return <NoPermissionsContainer/>
};

