import { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import useAxios from 'axios-hooks'

import { url } from '../../helpers'
import Toaster from '../Toaster'
import AppContext from '../AppContext'

/**
 * Hook to know if the user is currently logged in. Note that polling this hook will prolongate the user session, which should be avoided.
 * @param {boolean} redirect - Whether or not the hook should automatically handle user redirection if not logged in
 * @return {[loggedIn: boolean, loading: boolean]} Array containing the log in state and the loading state of the login test
 */
export const useLogin = (redirect) => {
  const history = useHistory()
  const { refreshUser } = useContext(AppContext)

  const [{ data, error }, refetch] = useAxios({
    url: url("profile/"),
  })

  useEffect(() => {
    if (error && redirect) {
      (async () => {
        await refreshUser()

        Toaster.show({
          message: "Your session has expired",
          icon: "time",
          intent: "warning",
        });

        history.push('/login')
      })()
    }
  }, [error, history, refreshUser, redirect])

  useEffect(refetch, [refetch])

  if (data) {
    return [true, false]
  }

  if (error) {
    return [false, false]
  }

  return [false, true]
}

export default useLogin
