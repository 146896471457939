import React, { useState } from "react";
import { Button, Icon, Spinner } from "rt-design-system-backup";

import { Table } from "antd";
import "./style.scss";
import { useHistory } from "react-router-dom";
import { formatDate } from "../../helpers";
import { FilterDropDown } from "../STPAPIConsoleTable/FilterDropDown";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useQueryParams } from "../../hooks/useQueryParams";

// Renders the prev and next buttons in the pagination
export const renderItem = (_, type, originalElement) => {
  if (type === "prev") {
    return <Button icon="arrow-left" intent="primary" />;
  } else if (type === "next") {
    return <Button icon="arrow-right" intent="primary" />;
  } else {
    return originalElement;
  }
};

// Renders the STP Mapping table
const TableInterAction = ({ data: mappings }) => {
  const [searchText, setSearchText] = useState("");

  const history = useHistory();
  const queryParams = useQueryParams();
  const loading = false;

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "Rule ID",
      dataIndex: "RuleID",
      fixed: "left",
      sorter: (a, b) => a.RuleID - b.RuleID,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <FilterDropDown
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          handleReset={handleReset}
          handleSearch={handleSearch}
          clearFilters={clearFilters}
          confirm={confirm}
        />
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? "#1890ff" : "#8e8e8e", fontSize: "16px" }}
        />
      ),
      onFilter: (value, record) =>
        record.RuleID.toString().includes(value.toString()),
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : "-"}
        />
      ),
    },

    {
      title: "System",
      dataIndex: "System",
      filters: mappings
        ? mappings
            .map((mapping) => ({ text: mapping.System, value: mapping.System }))
            .filter(
              (item, index, array) =>
                array.findIndex((obj) => obj.text === item.text) === index,
            )
        : [],
      filterSearch: true,
      onFilter: (value, record) => record.System === value,
      render: (data) => {
        return <div style={{ textAlign: "center" }}>{data ? data : "-"}</div>;
      },
    },
    {
      title: "System Column Name",
      dataIndex: "SystemColumnName",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <FilterDropDown
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          handleReset={handleReset}
          handleSearch={handleSearch}
          clearFilters={clearFilters}
          confirm={confirm}
          placeholder="Search System Column Name"
        />
      ),
      sorter: (a, b) => a.SystemColumnName.localeCompare(b.SystemColumnName),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? "#1890ff" : "#8e8e8e", fontSize: "16px" }}
        />
      ),
      onFilter: (value, record) =>
        record.SystemColumnName.toString().includes(value.toString()),
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : "-"}
        />
      ),
    },
    {
      title: "System Column Value",
      dataIndex: "SystemColumnValue",
      render: (data) => {
        return <div style={{ textAlign: "center" }}>{data ? data : "-"}</div>;
      },
    },
    {
      title: "NexTrade Field 1",
      dataIndex: "NexTradeField1",
      render: (data) => {
        return <div style={{ textAlign: "center" }}>{data ? data : "-"}</div>;
      },
    },
    {
      title: "NexTrade Field 1 Value",
      dataIndex: "NexTradeField1_Value",
      render: (data) => (
        <div style={{ textAlign: "center" }}>
          {data && data.length
            ? data.map((d, index) => (
                <span key={index}>
                  {d}
                  {index < data.length - 1 ? ", " : ""}
                </span>
              ))
            : "-"}
        </div>
      ),
    },
    {
      title: "NexTrade Field 2",
      dataIndex: "NexTradeField2",
      render: (data) => {
        return <div style={{ textAlign: "center" }}>{data ? data : "-"}</div>;
      },
    },
    {
      title: "NexTrade Field 2 Value",
      dataIndex: "NexTradeField2_Value",
      render: (data) => (
        <div style={{ textAlign: "center" }}>
          {data && data.length
            ? data.map((d, index) => (
                <span key={index}>
                  {d}
                  {index < data.length - 1 ? ", " : ""}
                </span>
              ))
            : "-"}
        </div>
      ),
    },
    {
      title: "NexTrade Field 3",
      dataIndex: "NexTradeField3",
      render: (data) => {
        return <div style={{ textAlign: "center" }}>{data ? data : "-"}</div>;
      },
    },
    {
      title: "NexTrade Field 3 Value",
      dataIndex: "NexTradeField3_Value",
      render: (data) => (
        <div style={{ textAlign: "center" }}>
          {data && data.length
            ? data.map((d, index) => (
                <span key={index}>
                  {d}
                  {index < data.length - 1 ? ", " : ""}
                </span>
              ))
            : "-"}
        </div>
      ),
    },
    {
      title: "NexTrade Field 4",
      dataIndex: "NexTradeField4",
      render: (data) => {
        return <div style={{ textAlign: "center" }}> {data ? data : "-"}</div>;
      },
    },
    {
      title: "NexTrade Field 4 Value",
      dataIndex: "NexTradeField4_Value",
      render: (data) => (
        <div style={{ textAlign: "center" }}>
          {data && data.length
            ? data.map((d, index) => (
                <span key={index}>
                  {d}
                  {index < data.length - 1 ? ", " : ""}
                </span>
              ))
            : "-"}
        </div>
      ),
    },
    {
      title: "NexTrade Field 5",
      dataIndex: "NexTradeField5",
      render: (data) => {
        return <div style={{ textAlign: "center" }}>{data ? data : "-"}</div>;
      },
    },
    {
      title: "NexTrade Field 5 Value",
      dataIndex: "NexTradeField5_Value",
      render: (data) => (
        <div style={{ textAlign: "center" }}>
          {data && data.length
            ? data.map((d, index) => (
                <span key={index}>
                  {d}
                  {index < data.length - 1 ? ", " : ""}
                </span>
              ))
            : "-"}
        </div>
      ),
    },
    {
      title: "Last Updated",
      dataIndex: "LastUpdated",
      sorter: (a, b) => {
        const fromDate = new Date(b.LastUpdated);
        const toDate = new Date(a.LastUpdated);
        return fromDate.getTime() - toDate.getTime();
      },
      render: (dateString) => {
        return (
          <div style={{ whiteSpace: "nowrap", textAlign: "center" }}>
            {dateString ? formatDate(dateString) : "-"}
          </div>
        );
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Last Updated By",
      dataIndex: "LastUpdatedBy",
      filterSearch: true,
      filters: mappings
        ? mappings
            .map((mapping) => ({
              text: mapping.LastUpdatedBy,
              value: mapping.LastUpdatedBy,
            }))
            .filter((item) => item.text)
            .filter(
              (item, index, array) =>
                array.findIndex((obj) => obj.text === item.text) === index,
            )
        : [],
      onFilter: (value, record) => record.LastUpdatedBy === value,
      render: (data) => {
        return <div style={{ textAlign: "center" }}>{data ? data : "-"}</div>;
      },
    },
    {
      title: "Created By",
      dataIndex: "CreatedBy",
      filterSearch: true,
      filters: mappings
        ? mappings
            .map((mapping) => ({
              text: mapping.CreatedBy,
              value: mapping.CreatedBy,
            }))
            .filter(
              (item, index, array) =>
                array.findIndex((obj) => obj.text === item.text) === index,
            )
        : [],
      onFilter: (value, record) => record.CreatedBy === value,
      render: (data) => {
        return <div style={{ textAlign: "center" }}>{data ? data : "-"}</div>;
      },
    },
    {
      title: "Status",
      dataIndex: "Status",
      filterSearch: true,
      filters: [
        {
          text: "Enabled",
          value: "Enabled",
        },
        {
          text: "Disabled",
          value: "Disabled",
        },
      ], // filteredValue: mappings.Status || null,
      onFilter: (value, record) => record.Status === value,
      render: (data) => {
        return <div style={{ textAlign: "center" }}>{data ? data : "-"}</div>;
      },
    },
  ];
  columns.push({
    title: "Actions",
    dataIndex: "",
    key: "x",
    render: (_, record) => {
      return (
        <div style={{ whiteSpace: "nowrap" }}>
          <Icon
            icon="eye-open"
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push(`/stp-mapping-console/${record.RuleID}`);
            }}
          />
          <Icon
            icon="edit"
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push(`/stp-mapping-console/update/${record.RuleID}`);
            }}
          />
        </div>
      );
    },
  });

  // Renders the STP Mapping table
  return (
    <div className="rtcmd-stp-mapping-table">
      <Table
        locale={{
          emptyText: "No STP Mapping to display",
        }}
        loading={
          loading
            ? {
                delay: 1500,
                indicator: <Spinner size={32} />,
              }
            : false
        }
        scroll={{
          x: mappings && mappings.length > 0,
        }}
        size="small"
        expandIconColumnIndex={13}
        columns={columns}
        dataSource={mappings ? mappings.sort() : []}
        tableLayout="auto"
        onChange={(pagination) => {
          history.push({
            pathname: window.location.pathname,
            search: `?page=${pagination.current}`,
          });
        }}
        pagination={{
          current: Number(queryParams.get("page")) || 1,
          pageSize: 20,
          itemRender: renderItem,
          showSizeChanger: false,
        }}
      />
    </div>
  );
};

const STPMapping = ({ data }) => {
  return (
    <TableInterAction
      data={data.sort((a, b) => {
        if (a.Status === "Disabled" && b.Status !== "Disabled") return 1;
        if (a.Status !== "Disabled" && b.Status === "Disabled") return -1;
        return b.RuleID - a.RuleID;
      })}
    />
  );
};

export default STPMapping;
