import React, {useEffect, useState, useContext, createContext} from "react";
import useAxios from "axios-hooks";
import {useParams, useHistory} from "react-router-dom";
import {Button} from "rt-design-system-backup";

import {url, getCSRF, parseDateInTheTimezone, resetToMidnight} from "../../../helpers";

import RequestViewContext from "../../RequestContext";
import RequestOverview from "../../RequestOverview";
import RequestMaturityPrices from "../../RequestMaturityPrices";
import RequestPricingInstructions from "../../RequestPricingInstructions";
import RequestPlantDetails from "../../RequestPlantDetails";
import RequestProductDetails from "../../RequestProductDetails";
import RequestVWP from "../../RequestVWP";
import {FormFooter} from "../../Form";
import Toaster from "../../Toaster";
import SocketContext from "../../SocketContext";
import RequestAdditional from "../../RequestAdditional"


import useLogin from "../../useLogin"
import Page from "../../Page";
import {Divider} from "antd";

const defaultFillRequestContext = {
    enabled: false,
    invalidate: (key) => {
        console.warn("No invalidate listener in place");
    },
    deregister: (key) => {
        console.warn("No deregister listener in place");
    },
    validate: (key) => {
        console.warn("No validate listener in place");
    },
};

export const FillRequestContext = createContext(defaultFillRequestContext);


  
const dateFormat = {
    formatDate: (date) => {
      if (date === "null") return "";
  
      const y = "" + date.getFullYear();
      const m = "" + (date.getMonth() + 1);
      const d = "" + date.getDate();
  
      return d.padStart(2, "0") + "/" + m.padStart(2, "0") + "/" + y;
    },
    parseDate: (str) => {
      const [d, m, y] = str.split(",")[0].split("/");
      return new Date(`${m}/${d}/${y}`);
    }, //Date class always expects date in this format we need to adjust it.
  };

  const formatDateForAPI = (date) => {
    if (!date) return null;

    const y = "" + date.getFullYear();
    const m = "" + (date.getMonth() + 1);
    const d = "" + date.getDate();

    return y + "-" + m.padStart(2, "0") + "-" + d.padStart(2, "0");
}
  

const FillRequest = ({editRequest = false}) => {
    const [date, setDate] = useState(new Date());
    const today = new Date();
    const {requestId} = useParams();
    const [newCustomer, setNewCustomer] = useState(null);
    const [newSalesEntity, setNewSalesEntity] = useState(null);
    const [pricingPeriod, setPricingPeriod] = useState({ start: null, end: null });

    const handlePricingPeriodChange = (start, end) => {
        setPricingPeriod({ start, end });
    };


    useLogin(true)

    const [{data: remoteRequest, loading}, refetch] = useAxios({
        url: url("/request/" + requestId + "/"),
    });

    const [{data: customersData, customersLoading},] = useAxios({
        url: url("/customers/"),
    });

    const [{data: salesEntityData, salesEntityLoading},] = useAxios({
        url: url("/sales_entity/"),
    });

    const socket = useContext(SocketContext);

    useEffect(() => {
        const refetcher = (req) => {
            if (!req || req.detail.id === requestId) {
                refetch();
            }
        };

        refetcher();
        socket.addEventListener("request_fill", refetcher);
        socket.addEventListener("request_update", refetcher);
        socket.addEventListener("request_cancel", refetcher);

        return () => {
            socket.removeEventListener("request_fill", refetcher);
            socket.removeEventListener("request_update", refetcher);
            socket.removeEventListener("request_cancel", refetcher);
        };
    }, [socket, refetch, requestId]);

    const [request, setRequest] = useState(false);

    useEffect(() => {
        if (remoteRequest && remoteRequest.request_maturities && remoteRequest.request_maturities.length > 0) {
            // Get the last request maturity
            const lastRequestMaturity = remoteRequest.request_maturities[remoteRequest.request_maturities.length - 1];
    
            // Check if it has trades and they are not empty
            if (lastRequestMaturity.trades && lastRequestMaturity.trades.length > 0) {
                // Get the last trade
                const lastTrade = lastRequestMaturity.trades[lastRequestMaturity.trades.length - 1];
                
                if (lastTrade.filled_at) {
                    setDate(new Date(lastTrade.filled_at));
                }
            }
        }
    
        setRequest(remoteRequest);
    }, [remoteRequest]);
    const history = useHistory();

    const goBack = () => {
        history.goBack()
    }

    const update = (update, tradeId) => {
        setRequest((request) => {
            if (tradeId) {
                const requestMaturities = request.request_maturities.slice();
                const updateIndex = requestMaturities.findIndex(maturity => maturity.trades.find(tr => tr.id === tradeId))
                const tradeIndex = requestMaturities[updateIndex].trades.findIndex(tr => tr.id === tradeId)
                const updateValue = parseInt(update['qt_id'], 10)
                const newRequestMaturities = requestMaturities.map(
                    (requestMaturity, index) => {
                        const requestTrades = requestMaturity.trades.slice();

                        if (updateIndex === 0 && 'qt_id' in update) update['qt_id'] = (updateValue + (index * 2)).toString()

                        const trades = requestTrades.map((trade, i) => {
                            if (updateIndex === 0 && 'qt_id' in update && i === tradeIndex) {
                                return {
                                    ...trade,
                                    ...update,
                                };
                            } else if (trade.id === tradeId) {
                                return {
                                    ...trade,
                                    ...update,
                                };
                            }
                            return trade;
                        });

                        return {
                            ...requestMaturity,
                            trades,
                        };
                    }
                );

                return {
                    ...request,
                    request_maturities: newRequestMaturities,
                };
            } else {
                return {
                    ...request,
                    ...update,
                };
            }
        });
    };

    const [{loading: fillLoading}, fillOrder] = useAxios(
        {
            url: url("/request/" + (request && request.id) + "/"),
            method: "PUT",
            headers: {
                "X-CSRFToken": getCSRF(),
            },
        },
        {
            manual: true,
        }
    );

    const submit = async () => {
        const trades = [];
        const removeUnecessaryComma = (x) => {
            if (!x) return x;
            if (typeof x === 'string') {
                return x.replaceAll(',', '')
            }
            return x
        }

        request.request_maturities.forEach((requestMaturity) => {
            requestMaturity.trades.forEach((trade) => {
                trades.push({
                    id: trade.id,
                    price: trade.price ? `${parseFloat(removeUnecessaryComma(trade.price)).toFixed(2)}`
                        : ["Quote", "At Market"].includes(request.request_type) && trade.compute_mw_premium
                            ? `${parseFloat(removeUnecessaryComma(trade.compute_mw_premium)).toFixed(2)}` : null,
                    qt_id: trade.qt_id ? `${parseInt(removeUnecessaryComma(trade.qt_id))}` : null,
                    forward_rate: trade.forward_rate
                        ? `${parseFloat(removeUnecessaryComma(trade.forward_rate)).toFixed(5)}`
                        : null,
                });
            });
        });

        let data = {
            comments: request.comments,
            lme_basis: request.lme_basis
                ? `${parseFloat(removeUnecessaryComma(request.lme_basis)).toFixed(2)}`
                : null,
            spot_exchange_rate: request.spot_exchange_rate
                ? `${parseFloat(removeUnecessaryComma(request.spot_exchange_rate)).toFixed(5)}`
                : null,
            trades,
            edit_fill: editRequest,
            filled_date: resetToMidnight(parseDateInTheTimezone(date)),
            pricing_period_start_date: pricingPeriod.start ? formatDateForAPI(pricingPeriod.start) : null,
            pricing_period_end_date: pricingPeriod.end ? formatDateForAPI(pricingPeriod.end) : null,
        }

        if (newSalesEntity) {
            data = {...data, sales_entity: newSalesEntity}
        }
        if (newCustomer) {
            data = {...data, customer: newCustomer}
        }

        try {
            const response = await fillOrder({
                data: data,
            });

            console.log(response.data)
            Toaster.show({
                message: editRequest ? "The request has been edited successfully." : "Request successfully filled. ",
                icon: "small-tick",
                intent: "success",
            });
            goBack()
        } catch (err) {
            Toaster.show({
                message: editRequest ? "An error occurred while editing the request." : "An error occurred while filling the request. ",
                icon: "warning-sign",
                intent: "danger",
            });
        }
    };

    const [invalidInputs, setInvalidInputs] = useState([]);

    const validate = (key) => {
        if (invalidInputs.indexOf(key) < 0) {
            return;
        }

        setInvalidInputs((invalidInputs) => {
            if (invalidInputs.indexOf(key) >= 0) {
                return [
                    ...invalidInputs.filter((invalidInputKey) => invalidInputKey !== key),
                ];
            } else {
                return invalidInputs;
            }
        });
    };

    const invalidate = (key) => {
        if (invalidInputs.indexOf(key) >= 0) return;

        setInvalidInputs((invalidInputs) => {
            if (invalidInputs.indexOf(key) >= 0) {
                return invalidInputs;
            } else {
                return [...invalidInputs, key];
            }
        });
    };
    const register = () => {
    };
    const deregister = validate;

    const isInvalid = invalidInputs.length;

    const additional = loading ? null : <RequestAdditional editable request={request}/>

    return (
        <RequestViewContext.Provider
            value={{
                // TODO: disable the refetch process
                refetch,
                /** Update the currently viewed request's context.
                 * @param update Object containing key value pairs of the itentity to udpate
                 * @param tradeId (Optional) Identifier of the trade to update
                 */
                update,
            }}
        >
            <Page
                title={editRequest ? "Edit request" : "Fill request"}
                tabs={[]}
            >
                <FillRequestContext.Provider
                    value={{
                        ...defaultFillRequestContext,
                        enabled: true,
                        validate,
                        invalidate,
                        deregister,
                        register,
                    }}
                >
                    <hr/>
                    {!(loading && customersLoading && salesEntityLoading && !request) && request ? (
                        <>
                           <RequestOverview
                                request={request}
                                editable={request.status === "For correction"}
                                customers={customersData}
                                salesEntity={salesEntityData}
                                setSalesEntity={setNewSalesEntity}
                                setCustomer={setNewCustomer}
                                date={date}
                                setDate={setDate}
                                dateFormat={dateFormat}
                                today={today}
                                isViewing={false}
                            />
                            <hr/>
                            <RequestMaturityPrices request={request} editable/>
                            
                            
                            {request.plant_details.length !== 0 && (
                                <>
                                    <Divider/>
                                    <RequestPlantDetails request={request}/>
                                </>
                            )}
                            {request.product_details && request.product_details.length !==0 && (
                                <>
                                    <RequestProductDetails request={request}/>
                                </>
                            )}
                            <RequestVWP request={request} editable={request.request_type !== 'Auto Quote'}/>
                            <Divider/>
                            <RequestPricingInstructions request={request} editable={ request.status === "Open"}
                            onPricingPeriodChange={handlePricingPeriodChange}/>    
                            {additional ? <>
                                <hr/>
                                {additional}
                            </> : null}
                            <FormFooter>
                                <Button
                                    onClick={() => {
                                        goBack()
                                    }}
                                    text="Cancel"
                                    minimal
                                    large
                                />
                                <Button
                                    disabled={isInvalid}
                                    large
                                    loading={fillLoading}
                                    type="submit"
                                    text={editRequest ? request.status === "For correction" ? "Save edits" : "Edit request" : "Fill request"}
                                    onClick={async () => {
                                        await submit();
                                    }}
                                    intent="primary"
                                />
                            </FormFooter>
                        </>
                    ) : null}
                    <div style={{height: "32px"}}></div>
                </FillRequestContext.Provider>
            </Page>
        </RequestViewContext.Provider>
    );
};

export default FillRequest;
