import React from "react";
import {Col, Row} from "antd";
import {Button, Icon} from "rt-design-system-backup";
import BrokerPriceTable from "../../../BrokerPriceTable";
import {useParams} from "react-router-dom";
import useAxios from "axios-hooks";
import {url} from "../../../helpers";
import { Overlay } from "@blueprintjs/core";
import LoadingIcon from "../../Icons/loading";
import {InputGroup} from "../../Form";
import {LayoutContext} from "../../Layout";

function BrokerPriceDashboard() {
    const {routeId} = useParams();

    const [{ loading: brokerLoading, data: brokers = [] }] = useAxios({
      url: url(`broker/`),
    });

    const [{ loading: maturityLoading, data: maturities = [] }] = useAxios({
      url: url(`/maturity/?display_only&marine_maturities=true`),
    });
    const [{ loading: routeLoading, data: routes = [] }] = useAxios({
      url: url(`/route/?freight_routes_only=true`),
    });

    const updateRoute = (e) => {
        if (e[0].value !== parseInt(routeId)) {
            window.location.href =`/order/broker/${e[0].value}/dashboard/`;
        }
    }

    const loading = brokerLoading || maturityLoading || routeLoading;
    return (
        loading === false ? (
          <div style={{margin: '-24px'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <p style={{marginLeft: '32px', fontWeight: 700, fontSize:26, color: 'black', flexGrow: 1}}>Broker Prices</p>
                <InputGroup
                    required
                    name="route"
                    type="select"
                    formStyle={{margin: 0, marginRight: 20}}
                    items={routes?.map((route) => {
                      return {
                        name: route.route,
                        value: route.id,
                      };
                    })}
                    onChange={(e) => {updateRoute(e)}}
                    placeholder={'Select one'}
                    defaultValue={[routes?.find((r) => r.id === parseInt(routeId))?.id]}
                />
                <LayoutContext.Consumer>
                  {({ setLayoutContext }) => {
                    return (
                      <Button
                        onClick={() =>
                          setLayoutContext({
                            isFFADrawerOpen: true,
                          })
                        }
                        intent="primary"
                        minimal
                        rightIcon={<Icon icon="arrow-right" />}
                      >
                        View FFA Activity
                      </Button>
                    );
                  }}
                </LayoutContext.Consumer>
            </div>
            <Row style={{marginBottom: '74px'}}>
                {brokers?.filter((brokerObject) => !['INTERNAL BROKER', 'Marex', 'Morgan Stanley', 'JP Morgan', 'Goldman Sachs', 'Derived', 'API'].includes(brokerObject.name)).map((broker) => {
                  return (
                   <Col sm={24} md={12} xl={6} key={broker.name} style={{ marginBottom: 8}}>
                     <BrokerPriceTable
                         broker={broker}
                         routeId={routeId}
                         maturities={maturities}
                     />
                   </Col>
                  )
                  })
                }
            </Row>
          </div>
        ): (
          <Overlay isOpen={loading}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 5 }}>
              <LoadingIcon /><h4>Loading Data</h4>
            </div>
          </Overlay>
        )
    );
}
export default BrokerPriceDashboard;
