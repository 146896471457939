import { createContext } from "react"

const AppContext = createContext({
    refreshUser: async () => {
        console.warn('No refresh user handler has been specified.')
    },
    user : null
});

export default AppContext
