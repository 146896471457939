import axios from "axios";
import {deepClone, url} from "../../helpers";

const MAX_DDP_FIELDS = 5;
const MAX_DDP_VALUES = 10;

const FORM_TYPE = Object.freeze({
    STATIC: "static",
    DYNAMIC: "dynamic",
});

// Default field interfaces to display field and values inputs in the form
const valueInterface = {
    title: "",
    value: "",
};

const fieldInterface = {
    field: {
        title: "",
        value: "",
    },
    values: [valueInterface],
};

// In edit mode it extracts only DDP data from existing data
// It returns the length of the DDP data
const extractNexTradeData = (data) => {
    if (data) {
        const filtered = Object.keys(data)
            .filter((key) => key.startsWith("NexTradeField"))
            .reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
            }, {});
        return {
            data: filtered,
            length: Object.keys(filtered).length,
        };
    } else {
        return {
            data: undefined,
            length: 0,
        };
    }
};

// Check if the system column value is included in NexTrade Field
const isNexTradeValue = (NexTradeFields, SystemColumnValue) => {
    return NexTradeFields.findIndex((d) => d[0] === SystemColumnValue) >= 0;
};

// Removes already selected fields from the dropdown options
const filterAlreadySelectedNexTradeFields = (data, ctxData) => {

    const keys = Object.keys(ctxData).filter(
        (key) => key.startsWith("NexTradeField") && !key.includes("Value")
    );

    const selectedValues = keys.map((key) => {
        // Check that ctxData[key] is an array and has at least one element before accessing its 'value' property
        if (
            Array.isArray(ctxData[key]) &&
            ctxData[key].length > 0 &&
            ctxData[key][0]?.value
        ) {

            return ctxData[key][0].value.toLowerCase();
        }
        return "";
    });
    return data.filter((item) => {
        return !selectedValues.includes(item[1].toLowerCase())
    });
};
// Removes already selected values from the dropdown options
const filterAlreadySelectedNexTradeFieldsValue = (data, ctxData, NexTradeFieldNum) => {
    const keys = Object.keys(ctxData).filter(
        (key) => key.startsWith(`NexTradeField${NexTradeFieldNum}`) && key.includes("Value")
    );
    if (keys.length) {
        return [
            ...data.filter(
                (item) => !keys.some((key) => ctxData[key][0]?.value === item?.value)
            ),
        ];
    }
    return deepClone(data);
};

// In edit mode it reconstructs the NexTrade Fields template according to the data
// It checks the number of values for each field and then adds the required number of values
// It also checks if the number of fields is less than the maximum limit and then adds the required number of fields
const reconstructInitialTemplate = (data, IField, IValue) => {
    const interfaceStruct = Object.keys(data).filter(
        (d) => !d.includes("_Value")
    );
    const initState = interfaceStruct.map((_) =>
        JSON.parse(JSON.stringify(IField))
    );
    initState.forEach((dta, i) => {
        const count =
            data[`${interfaceStruct[i]}_Value`] &&
            data[`${interfaceStruct[i]}_Value`].length;
        for (let i = 1; i < count; i++) {
            dta.values.push(IValue);
        }
    });
    return initState;
};

// Transform dropdown data coming from backend into the required format for dropdown options
const dropdownDataTransformer = (dataArr) =>
    dataArr ? dataArr?.map(([name, value]) => ({name, value: name})) : [];

// Extract NexTrade Fields data from the submitted form data and then reform it according to final format to submit
// The final format is an array of objects, each object contains the NexTrade Field and its possible values
const extractAndTransformFinalNexTradeData = (input) => {
    let output = [];

    // Extract NexTrade Fields data from the submitted form data
    for (let key in input) {
        if (
            input.hasOwnProperty(key) &&
            key.startsWith("NexTradeField") &&
            !key.includes("_Value")
        ) {
            let fieldIndex = parseInt(key.replace("NexTradeField", "").split("_")[0]);
            let maxValueIndex = 0;
            // Find the maximum value index for the current field
            for (let subKey in input) {
                if (
                    input.hasOwnProperty(subKey) &&
                    subKey.startsWith(`NexTradeField${fieldIndex}_Value`)
                ) {
                    let valueIndex = parseInt(
                        subKey.replace(`NexTradeField${fieldIndex}_Value`, "")
                    );
                    if (valueIndex > maxValueIndex) {
                        maxValueIndex = valueIndex;
                    }
                }
            }
            // Create a new object for the current field
            let newObj = {
                ddp_field: input[key][0].value,
                possible_values: [],
            };
            // Add all possible values for the current field
            for (let i = 1; i <= maxValueIndex; i++) {
                let valueKey = `NexTradeField${fieldIndex}_Value${i}`;
                if (input[valueKey]) {
                    newObj.possible_values.push({
                        possible_value: input[valueKey][0].value,
                    });
                }
            }

            output.push(newObj);
        }
    }

    return output;
};

// Check if the column name included in enum system column names
const isEnumSystemColumnName = (columnName, enumSystemNames) => {
    return enumSystemNames.includes(columnName)
};

// Async function to fetch system column names for a given system ID.
const fetchSystemColumnNames = async (systemId) => {
    const response = await axios.get(url(`mapping_system_columns/`));
    return response.data;
};

// Async function to fetch enum system column names for a given system ID.
const fetchEnumSystemColumnNames = async (systemId) => {
    const response = await axios.get(url(`mapping_system_columns/`));
    return response.data.filter(column => column.values.length > 0).map(column => column.id);
};

// Async function to fetch specific system column values for a given system ID and column name.
const fetchSpecificSystemColumnValues = async (systemId, columnName) => {
    const ids = (await axios.get(
        url(`mapping_system_columns/${columnName}/`)
    )).data.values;
    const values = (await axios.get(
        url(`mapping_system_column_values/`)
    )).data.filter(value => ids.includes(value.id));
    return values;
};


// Async function to fetch NexTrade Field values for a given field value.
const fetchNexTradeFieldValues = async (fieldVal) => {
    const response = await axios.get(url(`ddp_fields/${fieldVal}/values/`));
    return response.data;
};

const removeItemsWithPrefix = (obj, prefix) => {
    // Create a copy of the object to avoid mutating the original one
    let filteredObj = {...obj};

    // Iterate over the object keys
    for (let key in filteredObj) {
        // Check if the key starts with the specified prefix
        if (key.startsWith(prefix)) {
            // Delete the key-value pair from the object
            delete filteredObj[key];
        }
    }

    // Return the filtered object
    return filteredObj;
}


  //Sorts an array of objects alphabetically by a given key. Handles undefined and non-string values safely.
  const sortArrayByStringKey = (array, key) => {
    if (!Array.isArray(array)) {
      console.error("The first argument must be an array.");
      return [];
    }

    return array.slice().sort((a, b) => {
      // Handle potential undefined or null values safely
      const itemA = a && a[key] ? String(a[key]) : '';
      const itemB = b && b[key] ? String(b[key]) : '';

      // Use localeCompare for string comparison
      return itemA.localeCompare(itemB);
    });
  };



// Export constants and functions for use in other components or modules.
export {
    MAX_DDP_FIELDS,
    MAX_DDP_VALUES,
    FORM_TYPE,
    valueInterface,
    fieldInterface,
    extractNexTradeData,
    isNexTradeValue,
    filterAlreadySelectedNexTradeFields,
    filterAlreadySelectedNexTradeFieldsValue,
    reconstructInitialTemplate,
    dropdownDataTransformer,
    extractAndTransformFinalNexTradeData,
    isEnumSystemColumnName,
    fetchSystemColumnNames,
    fetchEnumSystemColumnNames,
    fetchSpecificSystemColumnValues,
    fetchNexTradeFieldValues,
    sortArrayByStringKey,
    removeItemsWithPrefix
};
