import React, {useContext, useEffect, useState} from "react";
import SocketContext from "../../SocketContext";
import useAxios from "axios-hooks";
import {divLoader, getCustomShortcuts, url} from "../../../helpers";
import {Card} from "rt-design-system-backup";
import {InputGroup} from "../../Form";
import { Overlay } from "@blueprintjs/core";
import 'chartjs-adapter-date-fns';
import LoadingIcon from "../../Icons/loading";
import { loadingModelCSS } from "../NewRequest";
import TradingViewHistoricalChart from "./HistoricalChart";
import MarineContext from "../../MarineContext";
import {MARINE_CARD_BACKGROUND_COLOR, LAST_COLORS, OFFER_COLORS, BID_COLORS} from "../../../helpers/constants";
import { getComponentHeight } from "../../../helpers";
import { dateFormat } from "../Maturities";
import {DateRangeInput} from "rt-design-system-backup";
import "./style.scss"

const HistoricalFFADashboard = ({
  defaultWindowHeight = null, relativeComponentId = 'FFAGraphComponent', 
  defaultRoute = null, defaultMaturity = null, defaultOfferType = 'last', defaultTime = 7, parentComponent = null }) => {
    const [routes, setRoutes] = useState([])
    const [maturities, setMaturities] = useState([])
    const [route, setRoute] = useState(defaultRoute !== null ? defaultRoute : null);
    const [maturity, setMaturity] = useState(defaultMaturity !== null ? defaultMaturity : null);
    const [offerType, setOfferType] = useState(defaultOfferType !== null ? defaultOfferType : 'last');
    const [routeType, setRouteType] = useState('FFA');
    const [time, setTime] = useState(defaultTime !== null ? defaultTime : 1);
    const [offers, setOffers] = useState([]);
    const [chartColors, setChartColors] = useState(LAST_COLORS);
    const [windowHeight, setWindowHeight] = useState(defaultWindowHeight !== null ? defaultWindowHeight : window.innerHeight);
    const [startDate, setStartDate] = useState(getLastWeek());
    const [endDate, setEndDate] = useState(new Date());

    function getLastWeek() {
      var today = new Date();
      var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
      return lastWeek;
    }

    const handleDateChange = (data) => {
      const start = data[0] || new Date()
      const end = data[1] || new Date()
  
      setStartDate(start)
      setEndDate(end)
    }
    const timeData = [
        {text: '1 Day', value: 1},
        {text: '1 Week', value: 7},
        {text: '1 Month', value: 30},
        {text: '1 Year', value: 365},
        {text: 'All', value: -1},
    ]
    const socket = useContext(SocketContext);

    const [{ data: routesData = null }] = useAxios({
      url: url(`/route/`),
    });

    const [{ data: maturitiesData = null }] = useAxios({
      url: url(`/maturity/?historical_maturities=true`),
    });

    useEffect(() => {
        if (!maturitiesData) return;
        setMaturities(maturitiesData)
    }, [maturitiesData])


    useEffect(() => {
        if (!routesData) return;
        const routesNeedsToRemoved = ['COA Comdty', 'CLA Comdty', 'C5', 'Sing VLSFO', 'Rott VLSFO']
        const filteredRoutes = routesData.filter((routeObject) => {
          if(routesNeedsToRemoved.includes(routeObject.route)) return false
          else return true
        })
        setRoutes(filteredRoutes)
    }, [routesData])


    useEffect(() => {
        if (routes.length && route === undefined) {
            if (defaultRoute !== null) {
              setRoute(defaultRoute);
              setRouteType(getRouteType(defaultRoute))
            }
            else {
              const dRoute = routes.find(r => r.route === 'Cape 5TC')
              setRoute(dRoute.id);
              setRouteType(getRouteType(dRoute.id))
            }
        }
        if (maturities.length && maturity === undefined) {
            if (defaultMaturity !== null) setMaturity(defaultMaturity);
            else {
              const dMaturity = getDefaultMaturity();
              setMaturity(dMaturity)
            }
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maturity, route, routes, maturities])

    const getDateRangeAndTimeQueryParams = () => {
      return `time_interval=${parentComponent === 'BunkerDashboard' ? time : null}&start_date=${parentComponent !== 'BunkerDashboard' ? dateFormat.formatDate(startDate) : null}&end_date=${parentComponent !== 'BunkerDashboard' ? dateFormat.formatDate(endDate) : null}`
    }
    const [{ data: chartData = null, loading }, refetch] = useAxios({
      url: url(`historical_offer/?maturity=${maturity}&route=${route}&type=${offerType}&${getDateRangeAndTimeQueryParams()}`),
    });

    useEffect(() => {
        if (refetch) refetch();
        if (offerType === 'last') {
            setChartColors(LAST_COLORS);
        } else if (offerType === 'offer') {
            setChartColors(OFFER_COLORS);
        } else if (offerType === 'bid') {
            setChartColors(BID_COLORS);
        }
    }, [maturity, route, offerType, refetch, time]);
    const getLocalTime = (time) => {
        const d = new Date(time);
        return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds()) / 1000;
    }
    useEffect(() => {
        if(!chartData) return;
        if(chartData['offers']){
          setOffers(chartData['offers'].map(obj => ({...obj, time: getLocalTime(obj.time)})));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartData])

    useEffect(() => {
        const updateOffers = (data) => {
          const offer = data.detail.chartData;
          if (offer.route === route && offer.maturity === maturity) {
              if(offer.type === offerType) {
                 offer.time = getLocalTime(offer.date);
                 setOffers([...offers, offer]);
              }
          }
        }
        socket.addEventListener("offer_update", updateOffers);
        return () => socket.removeEventListener("offer_update", updateOffers)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offers])

  useEffect(()=> {
    const bunkerOfferUpdate = (data) => {
        const routeID = data.detail.route
        const tempoffers = data.detail.offers;
        if (routeID.includes(route)) {
            const chartOffers = tempoffers.map(({ chartData }) => chartData);
            let offersArray = []
            chartOffers.map((offer)=> {
                if (offer.maturity === maturity && offer.type === offerType && offer.route === route) {
                    offer.time = getLocalTime(offer.time);
                    offersArray.push(offer)
                }
                return offer;
            })
            setOffers(
                [...offers, ...offersArray]
                .filter((obj, index, self) =>
                    index === self.findIndex((o) => (
                        o.time === obj.time
                    ))
                )
            );
        }
    }
    socket.addEventListener("bunker_offer_update", bunkerOfferUpdate);
    return () => socket.removeEventListener("bunker_offer_update", bunkerOfferUpdate)
  })

  const {setShowImage, setMainPadding} = useContext(MarineContext);
  const handleWindowResize = () => {
      setWindowHeight(defaultWindowHeight !== null ? getComponentHeight(relativeComponentId) : window.innerHeight);
  }

  useEffect(()=> {
      handleWindowResize()
      setShowImage(true);
      setMainPadding('8px');
      const resizeEvent = window.addEventListener('resize', handleWindowResize)
      return () => {
          setShowImage(false);
          setMainPadding('');
          window.removeEventListener('resize', resizeEvent);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const deductHeight = defaultWindowHeight !== null ? 0 : 90

  const getDefaultRoute = () => {
    const dRoute = routes.find(r => r.route === 'Cape 5TC')
    return dRoute ? dRoute.id : routes[0].id
  }

  const monthsDict = {
    0: 'jan',
    1: 'feb',
    2: 'mar',
    3: 'apr',
    4: 'may',
    5: 'jun',
    6: 'jul',
    7: 'aug',
    8: 'sep',
    9: 'oct',
    10: 'nov',
    11: 'dec'
  }

  const getDefaultMaturity = () => {
    const d = new Date()
    const dMaturity = maturities.find(
      m => m.type === "month" && m.value === monthsDict[d.getMonth() + 1] && m.year === d.getFullYear() + "-01-01"
    )
    
    return dMaturity ? dMaturity.string : maturities[0].string

  }

  const getRouteType = (tempRoute) => {
    if (!tempRoute) return 'FFA'

    const selectedRoute = routes.find(r => r.id === tempRoute)
    if (selectedRoute.type === 'Bunker') return 'Bunker'
    return 'FFA'
  }

  const changeRoute = (routeId) => {
    setRoute(routeId);
    const tempRouteType = getRouteType(routeId)
    if (routeType === 'FFA' && tempRouteType === 'Bunker' && offerType === 'last') {
      setOfferType('bid');
    }
    setRouteType(tempRouteType);
  }

  if ((parentComponent === 'BunkerDashboard' || parentComponent === 'FFADashboard') && loading === true) {
    return (
      <MarineContext.Consumer>
      {({isDarkModeOn}) => (
        routes.length !== 0 && maturities.length !== 0 && (
       <div style={{height: `${windowHeight - deductHeight}px`}}>
       <Card elevation={1} style={{height: `${windowHeight - deductHeight}px`, background: isDarkModeOn ? MARINE_CARD_BACKGROUND_COLOR : ''}}>
          <div style={{ justifyContent: 'center', paddingTop: `${(windowHeight - deductHeight - 30)/2}px`, display: 'flex'}}>
            <LoadingIcon />
            <h4 style={{color: isDarkModeOn ? 'white' : 'black'}}>Loading Data</h4>
          </div>
        </Card>
        </div>
      ))}
    </MarineContext.Consumer>
    )
  }

    return (
      <MarineContext.Consumer>
      {({isDarkModeOn}) => (
        routes.length !== 0 && maturities.length !== 0 && (
       <div style={{height: `${windowHeight - deductHeight}px`}}>
       <Card elevation={1} style={{height: `${windowHeight - deductHeight}px`, background: isDarkModeOn ? MARINE_CARD_BACKGROUND_COLOR : ''}}>
        {(parentComponent === 'BunkerDashboard' || parentComponent === 'FFADashboard') && loading === true ? divLoader(windowHeight, deductHeight, isDarkModeOn)
        : (
         <div style={{paddingBottom: 10, display: (parentComponent === 'BunkerDashboard' || parentComponent === 'FFADashboard') && loading === true ? 'none': ''}}>
            <div style={{display: 'flex', alignItems: 'flex-end'}}>
                <InputGroup
                    required
                    name="route"
                    type="select"
                    formStyle={{margin: 0, marginRight: 20, }}
                    items={(routes.length ? routes : []).map((route) => {
                      return {
                        name: route.route,
                        value: route.id,
                      };
                    })}
                    onChange={(e) => {changeRoute(e[0].value)}}
                    placeholder={'Select Route'}
                    defaultValue={defaultRoute !== null ? route !== null ? [route] : [defaultRoute] : [getDefaultRoute()]}
                />
                <InputGroup
                    required
                    name="maturity"
                    type="select"
                    formStyle={{margin: 0, marginRight: 20}}
                    items={(maturities.length ? maturities : []).map((maturity) => {
                      return {
                        name: maturity.shortened_string.replace(/\s+/g,'').toUpperCase(),
                        value: maturity.string,
                      };
                    })}
                    onChange={(e) => {setMaturity(e[0].value)}}
                    placeholder={'Select Maturity'}
                    defaultValue={defaultMaturity !== null ? maturity !== null ? [maturity] : [defaultMaturity] : [getDefaultMaturity()]}
                />
                {parentComponent !== 'BunkerDashboard' ? (
                  <div className={isDarkModeOn ? "historical-daterange-picker historical_date_range_input" : "historical-daterange-picker"} style={{paddingRight: 40, display: "flex"}}>
                    <DateRangeInput
                      singleMonthOnly
                      allowSingleDayRange
                      {...dateFormat}
                      popoverProps={{ minimal: true }}
                      value={[startDate, endDate]}
                      onChange={handleDateChange}
                      shortcuts={getCustomShortcuts()}
                    />
                  </div>
                ): (
                  <InputGroup
                    required
                    name="time"
                    type="select"
                    formStyle={{margin: 0, marginRight: 20}}
                    items={timeData.map((row) => {
                      return {
                        name: row.text,
                        value: row.value,
                      };
                    })}
                    onChange={(e) => {setTime(e[0].value)}}
                    placeholder={'Select Time'}
                    defaultValue={defaultTime !== null ? time !== null ? [time] : [defaultTime] : [1]}
                  />
                )}
                <div style={{flexGrow: 1}}></div>
                {routeType === 'Bunker' ? (
                  <InputGroup
                    required
                    name="Bunker Offer Type"
                    key="Bunker Offer Type"
                    type="select"
                    formStyle={{margin: 0}}
                    items={['offer', 'bid'].map((row) => {
                      return {
                        name: row,
                        value: row,
                      };
                    })}
                    onChange={(e) => {setOfferType(e[0].value)}}
                    placeholder={'Select Offer Type'}
                    defaultValue={offerType !== null ? [offerType] : defaultOfferType !== null ? [defaultOfferType] :['bid']}
                />
                ) : (
                  <InputGroup
                    required
                    name="Offer Type"
                    type="select"
                    formStyle={{margin: 0}}
                    items={['offer', 'bid', 'last'].map((row) => {
                      return {
                        name: row,
                        value: row,
                      };
                    })}
                    onChange={(e) => {setOfferType(e[0].value)}}
                    placeholder={'Select Offer Type'}
                    defaultValue={offerType !== null ? [offerType] : defaultOfferType !== null ? [defaultOfferType] :['last']}
                  />
                )}
            </div>
            <TradingViewHistoricalChart offers={offers} isDarkModeOn={isDarkModeOn} chartColors={chartColors}
                                        windowHeight={windowHeight} defaultWindowHeight={defaultWindowHeight} />
         </div>
        )}
       </Card>
       <Overlay
              isOpen={loading === true && parentComponent === null}
              className={loadingModelCSS}
              >
              <div style={{ top: '50%', left: '45%', position: 'fixed', display: 'flex'}}>
                <LoadingIcon />
                <h4>Loading Data</h4>
              </div>
            </Overlay>
       </div>
      ))}
    </MarineContext.Consumer>
    );
}

export default HistoricalFFADashboard;