import {Button, Table} from "rt-design-system-backup";
import React, {useEffect, useState} from "react";
import {css} from "@emotion/css";
import useAxios from "axios-hooks";
import {formatDate, getCSRF, getErrorMessage, url} from "../../../helpers";
import Toaster from "../../Toaster";


const tableCSS = css({
  'thead > tr > th': {
    verticalAlign: 'top',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#000000',
  },
  'tbody > tr > td': {
    fontSize: '16px',
    lineHeight: '22px',
    color: '#000000',
  }
});


const ConfigurationForm = ({
                             title,
                             dataSource,
                             columns,
                             editing,
                             setEditing,
                             data,
                             validation=false,
                             endpoint,
                             input = null,
                             halfWidth = false,
                             refetch,
                             lastUpdated = false,
                             fullWidth = false,
                             add = false,
                            isValid = false,
                            divWidth = null,
                           }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  const [, updateData] = useAxios(
    {
      url: url(endpoint),
      method: "POST",
      headers: {
        "X-CSRFToken": getCSRF(),
      },
    },
    {manual: true}
  );

  const send = async () => {
    try {
      await updateData({data: validation ? {'data': data, 'validation': validation} : data});

      Toaster.show({
        message: "Data updated. ",
        icon: "small-tick",
        intent: "success",
      });

      refetch()
      setEditing(false)
    } catch (err) {

      Toaster.show({
        message: err.response.data.error || "An error occurred while creating the request. "+getErrorMessage(err),
        icon: "warning-sign",
        intent: "danger",
      });
    }
  };

  useEffect(() => {
    window.addEventListener("resize", () => setIsMobile(window.innerWidth <= 900));
  }, []);

  return (
    <div style={{width: isMobile ? '100%' : halfWidth ? 350 : fullWidth ? '100%' : divWidth !== null ? divWidth : '45%'}}>
      <h5 style={{fontSize: '20px', display: 'inline'}}>{title}</h5>
      <span style={{float: 'right'}}>
          {editing ?
            <>
              <Button style={{width: 50, marginRight: 5}} minimal onClick={() => {
                refetch();
                setEditing(false)
              }}>
                Cancel
              </Button>
              <Button style={{width: 50}} intent="primary" onClick={send} disabled={!isValid}>
                Save
              </Button>
            </>
            :
            <Button style={{width: 50}} outlined intent="secondary" onClick={() => setEditing(true)}>
              Edit
            </Button>
          }
        </span>
      {input ||
      <Table
        style={{marginTop: 30}}
        pagination={false}
        dataSource={dataSource}
        className={tableCSS}
        scroll={{
          x: true,
        }}
        size="small"
        columns={columns}
      />
      }
      <div style={{marginTop: 15}}>
        {lastUpdated ?
          <p style={{color: '#706F6F', display: 'inline'}}><b>Last
            updated</b> {lastUpdated['user']} {formatDate(lastUpdated['date'])}</p>
          : null
        }
        {add ?
          <Button icon={'add'} style={{float: 'right', marginTop: -5, background: 'none'}} minimal
                  disabled={!editing} onClick={add}>Add new range</Button>
          : null
        }
      </div>
    </div>
  );
}

export default ConfigurationForm;
