import React, { useEffect, useState } from "react";
import TableInput from "../../../TableInput";
import ConfigurationForm from "../ConfigurationForm";
import { formatNumber, isValidNumber, url, getFontColorDelta } from "../../../../helpers";
import axios from "axios";
const MarketPremiumCurve = () => {
    const [editing, setEditing] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [marketData, setMarketData] = useState([]);
    const [lastUpdated, setLastUpdated] = useState()
    const [payload, setPayload] = useState({});
    const [refetch, setRefetch] = useState(false);

    useEffect(() => {
        axios.all([
            axios.get(url('/market_premium/?is_tier_customer_component=true')),
            axios.get(url('/market_premium/?tier_customer=Tier 1&is_tier_customer_component=true')),
            axios.get(url('/market_premium/?tier_customer=Tier 2&is_tier_customer_component=true')),
            axios.get(url('/market_premium/?tier_customer=Tier 3&is_tier_customer_component=true'))
        ])
        .then(axios.spread((data, data1, data2, data3) => {
            data['data']['data'][0].Premium.value = 'CME MWP'
            data1['data']['data'][0].Premium.value = 'CME MWP - T1'
            data2['data']['data'][0].Premium.value = 'CME MWP - T2'
            data3['data']['data'][0].Premium.value = 'CME MWP - T3'
            const tempMarketData = [
                data['data']['data'][0],
                data1['data']['data'][0],
                data2['data']['data'][0],
                data3['data']['data'][0],
            ]
            setLastUpdated(data1['data']['last_updated'])
            setMarketData(tempMarketData);
        }));
    }, [refetch])
    
    const formatBasisPremium = (value, key, isBasisPremium) => {
        if (isBasisPremium === true) {
            return formatNumber(value?.value || 0, false, 2, false)
        }
        if (['Premium', 'Unit'].includes(key)) return value;
        let primiumValue = marketData[0][key]
        if (primiumValue !== null) {
            primiumValue = primiumValue.value
        } else primiumValue = 0
        const cumulativeMargin = (value?.value || 0) + primiumValue
        return formatNumber(cumulativeMargin, false, 2, false)
    }

    const formatContents = (value, additional) => {
        if (value > 0) return '+' + value
        else if (value < 0) return '-' + value
        else return null
     }
    const MKPCol = (marketData.length && Object.keys(marketData[0]).filter(x => x !== 'id').map(key => {
        return {
          title: key,
          dataIndex: key,
          render: (value, record) => {
            return <div style={{"display": "flex", "alignItems": "baseline"}}>
                {!['Premium', 'Unit'].includes(key)  && <p style={{"margin": 0, flexGrow: 1, color: 'black'}}>{formatBasisPremium(value, key, record.Premium.value === 'CME MWP')}</p>}
                {(record.Premium.value !== 'CME MWP' || ['Premium', 'Unit'].includes(key))&& <TableInput
                    required
                    tabbable={!['Premium', 'Unit'].includes(key) && editing}
                    enabled={!['Premium', 'Unit'].includes(key) && editing}
                    value={formatNumber(value ? value.value || 0 : null, false, 1, false)}
                    isInvalid={(str) => {
                        const error = isValidNumber(str, true, true)
                        if (error) {
                        setIsValid(false)
                        }
                        return error
                    }}
                    formatContents={!['Premium', 'Unit'].includes(key) ? formatContents : (value, additional) => value}
                    onBlurCall={(str) => setTimeout(() => setIsValid(true), 1000)}
                    style={!['Premium', 'Unit'].includes(key) ? {textAlign: 'right', width: '80px', marginLeft: '10px'} : {}}
                    divStyle={!['Premium', 'Unit'].includes(key) ? {
                        textAlign: 'right',
                        color: getFontColorDelta(value?.value || 0)
                    }: key === 'Premium' ? {width: '130px'} : {}}
                    onChange={(x) => {
                        setPayload({
                        ...payload,
                        [value['id']]: x
                        })
                    }}
                    id={'margin'}
                />
                }
            </div>
        }}})) || []

    const refetchMarketPremium = () => setRefetch(!refetch)
    return (
        <>
            <ConfigurationForm title={'Market Premium Curve Configuration'} 
              dataSource={marketData} columns={MKPCol}
              endpoint={'/market_premium/custom_update/'}
              lastUpdated={lastUpdated}
              editing={editing} setEditing={setEditing}
              data={payload} refetch={refetchMarketPremium} isValid={isValid}
              divWidth={'80%'}
            />
        </>
    )
}
export default MarketPremiumCurve;
