import React, {useEffect, useState} from "react";
import { Row, Col } from "antd";
import useAxios from "axios-hooks";
import {DateRangeInput} from "rt-design-system-backup";

import { dateFormat } from "../../Maturities";
import Page from "../../../Page";
import { InputGroup } from "../../../Form";
import { url, getCustomShortcuts, ytd } from "../../../../helpers";
import LoadingIcon from '../../../Icons/loading';
import CSRCustomerTable from "./CustomerTable";
import CSRTable from "./CSRTable";
import CSRPieChart from "./CSRPieChart";
import PnlPerValidityChart from "./PnlPerValidityChart";
import CSRMixedChart from "./CSRMixedChart";


const CSRDashboard = () => {
    const [isExtraLargeScreen, setIsExtraLargeScreen] = useState(window.innerHeight > 900)
    const [customer, setCustomer] = useState()
    const [customers, setCustomers] = useState([])
    const [startDate, setStartDate] = useState(ytd);
    const [endDate, setEndDate] = useState(new Date());
    const [{ loading: customersLoading, data: customersData = [] }] = useAxios({
        url: url("/customer/"),
    });
    
    useEffect(() => {
        window.addEventListener("resize", () => setIsExtraLargeScreen(window.innerHeight > 900));
    }, [])

    const handleDateChange = (data) => {
        const start = data[0] || new Date()
        const end = data[1] || new Date()

        setStartDate(start)
        setEndDate(end)
    }

    useEffect(()=> {
        if (customersData.length) {
            setCustomer(-1)
            setCustomers([{display_name: 'All Customers', id: -1}, ...customersData])
        }
    }, [customersData])

    return (
        <>
        {customersLoading === true || customers.length === 0 ? (
            <div style={{ alignItems: "center", justifyContent: "center", height: '100%', display: 'flex'}}>
                <LoadingIcon />
                <p>Loading Data</p>
            </div>
        ) : (
            <Page title={"All Customers"} tabs={[]} leftActions={[]} actionsMarginBottom={'5px'}
                rightActions={[
                    <span>
                        <InputGroup
                            required
                            name="customer"
                            type="select"
                            formStyle={{margin: 0, marginRight: 20, }}
                            items={customers.map((item) => {
                            return {
                                name: item.display_name,
                                value: item.id,
                            };
                            })}
                            onChange={(e) => {setCustomer(e[0].value)}}
                            placeholder={'Select Customer Type'}
                            defaultValue={customer ? [customer] : [customers[0].id]}
                        />
                    </span>,
                    <span>
                        <DateRangeInput
                            singleMonthOnly
                            allowSingleDayRange
                            {...dateFormat}
                            popoverProps={{ minimal: true }}
                            value={[startDate, endDate]}
                            onChange={handleDateChange}
                            shortcuts={getCustomShortcuts(true)}
                        />
                    </span>
                ]}
            >
             <div>
                <Row>
                    <Col xs={24} xl={11} style={{paddingRight: 8}}>
                        <CSRCustomerTable 
                            selectedCustomer={customer} 
                            isExtraLargeScreen={isExtraLargeScreen}
                            start_date={startDate} 
                            end_date={endDate}
                        />
                        <CSRTable 
                            isExtraLargeScreen={isExtraLargeScreen} 
                            start_date={startDate} 
                            end_date={endDate}
                            selectedCustomer={customer} 
                        />
                    </Col>
                    <Col xs={24} xl={13}>
                        <Row>
                            <Col xl={7} style={{paddingRight: 8}}>
                              <CSRPieChart 
                                query_param={'sales_entity'} 
                                start_date={startDate} 
                                end_date={endDate} 
                                title={'PNL per Sales Entity'}
                                isExtraLargeScreen={isExtraLargeScreen}
                                selectedCustomer={customer} 
                              />
                            </Col>
                            <Col xl={17}>
                                <PnlPerValidityChart 
                                    customer_id={customer} 
                                    start_date={startDate} 
                                    end_date={endDate} 
                                    isExtraLargeScreen={isExtraLargeScreen}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginTop: '10px'}}>
                        <Col xl={24}>
                            <CSRMixedChart 
                                customer_id={customer} 
                                start_date={startDate} 
                                end_date={endDate} 
                                isExtraLargeScreen={isExtraLargeScreen}
                            />
                        </Col>
                        </Row>
                    </Col>
                </Row>
             </div>
            </Page> 
        )}
        </>
    )
}
export default CSRDashboard;
