import {defaultFormContext, FormFooter} from "../../Form";
import {Button} from "rt-design-system-backup";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";

const Footer = ({text}) => {
    const [formContext] = useState(defaultFormContext);
    const history = useHistory()
    return <FormFooter>
        <Button onClick={() => history.goBack()} text="Cancel" minimal large/>
        <Button
            disabled={!formContext.valid}
            large
            type="submit"
            intent="primary"
            text={text}
        />
    </FormFooter>
};
export default Footer;
