import React, {useEffect, useState} from "react"

import { Tooltip, Icon, DateRangeInput } from "rt-design-system-backup"
import Page from "../../Page"
import SortContext, { defaultSortContext } from "../../SortContext"
import { url, formatSort, report } from "../../../helpers"
import axios from "axios";
import FileDownload from "js-file-download";
import {Link} from "react-router-dom";
import CopperTradesTable from "./CooperTradesTable";

const CopperTradesPage = () => {
    const sortContext = { ...defaultSortContext, startDate: defaultSortContext.endDate }
    const [sortState, setSortState] = useState(sortContext)
    const [appliedSortState, setAppliedSortState] = useState(sortContext)

    const handleNewRange = ([startDate, endDate]) => {
        setSortState({
            ...sortState,
            startDate,
            endDate
        })
    }

    const downloadCsv = (e) => {
        e.preventDefault()
        report('trades_download')
        axios({
            url: url("/tradecsv/" + formatSort(appliedSortState)),
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            FileDownload(response.data, 'trades.csv');
        });
    }

    useEffect(() => {
        setAppliedSortState(sortState)
    }, [sortState]);

    return <SortContext.Provider value={appliedSortState}>
        <Page title="Trades" tabs={[]}
            rightActions={
                [
                    <span><DateRangeInput
                        singleMonthOnly
                        allowSingleDayRange {...{
                            formatDate: (date) => (date == null ? '' : new Date(new Date(new Date(date.setHours(1)).setMinutes(0)).setSeconds(0)).toLocaleDateString(navigator.language)),
                            parseDate: (str) => new Date(Date.parse(str)),
                        }} value={[sortState.startDate, sortState.endDate]} onChange={handleNewRange} popoverProps={{
                            minimal: true
                        }} /></span>,
                    <Tooltip intent="primary" content="Download Trades">
                        {true ? null : <a onClick={downloadCsv} href="#0" download>
                            <span className="rtcmd-page-action-icon">
                                <Icon icon="arrow-down"/>
                            </span>
                        </a>}
                    </Tooltip>,
                    <Link to={"/coppers"}>
                        <span style={{ fontSize: '16px', fontWeight: 'normal', marginLeft: 12 }}><u>View orders</u></span>
                    </Link>
                ]
            }
        >
            <CopperTradesTable />
        </Page>
    </SortContext.Provider>
}

export default CopperTradesPage
