import React from 'react';
import {Table} from "antd";
import {css} from "@emotion/css";
import {capitalize} from "../../helpers";
import {numericRenderBuilder} from "../RequestMaturityPrices/columns";


const tableCSS = css({
  '.ant-table-content':{
    boxShadow: 'none',
    height: '80px',
  },
  '.ant-table-thead, .ant-table tr, .ant-table-tbody > tr > td': {
    border: 'none',
  },
  '.ant-table.ant-table-small .ant-table-thead > tr > th, .ant-table.ant-table-small .ant-table-tbody > tr > td': {
    padding: '5px 0px',
  },
  '.ant-table-small .ant-table-thead > tr > th': {
    backgroundColor: "white",
  },
  'thead > tr > th': {
    verticalAlign: 'top',
    fontSize: '14px',
    lineHeight: '22px',
    border: 'none',
    color: '#000000',
  },
  '.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before': {
    content: 'none',
  },
  '.ant-table-tbody > tr > td': {
    fontSize: '13px',
  },
  '.ant-table.ant-table-small .ant-table-tbody .ant-table-row .ant-table-cell , .ant-table-cell': {
    padding: 0,
  },
  'tbody > tr:nth-child(odd) > td': {
    backgroundColor: "#F5F5F5",
  },
  'tr > .quantity': {
    textAlign: 'right',
  },
  'tr': {
    textAlign: 'left',
  }
});

const columns = [
  {
    title: "Maturities",
    dataIndex: "maturity",
    render: capitalize,
  },
  {
    title: "Buy/Sell",
    dataIndex: "action",
    render: capitalize,
  },
  {
    title: "Quantity (mt)",
    dataIndex: "quantity",
    className: "quantity",
    render: numericRenderBuilder(2),
  },

]

const CardMaturities = ({request, isTrader= false}) => {
  const maturities = request.request_maturities.map((requestMaturity) => {
    return {
      maturity: requestMaturity.maturity.shortened_string,
      action: requestMaturity.trades[0].action,
      quantity: requestMaturity.trades[0].quantity,
    };
  });

  return (
    <div style={{height: '85px', marginTop: 5}}>
      <Table
        pagination={false}
        dataSource={maturities.slice(0, 2)}
        className={tableCSS}
        scroll={{x: true}}
        size="small"
        columns={columns}
      />
      {maturities.length > 2 ? <p style={{margin: 0, fontSize: '13px', color:'#565656'}}>Displaying 2 of {maturities.length} maturites</p> : null}
    </div>
  );
};

export default CardMaturities;
