import React, {useEffect, useState} from "react";
import useAxios from "axios-hooks";

import {
  url,
  getCSRF,
  capitalize,
  formatNumber,
  isValidNumber,
  report,
  getErrorMessage,
} from "../../helpers";
import {Dialog, Button, TextArea} from "rt-design-system-backup";
import Form, { InputGroup, FormRow, FormFooter, FormContext } from "../Form";
import Toaster from "../Toaster";
import { useLogin } from '../useLogin'

import "../OrderViewDialog/style.scss";

const TradeCreateDialog = ({ isOpen, onClose, order }) => {
  useLogin(true)
  const [comments, setComments] = useState('');
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isOpen) {
      report("trade_create", {
        order_id: order.id,
      });
      setComments(order.comments)
    }
  }, [isOpen]);
  /* eslint-enable react-hooks/exhaustive-deps */

  let totalTraded = 0;

  if (order) {
    order.order_contracts.forEach((order_contract) => {
      order_contract.trade.forEach((trade) => {
        totalTraded = totalTraded + parseFloat(trade.quantity);
      });
    });
  }

  const [{ loading: brokersLoading, data: brokers }] = useAxios({
    url: url("broker/"),
  });
  const [{ loading: clearersLoading, data: clearers }] = useAxios({
    url: url("clearer/"),
  });

  const score = ["year", "quarter", "month"];

  const [{ loading: createLoading }, createTrade] = useAxios(
    {
      url: url("trade/"),
      method: "POST",
      headers: {
        "X-CSRFToken": getCSRF(),
      },
    },
    { manual: true }
  );

  const send = async (formData) => {
    try {
      report("trade_submitted", {
        order_id: order.id,
      });

      await createTrade({
        data: {
          linked_order: order.id,
          ...formData,
          value: formData.price,
          comments: comments,
          contracts: formData.contracts.split(","),
        },
      });

      report("trade_created", {
        order_id: order.id,
      });

      Toaster.show({
        message: "Trade successfully placed. ",
        icon: "small-tick",
        intent: "success",
      });

      onClose();
    } catch (err) {
      report("trade_failed", {
        order_id: order.id,
      });

      Toaster.show({
        message:
          "An error occurred while placing the trade. " + getErrorMessage(err),
        icon: "warning-sign",
        intent: "danger",
      });
    }
  };

  const loading = brokersLoading || clearersLoading;

  const contracts = order
    ? order.order_contracts
      .sort((a, b) => {
        return (
          new Date(a.contract.maturity.start) -
          new Date(b.contract.maturity.start) +
          (score.indexOf(a.contract.maturity.type) -
            score.indexOf(b.contract.maturity.type))
        );
      })
      .filter(({ quantity_traded }) => {
        return quantity_traded < order.quantity;
      })
    : null;
  const bidOffer = order ? [...new Set(order.order_contracts.filter(c => c.type).map(c => capitalize(c.type)))] : []
  return order ? (
    <Dialog
      className="modal"
      isOpen={isOpen}
      hasBackdrop={true}
      canEscapeKeyClose
      canOutsideClickClose={false}
      onClose={onClose}
      title="Add trade"
      style={{ maxWidth: "548px", width: "100%" }}
    >
      {loading ? (
        "Loading"
      ) : (
        <div style={{ padding: "0px 32px 0 32px" }}>
          <div className="rtcmd--view-order--header">
            <div>Order #{order.id}</div>
          </div>
          <div style={{ marginBottom: "32px" }}>
            <h6>Ordered</h6>
            <table className="rtcmd--dialog--table">
              <tr>
                <th>Contract</th>
                <th>Tenor</th>
                <th>Book</th>
                <th>Bid/Offer</th>
                <th>Price</th>
                <th>Qty</th>
              </tr>
              {(order.is_spread || order.is_cracks ? order.order_contracts.slice(0,1) : order.order_contracts).map((order_contract) => {
                return (
                  <tr>
                    <td>{[ ...new Set(order.order_contracts.map(c => c.contract.route.route))].join('/')}</td>
                    <td>
                      {order.is_spread || order.is_cracks ?
                        [...new Set(order.order_contracts.map((m) => {
                          return capitalize(m.maturity_shortened_string);
                        }))].join(", ") || order.maturities_to_display.map((m) => {return capitalize(m)}).join(', ')
                        : capitalize(order_contract.contract.maturity.shortened_string)}
                    </td>
                    <td>{order.book}</td>
                    <td>{(bidOffer.length ? [ ...new Set(bidOffer) ] : [capitalize(order.type)]).join('/')}</td>
                    <td>
                      {order.order_type !== "market"
                        ? formatNumber(order.value)
                        : "At best"}
                    </td>
                    <td>
                      {formatNumber(order_contract.quantity_traded, false, /^-?\d+$/.test(order_contract.quantity_traded) ? 0 : 2)}/
                      {formatNumber(order.quantity, false, /^-?\d+$/.test(order.quantity) ? 0 : 2)}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
          <Form onSubmit={send}>
            {
              <FormRow
                style={{
                  display: order.order_contracts.length > 1 ? "block" : "none",
                }}
              >
                <FormContext.Consumer>
                  {({ data }) => {
                    return (
                  <InputGroup
                    title="Tenors"
                    required
                    disabled={order.is_spread || order.is_cracks}
                    name="contracts"
                    type="select"
                    customError={(e) => {
                      return bidOffer.length > 1 && order.order_contracts.length !== e.length && 'Please select all maturities'
                    }}
                    multiple
                    items={contracts.map((contract) => {
                      return {
                        name: capitalize(
                          contract.contract.maturity.shortened_string
                        ),
                        value: contract.contract.id,
                        contract,
                      };
                    })}
                    defaultValue={contracts.map(
                      (contract) => contract.contract.id
                    )}
                  />)}}
                </FormContext.Consumer>
              </FormRow>
            }

            <FormRow>
              <InputGroup
                title="Price"
                required
                name="price"
                type="number"
                isInvalid={(value) => isValidNumber(value, false)}
              />
              <FormContext.Consumer>
                {({ data }) => {
                  return (
                    <InputGroup
                      rightElement={
                        <span>
                          {order.order_contracts[0].contract.route.suffix}
                        </span>
                      }
                      title="Quantity"
                      required
                      name="quantity"
                      type="number"
                      isInvalid={(value) => {
                        const numberError = isValidNumber(value, true);
                        if (numberError) return numberError;
                        // Number is valid
                        // Check if quantity is ok towards current traded quantities
                        let allowable = parseFloat(order.quantity);

                        if (data.contracts && data.contracts.length) {
                          data.contracts.forEach(({ contract }) => {
                            const delta =
                              parseFloat(order.quantity) -
                              contract["quantity_traded"];
                            if (delta < order.quantity) {
                              allowable = delta;
                            }
                          });

                          if (parseFloat(value) > allowable) {
                            return (
                              "Cannot exceed " +
                              formatNumber(allowable, false, 0) +
                              " for selected tenors"
                            );
                          }
                        } else {
                          return "Please select a tenor";
                        }
                      }}
                    />
                  );
                }}
              </FormContext.Consumer>
            </FormRow>
            <FormRow>
              <InputGroup
                title="Broker"
                required
                name="broker"
                type="select"
                items={brokers
                  .filter((broker) => broker.is_active)
                  .map((broker) => {
                    return {
                      name: broker.name,
                      value: broker.id,
                    };
                  })}
              />
              <InputGroup
                title="Clearer"
                required
                name="clearer"
                type="select"
                items={clearers.map((clearer) => {
                  return {
                    name: clearer.clearer,
                    value: clearer.clearer,
                  };
                })}
              />
            </FormRow>
            <br/>
            <h2>Additional Comments</h2>
            <TextArea name="comments" style={{width: '655px'}} growVertically={true} onChange={
              (evt) => {
                setComments(evt.target.value)
              }} defaultValue={order.comments}
            ></TextArea>
            <FormFooter>
              <Button onClick={onClose} text="Cancel" minimal />
              <FormContext.Consumer>
                {({ valid }) => {
                  return (
                    <Button
                      loading={createLoading}
                      type="submit"
                      disabled={!valid}
                      text="Add trade"
                      intent="primary"
                    />
                  );
                }}
              </FormContext.Consumer>
            </FormFooter>
          </Form>
        </div>
      )}
    </Dialog>
  ) : null;
};

export default TradeCreateDialog;
