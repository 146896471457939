import React, {useEffect, useState, useContext, createContext} from "react";
import useAxios from "axios-hooks";
import {useParams, useHistory} from "react-router-dom";
import {Button} from "rt-design-system-backup";

import {url, getCSRF} from "../../../helpers";

import RequestViewContext from "../../RequestContext";
import {FormFooter} from "../../Form";
import Toaster from "../../Toaster";
import SocketContext from "../../SocketContext";
import RequestAdditional from "../../RequestAdditional"
import useLogin from "../../useLogin"
import Page from "../../Page";
import {Divider} from "antd";
import CopperOverview from "../../CopperOverview";
import CopperPricingInstructions from "../../CopperOverview/CopperPricingInstructions";
import CopperTrades from "../../CopperOverview/CopperTrades";

const defaultFillRequestContext = {
  enabled: false,
  invalidate: (key) => {
    console.warn("No invalidate listener in place");
  },
  deregister: (key) => {
    console.warn("No deregister listener in place");
  },
  validate: (key) => {
    console.warn("No validate listener in place");
  },
};

export const FillRequestContext = createContext(defaultFillRequestContext);

const FillCopper = ({editRequest = false}) => {
  const {requestId} = useParams();

  useLogin(true)

  const [{data: remoteRequest, loading}, refetch] = useAxios({
    url: url("/copper/" + requestId + "/"),
  });

  const socket = useContext(SocketContext);

  useEffect(() => {
    const refetcher = (req) => {
      if (!req || req.detail.id === requestId) {
        refetch();
      }
    };

    refetcher();
    socket.addEventListener("request_fill", refetcher);
    socket.addEventListener("request_update", refetcher);
    socket.addEventListener("request_cancel", refetcher);

    return () => {
      socket.removeEventListener("request_fill", refetcher);
      socket.removeEventListener("request_update", refetcher);
      socket.removeEventListener("request_cancel", refetcher);
    };
  }, [socket, refetch, requestId]);

  const [request, setRequest] = useState(false);

  useEffect(() => {
    setRequest(remoteRequest);
  }, [remoteRequest]);

  const history = useHistory();

  const goBack = () => {
    history.goBack()
  }

  const update = (update, tradeId) => {
    setRequest((request) => {
      if (tradeId) {
        const newTrades = request.trades.map(
          (trade) => {
            if (trade.id === tradeId){
              return {
                ...trade,
                ...(update),
              };
            } else {
              return trade
            }
          }
        );

        return {
          ...request,
          trades: newTrades,
        };
      } else {
        return {
          ...request,
          ...update,
        };
      }
    });
  };

  const [{loading: fillLoading}, fillOrder] = useAxios(
    {
      url: url("/copper/" + (request && request.id) + "/"),
      method: "PUT",
      headers: {
        "X-CSRFToken": getCSRF(),
      },
    },
    {
      manual: true,
    }
  );

  const submit = async () => {
    try {
      await fillOrder({
        data: {...request, is_edit: editRequest},
      });

      Toaster.show({
        message: editRequest ? "The request has been edited successfully." : "Request successfully filled. ",
        icon: "small-tick",
        intent: "success",
      });
    } catch (err) {
      Toaster.show({
        message: editRequest ? "An error occurred while editing the request." : "An error occurred while filling the request. ",
        icon: "warning-sign",
        intent: "danger",
      });
    }
  };

  const [invalidInputs, setInvalidInputs] = useState([]);

  const validate = (key) => {
    if (invalidInputs.indexOf(key) < 0) {
      return;
    }

    setInvalidInputs((invalidInputs) => {
      if (invalidInputs.indexOf(key) >= 0) {
        return [
          ...invalidInputs.filter((invalidInputKey) => invalidInputKey !== key),
        ];
      } else {
        return invalidInputs;
      }
    });
  };

  const invalidate = (key) => {
    if (invalidInputs.indexOf(key) >= 0) return;

    setInvalidInputs((invalidInputs) => {
      if (invalidInputs.indexOf(key) >= 0) {
        return invalidInputs;
      } else {
        return [...invalidInputs, key];
      }
    });
  };
  const register = () => {
  };
  const deregister = validate;

  const isInvalid = invalidInputs.length;

  const additional = loading ? null : <RequestAdditional editable request={request}/>

  return (
    <RequestViewContext.Provider
      value={{
        // TODO: disable the refetch process
        refetch,
        /** Update the currently viewed request's context.
         * @param update Object containing key value pairs of the itentity to udpate
         * @param tradeId (Optional) Identifier of the trade to update
         */
        update,
      }}
    >
      <Page
        title={editRequest ? "Edit request" : "Fill request"}
        tabs={[]}
      >
        <FillRequestContext.Provider
          value={{
            ...defaultFillRequestContext,
            enabled: true,
            validate,
            invalidate,
            deregister,
            register,
          }}
        >
          <hr/>
          {!(loading && !request) && request ? (
            <>
              <CopperOverview request={request} showSpinner />
              <Divider />
              <CopperPricingInstructions request={request} editable={false} />
              <Divider />
              <CopperTrades request={request} editable />
              <Divider />
              {additional}
              <FormFooter>
                  <Button
                    onClick={() => {
                      goBack()
                    }}
                    text="Cancel"
                    minimal
                    large
                  />
                <Button
                  disabled={isInvalid}
                  large
                  loading={fillLoading}
                  type="submit"
                  text={editRequest ? request.status === "For correction" ? "Save edits" : "Edit request" : "Fill request"}
                  onClick={async () => {
                    await submit();
                    goBack()
                  }}
                  intent="primary"
                />
              </FormFooter>
            </>
          ) : null}
          <div style={{height: "32px"}}></div>
        </FillRequestContext.Provider>
      </Page>
    </RequestViewContext.Provider>
  );
};

export default FillCopper;
