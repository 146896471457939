import Form from "../../Form";
import TextInput from "./TextInput";
import Footer from "./Footer";
import React from "react";
import FormWrapper from "./FormWrapper";

const SystemColumnEditOrUpdateForm = ({data, onSubmit}) => <Form onSubmit={onSubmit}>
    <FormWrapper>
        <TextInput label="Name" name="name" data={data}/>
        <TextInput label="API Field Name" name="api_field" data={data}/>
        <Footer text={data ? "Update Column" : "Add Column"}/>
    </FormWrapper>
</Form>;

export default SystemColumnEditOrUpdateForm;