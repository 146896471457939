import React from 'react';
import '../../assets/scss/core.scss';
import {
  Intent,
  ITooltipProps as BPITooltipProps,
  PopoverPosition,
  PopperBoundary,
  Tooltip as BPTooltip,
} from '@blueprintjs/core';

export interface ITooltipProps extends BPITooltipProps {
  children?: any;
  boundary?: PopperBoundary;
  captureDismiss?: boolean;
  className?: string;
  content: JSX.Element | string;
  defaultIsOpen?: boolean;
  disabled?: boolean;
  enforceFocus?: boolean;
  hoverCloseDelay?: number;
  hoverOpenDelay?: number;
  inheritDarkTheme?: boolean;
  intent?: Intent;
  isOpen?: boolean;
  lazy?: boolean;
  minimal?: boolean;
  openOnTargetFocus?: boolean;
  position?: PopoverPosition;
  transitionDuration?: number;
  usePortal?: boolean;
}

export const Tooltip: React.FC<ITooltipProps> = (props) => {
  return <BPTooltip {...props} />;
};
