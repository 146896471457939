import { Col, Divider, Row } from "antd";
import useAxios from "axios-hooks";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Tab, Tabs } from "rt-design-system-backup";
import { hasGroup, hasPerm, url } from "../../../helpers";
import AppContext from "../../AppContext";
import DashboardCard from "../../DashboardCard";
import { ErrorBoundary } from "../../ErrorBoundary";
import Page from "../../Page";
import SocketContext from "../../SocketContext";
import AggregatedData from "./AggregateTable";
import "./style.scss";

const Dashboard = () => {
  const { user } = useContext(AppContext);
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);
  const [period, setPeriod] = useState("today");

  const [{ data: requests, loading }, refetch] = useAxios({
    url: url(`request/dashboard/?period=${period}`),
  });

  const [{ data: requests_apc_all, loading_apc_all }] = useAxios({
    url: url(`request/dashboard_apc_all/`),
  });

  const [{ data: requests_apc_today, loading_apc_today }] = useAxios({
    url: url(`request/dashboard_apc_today/`),
  });

  const [{ data: requests_apc_this_week, loading_apc_this_week }] = useAxios({
    url: url(`request/dashboard_apc_this_week/`),
  });

  let showReconcile = false;
  if (hasGroup(user, "Alu Back Office")) {
    showReconcile = true;
  }

  const socket = useContext(SocketContext);

  useEffect(() => {
    refetch();
    window.addEventListener("resize", () =>
      setIsMobile(window.innerWidth <= 900),
    );

    socket.addEventListener("request_create", refetch);
    socket.addEventListener("request_fill", refetch);
    socket.addEventListener("request_update", refetch);
    socket.addEventListener("request_cancel", refetch);
    socket.addEventListener("request_reconcile", refetch);
    socket.addEventListener("request_accept", refetch);
    socket.addEventListener("request_expire", refetch);
    socket.addEventListener("request_expire", refetch);
    socket.addEventListener("request_resubmit", refetch);

    return () => {
      socket.removeEventListener("request_create", refetch);
      socket.removeEventListener("request_fill", refetch);
      socket.removeEventListener("request_update", refetch);
      socket.removeEventListener("request_cancel", refetch);
      socket.removeEventListener("request_reconcile", refetch);
      socket.removeEventListener("request_accept", refetch);
      socket.removeEventListener("request_expire", refetch);
      socket.removeEventListener("request_expire", refetch);
      socket.removeEventListener("request_resubmit", refetch);
    };
  }, [socket, refetch]);

  return loading && !requests ? (
    "Loading..."
  ) : (
    <Page
      title={"Dashboard"}
      tabs={[]}
      leftActions={[
        hasPerm(user, "rtcmdmodels.add_request") ? (
          <Button
            className={"cmd_button"}
            large={isMobile ? true : null}
            intent="primary"
            onClick={() => {
              history.push("/aluminium/request/new");
            }}
            text="New request"
          />
        ) : null,
      ]}
    >
      <div className="rtcmd-page-tabs">
        <Tabs
          large
          minimal
          selectedTabId={period}
          onChange={async (index) => {
            await setPeriod(index);
            refetch();
          }}
        >
          <Tab id={"today"} title={"Today"} />
          <Tab id={"week"} title={"This Week"} />
        </Tabs>
      </div>

      {(requests || []).map((category) => {
        return (
          <div className={"cmd_dashboard"}>
            <Divider />
            <h1>
              {category.label} ({category.requests.length})
            </h1>
            <Row gutter={[16, 16]}>
              <AggregatedData data={category.agg_data} />
              {category.requests.length ? (
                category.requests.map((request) => (
                  <ErrorBoundary>
                    <DashboardCard
                      request={request}
                      showReconcile={showReconcile}
                    />
                  </ErrorBoundary>
                ))
              ) : (
                <Col span={24}>
                  <div
                    style={{
                      backgroundColor: "#E5EAEF",
                      height: "48px",
                      padding: 10,
                    }}
                  >
                    <p style={{ fontSize: "16px", lineHeight: "24px" }}>
                      There are no requests to display.
                    </p>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        );
      })}
      <br />
      <div>
        <Divider />
        <h1 style={{ fontSize: "30px"}}>APC</h1>
      </div>
      <div>
      {(requests_apc_all || []).map((category) => {
        return (
          <div className={"cmd_dashboard"}>
            <Divider />
            <h1>
              {category.label} ({category.requests.length})
            </h1>
            <Row gutter={[16, 16]}>
              <AggregatedData data={category.agg_data} />
              {category.requests.length ? (
                category.requests.map((request) => (
                  <ErrorBoundary>
                    <DashboardCard
                      request={request}
                      showReconcile={showReconcile}
                    />
                  </ErrorBoundary>
                ))
              ) : (
                <Col span={24}>
                  <div
                    style={{
                      backgroundColor: "#E5EAEF",
                      height: "48px",
                      padding: 10,
                    }}
                  >
                    <p style={{ fontSize: "16px", lineHeight: "24px" }}>
                      There are no requests to display.
                    </p>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        );
      })}
      </div>
      <div>
      {(requests_apc_today || []).map((category) => {
        return (
          <div className={"cmd_dashboard"}>
            <Divider />
            <h1>
              {category.label} ({category.requests.length})
            </h1>
            <Row gutter={[16, 16]}>
              <AggregatedData data={category.agg_data} />
              {category.requests.length ? (
                category.requests.map((request) => (
                  <ErrorBoundary>
                    <DashboardCard
                      request={request}
                      showReconcile={showReconcile}
                    />
                  </ErrorBoundary>
                ))
              ) : (
                <Col span={24}>
                  <div
                    style={{
                      backgroundColor: "#E5EAEF",
                      height: "48px",
                      padding: 10,
                    }}
                  >
                    <p style={{ fontSize: "16px", lineHeight: "24px" }}>
                      There are no requests to display.
                    </p>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        );
      })}
      </div>
      <div>
      {(requests_apc_this_week || []).map((category) => {
        return (
          <div className={"cmd_dashboard"}>
            <Divider />
            <h1>
              {category.label} ({category.requests.length})
            </h1>
            <Row gutter={[16, 16]}>
              <AggregatedData data={category.agg_data} />
              {category.requests.length ? (
                category.requests.map((request) => (
                  <ErrorBoundary>
                    <DashboardCard
                      request={request}
                      showReconcile={showReconcile}
                    />
                  </ErrorBoundary>
                ))
              ) : (
                <Col span={24}>
                  <div
                    style={{
                      backgroundColor: "#E5EAEF",
                      height: "48px",
                      padding: 10,
                    }}
                  >
                    <p style={{ fontSize: "16px", lineHeight: "24px" }}>
                      There are no requests to display.
                    </p>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        );
      })}
      </div>
    </Page>
  );
};

export default Dashboard;
