import React, {useEffect, useState} from "react";
import useAxios from "axios-hooks";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Card } from "rt-design-system-backup"
import { InputGroup } from "../../../Form";

import { url } from "../../../../helpers";
import { dateFormat } from "../../Maturities";
import LoadingIcon from "../../../Icons/loading";
import { formatNumber } from "../utils";


const PnlPerValidityChart = ({customer_id, start_date, end_date, isExtraLargeScreen}) => {
    ChartJS.register(
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      Tooltip,
      Legend
    );
    const [type, setType] = useState('PNL');
    const [{ loading: chartDataLoading, data: chartData }, refetch] = useAxios({
        url: url(`aluminium_analytics/pnl_per_validity/?start_date=${dateFormat.formatDate(start_date)}&end_date=${dateFormat.formatDate(end_date)}${customer_id !== -1 ? '&customer=' + customer_id : ''}&type=${type}`),
    });

    const options = {
        maintainAspectRatio: false,
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
              font: {
                family: 'RT_VickermanW01',
              }
            }
          },
          y: {
              ticks: {
                font: {
                  size: 14,
                  family: 'RT_VickermanW01',
                }
              }
          },
        },
        plugins: {
          legend: {
              display: false,
          },
          title: {
            display: false,
          },
          tooltip: {
            titleFont: {
              family: 'RT_VickermanW01'
            },
            bodyFont: {
              family: 'RT_VickermanW01'
            },
            callbacks: {
              label: function(context) {
                  return "Total " + formatNumber(JSON.parse(context.formattedValue)[0], false, 0)
              }
            }
          }
        },
    };
    const dataset = {
        labels: ['Live', '5min', '15min', '30min', '1h', '3h', 'EOD', 'Past Cutoff'],
        datasets: [
        chartDataLoading === false && chartData && {
            label: 'Total Pnl',
            data: [
                chartData?.Live, chartData?.Five_Min,
                chartData?.Fifteen_Min, chartData?.Thirty_Min, 
                chartData?.One_H, chartData?.Three_H, 
                chartData?.End_Of_Day, chartData?.Past_Cutoff
            ],
            backgroundColor: '#0087FA',
          }
        ]
    };
    useEffect(() => {
      if (!type || !customer_id || !start_date || !end_date) return
      refetch()
      // eslint-disable-next-line
    }, [type, customer_id, start_date, end_date])
    return (
        <Card elevation={1}
          style={{padding: '10px 14px 10px 18px', borderRadius: '8px', minHeight: isExtraLargeScreen === true ? '280px' : '230px',
        }}>
          <div style={{"display": 'inline-flex', "justifyContent": "flex-end", "width": '100%'}}>
            <InputGroup
              required
              name="validity_option"
              type="select"
              formStyle={{margin: 0, marginBottom: 0}}
              items={[{name: 'Total PnL (USD)', value: 'Pnl'}, {name: 'Total Qt (MT)', value:'MT'}].map((item) => {
              return {
                  name: item.name,
                  value: item.value,
              };
              })}
              onChange={(e) => {setType(e[0].value)}}
              placeholder={'Select Option'}
              defaultValue={['Pnl']}
            />
          </div>
            {chartDataLoading === true ? (
            <div style={{ alignItems: "center", justifyContent: "center", height: '100%', display: 'flex'}}>
                <LoadingIcon />
                <p>Loading Data</p>
            </div>
            ) : (
            <>
                <h1>{type === 'Pnl' ? 'Total PnL (USD)' : 'Total Qt (MT)'} Per Validity</h1>
                <div style={{height: isExtraLargeScreen === true ? "252px" : "180px", marginTop: '5px'}}>
                  <Bar data={dataset} options={options} />
                </div>
                
            </>
            )}  
        </Card>
    );
}
export default PnlPerValidityChart;
