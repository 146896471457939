import { Intent, ISpinnerProps as BPISpinnerProps, Spinner as BPSpinner } from '@blueprintjs/core';
import '../../assets/scss/core.scss';

export interface ISpinnerProps extends BPISpinnerProps {
  intent?: Intent;
  size?: number;
  value?: undefined | number;
}

export class Spinner extends BPSpinner {}
