import SimpleTable from "../SimpleTable";
import {formatDate} from "../../helpers";
import React from "react";

const CopperPricingInstructions = ({ request, editable}) => {
  return (
    <>
      <h4>Pricing Instructions</h4>
      <SimpleTable style={{ flex: "1 0 300px" }}>
        <tr>
          <td>Contract:</td>
          <td>{request.contract.name}</td>
        </tr>
        <tr>
          <td>Quota Month:</td>
          <td>{request.quota_month}</td>
        </tr>
        {request.pricing_date?
          <tr>
            <td>Pricing Date:</td>
            <td>{request.pricing_date}</td>
          </tr>:
            null
        }
        {request.target_price?
          <tr>
            <td>Target Price:</td>
            <td>{request.target_price}</td>
          </tr>:
            null
        }
        {request.valid_until?
          <tr>
            <td>Valid until:</td>
            <td>{formatDate(request.valid_until, true, true)}</td>
          </tr>:
            null
        }
      </SimpleTable>
    </>
  );
};

export default CopperPricingInstructions;
