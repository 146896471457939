import React from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { formatNumber } from "../../utils";

ChartJS.register(ArcElement);
const DoughnutGraph = ({chartDataLoading, chartData, pnlType}) => {
    ChartJS.register(Tooltip, Legend);
    const options = {
        cutout: '80%',
        maintainAspectRatio: false,
        responsive: true,
        plugins:{
          legend: {
            display: false
         },
         tooltip: {
          callbacks: {
            label: function(context) {
                return context.label + ': ' +formatNumber(context.formattedValue.replace(/,/g, ''), false, 0)
            }
         }
        }
        }
    }
    const plugins = [{
        key: 'testing_pnl',
        beforeDatasetDraw: (chart) => {
         var ctx = chart.ctx;
             ctx.restore();
             const x = chart.getDatasetMeta(0).data[0].x;
             const y = chart.getDatasetMeta(0).data[0].y - 10;
             ctx.save()
             ctx.font = "bold 32px RT_VickermanW01";
             ctx.fillStyle = '#252733';
             ctx.textJustify = 'top'
             ctx.textAlign = 'center'
             ctx.alignItems = 'center'
             ctx.fillText(chartData[pnlType].total ? '$' + formatNumber(chartData[pnlType].total, false, 0) : 0, x, y);
             ctx.font = "bold 14px RT_VickermanW01";
             ctx.fillStyle = '#002C5F';
             ctx.fillText('TTL AUTOQUOTE PNL', x, y+35);
             ctx.fillText('USD', x, y+55);
             ctx.save();
        },
        tooltip: {
          titleFont: {
            family: 'RT_VickermanW01'
          },
          bodyFont: {
            family: 'RT_VickermanW01'
          }
        }
      }]

    const data = {
        labels: ['Market Pnl', 'Premium Pnl', 'Tier Customer ALU Pnl', 'Tier Customer MWP Pnl'],
        datasets: [
          chartDataLoading === false && chartData && {
            data: [
              chartData[pnlType].market_pnl,
              chartData[pnlType].premium_pnl[0],
              chartData[pnlType].tier_customer_alu_pnl[0],
              chartData[pnlType].tier_customer_mwp_pnl[0]
            ],
            backgroundColor: [
              '#0017FA',
              '#003776',
              '#0059D1',
              '#0087FA',
              
            ]
          }
        ]
    }
    return (
        <div style={{height: '250px'}}>
            <Doughnut key={`testing${chartData[pnlType].total}`} data={data} options={options} plugins={plugins} height={120} width={120}/>
        </div>
    )
}

export default DoughnutGraph;