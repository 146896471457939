import useAxios from "axios-hooks";
import { Dialog, Button } from "rt-design-system-backup";
import { useEffect } from "react";

import { FormFooter } from "../Form";
import Toaster from "../Toaster";

import {
  capitalize,
  formatNumber,
  getCSRF,
  url,
  report,
  getErrorMessage,
} from "../../helpers";

import "./style.scss";

const OrderCancelDialog = ({ isOpen, onClose, order }) => {
  let totalTraded = 0;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isOpen) {
      report("order_cancel", {
        order_id: order.id,
      });
    }
  }, [isOpen]);
  /* eslint-enable react-hooks/exhaustive-deps */

  if (order) {
    order.order_contracts.forEach((order_contract) => {
      order_contract.trade.forEach((trade) => {
        totalTraded = totalTraded + parseFloat(trade.quantity);
      });
    });
  }

  const [{ loading }, cancelOrder] = useAxios(
    {
      url: url("order/" + (order ? order.id : "")+"/"),
      method: "DELETE",
      headers: {
        "X-CSRFToken": getCSRF(),
      },
    },
    { manual: true }
  );

  const submit = async () => {
    try {
      await cancelOrder();

      report("order_cancel_confirmed", {
        order_id: order.id,
      });

      Toaster.show({
        message: "Order successfully cancelled. ",
        icon: "small-tick",
        intent: "success",
      });

      onClose();
    } catch (err) {
      report("order_cancel_failed");

      Toaster.show({
        message:
          "An error occurred while cancelling the order. " +
          getErrorMessage(err),
        icon: "warning-sign",
        intent: "danger",
      });
    }
  };

  return order !== undefined ? (
    <Dialog
      isOpen={isOpen}
      hasBackdrop={true}
      canEscapeKeyClose
      canOutsideClickClose
      onClose={onClose}
      title={"Cancel order #" + order.id}
      style={{ maxWidth: "548px", width: "100%" }}
    >
      <div style={{ padding: "0 32px 0 32px" }}>
        <p
          style={{
            color: "#706F6F",
            fontSize: "16px",
            lineHeight: "32px",
            marginTop: "16px",
          }}
        >
          This will not impact existing trades for this order.
        </p>
        <hr />
        <div>
          <table className="rtcmd--dialog--table">
            <tr>
              <th>Contract</th>
              <th>Tenor</th>
              <th>Book</th>
              <th>Bid/Offer</th>
              <th>Price</th>
              <th>Qty</th>
            </tr>
            <tr>
              <td>{order.order_contracts[0].contract.route.route}</td>
              <td>
                {order.order_contracts
                  .map((order_contract) => {
                    return capitalize(
                      order_contract.contract.maturity.shortened_string
                    );
                  })
                  .join(", ")}
              </td>
              <td>{order.book}</td>
              <td>{capitalize(order.type)}</td>
              <td>
                {order.order_type !== "market"
                  ? formatNumber(order.value)
                  : "At best"}
              </td>
              <td>
                {formatNumber(order.quantity_traded, false,/^-?\d+$/.test(order.quantity_traded) ? 0 : 2)}/
                {formatNumber(order.total_quantity, false, /^-?\d+$/.test(order.total_quantity) ? 0 : 2)}
              </td>
            </tr>
          </table>
        </div>
        <hr />
        <FormFooter>
          <Button onClick={onClose} text="Keep order" minimal />
          <Button
            loading={loading}
            onClick={submit}
            text="Cancel order"
            intent="danger"
          />
        </FormFooter>
      </div>
    </Dialog>
  ) : null;
};

export default OrderCancelDialog;
