import { DateInput as BPDateInput, IDateInputProps as BPIDateInputProps } from '@blueprintjs/datetime';
import React from 'react';
import '../../../assets/scss/core.scss';
import { Button } from '../button';
import styled from 'styled-components';

export interface IDateInputProps extends BPIDateInputProps {
  closeOnSelection?: boolean;
  disabled?: boolean;
  shortcuts?: boolean;
  highlightCurrentDay?: boolean;
  reverseMonthAndYearMenus?: boolean;
}

export const DateInput: React.FC<IDateInputProps> = (props) => {
  const ButtonContainer = styled(Button)`
    .bp3-icon svg {
      fill: #002c5f;
    }
  }
  `;
  const rightIcon = <ButtonContainer minimal icon='calendar' />;

  return <BPDateInput rightElement={rightIcon} {...props} />;
};
