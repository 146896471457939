import React from 'react';
import '../../assets/scss/core.scss';
import { ButtonGroup as BPButtonGroup, IButtonGroupProps as BPIButtonGroupProps } from '@blueprintjs/core';

export interface IButtonGroupProps extends BPIButtonGroupProps {
  fill?: boolean;
  large?: boolean;
  minimal?: boolean;
}

export const ButtonGroup: React.FC<IButtonGroupProps> = (props) => {
  return <BPButtonGroup {...props} />;
};
