import React, {useCallback} from "react";
import {useHistory, useParams} from "react-router-dom";
import {
    useAddSystemColumnValue,
    useSystemColumns,
    useSystemColumnValue,
    useUpdateSystemColumnValue
} from "./api";
import {useSTPMappingSystemColumnContext} from "../../STPMappingSystemColumns/STPMappingSystemColumnsContext";
import Back from "../../STPMappingSystemColumns/Nav/Back";
import SystemColumnValueEditOrUpdateForm from "../../STPMappingSystemColumns/Form/SystemColumnValueEditOrUpdateForm";
import {errorToaster, successToaster} from "../../../helpers";

const EditForm = ({systemColumnValueId, columns, history}) => {
    const {reloadSystemColumns, reloadSystemColumnValues} = useSTPMappingSystemColumnContext()
    const {refetch: updateSystemColumnValue} = useUpdateSystemColumnValue(systemColumnValueId)
    const {data, loading, refetch: reloadSystemColumnValue} = useSystemColumnValue(systemColumnValueId);
    const onSubmit = useCallback((data) => {
        const submit = async () => {
            updateSystemColumnValue({data}).then(() => successToaster('System Column Value updated')).catch(()=> errorToaster('Failed to update System Column Value'));
            await reloadSystemColumnValue();
            await reloadSystemColumns();
            await reloadSystemColumnValues();
            history.goBack();
        }
        submit();
    }, [history, updateSystemColumnValue, reloadSystemColumns, reloadSystemColumnValue, reloadSystemColumnValues])
    return !loading && data && columns && <SystemColumnValueEditOrUpdateForm data={data} onSubmit={onSubmit} columns={columns}/>
};

const AddForm = ({columns, history}) => {
    const {reloadSystemColumns, reloadSystemColumnValues} = useSTPMappingSystemColumnContext()
    const {refetch: addSystemColumnValue} = useAddSystemColumnValue()
    const onSubmit = useCallback((data) => {
        const submit = async () => {
            addSystemColumnValue({data}).then(() => successToaster('System Column Value created')).catch(()=> errorToaster('Failed to create System Column Value'));
            await reloadSystemColumns();
            await reloadSystemColumnValues();
            history.goBack();
        }
        submit();
    }, [history, addSystemColumnValue, reloadSystemColumns, reloadSystemColumnValues])
    return <SystemColumnValueEditOrUpdateForm data={null} onSubmit={onSubmit} columns={columns}/>
};

const STPMappingSystemColumnValueForm = () => {
    const {systemColumnValueId} = useParams();
    const {data: columns, loading} = useSystemColumns();
    const history = useHistory();
    return <>
        <Back title='All values'/>
        {loading || (systemColumnValueId ?
            <EditForm systemColumnValueId={systemColumnValueId} columns={columns} history={history}/> :
            <AddForm columns={columns} history={history}/>)}
    </>
};

export default STPMappingSystemColumnValueForm;
