import { Checkbox as BPCheckbox, ICheckboxProps as BPICheckboxProps } from '@blueprintjs/core';
import React from 'react';
import '../../../assets/scss/core.scss';

export interface ICheckboxProps extends BPICheckboxProps {
  checked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  label?: string;
  large?: boolean;
  error?: boolean;
}

export const Checkbox: React.FC<ICheckboxProps> = (props) => {
  const { error, className, ...res } = props;
  const newProps = {
    ...res,
    className: error ? `${className || ''}error` : className,
  };
  return <BPCheckbox {...newProps} />;
};
