import React, {useState, useEffect, useContext, useRef} from "react";
import {Card} from "rt-design-system-backup";
import { getTime, url } from "../../../helpers";
import SocketContext from '../../../App/SocketContext';
import Axios from "axios";
import { Icon } from "rt-design-system-backup";
import { MARINE_CARD_BACKGROUND_COLOR } from "../../../helpers/constants";


const WTIBrentValueCell = (
    {
      children, created_at = '', enableTransition = false, fontColor = null
    }
  ) => {
    const [transitionColor, setTransitionColor] = useState('');
    const [prevChild, setPrevChild] = useState(children);

    useEffect(() => {
        console.log('Test0', children, prevChild, created_at, enableTransition);
        if (enableTransition && children !== '-' && prevChild !== '-') {
          try {
             // eslint-disable-next-line
             const currChild = parseFloat(children).toFixed(2);
             // eslint-disable-next-line
             const preChild = parseFloat(prevChild).toFixed(2);
             console.log('Test1', children, prevChild, created_at);
             if (currChild === preChild) {
                 setTransitionColor('orange');
             } else if(currChild > preChild){
                 setTransitionColor('#8DF58D');
             } else if (currChild < preChild) {
                 setTransitionColor('#FD4A4A');
             } else {
                 setTransitionColor('#8DF58D');
             }
          } catch (e) {}
            setTimeout(() => {setTransitionColor('')}, 2000);
        }
        if (prevChild !== children) setPrevChild(children);
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[children, created_at])
  
    return <p
        style={{backgroundColor: transitionColor, color: fontColor !== null ? fontColor : '', padding: 0}}
    >
        { children }
    </p>
  }

const WtiBrentComponent = ({route, title, isDarkModeOn}) => {
    const [data, setData] = useState(undefined)
    const [close, setClose] = useState(undefined)
    const [enableTransition, setEnableTransition] = useState(false);

    const socket = useContext(SocketContext)
    const fetchDataTimeout = useRef(() => { })
    useEffect(() => {
      const fetchData = async () => {
        clearTimeout(fetchDataTimeout.current)
        try {
            const brentData = await Axios.get(url(`/offer/get_wti_brent_data/?route=${route}`))
            const response = await Axios.get(url(`/close/?route=${route}`))
            setData(brentData.data);
            setClose(response.data);
            setEnableTransition(true);
        } catch (err) {
            console.log(err)
            fetchDataTimeout.current = setTimeout(fetchData, 30000)
        }
      }

      fetchData()

      return () => {
          clearTimeout(fetchDataTimeout.current)
      }
    }, [route])

    useEffect(()=> {
        const bunkerOfferUpdate = (socketdata) => {
            const routeID = socketdata.detail.route
            const tempoffers = socketdata.detail.offers;
            if (routeID.includes(route)) {
                let offer = tempoffers.find((offer) => offer.chartData.route === route);
                offer = offer.tableData
                const tempData = {
                    value: offer,
                    trend: offer.value >= data.value.value ? 1 : -1
                }
                setData(tempData)
            }
        }
        socket.addEventListener("bunker_offer_update", bunkerOfferUpdate);
        return () => socket.removeEventListener("bunker_offer_update", bunkerOfferUpdate)
      })



    const WtiBrentPTag = ({fontWeight, color, text, padding = 0, margin = 0}) => {
        return (
            <p style={{padding: padding, fontWeight: fontWeight, 
                       color: color, marginLeft: margin}}
            >
                {text}
            </p>
        )
    }

    const calculateChange = () => {
        if (data !== undefined && close !== undefined && close !== '') {
            return `${parseFloat(((data.value.value-close)/close)*100).toFixed(2)}%`
        }
        return '-'
    }

    const getChangeFontColor = () => {
        const change = calculateChange()
        if (change === '-') return 'orange'
        else if (parseFloat(change) > 0) return 'green'
        else return 'red'
    }

    return (
        <Card elevation={1} style={{padding: "7px 10px",  background: isDarkModeOn? MARINE_CARD_BACKGROUND_COLOR: '', color: isDarkModeOn? 'white': ''}}>
            <WtiBrentPTag fontWeight={700} color={isDarkModeOn ? '#0874c4': '#002c5f'} text={title}/>
            <div style={{display: '-webkit-inline-box'}}>
                {data && data.trend > 0 ? (
                    <Icon color="green" iconSize={14} icon="arrow-up" />
                ): data && data.trend < 0 ? (
                    <Icon color="red" iconSize={14} icon="arrow-down" />
                ): (
                    <Icon color="orange" iconSize={14} icon="arrow-left-right" />
                )}
                <WTIBrentValueCell created_at={getTime(data ? data.value : undefined)} enableTransition={enableTransition}
                    fontColor={isDarkModeOn ? 'white': ''}
                >
                    {data === undefined ? '-' :  Number(data.value.value).toFixed(2)}
                </WTIBrentValueCell>
                <WtiBrentPTag fontWeight={700} color={getChangeFontColor()} 
                    text={calculateChange()} margin={10}/>
                <WtiBrentPTag fontWeight={700} color={isDarkModeOn ? 'white': 'black'} 
                    text={close === undefined || close === '' ? '-' : Number(close).toFixed(2)} margin={10}/>
            </div>
            
        </Card>
    )
}
export default WtiBrentComponent;
