import React from 'react';
import '../../assets/scss/core.scss';
import {
  IPopoverProps as BPIPopoverProps,
  Popover as BpPopover,
  PopoverInteractionKind,
  PopoverPosition,
} from '@blueprintjs/core';

export interface IPopoverProps extends BPIPopoverProps {
  interactionKind?: PopoverInteractionKind;
  isOpen?: boolean;
  minimal?: boolean;
  position?: PopoverPosition;
  usePortal?: boolean;
}

export const Popover: React.FC<IPopoverProps> = (props) => {
  return <BpPopover {...props} />;
};
