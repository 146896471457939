import {Redirect} from "react-router-dom"
import {useContext, useEffect, useState} from "react"

import {Spinner} from "rt-design-system-backup"

import AppContext from "../AppContext"

const Logout = () => {
    const [done, setDone] = useState(false);

    const {refreshUser} = useContext(AppContext);

    // const [, logout] = useAxios(
    //     {
    //         url: url('auth/logout/'),
    //         method: 'POST',
    //         withCredentials: true,
    //         headers: {
    //             "X-CSRFToken": getCSRF()
    //         }
    //     },
    //     {
    //         manual: true
    //     }
    // )

    const logout = () => {
      localStorage.clear()
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        (async () => {
            try {
                await logout();
                await refreshUser()
            } catch (err) {
            }
            setDone(true);
        })()
    }, []);
    /* eslint-enable react-hooks/exhaustive-deps */

    return <>
        {
            !done ? <div style={{
                padding: '32px',
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translateX(-50%) translateY(-50%)"
            }}>
                <Spinner size={48}/>
            </div> : <Redirect to="/login"/>
        }
    </>
};

export default Logout
