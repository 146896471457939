import React from 'react';
import '../../../assets/scss/core.scss';
import {
  DateRangePicker as BPDateRangePicker,
  IDateRangePickerProps as BPIDateRangePickerProps,
  IDateRangeShortcut,
} from '@blueprintjs/datetime';

export interface IDateRangePickerProps extends BPIDateRangePickerProps {
  allowSingleDayRange?: boolean;
  contiguousCalendarMonths?: boolean;
  shortcuts?: boolean | IDateRangeShortcut[];
  singleMonthOnly?: boolean;
  highlightCurrentDay?: boolean;
  reverseMonthAndYearMenus?: boolean;
}

export const DateRangePicker: React.FC<IDateRangePickerProps> = (props) => {
  return <BPDateRangePicker {...props} />;
};
