import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Card } from "rt-design-system-backup"
import {MARINE_CARD_BACKGROUND_COLOR} from "../../../../helpers/constants";

const MetricBarChart = ({data, isDarkModeOn, BackgroundColor="#002060", showCustomToolTip=false}) => {
    ChartJS.register(
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      Tooltip,
      Legend
    );

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        animation: {
            duration: 0
        },
        scales: {
          x: {
            beginAtZero: true,
            grid:{
                display: false,
                color: isDarkModeOn ? 'white': ''
            },
            ticks: {
                color: isDarkModeOn ? 'white': ''
            },
            stacked: true,
          },
          y: {
              stacked: true,
              grid: {
                 borderDash: [8,4],
                 color: isDarkModeOn ? 'white': 'lightgrey'
              },
              ticks: {
                 color: isDarkModeOn ? 'white': 'black'
              },
          },
        },
        plugins: {
          legend: {
              display: false,
          },
          title: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                let offerString = "Offer";
                let messageString = "message";
                if (context.raw > 1) {
                  offerString = "Offers";
                  messageString = "messages"
                }
                if (showCustomToolTip === false) {
                  return context.formattedValue + offerString;
                }
                if(context.datasetIndex === 1){
                  return context.formattedValue + " unread " + messageString;
                } else {
                  return context.formattedValue + " read " + messageString;
                }
              }
            }
          }
        },
    };
    const dataset = {
        labels: data.labels,
        datasets: [
          {
            data: data.data,
            backgroundColor: BackgroundColor,
          },
          'unparsed_data' in data && {
            data: data.unparsed_data,
            backgroundColor: '#8DF58D'
          },
        ]
    };

    return (
        <Card elevation={1}
          style={{padding: '10px 14px 10px 18px', borderRadius: '8px', height: '320px',
              background: isDarkModeOn? MARINE_CARD_BACKGROUND_COLOR: '',
              color: isDarkModeOn? 'white': 'black',
        }}>
            <Bar data={dataset} options={options} />
        </Card>
    );
}
export default MetricBarChart;
