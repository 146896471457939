import React, {useEffect, useState} from "react";
import useAxios from "axios-hooks";

import { Input, Space, Table } from "antd";
import { Spinner, Button } from "rt-design-system-backup";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { dateFormat } from "../../Maturities";

import { url, getFontColorDelta } from "../../../../helpers";
import { formatNumber } from "../utils";
import { css } from "@emotion/css";
import "./style.css"

const tableCSS = css({
    '.ant-table-content .bp3-icon svg': {
        width: '20px',
        height: '20px',
    },
    '.ant-table-thead  tr  th': {
      'fontSize': '13px',
      'lineHeight': '18px'
    },
    '.ant-table-tbody  tr  td': {
      'fontSize': '13px',
      'lineHeight': '18px'
    },
});

const CSRTable = ({isExtraLargeScreen, start_date, end_date, selectedCustomer}) => {
    const [filteredInfo, setFilterInfo] = useState({});
    const [{loading: csrLoading, data: CSRs = []}, refetch] = useAxios({
        url: url(`/aluminium_analytics/fetch_total_pnl/?attribute=requestor&start_date=${dateFormat.formatDate(start_date)}&end_date=${dateFormat.formatDate(end_date)}${selectedCustomer !== -1 ? '&customer=' + selectedCustomer : ''}`)
    })
    const initial_search_text = localStorage.getItem('csr_filter_id')
    const [searchText, setSearchText] = useState(initial_search_text || "initState");

    const handleChange = (pagination, filters, sorter) => {
        setFilterInfo(filters);

        Object.keys(filters).forEach(filter => {
        if (filters[filter]) {
            localStorage.setItem(`csr_csr_filter_${filter}`, filters[filter].join())
        } else {
            localStorage.setItem(`csr_csr_filter_${filter}`, '-')
        }
        })
    };
    
    useEffect(()=>{
      if (!selectedCustomer && !start_date && !end_date) return
      refetch()
      // eslint-disable-next-line
    }, [selectedCustomer, start_date, end_date])
    
    const handleSearch = (selectedKeys, confirm) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    const columns = [
        {
            title: "CSR",
            dataIndex: "label",
            sorter: Array.sort,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
              }) => (
                <div style={{ padding: 8 }}>
                  <Input
                    placeholder={`Search CSR`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                      setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm)}
                    style={{ width: 160, marginBottom: 8, display: "block" }}
                  />
                  <Space size={"large"}>
                    <Button
                      intent="secondary"
                      onClick={() => handleReset(clearFilters)}
                      size="small"
                      style={{ minHeight: "24px", minWidth: 60 }}
                    >
                      Reset
                    </Button>
                    <Button
                      intent="primary"
                      icon={<SearchOutlined />}
                      onClick={() => handleSearch(selectedKeys, confirm)}
                      size="small"
                      style={{ minHeight: "24px", minWidth: 60 }}
                    >
                      Search
                    </Button>
                  </Space>
                </div>
            ),
            filterIcon: (filtered) => (
                <SearchOutlined
                  style={{ color: filtered ? "#1890ff" : "#8e8e8e", fontSize: "16px" }}
                />
            ),
            filteredValue: filteredInfo.label || null,
            onFilter: (value, record) => record.label && record.label === value,
            render: (text) => (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text || ""}
                />
            )
        },
        {
          title: "Qty",
          dataIndex: "total_quantity",
          sorter: (a, b) => {
            return a.total_quantity - b.total_quantity;
          },
          sortDirections: ["descend", "ascend"],
          render: (value) => {
            return (
              <div style={{ whiteSpace: "nowrap"}}>
                {formatNumber(value, false, 0)}
              </div>
            );
          },
        },
        {
            title: "TTL Pnl",
            dataIndex: "ttl_pnl",
            sorter: (a, b) => {
              return a.ttl_pnl - b.ttl_pnl;
            },
            sortDirections: ["descend", "ascend"],
            render: (value) => {
              return (
                <div style={{ whiteSpace: "nowrap", color: getFontColorDelta(value) }}>
                  {formatNumber(value, false, 0)}
                </div>
              );
            },
        }
    ]
    return (
      <div style={{"marginTop": "10px"}}>
        <Table
            className={tableCSS}
            locale={{
                emptyText: "No CSRs to display",
            }}
            loading={
                csrLoading
                ? {
                    delay: 1500,
                    indicator: <Spinner size={32} />,
                }
                : false
            }
            scroll={{
            x: CSRs && CSRs.length > 0,
            y: isExtraLargeScreen === true ? 300 : 200,
            }}
            bordered
            size="medium"
            expandIconColumnIndex={13}
            columns={columns}
            dataSource={CSRs}
            tableLayout="auto"
            pagination={false}
            onChange={handleChange}
        />    
      </div>
    )
}
export default CSRTable;
