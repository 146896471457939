import React from 'react';
import {Table} from "antd";
import {css} from "@emotion/css";
import {numericRenderBuilder} from "../../RequestMaturityPrices/columns";
import {capitalize} from "../../../helpers";


const tableCSS = css({
  '.ant-table-content':{
    boxShadow: 'none',
  },
  '.ant-table-thead, .ant-table tr, .ant-table-tbody > tr > td': {
    border: 'none',
  },
  '.ant-table.ant-table-small .ant-table-thead > tr > th, .ant-table.ant-table-small .ant-table-tbody > tr > td': {
    padding: '5px 0px',
  },
  '.ant-table-small .ant-table-thead > tr > th': {
    backgroundColor: "white",
  },
  'thead > tr > th': {
    verticalAlign: 'top',
    fontSize: '14px',
    lineHeight: '22px',
    border: 'none',
    color: '#000000',
    textAlign: 'center',
  },
  '.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before': {
    content: 'none',
  },
  '.ant-table-tbody > tr > td': {
    fontSize: '13px',
  },
  '.ant-table.ant-table-small .ant-table-tbody .ant-table-row .ant-table-cell , .ant-table-cell': {
    padding: 0,
  },
  'tbody > tr:nth-child(odd) > td': {
    backgroundColor: "#F5F5F5",
  },
  'tr': {
    textAlign: 'center',
  },
});

const columns = [
  {
    title: "",
    dataIndex: "date",
    render: capitalize,
  },
  {
    title: "LME",
    dataIndex: "LME",
    className: "quantity",
    render: numericRenderBuilder(2),
  },
  {
    title: "CME MJP",
    dataIndex: "MJP",
    className: "quantity",
    render: numericRenderBuilder(2),
  },
  {
    title: "CME MWP",
    dataIndex: "MWP",
    className: "quantity",
    render: numericRenderBuilder(2),
  },
  {
    title: "CME EDP",
    dataIndex: "EDP",
    className: "quantity",
    render: numericRenderBuilder(2),
  },
]

const AggregatedData = ({data}) => {
  return (
    <div style={{
      background: '#FFFFFF', padding: '16px', borderRadius: '8px', width: '351px',
      marginRight: 16, boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.13)'
    }}>
      <p style={{fontSize: '14px', marginBottom: 10}}>Aggregate Qty</p>
      <Table
        pagination={false}
        dataSource={data}
        className={tableCSS}
        scroll={{x: true}}
        size="small"
        columns={columns}
      />
    </div>
  );
};

export default AggregatedData;
