import React, { useCallback, useEffect, useState } from "react";
import Page from "../../Page";
import ConfigurationForm from "./ConfigurationForm";
import TableInput from "../../TableInput";
import { formatNumber, isValidNumber, renameInstrument, url } from "../../../helpers";
import useAxios from "axios-hooks";
import { Icon, Popover, Position } from "rt-design-system-backup";
import TierCustomerComponent from "./TierCustomer";


const Configuration = ({ configType = 'LMEPrice' }) => {
  const [{ data: validity_margins }, refetch_validity_margings] = useAxios({
    url: url('/validity_margin/'),
  });

  const [{ data: volume_uptick }, refetch_volume_uptick] = useAxios({
    url: url('/volume_uptick/'),
  });

  const [{ data: volatility_factor }, refetch_volatility_factor] = useAxios({
    url: url('/volatility_factor/'),
  });

  const [{ data: maturity_margin }, refetch_maturity_margin] = useAxios({
    url: url('/maturity_margin/'),
  });

  const [{ data: market_premium }, refetch_market_premium] = useAxios({
    url: url('/market_premium/'),
  });

  const [editingValidityMargin, setEditingValidityMargin] = useState(false)
  const [payloadValidityMargin, setPayloadValidityMargin] = useState({ })
  const [editingVolumeUptick, setEditingVolumeUptick] = useState(false)
  const [payloadVolumeUptick, setPayloadVolumeUptick] = useState({ })
  const [editingMaturityMargin, setEditingMaturityMargin] = useState(false)
  const [payloadMaturityMargin, setPayloadMaturityMargin] = useState({ })
  const [editingVolatilityFactor, setEditingVolatilityFactor] = useState(false)
  const [payloadVolatilityFactor, setPayloadVolatilityFactor] = useState(0)
  const [editingMKP, setEditingMKP] = useState(false)
  const [payloadMKP, setPayloadMKP] = useState({ })
  const [newVolumeUpticks, setNewVolumeUpticks] = useState(0)
  const [isValid, setIsValid] = useState(true)

  useEffect(() => {
    return () => {
      setEditingMaturityMargin(false)
      setEditingValidityMargin(false)
      setEditingVolatilityFactor(false)
      setEditingVolumeUptick(false)
      setEditingVolumeUptick(false)
    }
  }, [])

  useEffect(() => {
    if (volatility_factor) {
      setPayloadVolatilityFactor(volatility_factor['data'])
    }
    return () => {
    };
  }, [volatility_factor]);

  const validityMargin = (validity_margins && validity_margins['data'].map(value => {
    return value
  }
  )) || []

  const validityMarginCol = [
    {
      title: "Validity",
      dataIndex: "validity",
      render: x => <>
        <span style={{ fontSize: '16px' }}>{x}</span>
        {x === 'Past Cutoff' ? <span className="for-correction-tooltip">
          <Popover
            content={
              <div style={{
                backgroundColor: '#002C5F',
                color: 'white',
                padding: 10,
                borderRadius: 10,
                maxWidth: 235,
                marginBottom: 8,
              }}>
                Margin to be applied if validity is past the daily cut-off time (London 19:00)
              </div>
            }
            minimal
            position={Position.TOP}>
            <Icon style={{ color: '#706F6F' }} icon={'info-sign'} />
          </Popover>
        </span> : null}
      </>,
    },
    {
      title: "Margin ($)",
      dataIndex: "margin",
      render: value => {
        return <TableInput
          required
          tabbable={editingValidityMargin}
          enabled={editingValidityMargin}
          value={formatNumber(value['value'], false, 2, false)}
          isInvalid={(str) => {
            const error = isValidNumber(str, true, true)
            if (error) {
              setIsValid(false)
            }
            return error
          }}
          onBlurCall={(str) => setTimeout(() => setIsValid(true), 1000)}
          onChange={(x) => {
            setPayloadValidityMargin({
              ...payloadValidityMargin,
              [value['id']]: {
                ...(payloadValidityMargin[value['id']] || { }),
                'margin': x
              }
            })
            setIsValid(true)
          }}
          id={'margin'}
        />
      }
    },
  ]

  const [volumeUptick, setVolumeUptick] = useState([])

  const reinitializeVolumeUptick = useCallback(() => {
    if (volume_uptick) {
      setVolumeUptick(volume_uptick['data'].map(value => {
        return {
          'minimum': { 'id': value.id, 'value': payloadVolumeUptick[value.id] && payloadVolumeUptick[value.id]['minimum'] ? payloadVolumeUptick[value.id]['minimum'] : value.minimum },
          'maximum': { 'id': value.id, 'value': payloadVolumeUptick[value.id] && payloadVolumeUptick[value.id]['maximum'] ? payloadVolumeUptick[value.id]['maximum'] : value.maximum },
          'surcharge': { 'id': value.id, 'value': payloadVolumeUptick[value.id] && payloadVolumeUptick[value.id]['surcharge'] ? payloadVolumeUptick[value.id]['surcharge'] : value.surcharge },
          'id': `${value.id}`,
        }
      }).concat([...Array(newVolumeUpticks).keys()].map(data => {
        return {
          'minimum': { 'id': `new_min_${data}`, 'value': payloadVolumeUptick[`new_min_${data}`] && payloadVolumeUptick[`new_min_${data}`]['minimum'] ? payloadVolumeUptick[`new_min_${data}`]['minimum'] : '' },
          'maximum': { 'id': `new_max_${data}`, 'value': payloadVolumeUptick[`new_max_${data}`] && payloadVolumeUptick[`new_max_${data}`]['maximum'] ? payloadVolumeUptick[`new_max_${data}`]['maximum'] : '' },
          'surcharge': { 'id': `new_sur_${data}`, 'value': payloadVolumeUptick[`new_sur_${data}`] && payloadVolumeUptick[`new_sur_${data}`]['surcharge'] ? payloadVolumeUptick[`new_sur_${data}`]['surcharge'] : '' },
          'id': `new_${data}`,
        }
      })).filter(data => {
        return !(payloadVolumeUptick['delete'] && payloadVolumeUptick['delete'].includes(`${data.id}`))
      }))
    } else {
      setVolumeUptick([])
    }
  }, [payloadVolumeUptick, volume_uptick, newVolumeUpticks])

  useEffect(() => {
    reinitializeVolumeUptick()
  }, [reinitializeVolumeUptick, payloadVolumeUptick, volume_uptick])

  const handleCancelVolumeUptick = () => {
    setNewVolumeUpticks(0)
    setPayloadVolumeUptick({ })
    refetch_volume_uptick();
  }

  const volumeUptickCol = [
    {
      title: "Minimum",
      dataIndex: "minimum",
      render: value =>
        <TableInput
          required
          tabbable={editingVolumeUptick}
          enabled={editingVolumeUptick}
          key={`min_${value['id']}`}
          value={value['value']}
          isInvalid={(str) => {
            const error = isValidNumber(str, true, true)
            if (error) {
              setIsValid(false)
            }
            return error
          }}
          onBlurCall={(str) => setTimeout(() => setIsValid(true), 1000)}
          onChange={(x) => {
            setPayloadVolumeUptick({
              ...payloadVolumeUptick,
              [value['id']]: {
                ...(payloadVolumeUptick[value['id']] || { }),
                'minimum': x
              }
            })
          }}
          id={'margin'}
        />
    },
    {
      title: "Maximum",
      dataIndex: "maximum",
      render: value =>
        <TableInput
          required
          tabbable={editingVolumeUptick}
          enabled={editingVolumeUptick}
          key={`max_${value['id']}`}
          value={value['value']}
          isInvalid={(str) => {
            const error = isValidNumber(str, true, true)
            if (error) {
              setIsValid(false)
            }
            return error
          }}
          onBlurCall={(str) => setTimeout(() => setIsValid(true), 1000)}
          onChange={(x) => {
            setPayloadVolumeUptick({
              ...payloadVolumeUptick,
              [value['id']]: {
                ...(payloadVolumeUptick[value['id']] || { }),
                'maximum': x
              }
            })
          }}
          id={'margin'}
        />
    },
    {
      title: "Uptick ($)",
      dataIndex: "surcharge",
      render: value =>
        <TableInput
          required
          tabbable={editingVolumeUptick}
          enabled={editingVolumeUptick}
          key={`sur_${value['id']}`}
          value={formatNumber(value['value'], false, 2, false)}
          isInvalid={(str) => {
            const error = isValidNumber(str, true, true)
            if (error) {
              setIsValid(false)
            }
            return error
          }}
          onBlurCall={(str) => setTimeout(() => setIsValid(true), 1000)}
          onChange={(x) => {
            setPayloadVolumeUptick({
              ...payloadVolumeUptick,
              [value['id']]: {
                ...(payloadVolumeUptick[value['id']] || { }),
                'surcharge': x
              }
            })
          }}
          id={'margin'}
        />
    },
    {
      title: "",
      dataIndex: "id",
      render: value => <>
        <Icon
          style={editingVolumeUptick ? { cursor: 'pointer' } : { }}
          onClick={editingVolumeUptick ? () => {
            let newPayload = { ...payloadVolumeUptick }
            delete newPayload[value]

            setPayloadVolumeUptick({
              ...newPayload,
              'delete': [...(payloadVolumeUptick['delete'] || []), value],
            })
            reinitializeVolumeUptick(value)
          } : () => { }
          }
          icon="remove" color={editingVolumeUptick ? '#00438f' : '#a3a3a3'} />
      </>
    }
  ]

  const maturityMargin = { };
  ((maturity_margin && maturity_margin['data']) || []).forEach(value => {
    maturityMargin[value['label']] = { 'id': value['id'], 'margin': value['margin'] }
  })

  const maturityMarginCol = (maturity_margin && maturity_margin['data'].map(value => {
    return {
      title: value['label'],
      dataIndex: value['label'],
      render: value => editingMaturityMargin ?
        <TableInput
          required
          style={{ width: 60 }}
          tabbable={true}
          enabled={true}
          value={formatNumber(value['margin'], false, 2, false)}
          isInvalid={(str) => {
            const error = isValidNumber(str, true, true)
            if (error) {
              setIsValid(false)
            }
            return error
          }}
          onBlurCall={(str) => setTimeout(() => setIsValid(true), 1000)}
          onChange={(x) => {
            setPayloadMaturityMargin({
              ...payloadMaturityMargin,
              [value['id']]: x
            })
          }}
          id={'margin'}
        /> : <p style={{ width: 60, margin: '0', paddingLeft: '1px' }}>{formatNumber(value['margin'], false, 2, false)}</p>
    }
  })) || []

  const MKPValues = ((market_premium && market_premium['data']) || []).map((value) => {
    value.Premium.value = renameInstrument(value.Premium.value)
    return value});

  const MKPCol = (market_premium && Object.keys(market_premium['data'][0]).filter(x => x !== 'id').map(key => {
    return {
      title: key,
      dataIndex: key,
      render: value =>
        <TableInput
          required
          tabbable={!['Premium', 'Unit'].includes(key) && editingMKP}
          enabled={!['Premium', 'Unit'].includes(key) && editingMKP}
          value={formatNumber(value ? value['value'] : null, false, 2, false)}
          isInvalid={(str) => {
            const error = isValidNumber(str, true, true)
            if (error) {
              setIsValid(false)
            }
            return error
          }}
          onBlurCall={(str) => setTimeout(() => setIsValid(true), 1000)}
          onChange={(x) => {
            setPayloadMKP({
              ...payloadMKP,
              [value['id']]: x
            })
          }}
          id={'margin'}
        />
    }
  })) || []

  const VolatileFactorInput =
    <>
      <p style={{ marginTop: 15 }}>The factor is a multiplier, and must be between 1 and 8.</p>
      <div style={{ marginTop: 20 }}>
        <TableInput
          required
          style={{ width: 340, fontSize: '16px' }}
          tabbable={true}
          enabled={true}
          key={`volatility_factor_${payloadVolatilityFactor}`}
          disabled={!editingVolatilityFactor}
          value={payloadVolatilityFactor}
          isInvalid={(str) => {
            let error = null
            if ("" + parseFloat(str) !== str) {
              error = "Numeric value needed";
            } else if (parseFloat(str) > 8) {
              error = "Value cannot exceed 8";
            } else if (parseFloat(str) < 1) {
              error = "Value must be greater than or equal to 1";
            }
            if (error) {
              setIsValid(false)
              return error
            }
          }}
          onBlurCall={(str) => setTimeout(() => setIsValid(true), 1000)}
          onChange={(x) => setPayloadVolatilityFactor(x)}
          id={'VolatileFactor'}
        />
      </div>
    </>

  return (
    <>
      <h1 style={{ fontSize: '32px' }}>Configurations</h1>
      <Page
        title=""
        tabs={[
          { title: 'Market Premium', to: '/aluminium/configuration/MarketPremium' },
          { title: 'LME Price', to: '/aluminium/configuration/LMEPrice' },
          { title: 'Tier Customer', to: '/aluminium/configuration/TierCustomer' }
        ]}
      >
        {configType === "LMEPrice" ?
          <>
            <ConfigurationForm title={'Validity Margin'} dataSource={validityMargin} columns={validityMarginCol}
              endpoint={'/validity_margin/custom_update/'}
              lastUpdated={validity_margins && validity_margins['last_updated']}
              editing={editingValidityMargin} setEditing={setEditingValidityMargin}
              data={payloadValidityMargin} refetch={refetch_validity_margings} isValid={isValid}/>
            <br /><br /><br />
            <ConfigurationForm title={'Volume Uptick'} dataSource={volumeUptick} columns={volumeUptickCol}
              endpoint={'/volume_uptick/custom_update/'} validation={volumeUptick}
              lastUpdated={volume_uptick && volume_uptick['last_updated']}
              editing={editingVolumeUptick} setEditing={setEditingVolumeUptick}
              data={payloadVolumeUptick} refetch={() => { handleCancelVolumeUptick() }}
              add={() => setNewVolumeUpticks(newVolumeUpticks + 1)} isValid={isValid}/>
            <br /><br /><br />
            <ConfigurationForm title={'Maturity Margin'} dataSource={[maturityMargin]} columns={maturityMarginCol}
              endpoint={'/maturity_margin/custom_update/'}
              lastUpdated={maturity_margin && maturity_margin['last_updated']}
              editing={editingMaturityMargin} setEditing={setEditingMaturityMargin}
              refetch={refetch_maturity_margin} data={payloadMaturityMargin} fullWidth={true} isValid={isValid}/>
            <br /><br /><br />
            <ConfigurationForm title={'Volatility Factor'} dataSource={[]} columns={[]}
              endpoint={'/volatility_factor/custom_update/'}
              data={{ 'volatility_factor': payloadVolatilityFactor }} editing={editingVolatilityFactor}
              setEditing={setEditingVolatilityFactor} input={VolatileFactorInput} halfWidth={true}
              refetch={refetch_volatility_factor} lastUpdated={volatility_factor && volatility_factor['last_updated']} isValid={isValid}/>
          </> 
        : configType === "MarketPremium" ?
          <ConfigurationForm title={'Market Premium Curve Configuration'} dataSource={MKPValues} columns={MKPCol}
            endpoint={'/market_premium/custom_update/'} data={payloadMKP} editing={editingMKP}
            setEditing={setEditingMKP} lastUpdated={market_premium && market_premium['last_updated']}
            refetch={refetch_market_premium} fullWidth={true} isValid={isValid}/>
        :
        <>
          <TierCustomerComponent />
        </> 
        }
        <br /><br /><br />
      </Page>
    </>
  );
}

export default Configuration;
