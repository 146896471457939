import React from "react";
import {Dialog, Icon, Spinner} from "rt-design-system-backup";

const differingKeys = (o1, o2) => Object.keys(o2).filter(k => o1[k] !== o2[k]);
const isMissing = (k, actual) => !(k in actual);
const MissingMessage = ({field}) => <>{field} is missing.</>
const DifferMessage = ({field, expected, actual}) => <>{field} expected to be {expected[field]} instead
    of {actual[field]}.</>
const Diffs = ({actual, expected}) => <ul>
    {differingKeys(actual, expected).map((field) =>
        <li>
            {isMissing(field, actual)
                ? <MissingMessage field={field}/>
                : <DifferMessage actual={actual} expected={expected} field={field}/>
            }
        </li>
    )}
</ul>

const ValidationDialog = ({title, onClose, children}) => <Dialog
    className="modal"
    isOpen={true}
    hasBackdrop={true}
    canEscapeKeyClose={true}
    canOutsideClickClose={true}
    onClose={onClose}
    style={{maxWidth: "548px", width: "100%"}}
    title={title}
>
    <div style={{padding: "0px 32px 0 32px"}}>
        <div style={{
            textAlign: "center",
            paddingTop: "30px"
        }}>
            {children}
        </div>
    </div>
</Dialog>

const Result = ({result}) => <>
    <h3>Alu trade #{result.alu_trade}</h3>
    <Diffs actual={result.actual_payload} expected={JSON.parse(result.expected_payload)}/>
</>

const Results = ({onClose, results}) => <ValidationDialog title="Validation errors" onClose={onClose}>
    <div style={{textAlign: "left"}}>
        <h2>The expected output does not match for the following trades:</h2>
        {results.map(result => <Result result={result}/>)}
    </div>
</ValidationDialog>

const Success = ({onClose}) => <ValidationDialog title="The ruleset is valid" onClose={onClose}>
    <Icon icon="tick" size={100} intent="success"/>
</ValidationDialog>

const Loading = ({onClose}) => <ValidationDialog title="Validating" onClose={onClose}>
    <Spinner size={32}/>
</ValidationDialog>

const MappingValidationResults = ({validationState: {results, onClose, loading, show}}) => <>
    {
        show && (
            loading ? <Loading onClose={onClose}/> :
                results && results.length > 0 ? <Results onClose={onClose} results={results}/> :
                    <Success onClose={onClose}/>
        )
    }
</>

export default MappingValidationResults;
