import {useContext } from 'react'
import { TextArea, FormGroup } from 'rt-design-system-backup'
import SimpleTable from "../SimpleTable";
import RequestViewContext from "../RequestContext"

const RequestAdditional = ({ request, editable }) => {
  const {update} = useContext(RequestViewContext)
  return request.comments || editable ? <>
    <h2>Additional</h2>
    {editable ?
      <FormGroup label="Comments">
        <TextArea name="comments" style={{width: '655px'}} growVertically={true} onChange={(evt) => {
          update({
            comments: evt.target.value
          })
          }} defaultValue={request.comments || ""}></TextArea>
      </FormGroup> : <SimpleTable>
      <tr>
        <td>
          Comments:
        </td>
        <td>
          {request.comments}
        </td>
      </tr>
    </SimpleTable> }
  </>  : null
}

export default RequestAdditional
