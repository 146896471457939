import { Tabs as BPTabs, Tab as BPTab, Expander as BPExpander, ITabsProps as BPITabsProps } from '@blueprintjs/core';
import React from 'react';
import '../../assets/scss/core.scss';

export interface ITabsProps extends BPITabsProps {
  minimal?: boolean;
  children?: any;
}

export const Expander = BPExpander;
export const Tabs = (props: ITabsProps) => {
  const { minimal, className, ...rest } = props;
  const res = {
    ...rest,
    className: minimal ? className : `${className || ''}tabs-standard`,
  };
  return <BPTabs {...res} animate={false} />;
};

export const Tab = BPTab;
