import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useAxios from "axios-hooks";
import { Button, Tooltip, Icon } from "rt-design-system-backup";
import Page from "../../Page";
import STPMappingTable from "../../STPMappingTable";
import MappingValidationResults from "../../MappingValidation/MappingValidationResults";
import { useMappingValidation } from "../../MappingValidation/MappingValidationContext";
import { convertArrayToCSV, downloadCSV, url } from "../../../helpers";
import { mappingTableHeaders } from "../../../helpers/constants";

function reverseTransformationNexTrade(output) {
  let originalObject = {};
  output.forEach((item, i) => {
    let NexTradeFieldKey = `NexTradeField${i + 1}`;
    originalObject[NexTradeFieldKey] = item.ddp_field;
    item.possible_values.forEach((valueItem) => {
      let valueKey = `${NexTradeFieldKey}_Value`;
      if (typeof originalObject[valueKey] !== "object") {
        originalObject[valueKey] = [];
      }
      originalObject[valueKey].push(valueItem.possible_value);
    });
  });
  return originalObject;
}

export const singleMappingTransformer = (data) => {
  const {
    id,
    enabled,
    system,
    system_column,
    created_by,
    last_updated_by,
    output,
    conditions,
    updated_at,
  } = data;
  const ddpData = reverseTransformationNexTrade(conditions);
  const tempData = {
    RuleID: id,
    System: system,
    SystemColumnId: system_column.id,
    SystemColumnName: system_column.name,
    SystemColumnValue:
      output && output?.ddp_field
        ? output.ddp_field
        : output.specific_value.value,
    SpecificSystemColumnValue:
      output && output?.ddp_field ? output.ddp_field : output.specific_value.id,
    LastUpdated: updated_at,
    LastUpdatedBy: last_updated_by?.full_name,
    CreatedBy: created_by?.full_name,
    Status: enabled ? "Enabled" : "Disabled",
    ...ddpData,
  };
  return tempData;
};
export const mappingTransformer = (data) =>
  data.map((data) => singleMappingTransformer(data));

const ActionButton = ({ tooltipText, onClick, download, icon, text }) => (
  <Tooltip intent="primary" content={tooltipText}>
    <span
      onClick={onClick}
      href="#"
      download={download}
      style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
    >
      <span className="rtcmd-page-action-icon">
        <Icon icon={icon} />
      </span>
      <span style={{ fontSize: "16px", fontWeight: "normal", marginLeft: 12 }}>
        <u>{text}</u>
      </span>
    </span>
  </Tooltip>
);

const NavigButton = ({ location, text, history }) => (
  <Button
    onClick={() => history.push(location)}
    intent="primary"
    text={text}
    style={{ marginRight: "10px" }}
  />
);

const STPMapping = () => {
  const history = useHistory();
  const [{ data, loading }, refetch] = useAxios({
    url: url("mapping_rules/"),
  });
  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const validation = useMappingValidation();
  const validate = useCallback(
    (e) => {
      e.preventDefault();
      validation.validate();
    },
    [validation]
  );

  const mappings = data && mappingTransformer(data);
  const downloadCsv = (e, data) => {
    e.preventDefault();
    const csvData = convertArrayToCSV(mappingTableHeaders, data);
    downloadCSV(csvData, "mapping-rules.csv");
  };

  if (loading) {
    return <div>loading</div>;
  }
  return (
    <Page
      title="STP Mapping"
      tabs={[]}
      leftActions={
        <>
          <NavigButton
            history={history}
            location="/stp-mapping-console/new"
            text="New Mapping"
          />
          <NavigButton
            history={history}
            location="/stp-mapping-console/system-columns"
            text="Edit System Columns"
          />
        </>
      }
      rightActions={[
        <Tooltip intent="primary" content="Download ALL STP Mapping Rules">
          <a
            onClick={(e) => downloadCsv(e, mappings)}
            href="#0"
            download
            style={{ display: "flex", alignItems: "center" }}
          >
            <span className="rtcmd-page-action-icon">
              <Icon icon="arrow-down" />
            </span>
            <span
              style={{ fontSize: "16px", fontWeight: "normal", marginLeft: 12 }}
            >
              <u>Download</u>
            </span>
          </a>
        </Tooltip>,
        <ActionButton
          tooltipText="Validate STP Mapping Rules"
          onClick={validate}
          icon="tick"
          text="Validate"
        />,
      ]}
    >
      <MappingValidationResults validationState={validation} />
      <STPMappingTable data={mappings} />
    </Page>
  );
};
export default STPMapping;
