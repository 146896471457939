import {formatDate} from "../../helpers";

import SimpleTable from "../SimpleTable";
import React from "react";


const CopperOverview = ({request}) => {
  return (
    <div>
      <h4 style={{fontSize: '24px', lineHeight: '32px'}}>Order #{request.id}</h4>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <SimpleTable style={{ flex: "0 0 auto", fontSize: '14px', lineHeight: '22px'}}>
          <tr>
            <td>Status:</td>
            <td>{request.status}</td>
          </tr>
          <tr>
            <td>Order Creation Date:</td>
            <td>
              {formatDate(request.created_at, true, true)}
            </td>
          </tr>
          <tr>
            <td>Requestor:</td>
            <td>{request.requestor.full_name}</td>
          </tr>
          <tr>
            <td>Trader:</td>
            <td>{request.trader ? request.trader.full_name : '-'}</td>
          </tr>
          <tr>
            <td>Strategy:</td>
            <td>{request.strategy}</td>
          </tr>
          <tr>
            <td>Order Type:</td>
            <td>{request.order_type}</td>
          </tr>
          <tr>
            <td>Entity:</td>
            <td>{request.entity.name}</td>
          </tr>
          { request.customer ?
            <tr>
              <td>Customer:</td>
              <td>{request.customer && request.customer.display_name}</td>
            </tr> :
            <tr>
              <td>Entity to:</td>
              <td>{request.entity_to && request.entity_to.name}</td>
            </tr>
          }
        </SimpleTable>
      </div>
    </div>
  );
};

export default CopperOverview;
