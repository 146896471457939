import { formatNumber } from "../../helpers";

const numericRender = (value, decimals) => {
  return formatNumber(value, true, decimals);
};

export const numericRenderBuilder = (decimals) => {
  return (value) => numericRender(value, decimals);
};



const columns = (data, brokers, clearers, isEditable, handleEditStatus, handleChange, saveChanges) => {
  const inputStyles = {
    borderRadius: "5px",
    border: "none",
    padding: '2px 4px',
    height: "28px",
    width: "100px"
  }

  const buttonStyles = {
    backgroundColor: "transparent",
    outline: "none",
    border: "1px solid gray",
    borderRadius: "4px",
    cursor: 'pointer'

  }
  return [
    {
      title: "Trade",
      fixed: "left",
      dataIndex: "trade",
    },
    {
      title: "Time",
      dataIndex: "time",
    },
    {
      title: "Tenor",
      dataIndex: "tenor",
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (value, data) => isEditable === data.trade ? (
        <input
          style={inputStyles}
          type="number"
          name="price"
          placeholder={value}
          value={parseFloat(value.toString().replace(/,/g, ''))}
          onChange={(e) => handleChange('price', e.target.value)}
        />
      ) : value

    },
    {
      title: "Qty",
      dataIndex: "qty",
      render: (value, data) => isEditable === data.trade ? <input style={inputStyles} type="number" name="qty" value={value} onChange={(e) => handleChange('qty', e.target.value)} id={value} /> : value

    },
    {
      title: "Broker",
      dataIndex: "broker",
      render: (value, data) => isEditable === data.trade ?
        <select style={inputStyles} defaultValue={value} onChange={(e) => handleChange('broker', e.target.value)}>
          {brokers.map(d => <option value={d.id} key={d.id}>{d.short}</option>)}
        </select>
        : brokers?.find(d => d.id === value).short
    },
    {
      title: "Clearer",
      dataIndex: "clearer",
      render: (value, data) => isEditable === data.trade ?

        <select style={inputStyles} defaultValue={value} onChange={(e) => handleChange('clearer', e.target.value)}>
          {clearers.map((data) => <option key={data.id} value={data.clearer}>{data.clearer}</option>)}
        </select>
        : value
    },
    {
      title: "Trader",
      dataIndex: "trader"
    },
    {
      title: "Actions",
      render: (value) => isEditable === value.trade ?
        <button onClick={saveChanges} style={buttonStyles}>
          Save
        </button> : <button onClick={() => handleEditStatus(+value.trade)} style={buttonStyles}>
          Edit
        </button>

    }

  ];
};

export default columns;
