import React, {useEffect, useRef, useState} from "react"

import { Card, Tooltip } from "rt-design-system-backup"

import { formatNumber, isValidPrice } from "../../helpers"

import "./style.scss"
import {MARINE_CARD_BACKGROUND_COLOR} from "../../helpers/constants";

const PriceTableCell = (
    {
      children, isHeader=false, tooltip, created_at = '', valid_until=  null, onClick= () => {},
      enableTransition = false, fontColor = null, setRecalculateOffers = null, textAlign = ''
    }
) => {
    const [transitionColor, setTransitionColor] = useState('');
    const [prevChild, setPrevChild] = useState(children);
    const [showStar, setShowStar] = useState(false);
    const showStarRef = useRef(() => {});

    useEffect(() => {
        if (enableTransition) {
            if(valid_until !== null && !isValidPrice(valid_until)) return;
            try {
             // eslint-disable-next-line
             const currChild = parseInt(children.replace(/\,/g,''));
             // eslint-disable-next-line
             const preChild = parseInt(prevChild.replace(/\,/g,''));
             if (currChild === preChild) {
                 setTransitionColor('orange');
             } else if(currChild > preChild){
                 setTransitionColor('#8DF58D');
             } else if (currChild < preChild) {
                 setTransitionColor('#FD4A4A');
             } else {
                 setTransitionColor('#8DF58D');
             }
            } catch (e) {}
            setTimeout(() => {setTransitionColor('')}, 2000);
        }
        setPrevChild(children);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[children, created_at])

    useEffect(() => {
        const updateValue = () => {
            if (setRecalculateOffers !== null) {
                setRecalculateOffers(Math.random());
            }
            setShowStar(true)
        };
        if (valid_until !== null) {
            if (isValidPrice(valid_until)) {
                const currDate = new Date();
                const validityDate = new Date(valid_until);
                setShowStar(false);
                clearTimeout(showStarRef.current);
                showStarRef.current = setTimeout(updateValue, validityDate.getTime() - currDate.getTime())
            } else {
                setShowStar(true);
            }
        }
        return () => clearTimeout(showStarRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valid_until])
    return <td
        style={{backgroundColor: transitionColor,
                fontWeight: isHeader ? 600 : 400,
                color: fontColor !== null ? fontColor : '',
                borderBottom: '1px solid white',
                textAlign: textAlign}}
        onClick={onClick}
    >
        { tooltip ? <Tooltip content={tooltip} intent="primary">
            {showStar === true? children + '*' : children }
        </Tooltip> : children }
    </td>
}

const PriceTableRow = ({ children, isDarkModeOn }) => {
    return <tr className="rtcmd-price-table-table-row" style={isDarkModeOn ? {background: MARINE_CARD_BACKGROUND_COLOR, color: 'white'}:{}}>
        { children }
    </tr>
}

const PriceTableHeader = ({ children, icon, spot, mtd, change, onClick, isDarkModeOn }) => {
    return <>
        <div className="rtcmd-price-table-header" style={isDarkModeOn ? {background: MARINE_CARD_BACKGROUND_COLOR, color: 'white'}:{}}>
            <div className="rtcmd-price-table-header-name">
                { children }
            </div>
            { icon ? <div className="rtcmd-price-table-header-icon" onClick={onClick}>
                Broker { icon }
            </div> : null }
        </div>
        { 
            spot || mtd ? <div className="rtcmd-price-table-header-numbers"
                               style={isDarkModeOn ? {background: MARINE_CARD_BACKGROUND_COLOR, color: 'white'}:{}}>
                {
                    spot ? <div className="rtcmd-price-table-header-number">
                        <div className="rtcmd-price-table-header-number-header">
                            Spot price
                        </div>
                        <div className="rtcmd-price-table-header-number-value">
                            { formatNumber(spot) }
                        </div>
                    </div> : null
                }
                <div className="rtcmd-price-table-header-number" style={{marginLeft: '15px'}}>
                        <div className="rtcmd-price-table-header-number-header" style={{height: '20px'}}>
                            {' '}
                        </div>
                        <div className="rtcmd-price-table-header-number-value" style={change>0?{color: '#008000'}:{color: '#FF0000'}}>
                            { formatNumber(change) }
                        </div>
                    </div>
                <div className="rtcmd-price-table-header-numbers-filler">
                </div>
                {
                    mtd ? <div className="rtcmd-price-table-header-number">
                        <div className="rtcmd-price-table-header-number-header">
                            MTD
                        </div>
                        <div className="rtcmd-price-table-header-number-value">
                            { formatNumber(mtd) }
                        </div>
                    </div> : null
                }
            </div> : null
        }
        <div className="rtcmd-price-table-header-separator" />
    </>
}

const PriceTable = ({ children, tableHeader, header, isDarkModeOn }) => {
    return <Card elevation={2} style={{marginRight: 8, paddingTop: 8, paddingBottom: 8, background: isDarkModeOn? MARINE_CARD_BACKGROUND_COLOR: '', color: isDarkModeOn? 'white': ''}}>
        <div className="rtcmd-price-table">
            { header }
            <div className="table-container">
              <table className="rtcmd-price-table-table">
                <thead className="rtcmd-price-table-table-header">
                    <PriceTableRow>
                        { tableHeader }
                    </PriceTableRow>
                </thead>
                <tbody className="rtcmd-price-table-table-body">
                    { children }
                </tbody>
              </table>
            </div>
        </div>
    </Card>
}

export {
    PriceTable,
    PriceTableHeader,
    PriceTableRow,
    PriceTableCell
}