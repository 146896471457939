export const MARINE_CARD_BACKGROUND_COLOR = '#0d1424'
export const BID_COLORS = {
    topColor: 'rgb(99,255,154)',
    bottomColor: 'rgb(99,255,154, 0.04)',
    lineColor: 'rgb(33,190,87)'
}
export const LAST_COLORS = {
    topColor: 'rgba(33, 150, 243, 0.56)',
    bottomColor: 'rgba(33, 150, 243, 0.04)',
    lineColor: 'rgba(33, 150, 243, 1)'
}
export const OFFER_COLORS = {
    topColor: 'rgba(241,2,10,0.56)',
    bottomColor: 'rgba(255, 82, 82, 0.04)',
    lineColor: 'rgba(255, 82, 82, 1)'
}
export const BUNKER_COLORS = {
    topColor: 'rgba(38,198,218, 0.56)',
    bottomColor: 'rgba(38,198,218, 0.04)',
    lineColor: 'rgba(38,198,218, 1)'
}
export const timeData = [
    {text: '1 Day', value: 1},
    {text: '1 Week', value: 7},
    {text: '1 Month', value: 30},
    {text: '1 Year', value: 365},
    {text: 'All', value: -1},
]


export const mappingTableHeaders = [
    {
        title: "Rule ID",
        dataIndex: "RuleID",
    },
    {
        title: "System",
        dataIndex: "System",
    },
    {
        title: "System Column Name",
        dataIndex: "SystemColumnName",
    },
    {
        title: "System Column Value",
        dataIndex: "SystemColumnValue",
    },
    {
        title: "NexTrade Field 1",
        dataIndex: "NexTradeField1",
    },
    {
        title: "NexTrade Field 1 Value",
        dataIndex: "NexTradeField1_Value",
    },
    {
        title: "NexTrade Field 2",
        dataIndex: "NexTradeField2",
    },
    {
        title: "NexTrade Field 2 Value",
        dataIndex: "NexTradeField2_Value",
    },
    {
        title: "NexTrade Field 3",
        dataIndex: "NexTradeField3",
    },
    {
        title: "NexTrade Field 3 Value",
        dataIndex: "NexTradeField3_Value",
    },
    {
        title: "NexTrade Field 4",
        dataIndex: "NexTradeField4",
    },
    {
        title: "NexTrade Field 4 Value",
        dataIndex: "NexTradeField4_Value",
    },
    {
        title: "NexTrade Field 5",
        dataIndex: "NexTradeField5",
    },
    {
        title: "NexTrade Field 5 Value",
        dataIndex: "NexTradeField5_Value",
    },
    {
        title: "Last Updated",
        dataIndex: "LastUpdated",
    },
    {
        title: "Last Updated By",
        dataIndex: "LastUpdatedBy",
    },
    {
        title: "Created By",
        dataIndex: "CreatedBy",
    },
    {
        title: "Status",
        dataIndex: "Status",
    },
];

export const STPAPIHeaders = [
    {
        title: "STP ID",
        dataIndex: "id"
    },
    {
        title: "DDP ID",
        dataIndex: "DDPId"
    },
    {
        title: "REQUEST ID",
        dataIndex: "requestId"
    },
    {
        title: "Fusion ID",
        dataIndex: "fusionID"
    },
    {
        title: "Acknowledgement Message",
        dataIndex: "acknowledgementMessage"
    },
    {
        title: "Status",
        dataIndex: "status"
    },
    {
        title: "Created At",
        dataIndex: "createdAt"
    },
    {
        title: "System",
        dataIndex: "system"
    }

];


export const LogColumns = [
    {
      title: "Log ID",
      dataIndex: "logID",
      key: "logID",
    },
    {
      title: "Date & Time (UTC)",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Module",
      dataIndex: "module",
      key: "module",
    },
    {
      title: "Activity Type",
      dataIndex: "activityType",
      key: "activityType",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];
  