import { IFileInputProps as BPIFileInputProps, FileInput as BPFileInput } from '@blueprintjs/core';
import React from 'react';
import '../../../assets/scss/core.scss';

export interface IFileInputProps extends BPIFileInputProps {
  buttonText?: string;
  disabled?: boolean;
  large?: boolean;
  text?: React.ReactNode;
}
export const FileInput: React.FC<IFileInputProps> = (props) => {
  return <BPFileInput {...props} />;
};
