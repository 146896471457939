import * as React from 'react';
import {
  CollapsibleList as BPCollapsibleList,
  ICollapsibleListProps as BPICollapsibleListProps,
} from '@blueprintjs/core';
import '../../assets/scss/core.scss';

export interface ICollapsibleListProps extends BPICollapsibleListProps {
  visibleItemCount?: number;
  collapseFrom?: 'start' | 'end';
  children: React.ReactNode;
}

export const CollapsibleList: React.FC<ICollapsibleListProps> = (props) => {
  return <BPCollapsibleList {...props} />;
};

CollapsibleList.defaultProps = {
  className: 'bp3-breadcrumbs',
};
