import {Tag} from "antd";
import React from "react";

const StatusTag = ({status}) => {
  const statusColorMapping = {
    'Generated': '#0091B5',
    'Expired': '#3D3D3D',
    'Cancelled': '#3D3D3D',
    'Open': '#005EB8',
    'Reconciled': '#349D8C',
    'Filled': '#009235',
    'For correction': '#EA7600',
  }
  return (
    <Tag style={{borderRadius: '32px', fontSize: '14px', lineHeight: '22px'}} color={statusColorMapping[status]}>
      {status}
    </Tag>)
}

export default StatusTag;
