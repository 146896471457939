import { Table } from "rt-design-system-backup";

import columns from "./columns";
import { css } from '@emotion/css'
import React from "react";


const tableCSS = css({
  'thead > tr > th': {
    verticalAlign: 'top',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#000000',
  },
  'tbody > tr > td': {
    fontSize: '14px',
    lineHeight: '22px',
    color: '#000000',
  },
});

const CopperTrades = ({ request, editable }) => {
  const trades = (editable ? request.trades : request.filled_trades.length ? request.filled_trades : request.trades).map((trade) => {
    return {
      trade_id: trade.id,
      maturity: trade.maturity_str,
      action: trade.action,
      quantity: trade.remaining_quantity || trade.quantity,
      price: trade.price,
      qt_id: trade.qt_id,
      lme_qt: trade.qt_id,
    };
  });

  return (
    <>
      <h4>Maturity prices</h4>
      <div style={{ position: "relative" }}>
        <div style={{ display: "inline-block", maxWidth: "100%" }}>
          <Table
            pagination={false}
            dataSource={trades}
            className={tableCSS}
            scroll={{
              x: true,
            }}
            size="small"
            columns={columns(request, editable)}
          />
        </div>
      </div>
    </>
  );
};

export default CopperTrades;
