import { createContext } from "react"

const defaultSortContext = {
    startDate: new Date(new Date(new Date(new Date(new Date().getTime() - 180 * (24 * 60 * 60 * 1000)).setHours(0)).setMinutes(0)).setSeconds(0)),
    endDate: new Date(new Date(new Date(new Date(new Date().getTime()).setHours(0)).setMinutes(0)).setSeconds(0))
}
const SortContext = createContext(defaultSortContext)

export default SortContext
export { defaultSortContext }

