import {Button} from "rt-design-system-backup";
import React from "react";
import {useHistory} from "react-router-dom";

const Nav = ({to, text}) => {
    const history = useHistory();
    return <Button
        onClick={() => history.push(to)}
        intent="primary" text={text}
        style={{marginRight: '10px', float: 'right'}}
    />;
}

export default Nav;