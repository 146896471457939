import React, {useContext, useEffect, useRef, useState} from 'react';
import { url } from "../../../helpers";
import Axios from "axios";
import { Row, Col } from "antd"
import RoutePriceTable from "../../../PriceTable/Route";
import TradeActivityTable from "../../../TradeActivityTable";
import "./style.scss";
import MarineContext from "../../MarineContext";
import SocketContext from "../../../App/SocketContext";
import HistoricalFFADashboard from '../HistoricalFFADashboard';

const MarineDashboard = () => {
  const [routes, setRoutes] = useState([])
  const [maturities, setMaturities] = useState([])
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const fetchDataTimeout = useRef(() => { })
  const socket = useContext(SocketContext);

  useEffect(() => {
      const fetchData = async () => {
        clearTimeout(fetchDataTimeout.current)
        try {
            const routes = await Axios.get(url('/route/?freight_routes_only=true'))
            const maturities = await Axios.get(url('/maturity/?display_only&marine_maturities=true'))
            maturities.data = maturities.data.filter(o => o.shortened_string !== "q4 22") //temporarily removes Q2 22 from FFA screen
            setRoutes(routes.data.sort((a,b) =>
              a.route[0].localeCompare(b.route[0], undefined, { caseFirst: 'false' })
            ))
            setMaturities(maturities.data)
        } catch (err) {
            console.log(err)
            fetchDataTimeout.current = setTimeout(fetchData, 30000)
        }
      }

      fetchData()

      socket.addEventListener("ffa_dashboard_update",() => {window.location.reload();} );
      return () => {
          clearTimeout(fetchDataTimeout.current)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {setShowImage, setMainPadding} = useContext(MarineContext);

  const handleWindowResize = () => {
      setWindowHeight(window.innerHeight);
  }

  useEffect(()=> {
    setShowImage(true);
    if (windowHeight < 800) setMainPadding('16px 16px 10px 16px');
    else setMainPadding('32px 32px 12px 32px');
    const resizeEvent = window.addEventListener('resize', handleWindowResize)
    return () => {
        setShowImage(false);
        setMainPadding('');
        window.removeEventListener('resize', resizeEvent);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
      <MarineContext.Consumer>
        {({isDarkModeOn}) => (
          routes.length !== 0 && maturities.length && (
          <div style={{background: isDarkModeOn? 'black': ''}}>
            <Row style={{marginBottom: '10px'}}>
                <Col sm={24} md={12} lg={8} xl={6} key={'cape 5tc'} style={{ marginBottom: 8}}>
                  <RoutePriceTable route={routes.find((obj)=> obj.route.toLowerCase() === 'cape 5tc')} maturities={maturities} isDarkModeOn={isDarkModeOn} />
                </Col>
                {routes.filter((obj) => obj.route.toLowerCase() !== 'cape 5tc').sort((a,b) => a.route[0].localeCompare(b.route[0], undefined, { caseFirst: 'false' })).map((route) => {
                  return (
                   <Col sm={24} md={12} lg={8} xl={6} key={route.route} style={{ marginBottom: 8}}>
                     <RoutePriceTable route={route} maturities={maturities} isDarkModeOn={isDarkModeOn} />
                   </Col>
                  )
                  })
                }
                <Col sm={24} md={12} lg={8} xl={8}>
                   <TradeActivityTable isDarkModeOn={isDarkModeOn}/>
                </Col>
                <Col id={"FFAGraphComponent"} sm={24} md={24} lg={16} xl={16}>
                    <HistoricalFFADashboard defaultWindowHeight={500} defaultMaturity={maturities[1].string} defaultRoute={routes[1].id} 
                      defaultTime={7} defaultOfferType={'last'} parentComponent={'FFADashboard'}
                    />
                </Col>
            </Row>
          </div>
        )
        )}
      </MarineContext.Consumer>
  );
};

export default MarineDashboard;
