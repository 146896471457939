import React from 'react';
import SimpleTable from "../SimpleTable";
import {formatDate, formatNumber, renameInstrument} from "../../helpers";
import {currency_symbol_mapping} from "../RequestMaturityPrices/columns";

const PricingTable = ({request}) => {
  const replacementQuoteTypes = ["Postponement Auto Quote", "Anticipation Auto Quote"]
  return (
    <div style={{height: '80px'}}>
      <SimpleTable style={{width: '100%', backgroundColor: '#F5F5F5'}} noBold>
        {['APC', 'CSP'].includes(request.request_type) ?
          <tr>
            <td>
              Pricing Period:
            </td>
            <td style={{float: 'right'}}>
              {request.pricing_period_start_date ? formatDate(request.pricing_period_start_date, true, true)
                + " to " : null}
              {formatDate(request.pricing_period_end_date, true, true)}
            </td>
          </tr> : null}
        {[ ...replacementQuoteTypes, 'Quote', "Auto Quote", "Indic Quote"].includes(request.request_type) || ((!['At Market', 'APC'].includes(request.request_type)) && ['Filled', 'Reconciled', 'For correction'].includes(request.status)) ?
          <>
            {["Postponement Auto Quote", "Anticipation Auto Quote", "Limit Order", "Quote", "Auto Quote", "Indic Quote"].includes(request.request_type) ?
              <>
                <tr>
                  <td>
                    {replacementQuoteTypes.includes(request.request_type) ? "Cost" :"LME Adjusted"} ({currency_symbol_mapping[request.currency]}/mt):
                  </td>
                  <td style={{float: 'right'}}>
                    {replacementQuoteTypes.includes(request.request_type) && request.vwap_lme_adjusted === 0 ? 0 : formatNumber(
                      request.currency !== "USD"
                        ? request.vwap_lme_adjusted_currency
                        : request.vwap_lme_adjusted,
                      true,
                      true
                    )}
                  </td>
                </tr>
              </>:null
            }
            {
              <tr>
                <td>
                  LME Basis 3M ($/mt):
                </td>
                <td style={{float: 'right'}}>
                  {formatNumber(request.lme_basis, true, 2)}
                </td>
              </tr>
            }
          </>
          : null
        }
        {['At Market'].includes(request.request_type) ?
          <tr>
            <td>
              Instrument:
            </td>
            <td style={{float: 'right'}}>
              {renameInstrument(request.request_maturities[0].trades.map(trade => trade.instrument.name).join(' + '))}
            </td>
          </tr> : null}
          {['Limit Order'].includes(request.request_type) && ['Open'].includes(request.status)?
          <tr>
            <td>
              Normalized Target Price ($/mt):
            </td>
            <td style={{float: 'right'}}>
              {formatNumber(request.requested_price_adjusted_to_3m, false, 2)}
            </td>
          </tr> : null}
        {['Limit Order'].includes(request.request_type) && ['Generated', 'Open', 'Cancelled', 'Expired'].includes(request.status)?
          <tr>
            <td>
              Target Price ($/mt) / Basis:
            </td>
            <td style={{float: 'right'}}>
              {formatNumber(request.requested_price, false, 2)} / {request.benchmark}
            </td>
          </tr> : null}
        {['APC'].includes(request.request_type) && ['Filled', 'Reconciled', 'For correction'].includes(request.status) ?
          <tr>
            <td>
              Fixing Spread:
            </td>
            <td style={{float: 'right'}}>
              {request.request_maturities[0].trades[0].price}
            </td>
          </tr>
          : null
        }
        {["APC", "CSP"].includes(request.request_type) && ['Generated', 'Open', 'Cancelled', 'Expired'].includes(request.status) ? (
          <tr>
            <td>Pricing Basis:</td>
            <td style={{float: 'right'}}>{request.benchmark}</td>
          </tr>
        ) : null}
        {['At Market', 'APC', 'CSP', 'Limit Order'].includes(request.request_type) || (["Postponement Auto Quote", "Anticipation Auto Quote", 'Quote', 'Auto Quote', 'Indic Quote'].includes(request.request_type)) ?
          <tr>
            <td>
              Total qty (mt):
            </td>
            <td style={{float: 'right'}}>
              {formatNumber(request.total_quantity, false, 2)}
            </td>
          </tr> : null}
      </SimpleTable>
    </div>
  );
};

export default PricingTable;
