import React, {createContext, useEffect, useRef, useState} from "react"
import deepMerge from "deepmerge"

import { Button, Drawer } from "rt-design-system-backup"

import Nav from "../Nav"

import "./style.scss"
import Axios from "axios";
import {url} from "../../helpers";
import timeAgo from "../../TradeActivityTable/utils";
import MarineContext from "../MarineContext";

const defaultLayoutContext = {
    isFFADrawerOpen: false,
    isMarketActivityDrawerOpen: false,
    setLayoutContext: () => { }
}

const LayoutContext = createContext(defaultLayoutContext)

const LayoutProvider = ({ children }) => {
    const [layoutContextValue, setLayoutContextValue] = useState(defaultLayoutContext)

    return <LayoutContext.Provider value={{
        ...layoutContextValue,
        setLayoutContext: (value) => {
            setLayoutContextValue(
                deepMerge(layoutContextValue, value)
            )
        }
    }}>
        {children}
    </LayoutContext.Provider>
}

const FFADrawer = () => {
    const [messages, setMessages] = useState([]);
    const fetchMessagesTimeout = useRef(() => {});

    useEffect(() => {
        const fetchMessages = async () => {
          clearTimeout(fetchMessagesTimeout.current);
          try {
            const msgData = await Axios.get(
                url("/message/")
            );
            setMessages(msgData.data)

          } catch (err) {
            console.log(err);
          }
          fetchMessagesTimeout.current = setTimeout(fetchMessages, 30000);
        };

        fetchMessages();

        return () => {
          clearTimeout(fetchMessagesTimeout.current);
        };
    }, []);
    return <LayoutContext.Consumer>
        {({ isFFADrawerOpen, setLayoutContext }) => {
            return <Drawer
                size="min(376px, 100vw)"
                usePortal={false}
                hasBackdrop={false}
                isOpen={isFFADrawerOpen}
                onClose={() => setLayoutContext({
                    isFFADrawerOpen: false
                })}
                canOutsideClickClose={false}
            >
                <div className="rtcmd-ffa-drawer-wrapper">
                    <div className="rtcmd-ffa-drawer-wrapper-control">
                        <Button onClick={() => setLayoutContext({
                            isFFADrawerOpen: false
                        })} outlined minimal rightIcon="arrow-right">
                            Hide
                        </Button>
                    </div>
                    <h5>
                        FFA trade activity (today)
                    </h5>
                    {
                        messages.map((message) => {
                            return <div className="rtcmd-ffa-drawer-entry">
                                <div className="rtcmd-ffa-drawer-entry-header">
                                    {message.content}
                                </div>
                                <div className="rtcmd-ffa-drawer-info">
                                    <div>
                                        Broker: <>{message.sender && <>{message.sender.broker && message.sender.broker.name ? message.sender.broker.name : "unregistered"}</>}</>
                                    </div>
                                    <div className="rtcmd-ffa-drawer-flex-filler">

                                    </div>
                                    <div>
                                        {timeAgo(message.created_at)}
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </Drawer>
        }}
    </LayoutContext.Consumer>
}

const Layout = ({ children }) => {
    return <LayoutProvider>
        <Nav />
        <div className={"rtcmd-ffa-drawer-container"}>
            <div>
                <FFADrawer />
            </div>
        </div>
        <MarineContext.Consumer>
            {(({isDarkModeOn, showImage, mainPadding}) => (
                <main className="rtcmd-main-content-wrapper" style={isDarkModeOn && showImage ? {background: 'black', padding: mainPadding, height: '98vh'}: showImage ? {padding: mainPadding}: {}}>
                    {children}
                </main>
            ))}
        </MarineContext.Consumer>
    </LayoutProvider>
}

export default Layout

export { LayoutContext }