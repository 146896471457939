import React from 'react';
import '../../assets/scss/core.scss';
import {
  Button as BPButton,
  Classes,
  IButtonProps as BPIButtonProps,
  IconName,
  Intent,
  removeNonHTMLProps,
  Utils,
  AnchorButton as BPAnchorButton,
  MaybeElement,
} from '@blueprintjs/core';
import { Icon } from '../..';

export interface IButtonProps extends BPIButtonProps {
  text?: React.ReactNode;
  icon?: IconName | MaybeElement;
  rightIcon?: IconName | MaybeElement;
  intent?: Intent;
  loading?: boolean;
  large?: boolean;
  minimal?: boolean;
  outlined?: boolean;
  disabled?: boolean;
  children?: any;
}

class RTButton extends BPButton {
  protected renderChildren(): React.ReactNode {
    const { children, icon, loading, rightIcon, text } = this.props;

    return [
      loading && (
        <div key='loading-icon' className='bp3-spinner bp3-button-spinner'>
          <div className='bp3-spinner-animation'>
            <svg width='14' height='14' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M9.99991 13.6364C9.49785 13.6364 9.09082 14.0434 9.09082 14.5454V18.1818C9.09082 18.6839 9.49785 19.0909 9.99991 19.0909C10.502 19.0909 10.909 18.6839 10.909 18.1818V14.5454C10.909 14.0434 10.502 13.6364 9.99991 13.6364Z'
                fill='#002C5F'
              />
              <path
                d='M9.99991 0C9.49785 0 9.09082 0.40703 9.09082 0.909091V2.72727C9.09082 3.22933 9.49785 3.63636 9.99991 3.63636C10.502 3.63636 10.909 3.22933 10.909 2.72727V0.909091C10.909 0.40703 10.502 0 9.99991 0Z'
                fill='#002C5F'
              />
              <path
                d='M5.45455 9.09091C5.45455 8.58885 5.04752 8.18182 4.54545 8.18182H0.909091C0.40703 8.18182 0 8.58885 0 9.09091C0 9.59298 0.40703 10 0.909091 10H4.54545C5.04752 10 5.45455 9.59298 5.45455 9.09091Z'
                fill='#002C5F'
              />
              <path
                d='M19.0914 8.18182H15.455C14.9529 8.18182 14.5459 8.58885 14.5459 9.09091C14.5459 9.59298 14.9529 10 15.455 10H19.0914C19.5934 10 20.0004 9.59298 20.0004 9.09091C20.0004 8.58885 19.5934 8.18182 19.0914 8.18182Z'
                fill='#002C5F'
              />
              <path
                d='M5.50053 12.305L2.92931 14.8764C2.57434 15.2314 2.57434 15.807 2.92931 16.162C3.10683 16.3395 3.33949 16.4282 3.57216 16.4282C3.80483 16.4282 4.0375 16.3395 4.21501 16.162L6.78628 13.5907C7.14125 13.2356 7.14125 12.6601 6.78622 12.305C6.43119 11.95 5.85562 11.95 5.50053 12.305Z'
                fill='#002C5F'
              />
              <path
                d='M13.8574 6.14303C14.09 6.14303 14.3227 6.05431 14.5002 5.87673L17.0714 3.3054C17.4265 2.95037 17.4265 2.37479 17.0714 2.01976C16.7165 1.66479 16.1408 1.66473 15.7858 2.01976L13.2145 4.59109C12.8595 4.94612 12.8595 5.5217 13.2145 5.87673C13.392 6.05431 13.6247 6.14303 13.8574 6.14303Z'
                fill='#002C5F'
              />
              <path
                d='M14.5002 12.305C14.1452 11.9501 13.5695 11.95 13.2145 12.305C12.8595 12.66 12.8595 13.2356 13.2145 13.5906L15.7858 16.162C15.9633 16.3395 16.196 16.4282 16.4286 16.4282C16.6612 16.4282 16.894 16.3394 17.0714 16.162C17.4265 15.8069 17.4265 15.2313 17.0714 14.8763L14.5002 12.305Z'
                fill='#002C5F'
              />
            </svg>
          </div>
        </div>
      ),
      <Icon key='leftIcon' icon={icon} />,
      (!Utils.isReactNodeEmpty(text) || !Utils.isReactNodeEmpty(children)) && (
        <span key='text' className={Classes.BUTTON_TEXT}>
          {text}
          {children}
        </span>
      ),
      <Icon key='rightIcon' icon={rightIcon} />,
    ];
  }

  protected getButtonProps() {
    const { icon, text, rightIcon } = this.props;
    const buttonProps = this.getCommonButtonProps();
    let className = buttonProps.className;

    if (!text) {
      className = `${className || ''} no-text`;
    }
    if ((icon || rightIcon) && text) {
      className = `${className || ''} has-icon`;
    }

    return {
      ...buttonProps,
      className,
    };
  }

  public render() {
    return (
      <button type='button' ref={this.handleRef} {...removeNonHTMLProps(this.props)} {...this.getButtonProps()}>
        {this.renderChildren()}
      </button>
    );
  }
}

export const Button: React.FC<IButtonProps> = (props) => {
  return <RTButton {...props} />;
};

export const AnchorButton = BPAnchorButton;
