import React, { useEffect } from "react";
import useAxios from "axios-hooks";

import {
  url,
  getCSRF,
  report,
  isValidNumber,
  getErrorMessage,
} from "../../helpers";
import { Dialog, Button } from "rt-design-system-backup";
import Form, { InputGroup, FormRow, FormFooter, FormContext } from "../Form";
import { useLogin } from '../useLogin'
import Toaster from "../Toaster";

import "./style.scss";

const OrderCreateDialog = ({ isOpen, onClose, prefill }) => {
  useLogin(true)

  const [{ loading: routesLoading, data: routes }] = useAxios({
    url: url("route/"),
  });
  const [{ loading: maturitiesLoading, data: rawMaturities }] = useAxios({
    url: url("maturity/?display_only_orderbook"),
  });
  const [{ loading: booksLoading, data: rawBooks }] = useAxios({
    url: url("book/"),
  });

  useEffect(() => {
    if (isOpen === true) {
      report("order_create");
    }
  }, [isOpen]);

  let books = [];

  if (rawBooks) {
    books = rawBooks.map((rawBook) => ({
      name: rawBook.book,
      value: rawBook.book,
    }));
  }

  const score = ["year", "quarter", "month"];

  const maturities = (rawMaturities || [])
    .map((maturity) => {
      return {
        name:
          maturity.shortened_string.substring(0, 1).toUpperCase() +
          maturity.shortened_string.substring(1),
        value: "" + maturity.shortened_string,
        maturity: maturity,
      };
    })
    .sort((a, b) => {
      return (
        new Date(a.maturity.start) -
        new Date(b.maturity.start) +
        (score.indexOf(a.maturity.type) - score.indexOf(b.maturity.type))
      );
    });

  const orderTypes = [
    {
      name: "Limit order",
      value: "limit",
    },
    {
      name: "Market order",
      value: "market",
    },
  ];

  const types = [
    {
      name: "Bid",
      value: "bid",
    },
    {
      name: "Offer",
      value: "offer",
    },
  ];

  const [{ loading: createLoading }, createOrder] = useAxios(
    {
      url: url("order/"),
      method: "POST",
      headers: {
        "X-CSRFToken": getCSRF(),
      },
    },
    { manual: true }
  );

  const send = async (formData) => {
    try {
      report("order_submitted");

      await createOrder({
        data: {
          ...formData,
          value: formData.order_type === "market" ? undefined : formData.price,
          maturities: formData.maturities.split(",").map((key) => {
            const maturity = maturities.find((val) => {
              return val.maturity.shortened_string === key.toLowerCase()
                ? true
                : false;
            });

            return maturity.maturity;
          }),
        },
      });
      report("order_created");

      Toaster.show({
        message: "Order successfully placed. ",
        icon: "small-tick",
        intent: "success",
      });

      onClose();
    } catch (err) {
      report("order_failed");

      Toaster.show({
        message:
          "An error occurred while creating the order. " + getErrorMessage(err),
        icon: "warning-sign",
        intent: "danger",
      });
    }
  };

  const loading = routesLoading || maturitiesLoading || booksLoading;

  return (
    <Dialog
      className="modal"
      isOpen={isOpen}
      hasBackdrop={true}
      canEscapeKeyClose
      canOutsideClickClose={false}
      onClose={onClose}
      title="New order"
      style={{ maxWidth: "548px", width: "100%" }}
    >
      {loading ? (
        "Loading"
      ) : (
        <div style={{ padding: "32px 32px 0 32px" }}>
          <Form onSubmit={send}>
            <InputGroup
              title="Contract"
              name="route"
              required
              type="button-select"
              items={routes.map((route) => {
                return {
                  name: route.route,
                  value: route.id,
                };
              })}
              defaultValue={
                prefill
                  ? [prefill.order_contracts[0].contract.route.id]
                  : undefined
              }
            />
            <FormRow>
              <InputGroup
                title="Tenors"
                itemPredicate={(qs, it) => {
                  return it.name.toLowerCase().indexOf(qs.toLowerCase()) >= 0;
                }}
                required
                name="maturities"
                type="select"
                multiple
                items={maturities}
                defaultValue={
                  prefill
                    ? prefill.order_contracts.map((order_contract) => {
                      return order_contract.contract.maturity
                        .shortened_string;
                    })
                    : undefined
                }
              />
              <InputGroup
                title="Book"
                required
                name="book"
                type="select"
                items={books}
                defaultValue={prefill ? [prefill.book] : undefined}
              />
            </FormRow>
            <FormRow>
              <InputGroup
                title="Order type"
                required
                name="order_type"
                type="select"
                items={orderTypes}
                defaultValue={prefill ? [prefill.order_type] : ["limit"]}
              />
              <FormContext.Consumer>
                {({ data }) => {
                  const isMarket =
                    data["order_type"] &&
                    data["order_type"].length &&
                    data["order_type"][0].value === "market";
                  return !isMarket ? (
                    <InputGroup
                      title="Price"
                      required
                      name="price"
                      type="number"
                      isInvalid={(value) => {
                        return isValidNumber(value, true);
                      }}
                    />
                  ) : (
                    <>
                      <span></span>
                      <InputGroup
                        title="Price"
                        disabled
                        name="price"
                        type="text"
                        placeholder="At best"
                      />
                    </>
                  );
                }}
              </FormContext.Consumer>
            </FormRow>
            <FormRow>
              <InputGroup
                title="Bid/Offer"
                required
                name="type"
                type="button-select"
                items={types}
                defaultValue={prefill ? [prefill.type] : undefined}
              />
              <FormContext.Consumer>
                {({ data }) => {
                  const route =
                    data.route && data.route.length
                      ? routes.find((i) => {
                        return i.id === data.route[0];
                      })
                      : null;
                  return (
                    <InputGroup
                      title="Quantity"
                      isInvalid={(value) => {
                        return isValidNumber(value, true);
                      }}
                      rightElement={
                        route ? <span>{route.suffix}</span> : undefined
                      }
                      required
                      name="quantity"
                      type="number"
                    />
                  );
                }}
              </FormContext.Consumer>
            </FormRow>
            <FormFooter>
              <Button onClick={onClose} text="Cancel" minimal />
              <FormContext.Consumer>
                {({ valid }) => {
                  return (
                    <Button
                      loading={createLoading}
                      disabled={!valid}
                      type="submit"
                      text="Add order"
                      intent="primary"
                    />
                  );
                }}
              </FormContext.Consumer>
            </FormFooter>
          </Form>
        </div>
      )}
    </Dialog>
  );
};

export default OrderCreateDialog;
