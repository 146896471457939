import styled from "styled-components";

const SimpleTable = styled.table`
  td {
    padding: 2px 8px;
    line-height: 22px;

    &:first-child {
      padding-left: 0;
      ${(props) => (props.noBold ? "" : "font-weight: bold")};
    }
    &:last-child {
      padding-right: 0;
    }
  }
`;

export default SimpleTable;
