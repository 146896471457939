import {
  IconName,
  Intent,
  ITagInputProps as BPITagInputProps,
  MaybeElement,
  AbstractPureComponent2,
  DISPLAYNAME_PREFIX,
  Classes,
  Icon,
  IconSize,
  IRef,
  Keys,
  refHandler,
  setRef,
  Utils,
} from '@blueprintjs/core';
import React from 'react';
import classNames from 'classnames';
import '../../../assets/scss/core.scss';
import { Tag } from '../tag';

export interface ITagInputProps extends BPITagInputProps {
  disabled?: boolean;
  fill?: boolean;
  intent?: Intent;
  large?: boolean;
  leftIcon?: IconName | MaybeElement;
  values: React.ReactNode[];
}

export interface ITagInputState {
  activeIndex: number;
  inputValue: string;
  isInputFocused: boolean;
  prevInputValueProp?: string;
}

export type TagInputAddMethod = 'default' | 'blur' | 'paste';

const NONE = -1;

export class RTTagInput extends AbstractPureComponent2<ITagInputProps, ITagInputState> {
  public static displayName = `${DISPLAYNAME_PREFIX}.TagInput`;

  public static defaultProps: Partial<ITagInputProps> = {
    addOnBlur: false,
    addOnPaste: true,
    inputProps: {},
    separator: /[,\n\r]/,
    tagProps: {},
  };

  public static getDerivedStateFromProps(
    props: Readonly<ITagInputProps>,
    state: Readonly<ITagInputState>,
  ): Partial<ITagInputState> | null {
    if (props.inputValue !== state.prevInputValueProp) {
      return {
        inputValue: props.inputValue,
        prevInputValueProp: props.inputValue,
      };
    }
    return null;
  }

  public state: ITagInputState = {
    activeIndex: NONE,
    inputValue: this.props.inputValue || '',
    isInputFocused: false,
  };

  public inputElement: HTMLInputElement | null = null;

  private handleRef: IRef<HTMLInputElement> = refHandler(this, 'inputElement', this.props.inputRef);

  public render() {
    const { className, disabled, fill, inputProps, intent, large, leftIcon, placeholder, values } = this.props;

    const classes = classNames(
      Classes.INPUT,
      Classes.TAG_INPUT,
      {
        [Classes.ACTIVE]: this.state.isInputFocused,
        [Classes.DISABLED]: disabled,
        [Classes.FILL]: fill,
        [Classes.LARGE]: large,
      },
      Classes.intentClass(intent),
      className,
    );
    const isLarge = classes.indexOf(Classes.LARGE) > NONE;

    // use placeholder prop only if it's defined and values list is empty or contains only falsy values
    const isSomeValueDefined = values.some((val) => !!val);
    const resolvedPlaceholder = placeholder == null || isSomeValueDefined ? inputProps?.placeholder : placeholder;

    return (
      <div className={classes} onBlur={this.handleContainerBlur} onClick={this.handleContainerClick}>
        <Icon
          className={Classes.TAG_INPUT_ICON}
          icon={leftIcon}
          iconSize={isLarge ? IconSize.LARGE : IconSize.STANDARD}
        />
        <div className={Classes.TAG_INPUT_VALUES}>
          {values.map(this.maybeRenderTag)}
          {this.props.children}
          <input
            value={this.state.inputValue}
            {...inputProps}
            onFocus={this.handleInputFocus}
            onChange={this.handleInputChange}
            onKeyDown={this.handleInputKeyDown}
            onKeyUp={this.handleInputKeyUp}
            onPaste={this.handleInputPaste}
            placeholder={resolvedPlaceholder}
            ref={this.handleRef}
            className={classNames(Classes.INPUT_GHOST, inputProps?.className)}
            disabled={disabled}
          />
        </div>
        {this.props.rightElement}
      </div>
    );
  }

  public componentDidUpdate(prevProps: ITagInputProps) {
    if (prevProps.inputRef !== this.props.inputRef) {
      setRef(prevProps.inputRef, null);
      this.handleRef = refHandler(this, 'inputElement', this.props.inputRef);
      setRef(this.props.inputRef, this.inputElement);
    }
  }

  private addTags = (value: string, method: TagInputAddMethod = 'default') => {
    const { inputValue, onAdd, onChange, values } = this.props;
    const newValues = this.getValues(value);
    let shouldClearInput = onAdd?.(newValues, method) !== false && inputValue === undefined;
    // avoid a potentially expensive computation if this prop is omitted
    if (Utils.isFunction(onChange)) {
      shouldClearInput = onChange([...values, ...newValues]) !== false && shouldClearInput;
    }
    // only explicit return false cancels text clearing
    if (shouldClearInput) {
      this.setState({ inputValue: '' });
    }
  };

  private maybeRenderTag = (tag: React.ReactNode, index: number) => {
    if (!tag) {
      return null;
    }
    const { large, tagProps, rightElement } = this.props;
    const props = Utils.isFunction(tagProps) ? tagProps(tag, index) : tagProps;
    return (
      <Tag
        active={index === this.state.activeIndex}
        data-tag-index={index}
        key={tag + '__' + index}
        large={!rightElement ? large : false}
        extraLarge={!!(rightElement && large)}
        onRemove={this.props.disabled ? undefined : this.handleRemoveTag}
        round
        {...props}
      >
        {tag}
      </Tag>
    );
  };

  private getNextActiveIndex(direction: number) {
    const { activeIndex } = this.state;
    if (activeIndex === NONE) {
      // nothing active & moving left: select last defined value. otherwise select nothing.
      return direction < 0 ? this.findNextIndex(this.props.values.length, -1) : NONE;
    } else {
      // otherwise, move in direction and clamp to bounds.
      // note that upper bound allows going one beyond last item
      // so focus can move off the right end, into the text input.
      return this.findNextIndex(activeIndex, direction);
    }
  }

  private findNextIndex(startIndex: number, direction: number) {
    const { values } = this.props;
    let index = startIndex + direction;
    while (index > 0 && index < values.length && !values[index]) {
      index += direction;
    }
    return Utils.clamp(index, 0, values.length);
  }

  /**
   * Splits inputValue on separator prop,
   * trims whitespace from each new value,
   * and ignores empty values.
   */
  private getValues(inputValue: string) {
    const { separator } = this.props;
    // NOTE: split() typings define two overrides for string and RegExp.
    // this does not play well with our union prop type, so we'll just declare it as a valid type.
    return (separator === false ? [inputValue] : inputValue.split(separator as string))
      .map((val) => val.trim())
      .filter((val) => val.length > 0);
  }

  private handleContainerClick = () => {
    this.inputElement?.focus();
  };

  private handleContainerBlur = ({ currentTarget }: React.FocusEvent<HTMLDivElement>) => {
    this.requestAnimationFrame(() => {
      // we only care if the blur event is leaving the container.
      // defer this check using rAF so activeElement will have updated.
      if (!currentTarget.contains(document.activeElement)) {
        if (this.props.addOnBlur && this.state.inputValue !== undefined && this.state.inputValue.length > 0) {
          this.addTags(this.state.inputValue, 'blur');
        }
        this.setState({ activeIndex: NONE, isInputFocused: false });
      }
    });
  };

  private handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    this.setState({ isInputFocused: true });
    this.props.inputProps?.onFocus?.(event);
  };

  private handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ activeIndex: NONE, inputValue: event.currentTarget.value });
    this.props.onInputChange?.(event);
    this.props.inputProps?.onChange?.(event);
  };

  private handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // HACKHACK: https://github.com/palantir/blueprint/issues/4165

    const { selectionEnd, value } = event.currentTarget;
    const { activeIndex } = this.state;

    let activeIndexToEmit = activeIndex;

    if (event.which === Keys.ENTER && value.length > 0) {
      this.addTags(value, 'default');
    } else if (selectionEnd === 0 && this.props.values.length > 0) {
      // cursor at beginning of input allows interaction with tags.
      // use selectionEnd to verify cursor position and no text selection.
      if (event.which === Keys.ARROW_LEFT || event.which === Keys.ARROW_RIGHT) {
        const nextActiveIndex = this.getNextActiveIndex(event.which === Keys.ARROW_RIGHT ? 1 : -1);
        if (nextActiveIndex !== activeIndex) {
          event.stopPropagation();
          activeIndexToEmit = nextActiveIndex;
          this.setState({ activeIndex: nextActiveIndex });
        }
      } else if (event.which === Keys.BACKSPACE) {
        this.handleBackspaceToRemove(event);
      } else if (event.which === Keys.DELETE) {
        this.handleDeleteToRemove(event);
      }
    }

    this.invokeKeyPressCallback('onKeyDown', event, activeIndexToEmit);
  };

  private handleInputKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    this.invokeKeyPressCallback('onKeyUp', event, this.state.activeIndex);
  };

  private handleInputPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const { separator } = this.props;
    const value = event.clipboardData.getData('text');

    if (!this.props.addOnPaste || value.length === 0) {
      return;
    }

    // special case as a UX nicety: if the user pasted only one value with no delimiters in it, leave that value in
    // the input field so that the user can refine it before converting it to a tag manually.
    if (separator === false || value.split(separator!).length === 1) {
      return;
    }

    event.preventDefault();
    this.addTags(value, 'paste');
  };

  private handleRemoveTag = (event: React.MouseEvent<HTMLSpanElement>) => {
    // using data attribute to simplify callback logic -- one handler for all children
    const index = +event.currentTarget.parentElement!.getAttribute('data-tag-index')!;
    this.removeIndexFromValues(index);
  };

  private handleBackspaceToRemove(event: React.KeyboardEvent<HTMLInputElement>) {
    const previousActiveIndex = this.state.activeIndex;
    // always move leftward one item (this will focus last item if nothing is focused)
    this.setState({ activeIndex: this.getNextActiveIndex(-1) });
    // delete item if there was a previous valid selection (ignore first backspace to focus last item)
    if (this.isValidIndex(previousActiveIndex)) {
      event.stopPropagation();
      this.removeIndexFromValues(previousActiveIndex);
    }
  }

  private handleDeleteToRemove(event: React.KeyboardEvent<HTMLInputElement>) {
    const { activeIndex } = this.state;
    if (this.isValidIndex(activeIndex)) {
      event.stopPropagation();
      this.removeIndexFromValues(activeIndex);
    }
  }

  /** Remove the item at the given index by invoking `onRemove` and `onChange` accordingly. */
  private removeIndexFromValues(index: number) {
    const { onChange, onRemove, values } = this.props;
    onRemove?.(values[index], index);
    if (Utils.isFunction(onChange)) {
      onChange(values.filter((_, i) => i !== index));
    }
  }

  private invokeKeyPressCallback(
    propCallbackName: 'onKeyDown' | 'onKeyUp',
    event: React.KeyboardEvent<HTMLInputElement>,
    activeIndex: number,
  ) {
    this.props[propCallbackName]?.(event, activeIndex === NONE ? undefined : activeIndex);
    this.props.inputProps![propCallbackName]?.(event);
  }

  /** Returns whether the given index represents a valid item in `this.props.values`. */
  private isValidIndex(index: number) {
    return index !== NONE && index < this.props.values.length;
  }
}

export const TagInput: React.FC<ITagInputProps> = (props) => {
  return <RTTagInput {...props} />;
};
