import React, {useState} from "react";
import useAxios from "axios-hooks";

import { Input, Space, Table } from "antd";
import { Spinner, Button } from "rt-design-system-backup";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { dateFormat } from "../../Maturities";

import { url, getFontColorDelta } from "../../../../helpers";
import { css } from "@emotion/css";
import { formatNumber } from "../utils";
import "./style.css"

const tableCSS = css({
    '.ant-table-content .bp3-icon svg': {
        width: '20px',
        height: '20px',
    },
    '.ant-table-thead  tr  th': {
      'fontSize': '13px',
      'lineHeight': '18px'
    },
    '.ant-table-tbody  tr  td': {
      'fontSize': '13px',
      'lineHeight': '18px'
    },
});

const CSRCustomerTable = ({selectedCustomer, isExtraLargeScreen, start_date, end_date}) => {
    const [filteredInfo, setFilterInfo] = useState({});
    const [{loading: customerLoading, data: customers = []}] = useAxios({
        url: url(`/customer/get_customers_by_tier/?tier_customer=All&start_date=${dateFormat.formatDate(start_date)}&end_date=${dateFormat.formatDate(end_date)}`)
    })
    const initial_search_text = localStorage.getItem('csr_customer_filter_id')
    const [searchText, setSearchText] = useState(initial_search_text || "initState");

    const handleChange = (pagination, filters, sorter) => {
        setFilterInfo(filters);

        Object.keys(filters).forEach(filter => {
        if (filters[filter]) {
            localStorage.setItem(`csr_customer_filter_${filter}`, filters[filter].join())
        } else {
            localStorage.setItem(`csr_customer_filter_${filter}`, '-')
        }
        })
    };
    
    const handleSearch = (selectedKeys, confirm) => {
      confirm();
      setSearchText(selectedKeys[0]);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const columns = [
        {
            title: "Customer",
            dataIndex: "customer",
            sorter: Array.sort,
            width: '150px',
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div style={{ padding: 8 }}>
                <Input
                  placeholder={`Search Customer`}
                  value={selectedKeys[0]}
                  onChange={(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  onPressEnter={() => handleSearch(selectedKeys, confirm)}
                  style={{ width: 160, marginBottom: 8, display: "block" }}
                />
                <Space size={"large"}>
                  <Button
                    intent="secondary"
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ minHeight: "24px", minWidth: 60 }}
                  >
                    Reset
                  </Button>
                  <Button
                    intent="primary"
                    icon={<SearchOutlined />}
                    onClick={() => handleSearch(selectedKeys, confirm)}
                    size="small"
                    style={{ minHeight: "24px", minWidth: 60 }}
                  >
                    Search
                  </Button>
                </Space>
              </div>
            ),
            filterIcon: (filtered) => (
                <SearchOutlined
                  style={{ color: filtered ? "#1890ff" : "#8e8e8e", fontSize: "16px" }}
                />
            ),
            filteredValue: filteredInfo.customer || null,
            onFilter: (value, record) => record.customer && record.customer.toLowerCase().trim() === value.toLowerCase().trim(),
            render: (text) => (
              <Highlighter
                  highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                  searchWords={[searchText]}
                  textToHighlight={text || ""}
              />
          )
        },
        {
            title: "Acc Rate",
            dataIndex: "acceptance_ratio",
            width: "100px",
            sorter: (a, b) => {
              return parseFloat(a.acceptance_ratio) - parseFloat(b.acceptance_ratio);
            },
            sortDirections: ["descend", "ascend"],
            render: (value) => {
              return (
                <div style={{ whiteSpace: "nowrap" }}>
                  {formatNumber(value * 100, false, 2)}%
                </div>
              );
            },
        },
        {
          title: "Qty",
          dataIndex: "total_quantity",
          width: '80px',
          sorter: (a, b) => {
            return a.total_quantity - b.total_quantity;
          },
          sortDirections: ["descend", "ascend"],
          render: (value) => {
            return (
              <div style={{ whiteSpace: "nowrap"}}>
                {formatNumber(value, false, 0)}
              </div>
            );
          },
        },
        {
          title: "MKT Pnl",
          dataIndex: "market_pnl",
          width: '100px',
          sorter: (a, b) => {
            return a.market_pnl - b.market_pnl;
          },
          sortDirections: ["descend", "ascend"],
          render: (value) => {
            return (
              <div style={{ whiteSpace: "nowrap", color: getFontColorDelta(value) }}>
                {formatNumber(value, false, 0)}
              </div>
            );
          },
        },
        {
            title: "TTL Pnl",
            dataIndex: "ttl_pnl",
            width: '100px',
            sorter: (a, b) => {
              return a.ttl_pnl - b.ttl_pnl;
            },
            sortDirections: ["descend", "ascend"],
            render: (value) => {
              return (
                <div style={{ whiteSpace: "nowrap", color: getFontColorDelta(value) }}>
                  {formatNumber(value, false, 0)}
                </div>
              );
            },
        }
    ]
    return (
      <>
        <Table
            className={tableCSS}
            rowClassName={(record) => record.customer_id === selectedCustomer ? 'table-row-selected' : ''}
            locale={{
                emptyText: "No customers to display",
            }}
            loading={
                customerLoading
                ? {
                    delay: 1500,
                    indicator: <Spinner size={32} />,
                }
                : false
            }
            scroll={{
            x: customers && customers.length > 0,
            y: isExtraLargeScreen === true ? 400 : 320,
            }}
            bordered
            size="medium"
            cellFontSize={12}
            columns={columns}
            dataSource={customers}
            tableLayout="auto"
            pagination={false}
            onChange={handleChange}
        />    
      </>
    )
}
export default CSRCustomerTable;
