import React, {useEffect, useRef} from "react";

import { createChart, ColorType, LineStyle } from 'lightweight-charts';
import {MARINE_CARD_BACKGROUND_COLOR} from "../../../helpers/constants";
import "../../../style.scss"

const TradingViewHistoricalChart = ({offers, isDarkModeOn, chartColors, windowHeight, defaultWindowHeight}) => {
    const chartContainerRef = useRef();
	const toolTipRef = useRef();
	const noDataDivRef = useRef();
    useEffect(() => {
        const chart = createChart(chartContainerRef.current, {
			rightPriceScale: {
				scaleMargins: {
				  top: 0.2,
				  bottom: 0.2,
				},
			},
			layout: {
				background: { type: ColorType.Solid, color: isDarkModeOn === true ? MARINE_CARD_BACKGROUND_COLOR : '#fff' },
				textColor: isDarkModeOn === true ? '#fff' : 'black',
			},
			width: chartContainerRef.current.clientWidth,
			height: defaultWindowHeight !== null ? windowHeight - 90 : windowHeight - 190,
			grid: {
				vertLines: {
					color: 'rgba(197, 203, 206, 0.4)',
					style: LineStyle.Dotted,
				},
				horzLines: {
					color: 'rgba(197, 203, 206, 0.4)',
					style: LineStyle.Dotted,
				},
			},
			crosshair: {
				vertLine: {
				  labelVisible: true,

				},
			},
			localization: {
				locale: 'en-US',
			}
		});
		const handleResize = () => {
			chart.applyOptions({ width: chartContainerRef.current.clientWidth});
		};
		chart.timeScale().fitContent();

		const offerSeries = chart.addAreaSeries(
			{ topColor: chartColors.topColor, bottomColor: chartColors.bottomColor,
  			  lineColor: chartColors.lineColor, lineWidth: 2, }
		);
		offerSeries.setData(offers);
		window.addEventListener('resize', handleResize);
		const toolTipWidth = 200;
		const toolTipHeight = 90;
		const toolTipMargin = -80;

		chart.subscribeCrosshairMove(function(param) {
		if (param.point === undefined || !param.time || param.point.x < 0 || param.point.x > chartContainerRef.current.clientWidth || param.point.y < 0 || param.point.y > chartContainerRef.current.clientHeight) {
			toolTipRef.current.style.display = 'none';
		} else {
			const dateStr = new Date(param.time * 1000).toLocaleString('en-US', {timeZone: 'UTC'});
			toolTipRef.current.style.display = 'block';
			let toolTipTextColor = 'black'
			if (isDarkModeOn === true) {
				toolTipTextColor = '#ffffff'
				toolTipRef.current.style.background = MARINE_CARD_BACKGROUND_COLOR;
			} else {
				toolTipRef.current.style.background = '#ffffff';
			}

			const price = param.seriesPrices.get(offerSeries);
			const offer = offers.filter((offer) => offer.time === param.time)
			toolTipRef.current.innerHTML = '<div style="font-size: 12px; margin: 4px 0px; color:' + toolTipTextColor +'; font-weight: 600"> Value: <b>' + Math.round(100 * price) / 100 + '</b></div><div style="color:' + toolTipTextColor +'; font-weight: 600">Time: ' + dateStr + '</div><div style="color:' + toolTipTextColor +'; font-weight: 600">Broker: <b>' + offer[0].broker + '</b></div>';
			const coordinate = offerSeries.priceToCoordinate(price);
			let shiftedCoordinate = param.point.x - 50;
			if (coordinate === null) {
				return;
			}
			shiftedCoordinate = Math.max(0, Math.min(chartContainerRef.current.clientWidth - toolTipWidth, shiftedCoordinate));
			var coordinateY = coordinate - toolTipHeight - toolTipMargin > 0 ? coordinate - toolTipHeight - toolTipMargin : Math.max(0, Math.min(chartContainerRef.current.clientHeight - toolTipHeight - toolTipMargin, coordinate + toolTipMargin));
			toolTipRef.current.style.left = shiftedCoordinate + 'px';
			toolTipRef.current.style.top = coordinateY + 'px';
		}
		});

		return () => {
			window.removeEventListener('resize', handleResize);
			chart.remove();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offers, isDarkModeOn, windowHeight]);

	return (
		<div style={{marginTop: 30}}>
			<div ref={chartContainerRef} />
			<div ref={toolTipRef} className="trading-view-tooltip" style={{border: `1px solid ${chartColors.topColor}`}}/>
			<div ref={noDataDivRef} className="no_data_available_div"
				 style={{display: offers.length === 0 ? 'block': 'none', color: isDarkModeOn ? 'white' : ''}}
			>
				No data available for this time period
			</div>
		</div>
	);
}
export default TradingViewHistoricalChart;
