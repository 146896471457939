import { Intent, IProgressBarProps as BPIProgressBarProps, ProgressBar as BPProgressBar } from '@blueprintjs/core';
import React from 'react';
import '../../assets/scss/core.scss';

export interface IProgressBarProps extends BPIProgressBarProps {
  intent?: Intent;
}

export const ProgressBar = (props: IProgressBarProps) => {
  return <BPProgressBar {...props} />;
};
