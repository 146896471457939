import { Button } from "rt-design-system-backup";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const BreadcrumbWrapper = styled.div`
  margin-top: -16px;
`;

const Breadcrumb = ({text, link}) => {
  const history = useHistory();

  // TODO: modularize this
  return (
    <BreadcrumbWrapper>
      <Button
        icon="chevron-left"
        minimal
        style={{fontSize: '16px', lineHeight: '24px'}}
        onClick={() => {
          history.push(link);
        }}
      >
        {text}
      </Button>
    </BreadcrumbWrapper>
  );
};

export default Breadcrumb;
