import { Icon as BPIcon, IconName, IIconProps as BPIIconProps, Intent, MaybeElement } from '@blueprintjs/core';
import React from 'react';
import '../../assets/scss/core.scss';

export interface IIconProps extends BPIIconProps {
  children?: never;
  className?: string;
  color?: string;
  htmlTitle?: string;
  icon: IconName | MaybeElement;
  iconSize?: number;
  intent?: Intent;
  style?: React.CSSProperties;
  tagName?: keyof JSX.IntrinsicElements;
  title?: string | false | null;
}

export class Icon extends BPIcon {}
