import React, {useEffect, useState, useContext} from "react";
import "./style.scss"
import {MARINE_CARD_BACKGROUND_COLOR} from "../../../helpers/constants";
import Axios from "axios";
import {calculateDelta, formatNumber, getBestOffer, url, getTime, getFontColorDelta} from "../../../helpers";
import { Card, Tooltip } from "rt-design-system-backup"
import SocketContext from '../../../App/SocketContext'

const getCell = (val) => {
  if (!val) return "-";

  return formatNumber(val);
};

const formatDate = (date_str) => {
  return !date_str
    ? ''
    : "Received at: " + new Date(date_str).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
};

const TableCell = (
  {
    children, tooltip, created_at = '', enableTransition = false, fontColor = null
  }
) => {
  const [transitionColor, setTransitionColor] = useState('');
  const [prevChild, setPrevChild] = useState(children);

  useEffect(() => {
      if (enableTransition && children !== '-' && prevChild !== '-') {
        try {
           // eslint-disable-next-line
           const currChild = parseFloat(children.replace(/\,/g,'')).toFixed(2);
           // eslint-disable-next-line
           const preChild = parseFloat(prevChild.replace(/\,/g,'')).toFixed(2);
           if (currChild === preChild) {
               setTransitionColor('orange');
           } else if(currChild > preChild){
               setTransitionColor('#8DF58D');
           } else if (currChild < preChild) {
               setTransitionColor('#FD4A4A');
           } else {
               setTransitionColor('#8DF58D');
           }
        } catch (e) {}
          setTimeout(() => {setTransitionColor('')}, 2000);
      }
      if (prevChild !== children) setPrevChild(children);
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[children, created_at])

  return <td
      style={{backgroundColor: transitionColor, color: fontColor !== null ? fontColor : ''}}
  >
      { tooltip ? <Tooltip content={tooltip} intent="primary">
            { children }
        </Tooltip> : children }
  </td>
}

const BunkerTable = ({route, maturities, title, isDarkModeOn, is_HI5 = false}) => {
    const [themeStyles, setThemeStyles] = useState(theme.light)
    const [offers, setOffers] = useState([]);
    const [closes, setCloses] = useState([]);
    const [offers05, setOffers05] = useState([]);
    const [closes05, setCloses05] = useState([]);
    const [enableTransition, setEnableTransition] = useState(false);

    const socket = useContext(SocketContext);

    useEffect(() => {
      const fetchContracts = async () => {
          try {
            if (!is_HI5) {
              const loadOffers = async() =>{
                const res = await Axios.get(
                  url("/offer/?route=" + route.id)
                );
                setOffers(res.data);
              }
              const loadClose = async () =>{
                const closeResponse = await Axios.get(
                  url("/close/?route=" + route.id)
                );
                setCloses(closeResponse.data);
              }
              await Promise.all([
                loadOffers(), loadClose()
              ]);
              setEnableTransition(true);
            } else {
              const loadOffers = async() =>{
                return await Axios.get(
                  url("/offer/?route=" + route[0].id)
                );
              }
              const loadClose = async () =>{
                return await Axios.get(
                  url("/close/?route=" + route[0].id)
                );
              }
              const loadOffers05 = async() =>{
                return await Axios.get(
                  url("/offer/?route=" + route[1].id)
                );
              }
              const loadClose05 = async() =>{
                return await Axios.get(
                  url("/close/?route=" + route[1].id)
                );
              }
              Promise.all([
                loadOffers(), loadClose(), loadOffers05(), loadClose05()
              ]).then(([offerResponse, closeResponse, offer05Response, close05Response]) => {
                setOffers(offerResponse.data);
                setCloses(closeResponse.data);
                setOffers05(offer05Response.data);
                setCloses05(close05Response.data); 
                setEnableTransition(true);
              });
            }
          } catch (err) {
            console.log(err);
          }
      };
      fetchContracts();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [route]);

    useEffect(() => {
      const validateAndSetOffers = (socketOffers, routeID, offerRouteID) => {
        if (offerRouteID.includes(routeID)) {
          const tableOffers = []
          socketOffers.map(({ tableData, chartData }) => { 
            if (chartData.route === routeID) {
              tableOffers.push(tableData)
            } 
            return tableData
          });
          setOffers([...tableOffers, ...offers]);
        }
      }
      
      const updateOffers = (data) => {
        const routeID = data.detail.route
        const socketOffers = data.detail.offers;
        if (!routeID) return;
        if (is_HI5) {
          validateAndSetOffers(socketOffers, route[0].id, routeID)
          if (routeID.includes(route[1].id)) {
            const tableOffers = []
            socketOffers.map(({ tableData, chartData }) => { 
              if (chartData.route === routeID) {
                tableOffers.push(tableData)
              } 
              return tableData
            });
            setOffers05([...tableOffers, ...offers05]);
          }
        } else {
          validateAndSetOffers(socketOffers, route.id, routeID)
        }
      }
      socket.addEventListener("bunker_offer_update", updateOffers);
      return () => socket.removeEventListener("bunker_offer_update", updateOffers)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offers, offers05])

    const rows = maturities.map((maturity) => {
      return {
        maturity: maturity,
        bid: getBestOffer(offers, maturity, "bid"),
        offer: getBestOffer(offers, maturity, "offer"),
        close: closes.find((close) => {
          return (
              close.contract.maturity.string === maturity.string
          )
        }),
        bid_05: is_HI5 === true ? getBestOffer(offers05, maturity, "bid") : null,
        offer_05: is_HI5 === true ? getBestOffer(offers05, maturity, "offer") : null,
        close_05: is_HI5 === true ? closes05.find((close) => {
          return (
              close.contract.maturity.string === maturity.string
          )
        }) : null,
      };
    });

    useEffect(()=>{
        const th = isDarkModeOn? theme.dark : theme.light
        setThemeStyles(th)
    },[isDarkModeOn])
    
    const calculate_value = (offer05, offer) => {
      if (!is_HI5) {
        return getCell(offer?.value);
      }
      const {value: offer05Value = 0} = offer05 || {};
      const {value: offerValue = 0} = offer || {};
      return getCell(offer05Value - offerValue);
    }

    const getDeltaValue = (bid, offer, close) => {
        let delta = calculateDelta(bid, offer, close);
        if (isNaN(delta)) return "-";
        return delta.toFixed(2);
    }

    const getMaturityString = (str, index) => {
      if (index === 0 ) return `${str}*`
      return str
    }

    return (
      
     <Card elevation={1} style={{padding: 0, paddingBottom: 15, paddingTop: 8, borderRadius: 10, background: isDarkModeOn? MARINE_CARD_BACKGROUND_COLOR: '', color: isDarkModeOn? 'white': ''}}>
      <table style={{width: '100%', ...themeStyles.table}}>
        <tr className="table-row">
            <th  style = {themeStyles.tableHeaderStyles} colSpan={5}><span className="bunker-table-heading">{title}</span></th>
        </tr>
        <tr className="row-heading bunker-row" style = {themeStyles.headerStyles}>
            <td>Tenor</td>
            <td>Bid</td>
            <td>Offer</td>
            <td>Delta</td>
            <td style={{marginTop: 0}}>Close</td>
        </tr>
        {rows.map(({ maturity, bid, offer, close, bid_05, offer_05, close_05 }, index) => (
            <tr className={ index%2 === 0 ? themeStyles.tableRowClass[1] + ' bunker-row' :themeStyles.tableRowClass[0] + ' bunker-row'}>
                <td>{getMaturityString(maturity.shortened_string.toUpperCase(), index)}</td>
                <TableCell key={index+'bunker_bid'} created_at={getTime(bid)} enableTransition={enableTransition} tooltip={formatDate(bid?.received_at)}>
                  {calculate_value(bid_05, bid)}
                </TableCell>
                <TableCell key={index+'bunker_offer'} created_at={getTime(offer)} enableTransition={enableTransition} tooltip={formatDate(offer?.received_at)}>
                  {calculate_value(offer_05, offer)}
                </TableCell>
                <TableCell 
                  key={index+'bunker_delta'} 
                  fontColor={getFontColorDelta(getDeltaValue(calculate_value(bid_05, bid), calculate_value(offer_05, offer), calculate_value(close_05, close)))} 
                >
                  {getDeltaValue(calculate_value(bid_05, bid), calculate_value(offer_05, offer), calculate_value(close_05, close))}
                </TableCell>
                <TableCell key={index+'bunker_close'} tooltip={formatDate(close?.received_at)} >{calculate_value(close_05, close)}</TableCell>
            </tr>
        ))}
      </table>
     </Card>
    );
};

const theme =  {
    light : {
        headerStyles : {backgroundColor: 'white', color: '#002C5F'},
        tableHeaderStyles : {backgroundColor: 'white', color: '#002C5F', borderBottom: '1px dotted grey'},
        table : {backgroundColor: "#FFFFFF"},
        tableRowClass: ["greyBgColor", "whiteBgColor"],
        body: "body-light"
    },
    dark : {
        headerStyles : {backgroundColor: MARINE_CARD_BACKGROUND_COLOR, color: 'white'},
        tableHeaderStyles : {backgroundColor: MARINE_CARD_BACKGROUND_COLOR, color: 'white', borderBottom: '1px dotted white'},
        table : {backgroundColor: MARINE_CARD_BACKGROUND_COLOR},
        tableRowClass: ["darkGreyBgColor", "blackBgColor"],
        body: "body-dark"
    }
}

export default BunkerTable;