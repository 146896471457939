
const formatNumber = (x, showDash, number = 2) => {
    if ((x === 0 || x === null) && showDash) {
      return "-";
    }

    let parsedNumber = parseFloat(x).toFixed(number)
    parsedNumber = number === 1 ? parsedNumber.replace(/\.0/g, '') : parsedNumber.replace(/\.00/g, '')
  
    return parsedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  };

export {formatNumber};