import { IFormGroupProps as BPIFormGroupProps, FormGroup as BPFormGroup, Intent } from '@blueprintjs/core';
import React from 'react';
import '../../../assets/scss/core.scss';

export interface IFormGroupProps extends BPIFormGroupProps {
  className?: string;
  contentClassName?: string;
  disabled?: boolean;
  helperText?: React.ReactNode;
  inline?: boolean;
  intent?: Intent;
  label?: React.ReactNode;
  labelFor?: string;
  labelInfo?: React.ReactNode;
}

export const FormGroup: React.FC<IFormGroupProps> = (props) => {
  return <BPFormGroup {...props} />;
};
