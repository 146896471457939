import React, {useContext, useEffect, useState} from "react";
import useAxios from "axios-hooks";
import styled from "styled-components";
import {useHistory, useParams} from "react-router-dom";
import Toaster from "../../Toaster";

import {Button, Icon, TextArea,} from "rt-design-system-backup";

import Form, {FormContext, FormFooter, InputGroup} from "../../Form";
import {getCSRF, getErrorMessage, isValidNumber, report, url,} from "../../../helpers";

import useLogin from '../../useLogin'

import "./style.scss";
import {css} from "@emotion/css";

const Column = styled.div`
  max-width: 450px;
`;


export const loadingModelCSS = css({
  '.bp3-overlay-content': {
    borderRadius: '5px',
    backgroundColor: '#BFCAD7',
    left: '45%',
    top: '10%',
    fontSize: '18px',
    padding: 10,
  },
  '.bp3-overlay-content h4': {
    margin: 5,
  }
});

const TradeInputWrapper = styled.div`
  & > div {
    display: flex;
    flex-direction: row;

    .bp3-form-group {
      margin: 4px;
      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        margin-left: 0;
      }
    }

    .rtcmd-button-select {
      flex-wrap: nowrap;
    }
  }
`;

const TradeInput = ({ report, remove, id, routes, unit, lastAction, showQuantity, defaults}) => {
  const {defaultAction, defaultRoute, defaultQuantity} = defaults;
  return (
    <TradeInputWrapper>
      <Form tag="div" onChange={report}>
        <InputGroup
          defaultValue={defaultAction ? [defaultAction] : lastAction ? [lastAction] : ['offer']}
          required
          name="action"
          type="select"
          items={[
            {
              name: "Bid",
              value: "bid",
            },
            {
              name: "Offer",
              value: "offer",
            },
          ]}
        />
        <InputGroup required defaultValue={defaultRoute ? [defaultRoute] : null}
                    name="contract" placeholder={"Contract"} type="select" items={routes} />
        {id > 0 ?
          <InputGroup
            defaultValue={defaultQuantity ? defaultQuantity : showQuantity}
            key={id+showQuantity}
            disabled={true}
            placeholder="Quantity"
            name="quantity"
            type="number"
            rightElement={<span style={{ color: '#002C5F' }}>{unit}</span>}
          /> :
          <InputGroup
            isInvalid={(value) => {
              return isValidNumber(value, true);
            }}
            defaultValue={defaultQuantity ? defaultQuantity : null}
            rightElement={<span style={{ color: '#002C5F' }}>{unit}</span>}
            required
            placeholder="Quantity"
            name="quantity"
            type="number"
          />
        }
        <div className="bp3-form-group rtcmd-request-form-icon" data-key={id}>
          <Icon onClick={id > 0 ? remove : void 0} icon="remove" />
        </div>
      </Form>
    </TradeInputWrapper>
  );
};

const TypeAwareNewRequestForm = ({ unit, routes, initialRoutes }) => {
  const [trades, setTrades] = useState(initialRoutes.length ? initialRoutes : [false]);
  const actions = trades.filter(trade => trade && trade.data && trade.data.action).map(trade => {
      return trade.data.action[0].value
  })

  const { invalid, setFormContext, ...formContext } = useContext(FormContext);
  const lastAction = actions && actions[actions.length-1]

  const alreadySelectedRoutes =
      formContext.data &&
        formContext.data.trades &&
        formContext.data.trades.length
        ? formContext.data.trades
          .map((trade) => {
            if (trade && trade.contract && trade.contract.length)
              return trade.contract[0].value;
            return false;
          })
          .filter((item) => item)
        : [];
      const filterExistingRoutes = (routes) => {
        return routes.filter((route) => {
          return alreadySelectedRoutes.indexOf(route.value) < 0;
        });
  };

  const updateTradesStatus = () => {
    let valid = true;
    let data = [];

    trades.forEach((trade) => {
      if (trade.valid !== true) {
        valid = false;
      }

      data.push(trade.data);
    });

    setFormContext((formContext) => {
      let invalid = [...formContext.invalid]

      if (!valid && formContext.invalid.indexOf("trades") < 0) {
          invalid = [...invalid, "trades"]
      } else if (valid && formContext.invalid.indexOf("trades" >= 0)) {
           invalid = invalid.filter((eName) => eName !== "trades")
      }

      if (!invalid.includes("multi-type") && trades.length > 2 && actions.includes('offer') && actions.includes('bid')) {
           invalid = [...invalid, "multi-type"]
      } else if (!(trades.length > 2 && actions.includes('offer') && actions.includes('bid'))) {
           invalid = invalid.filter((eName) => eName !== "multi-type")
      }
      return {invalid: invalid};
    });
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    updateTradesStatus();

    setFormContext({
      data: {
        trades: trades.map((trade) => trade.data),
      },
    });
  }, [trades]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const report = (key) => {
    return (data) => {
      const tradesCopy = [...trades];
      tradesCopy[key] = data;

      if (JSON.stringify(data) !== JSON.stringify(trades[key])) {
        setTrades(tradesCopy);
      }
    };
  };

  const remove = (key) => {
    return () => {
      const tradesCopy = [...trades].filter((_, itemKey) => itemKey !== key);
      setTrades(tradesCopy);
    };
  };

  return (
    <>
      <p style={{marginBottom: 0, fontSize: '16px'}}>Contracts</p>
      <FormContext.Consumer>
        {(ctx) => {
          return (
            <>
              {trades.map((trade, key) => {
                return (
                  <TradeInput
                    key={key}
                    id={key}
                    remove={remove(key)}
                    report={report(key)}
                    unit={unit}
                    showQuantity={key ? trades[0].data.quantity : null}
                    lastAction={lastAction}
                    defaults={trade.defaults || {}}
                    routes={filterExistingRoutes(
                      routes.map((route) => {
                        return {
                          name: route.route,
                          value: route.id,
                        };
                      })
                    )}
                  />
                );
              })}
              {ctx.invalid.includes("multi-type") ?
                <p style={{color: 'red'}}>
                  Only two bid/offer trades are allowed
                </p>
                : null
              }
              <Button
                onClick={() => {
                  setTrades([...trades, false]);
                }}
                icon="add"
                minimal
              >
                Add maturity
              </Button>
            </>
          );
        }}
      </FormContext.Consumer>
    </>
  );
};

const NewRequestForm = ({updating=false}) => {
  const [comments, setComments] = useState('');
  useLogin(true)
  const {oderId} = useParams();

  const [{ loading: orderLoading, data: orderDetail }] = useAxios({
    url: url(`order/${oderId}/`),
  });
  const [{ loading: routesLoading, data: routes }] = useAxios({
    url: url("route/"),
  });
  const [{ loading: maturitiesLoading, data: rawMaturities }] = useAxios({
    url: url("maturity/?display_only_orderbook"),
  });
  const [{ loading: booksLoading, data: rawBooks }] = useAxios({
    url: url("book/"),
  });

  useEffect(() => {
    if (orderDetail) {
      setComments(orderDetail.comments)
    }
  }, [orderDetail])

  const books = (rawBooks && rawBooks.map(rawBook => ({name: rawBook.book, value: rawBook.book}))) || [];

  const score = ["year", "quarter", "month"];

  const maturities = (rawMaturities || [])
    .map((maturity) => {
      return {
        name:
          maturity.shortened_string.substring(0, 1).toUpperCase() +
          maturity.shortened_string.substring(1),
        value: "" + maturity.shortened_string,
        maturity: maturity,
      };
    })
    .sort((a, b) => {
      return (
        new Date(a.maturity.start) -
        new Date(b.maturity.start) +
        (score.indexOf(a.maturity.type) - score.indexOf(b.maturity.type))
      );
    });

  const orderTypes = [
    {
      name: "Limit order",
      value: "limit",
    },
    {
      name: "Market order",
      value: "market",
    },
  ];

  const history = useHistory();

  const [{ loading: updateLoading }, updateRequest] = useAxios(
    {
      url: "",
      method: "PUT",
      headers: {
        "X-CSRFToken": getCSRF(),
      },
    },
    { manual: true }
  );

  const send = async (formValues, formState) => {
    const trades = (formState.data.trades || []).map((trade) => {
      return {
        action: trade.action[0].value,
        route: trade.contract[0].value,
        quantity: trade.quantity,
      };
    });

    const selectedMaturity = maturities.find((val) => {
        return val.maturity.shortened_string === formValues.maturity
    }).maturity;

    const formData = {
      type: formState.data.trades[0].action[0].value,
      quantity: formState.data.trades[0].quantity,
      book: formValues.book,
      order_type: formValues.order_type,
      trades: trades,
      is_cracks: true,
      price: formValues.price,
      comments: comments,
      route: formState.data.trades.map(trade => trade.contract[0].value),
      maturity: selectedMaturity,
      value: formValues.order_type === "market" ? undefined : formValues.price,
    };

    try {
      report("order_submitted");
      await updateRequest({
        url: url(`order/${orderDetail.id}/`),
        data: formData,
      });
      report("order_created");

      Toaster.show({
        message: "Order updated. ",
        icon: "small-tick",
        intent: "success",
      });
      history.push('/orders')

    } catch (err) {
      report("order_failed");

      Toaster.show({
        message:
          "An error occurred while updating the order. " + getErrorMessage(err),
        icon: "warning-sign",
        intent: "danger",
      });
    }
  };

  const loading = routesLoading || maturitiesLoading || booksLoading || orderLoading;
  const initialRoutes = updating && orderDetail ?
    [...new Set(orderDetail.order_contracts.map(c => c.contract.route))].map(route => {
     const oc = orderDetail.order_contracts.filter(oc => oc.contract.route.id === route.id)[0]
     return  {defaults: {defaultAction: oc.contract.type, defaultRoute: oc.contract.route.id, defaultQuantity: orderDetail.quantity},
              data: {quantity: orderDetail.quantity}}
    }) : [];

  return loading ? (
    "Loading..."
  ) : (
    <Form onSubmit={send}>
      <h1>Edit order</h1>
      <Button onClick={() => history.push(`/order/${oderId}/update/`)} intent="primary" text="Switch to  order" />
      <hr />
      <Column>
        { updating ?
          <InputGroup
            title="Order type"
            required
            name="order_type"
            type="select"
            defaultValue={[orderDetail.order_type]}
            items={orderTypes}
          /> :
          <InputGroup
            title="Order type"
            required
            name="order_type"
            type="select"
            items={orderTypes}
            placeholder={'Select one'}
          />
        }
        <InputGroup
          title="Book"
          required
          name="book"
          defaultValue={updating ? [orderDetail.book] : null}
          type="select"
          placeholder={'Select one'}
          items={books}
        />
        <FormContext.Consumer>
          {({data}) => {
            const route =
                    data.route && data.route.length
                ? routes.find((i) => {
                  return i.id === data.route[0].value;
                })
                : null;
            return (
              <>
                <TypeAwareNewRequestForm unit={route && route.suffix} routes={routes} initialRoutes={initialRoutes}/>
              </>);
          }}
        </FormContext.Consumer>
        <InputGroup
          title="Maturity"
          name="maturity"
          required
          type="select"
          defaultValue={updating ? [orderDetail.order_contracts[0].maturity_shortened_string] : null}
          items={maturities}
          placeholder={'Select one'}
        />
        <FormContext.Consumer>
          {({ data }) => {
            const isMarket =
              data["order_type"] &&
              data["order_type"].length &&
              data["order_type"][0].value === "market";
            return !isMarket ? (
              <InputGroup
                title="Price"
                required
                name="price"
                type="number"
                defaultValue={updating ? orderDetail.value : null}
                placeholder="Enter price"
                isInvalid={(value) => {
                  return isValidNumber(value, false);
                }}
              />
            ) : (
              <>
                <span></span>
                <InputGroup
                  title="Price"
                  disabled
                  name="price"
                  type="text"
                  placeholder="At best"
                />
              </>
            );
          }}
        </FormContext.Consumer>
      </Column>
      <h2>Additional Comments</h2>
      <TextArea name="comments" style={{width: '655px'}} growVertically={true} onChange={
        (evt) => {
          setComments(evt.target.value)
        }} defaultValue={orderDetail.comments}
      ></TextArea>
      <FormFooter>
        <Button
          onClick={() => {
            history.push("/aluminium");
          }}
          text="Cancel"
          minimal
          large
        />
        <FormContext.Consumer>
          {({ valid, data }) => {
            return (
              <Button
                large
                loading={updateLoading}
                disabled={!valid}
                type="submit"
                intent="primary"
                text={"Save"}
              />);
          }}
        </FormContext.Consumer>
      </FormFooter>
      <div style={{ display: "block", height: "32px" }}></div>
    </Form>
  );
};

const UpdateCracks = ({updating=false}) => {
  return <NewRequestForm updating={updating}/>;
};

export default UpdateCracks;
