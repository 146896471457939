import { MultiSelect as BPMultiSelect, IMultiSelectProps as BPIMultiSelectProps } from '@blueprintjs/select';
import React, { useEffect, useRef, useState } from 'react';
import '../../../assets/scss/core.scss';
import { Button } from '../button';
import styled from 'styled-components';

const SelectContainer = styled.div`
  display: inline-block;
  font-size: 0;
  width: 100%;
`;

export type IMultiSelectProps<T> = BPIMultiSelectProps<T>;

export const MultiSelect: React.FC<IMultiSelectProps<any>> = (props) => {
  const { popoverProps, tagInputProps, selectedItems, ...rest } = props;
  const { popoverClassName, ...res } = popoverProps || {};
  const { ...tagProps } = tagInputProps || {};
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);
  const inputRef = useRef<any>(null);
  const [popoverRef, setPopoverRef] = useState<HTMLElement | null>(null);

  const handlePopover = (ref: HTMLElement | null) => {
    setPopoverRef(ref);
  };

  useEffect(() => {
    if (inputRef.current && popoverRef) {
      const elementWidth = inputRef?.current?.offsetWidth || 0;
      const width = elementWidth ? elementWidth - 0.3 : 0;

      const element = popoverRef.querySelector('.bp3-menu');
      if (element) {
        element.setAttribute('style', `width: ${width ? `${width}px` : 'auto'}`);
      }
    }
  }, [inputRef.current, popoverRef]);

  const controlPopover = () => {
    setPopoverIsOpen(true);
  };

  const handleInteraction = (nextOpenState: boolean) => {
    setPopoverIsOpen(nextOpenState);
  };

  const overProps = {
    ...res,
    popoverClassName: `default-width ${popoverClassName || ''} ${tagProps?.large && 'bp3-large'}`,
    popoverRef: props.popoverProps?.popoverRef || handlePopover,
    isOpen: popoverIsOpen,
    onInteraction: handleInteraction,
  };

  const renderRightElement = (
    <Button key='icon' icon={popoverIsOpen ? 'caret-up' : 'caret-down'} minimal onClick={controlPopover} />
  );

  const tagInput = {
    ...tagProps,
    rightElement: renderRightElement,
  };

  return (
    <SelectContainer ref={inputRef}>
      <BPMultiSelect {...rest} selectedItems={selectedItems} tagInputProps={tagInput} popoverProps={overProps} />
    </SelectContainer>
  );
};
