import {
  Intent,
  ITextAreaProps as BPITextAreaProps,
  Classes,
  AbstractPureComponent2,
  IRefCallback,
  refHandler,
  setRef,
  DISPLAYNAME_PREFIX,
} from '@blueprintjs/core';
import React from 'react';
import '../../../assets/scss/core.scss';
import classNames from 'classnames';

export interface ITextAreaProps extends BPITextAreaProps {
  fill?: boolean;
  growVertically?: boolean;
  intent?: Intent;
  large?: boolean;
  small?: boolean;
}

export interface ITextAreaState {
  height?: number;
  defaultHeight?: number;
}

class RTTextArea extends AbstractPureComponent2<ITextAreaProps, ITextAreaState> {
  public static displayName = `${DISPLAYNAME_PREFIX}.TextArea`;

  public state: ITextAreaState = {};

  // used to measure and set the height of the component on first mount
  public textareaElement: HTMLTextAreaElement | null = null;

  private handleRef: IRefCallback<HTMLTextAreaElement> = refHandler(this, 'textareaElement', this.props.inputRef);

  public componentDidMount() {
    if (this.props.growVertically && this.textareaElement !== null) {
      // HACKHACK: this should probably be done in getSnapshotBeforeUpdate
      /* eslint-disable-next-line react/no-did-mount-set-state */
      this.setState({
        height: this.textareaElement?.scrollHeight,
      });
    }
  }

  public componentDidUpdate(prevProps: ITextAreaProps) {
    if (prevProps.inputRef !== this.props.inputRef) {
      setRef(prevProps.inputRef, null);
      this.handleRef = refHandler(this, 'textareaElement', this.props.inputRef);
      setRef(this.props.inputRef, this.textareaElement);
    }
  }

  public render() {
    const { className, fill, inputRef, intent, large, small, growVertically, ...htmlProps } = this.props;

    const rootClasses = classNames(
      Classes.INPUT,
      Classes.intentClass(intent),
      {
        [Classes.FILL]: fill,
        [Classes.LARGE]: large,
        [Classes.SMALL]: small,
      },
      className,
    );

    console.log(this.state);

    // add explicit height style while preserving user-supplied styles if they exist
    let { style = {} } = htmlProps;
    if (growVertically && this.state.height != null) {
      // this style object becomes non-extensible when mounted (at least in the enzyme renderer),
      // so we make a new one to add a property
      style = {
        ...style,
        height: `${this.state.height}px`,
      };
    }

    return (
      <textarea
        {...htmlProps}
        className={rootClasses}
        onChange={this.handleChange}
        ref={this.handleRef}
        style={style}
      />
    );
  }

  private handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      defaultHeight: !this.state.defaultHeight ? e.target.scrollHeight + 2 : this.state.defaultHeight,
    });
    if (this.props.growVertically && this.state.defaultHeight) {
      this.setState({
        height: this.state.defaultHeight < e.target.scrollHeight ? e.target.scrollHeight : this.state.defaultHeight,
      });
    }

    if (this.props.onChange != null) {
      this.props.onChange(e);
    }
  };
}

export const TextArea: React.FC<ITextAreaProps> = (props) => {
  return <RTTextArea {...props} />;
};
