import { useState, useRef, createContext, useContext, useEffect } from "react";
import PropTypes from "prop-types";

import ButtonSelect from "../ButtonSelect";
import Select from "../Select";
import {
  FormGroup,
  InputGroup as RTInputGroup,
} from "rt-design-system-backup";

import "./style.scss";

const defaultFormContext = {
  valid: true,
  invalid: [],
  data: {},
  setFormContext: () => {
    console.warn("No form context listener is available");
  },
};

const FormContext = createContext(defaultFormContext);

const Input = ({
  type = "text",
  items,
  placeholder,
  required,
  name,
  large,
  multiple,
  intent,
  onChange,
  inputRef,
  ...props
}) => {
  const common = {
    placeholder,
    required,
    name,
    large,
    onChange,
    fill: true,
    intent,
    inputRef,
    ...props,
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (
      onChange &&
      props.defaultValue &&
      type !== "select" &&
      type !== "button-select"
    ) {
      onChange(props.defaultValue);
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  switch (type) {
    case "number":
      return <RTInputGroup {...common} type="text" />;
    case "email":
      return <RTInputGroup {...common} type="email" />;
    case "button-select":
      return <ButtonSelect {...common} items={items} multiple={multiple} />;
    case "select":
      return <Select {...common} items={items} multiple={multiple} />;
    default:
      return <RTInputGroup {...common} type="text" />;
  }
};

Input.propTypes = {
  type: PropTypes.oneOf([
    "text",
    "number",
    "email",
    "password",
    "select",
    "button-select",
  ]),
  placeholder: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  changeHandler: PropTypes.func,
  name: PropTypes.string.isRequired,
  inputRef: PropTypes.func,
};

const InputGroup = ({
  type = "text",
  items,
  placeholder,
  value,
  required,
  name,
  key,
  large,
  multiple,
  isValid,
  isInvalid,
  helper,
  title,
  customError,
  addWarning = () => {},
  onChange,
  formStyle = {},
  ...props
}) => {
  if (!placeholder) placeholder = "";

  const inputProps = {
    type,
    items,
    placeholder,
    required,
    name,
    large,
    multiple,
    ...props,
  };

  const formContext = useContext(FormContext);

  const [currentValue, setValue] = useState();
  const [isRequired, setRequired] = useState(required);
  const [warning, setWarning] = useState('');

  const [status, setStatus] = useState({
    intent: "default",
    text: helper || undefined,
  });

  const onChangeHandler = (event) => {
    const value = event.type ? event.target.value : event;
    setValue(value);

    formContext.setFormContext((formContext) => {
      const update = {
        data: {
          ...formContext.data,
          [name]: value,
        },
      };

      return update;
    });

    if (onChange) onChange(value);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setValidity(currentValue, currentValue);

    if (required !== isRequired) {
      setRequired(required);
    }
  }, [required, isValid, isInvalid, currentValue]);

  useEffect(() => {
    return () => {
      validate();
    };
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const setValidity = (value, changeStatus) => {
    if (!value) value = "";

    let error =
      required && value.length <= 0
        ? "This field is required"
        : isInvalid && value.length > 0
        ? isInvalid(value)
        : false;

    setWarning(addWarning(value))
    const valid = isValid ? isValid(value) : false;
    const cError = customError ? customError(value) : false;
    if(cError){
      error = cError
    }

    if (error && !formContext.invalid.some((eName) => eName === name)) {
      invalidate();
    } else if (!error && formContext.invalid.some((eName) => eName === name)) {
      validate();
    }

    if (changeStatus)
      setStatus({
        intent: error ? "danger" : valid ? "success" : "default",
        text: error ? error : valid ? valid : helper,
      });
  };

  const invalidate = () => {
    if (formContext.setFormContext)
      formContext.setFormContext((formContext) => {
        if (formContext.invalid.indexOf(name) < 0)
          return { invalid: [...formContext.invalid, name] };
        return {};
      });
  };

  const validate = () => {
    if (formContext.setFormContext)
      formContext.setFormContext((formContext) => {
        if (formContext.invalid.indexOf(name) >= 0)
          return {
            invalid: [
              ...[...formContext.invalid].filter((eName) => eName !== name),
            ],
          };
        return {};
      });
  };

  const isInvalidInForm = () => {
    return formContext.invalid.some((eName) => eName === name);
  };

  const refInit = (elem) => {
    if (elem && elem.current) {
      if (elem.current.value.length <= 0 && required && !isInvalidInForm()) {
        invalidate();
      }
    } else if (elem && typeof elem.value !== "undefined") {
      if (elem.value.length <= 0 && required && !isInvalidInForm()) {
        invalidate();
      }
    }
  };

  return (
    <FormGroup
      helperText={status.text}
      intent={status.intent}
      label={title}
      labelFor={name}
      style={formStyle}
    >
      <Input
        {...inputProps}
        key={key}
        inputRef={refInit}
        intent={status.intent}
        onChange={onChangeHandler}
      />
      {warning ? <span style={{color: 'red'}}>{warning}</span> : null}
    </FormGroup>
  );
};

InputGroup.propTypes = {
  ...Input.propTypes,
  isValid: PropTypes.func,
  isInvalid: PropTypes.func,
  helper: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func,
};

const FormRow = ({ children, ...props }) => {
  return (
    <div className="rtcmd--form--row--wrapper" {...props}>
      {children}
    </div>
  );
};

const FormFooter = ({ children }) => {
  return <div className="rtcmd--form--footer">{children}</div>;
};

const Form = ({
  children,
  ref,
  onSubmit,
  method = "post",
  action = "",
  preventDefaultBehavior = true,
  onChange,
  tag,
}) => {
  const form = useRef(false);

  const [formState, setFormState] = useState(defaultFormContext);

  useEffect(() => {
    onChange && onChange(formState);
  }, [formState, onChange]);

  const WrapperTag = tag || "form";

  return (
    <WrapperTag
      ref={form}
      method={method}
      action={action}
      onSubmit={(e) => {
        if (preventDefaultBehavior) {
          e.preventDefault();
        }

        if (!form.current) return;

        const formData = new FormData(form.current);

        let parsedData = {};

        formData.forEach((value, key) => {
          parsedData[key] = value;
        });

        if (onSubmit) onSubmit(parsedData, formState);
      }}
    >
      <FormContext.Provider
        value={{
          ...formState,
          setFormContext: (update) => {
            setFormState((currentContext) => {
              let newContext;

              if (typeof update === "function") {
                const contextUpdate = update(currentContext);

                newContext = {
                  ...currentContext,
                  ...contextUpdate,
                  data: {
                    ...currentContext.data,
                    ...contextUpdate.data,
                  },
                };
              } else {
                newContext = {
                  ...currentContext,
                  ...update,
                  data: {
                    ...currentContext.data,
                    ...update.data,
                  },
                };
              }

              if ((newContext.invalid && newContext.invalid.length > 0) 
                  || (newContext.invalid_plant_details && newContext.invalid_plant_details === true)
                  || (newContext.invalid_postponement_trades && newContext.invalid_postponement_trades === true)) {
                newContext.valid = false;
              } else {
                newContext.valid = true;
              }

              return newContext;
            });
          },
        }}
      >
        {children}
      </FormContext.Provider>
    </WrapperTag>
  );
};

Form.propTypes = {
  ref: PropTypes.func,
  onSubmit: PropTypes.func,
  method: PropTypes.oneOf(["post"]),
  action: PropTypes.string,
  preventDefaultBehavior: PropTypes.bool,
};

export {
  Input,
  InputGroup,
  FormRow,
  FormFooter,
  FormContext,
  defaultFormContext,
};

export default Form;
