import React from "react";
import ValidityPremiumCurve from "./ValidityPremiumCurve";
import MarketPremiumCurve from "./MarketPremiumCurveConfiguration";
import TierCustomerGroupComponent from "./TierCustomerGroup";


const TierCustomerComponent = () => {
    
    return (
        <div>
            <ValidityPremiumCurve />
            <br/><br/><br/>
            <MarketPremiumCurve />
            <br/><br/><br/>
            <TierCustomerGroupComponent />
        </div>
    )
}

export default TierCustomerComponent;