import React, {useContext, useState} from "react";
import {FormContext, InputGroup} from "../Form";
import {deepClone} from "../../helpers";
import {Button} from "rt-design-system-backup";
import {
    MAX_DDP_FIELDS,
    MAX_DDP_VALUES,
    fieldInterface,
    filterAlreadySelectedNexTradeFields,
    filterAlreadySelectedNexTradeFieldsValue,
    reconstructInitialTemplate,
    valueInterface,
    dropdownDataTransformer,
    fetchNexTradeFieldValues,
    removeItemsWithPrefix
} from "./helper";

// NexTradeForm is a React component for dynamically handling a form with NexTrade Fields and their values.
const NexTradeForm = ({data: _data, NexTradeFields}) => {
    const [data, setData] = useState(_data)
    const formContext = useContext(FormContext);
    const EditMode = !!data;
    const initialNexTradeTemplate = EditMode ? reconstructInitialTemplate(data, fieldInterface, valueInterface) : [fieldInterface];

    //This state holds the final template i.e how much fields inputs and how much values inputs against each field will be there, we can modify this data to add or remove field and values inputs
    //Note: this state doesnt hold actual data
    const [NexTradeTemplate, setNexTradeTemplate] = useState(initialNexTradeTemplate);

    //This is actual data we hold for each dppfield with available values fetched from backend
    const [NexTradeFieldValues, setNexTradeFieldValues] = useState({});

    //Update the form context on form change
    const formContextUpdateHandler = (name, value) => {
        formContext.setFormContext((currentContext) => {
            return {
                ...currentContext, data: {
                    ...currentContext.data, [name]: value,
                },
            };
        });
    };

    // Handles changes in NexTrade Field inputs and fetches corresponding values from the backend.
    const handleDPPFieldInputChange = async (NexTradeFieldNum, inputValue) => {
        const val = inputValue[0]?.value;
        if (val) {
            const data = await fetchNexTradeFieldValues(val);
            const modData = dropdownDataTransformer(data);
            setNexTradeFieldValues((prev) => {
                const tempData = deepClone(prev);
                tempData[`NexTradeField${NexTradeFieldNum}`] = deepClone(modData);
                return tempData;
            });
        }
        formContextUpdateHandler(`NexTradeField${NexTradeFieldNum}`, inputValue);
    };

    // Returns dropdown items for NexTrade Fields, filtering out already selected fields.
    const getNexTradeFieldDropdownItems = () => {
        return dropdownDataTransformer(deepClone(filterAlreadySelectedNexTradeFields(NexTradeFields, formContext.data)));
    };

    // Returns dropdown items for NexTrade Field values, based on the selected NexTrade Field.
    const getNexTradeValueDropdownItems = (NexTradeFieldNum) => {
        if (NexTradeFieldValues[`NexTradeField${NexTradeFieldNum}`]?.length) {
            return deepClone(filterAlreadySelectedNexTradeFieldsValue(NexTradeFieldValues[`NexTradeField${NexTradeFieldNum}`], formContext.data, NexTradeFieldNum));
        } else {
            return [{}];
        }
    };

    // Handles click event for adding a new NexTrade Field value input.
    const addNexTradeFieldValueButtonClickHandler = (index) => {
        setNexTradeTemplate((prev) => {
            const data = [...deepClone(prev)];
            data[index].values.push(valueInterface);
            return data;
        });
    };

    // Handles click event for adding a new NexTrade Field input.
    const addNexTradeFieldButtonClickHandler = () => {
        setNexTradeTemplate((prev) => {
            const data = [...prev];
            data.push(fieldInterface);
            return data;
        });
    };

    // Checks if the maximum limit of DDP values for a field is reached.
    const isNexTradeValueMaxLimitReached = (DPPFieldNum, templateValueCount) => {
        const currentFieldValue = NexTradeFieldValues && NexTradeFieldValues[`NexTradeField${DPPFieldNum}`] ? NexTradeFieldValues[`NexTradeField${DPPFieldNum}`].length : 0;
        return (templateValueCount < currentFieldValue && templateValueCount < MAX_DDP_VALUES);
    };

    // Handler for removing the last NexTrade Field
    const removeNexTradeFieldButtonClickHandler = () => {
        setNexTradeTemplate((prev) => {
            const newData = [...prev];
            newData.pop(); // Remove the last field from the template
            return newData;
        });


        setData(removeItemsWithPrefix(data, `NexTradeField${NexTradeTemplate.length}`))


        formContext.setFormContext((currentContext) => {
            const newContext = {...currentContext};
            const lastFieldKey = `NexTradeField${NexTradeTemplate.length}`;
            delete newContext.data[lastFieldKey]; // Remove the data for the last field
            // Remove data for the values of the last field
            for (const key in newContext.data) {
                if (key.startsWith(`${lastFieldKey}_Value`)) {
                    delete newContext.data[key];
                }
            }
            return newContext;
        });
    };

    // Handler for removing the last NexTrade Field value of a specific field
    const removeNexTradeFieldValueButtonClickHandler = (fieldIndex) => {
        formContext.setFormContext((currentContext) => {
            const newContext = {...currentContext};
            const lastValueKey = `NexTradeField${fieldIndex + 1}_Value${NexTradeTemplate[fieldIndex].values.length}`;
            delete newContext.data[lastValueKey]; // Remove the data for the last value
            return newContext;
        });

        setNexTradeTemplate((prev) => {
            const newData = [...prev];
            newData[fieldIndex].values.pop(); // Remove the last value from the specified field
            return newData;
        });

    };

    // The render method of the component, which includes the form fields and buttons.
    return (<>
        {NexTradeTemplate.map(({_, values}, index) => {
            return (<>
                <InputGroup
                    required
                    type="select"
                    placeholder={"Select one"}
                    title={`NexTrade Field ${index + 1}`}
                    name={`NexTradeField${index + 1}`}
                    // defaultValue={EditMode ? [data[`NexTradeField${index + 1}`]] : ""}
                    defaultValue={EditMode ? [data[`NexTradeField${index + 1}`]] : ""}
                    onChange={(value) => handleDPPFieldInputChange(index + 1, value)}
                    items={getNexTradeFieldDropdownItems()}
                />
                <h1>NexTrade Field Values</h1>
                {values.map((_, idx) => (<InputGroup
                    key={`NexTradeField${index + 1}_Value${idx + 1}+${idx}+${NexTradeFieldValues[`NexTradeField${index + 1}`]}`}
                    required
                    defaultValue={EditMode && data[`NexTradeField${index + 1}_Value`] ? [data[`NexTradeField${index + 1}_Value`][idx]] : ""}
                    name={`NexTradeField${index + 1}_Value${idx + 1}`}
                    type="select"
                    placeholder={"Select one"}
                    onChange={(value) => formContextUpdateHandler(`NexTradeField${index + 1}_Value${idx + 1}`, value)}
                    items={getNexTradeValueDropdownItems(index + 1)}
                />))}
                <div style={{display: "flex", gap: "10px"}}>
                    {isNexTradeValueMaxLimitReached(index + 1, values.length) && (<Button
                        style={{
                            width: "fit-content", borderRadius: "10px", margin: "4px 0",
                        }}
                        onClick={() => addNexTradeFieldValueButtonClickHandler(index)}
                        intent="none"
                        minimal
                        outlined
                        text="+ Add NexTrade Field Value"
                    />)}
                    {values.length > 1 && ( // Show remove button only if more than one value exists
                        <Button
                            style={{
                                width: "fit-content", borderRadius: "10px", margin: "4px 0",
                            }}
                            onClick={() => removeNexTradeFieldValueButtonClickHandler(index)}
                            intent="danger"
                            minimal
                            outlined
                            text="- Remove NexTrade Field Value"
                        />)}
                </div>
            </>);
        })}
        <div
            style={{
                display: "flex", alignContent: "center", gap: 16, marginTop: 24,
            }}
        >
            {NexTradeTemplate.length < MAX_DDP_FIELDS && (<Button
                style={{
                    width: "fit-content", borderRadius: "10px", margin: "4px 0", display: "block",
                }}
                onClick={addNexTradeFieldButtonClickHandler}
                intent="primary"
                minimal
                outlined
                text="+ Add NexTrade Field "
            />)}
            {NexTradeTemplate.length > 1 && ( // Show remove button only if more than one field exists
                <Button
                    style={{
                        width: "fit-content", borderRadius: "10px", margin: "4px 0", display: "block",
                    }}
                    onClick={removeNexTradeFieldButtonClickHandler}
                    intent="danger"
                    minimal
                    outlined
                    text="- Remove NexTrade Field"
                />)}
        </div>
    </>);
};

export default NexTradeForm;