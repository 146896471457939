import React, { useState, useRef, useEffect } from "react";

import {
  Select as RTSelect,
  MultiSelect as RTMultiSelect,
  MenuItem,
} from "rt-design-system-backup";
import MarineContext from "../MarineContext";
import {MARINE_CARD_BACKGROUND_COLOR} from "../../helpers/constants";

const Select = ({
  items,
  multiple,
  name,
  required,
  onChange,
  placeholder,
  fill,
  intent,
  inputRef,
  large,
  defaultValue,
  ...props
}) => {
  const value = (defaultValue || [])
    .filter((item) => {
      return items.find((i) => {
        return i.value === item;
      });
    })
    .map((item) => {
      return items.find((i) => {
        return i.value === item;
      });
    });

  const [selected, setStateSelected] = useState(value);

  const input = useRef(false);

  useEffect(() => {
    if (inputRef && typeof inputRef === "function") inputRef(input);
  }, [input, inputRef]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (onChange && defaultValue) onChange(value);
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const setSelected = (arg) => {
    if (typeof arg !== "function") {
      if (onChange) onChange(arg);
      setStateSelected(arg);
    } else {
      if (onChange) onChange(arg(selected));
      setStateSelected(arg(selected));
    }
  };

  const Tag = multiple ? RTMultiSelect : RTSelect;

  return (
    <>
      <MarineContext.Consumer>
        {({isDarkModeOn, showImage}) => (
          <Tag
            items={items}
            {...props}
            onItemSelect={(item) => {
              if (!multiple) {
                setSelected([item]);
                return;
              }

              setSelected((items) => {
                if (items.find((el) => el.value === item.value)) {
                  return [...items].filter((el) => el.value !== item.value);
                } else {
                  return [...items, item];
                }
              });
            }}
            selectedItems={selected}
            popoverProps={{
              minimal: true,
            }}
            value={selected.length ? selected : undefined}
            inputValueRenderer={(value) => {
              return value.name || undefined;
            }}
            defaultSelectedItem={
              defaultValue
                ? items.find((item) => {
                    return item.value === defaultValue[0];
                  })
                : undefined
            }
            inputProps={{
              large: large || false,
              placeholder: placeholder ,
              style: {
                color: isDarkModeOn && showImage ? 'white': '',
                background: isDarkModeOn && showImage ? MARINE_CARD_BACKGROUND_COLOR: '',
              }
            }}
            resetOnClose={props.resetOnClose || false}
            tagInputProps={{
              large: large || false,
              placeholder: placeholder,
              tagProps: {
                intent: "none",
                minimal: true,
                round: true,
              },
              onRemove: (itemName) => {
                if (!props.disabled) {
                 setSelected([...selected].filter((item) => item.name !== itemName));
                }
              },
            }}
            intent={intent}
            tagRenderer={(item) => {
              return item.name;
            }}
            fill={fill}
            placeholder={placeholder}
            itemPredicate={(query, item) => {
              return (
                item &&
                (`${item.value}`.toLowerCase().includes(query.toLowerCase()) ||
                  `${item.name}`.toLowerCase().includes(query.toLowerCase()))
              );
            }}
            itemRenderer={(item, { handleClick }) => {
              return (
                <MenuItem
                  onClick={handleClick}
                  text={item.name}
                  style={isDarkModeOn && showImage ? {background: MARINE_CARD_BACKGROUND_COLOR, color: 'white'}: {}}
                  icon={
                    multiple
                      ? selected.find((el) => el.value === item.value)
                        ? "tick"
                        : "blank"
                      : undefined
                  }
                />
              );
            }}
          />
        )}
      </MarineContext.Consumer>
      <input
        ref={input}
        style={{ display: "none" }}
        onChange={onChange}
        required={required || undefined}
        name={name}
        value={selected.map((item) => item.value).join(",")}
      />
    </>
  );
};

export default Select;
