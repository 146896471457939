import React, {useContext, useEffect, useState} from "react";
import { Card } from "rt-design-system-backup";
import {PriceTableRow, PriceTableCell} from "../PriceTable/Base";
import {formatNumber, url, getTooltip, getTime, calculateDelta, getFontColorDelta, getBestOffer} from "../helpers";
import Axios from "axios";
import "./style.scss"
import SocketContext from "../App/SocketContext";

function BrokerPriceTable({broker, routeId, maturities}) {
    const [offers, setOffers] = useState([]);
    const [lasts, setLasts] = useState([]);
    const [enableTransition, setEnableTransition] = useState(false);
    const [closes, setCloses] = useState([]);
    const getCell = (val) => {
      if (!val) return "-";
      return formatNumber(val);
    };
    useEffect(() => {
      const fetchContracts = async () => {

          try {
            const res = await Axios.get(
              url("/offer/?route=" + routeId + "&broker=" + broker.id)
            );
            setOffers(res.data);
            const lastResponse = await Axios.get(
              url("/last/?route=" + routeId + "&broker=" + broker.id)
            );
            setLasts(lastResponse.data.data)
            const closeResponse = await Axios.get(
              url("/close/?route=" + routeId)
            );
            setCloses(closeResponse.data)
            setEnableTransition(true);
          } catch (err) {
            console.log(err);
          }
      };

      fetchContracts();

    }, [routeId, broker])

    const socket = useContext(SocketContext);

    useEffect(() => {
    const updateOffers = (data) => {
      const offer = data.detail.tableData;
      const routeID = offer.contract.route.id;
      if (parseInt(routeId) === routeID && offer.from_message?.sender?.broker?.name === broker.name) {
        if (offer.type === 'bid' || offer.type === 'offer') {
          setOffers([offer, ...offers]);
        } else if(offer.type === 'last') {
          setLasts([offer, ...lasts]);
        }
      }
    }
    socket.addEventListener("offer_update", updateOffers);
    return () => socket.removeEventListener("offer_update", updateOffers)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offers, lasts])

    const rows = maturities.map((maturity) => {
        return {
          maturity: maturity,
          bid: getBestOffer(offers, maturity, "bid"),
          offer: getBestOffer(offers, maturity, "offer"),
          close: closes.find((close) => {
            return (
                close.contract.maturity.string === maturity.string
            )
          }),
          last: lasts.find((last) => {
            return (
                last.contract.maturity.string === maturity.string
            )
          })
        };
    });

    return(
        <Card elevation={2} style={{marginRight: 8, padding: 10}}>
            <div>
                <p style={{paddingTop: 0, fontWeight: 700}}>{broker.name}</p>
                <hr style={{marginBottom: 8, marginTop: 8}}/>
                <div>
                <table style={{width: '100%', tableLayout: 'fixed', height: '100%'}}>
                    <thead>
                    <PriceTableRow>
                        <PriceTableCell isHeader={true}>Tenor</PriceTableCell>
                        <PriceTableCell isHeader={true}>Bid</PriceTableCell>
                        <PriceTableCell isHeader={true}>Offer</PriceTableCell>
                        <PriceTableCell isHeader={true}>Delta</PriceTableCell>
                        <PriceTableCell isHeader={true}>Close</PriceTableCell>
                        <PriceTableCell isHeader={true}>Last</PriceTableCell>
                    </PriceTableRow>
                    </thead>
                    <tbody>
                    {rows.map((row, index) => {
                        return(
                            <PriceTableRow>
                                <PriceTableCell>
                                    {row.maturity.shortened_string.toUpperCase()}
                                </PriceTableCell>
                                <PriceTableCell tooltip={getTooltip(row.bid)} created_at={getTime(row.bid)}
                                                key={`${index}bid`} valid_until={row.bid?.valid_until}
                                                enableTransition={enableTransition}>
                                    {getCell(row.bid?.value)}
                                </PriceTableCell>
                                <PriceTableCell tooltip={getTooltip(row.offer)} created_at={getTime(row.offer)}
                                                key={`${index}offer`} valid_until={row.offer?.valid_until}
                                                enableTransition={enableTransition}>
                                    {getCell(row.offer?.value)}
                                </PriceTableCell>
                                <PriceTableCell
                                    fontColor={getFontColorDelta(calculateDelta(row.bid?.value, row.offer?.value, row.close?.value))}
                                >
                                    {calculateDelta(row.bid?.value, row.offer?.value, row.close?.value)}
                                </PriceTableCell>
                                <PriceTableCell>{getCell(row.close?.value)}</PriceTableCell>
                                <PriceTableCell tooltip={getTooltip(row.last)} created_at={getTime(row.last)} key={`${index}last`}
                                    enableTransition={enableTransition}>
                                    {getCell(row.last?.value)}
                                </PriceTableCell>
                            </PriceTableRow>
                        )
                    })}
                    </tbody>
                </table>
                </div>
            </div>
        </Card>
    )
}
export default BrokerPriceTable;
