import React, {useEffect} from "react";
import useAxios from "axios-hooks";

import { Pie } from "react-chartjs-2";
import { Card } from "rt-design-system-backup"

import { url } from "../../../../helpers";
import { dateFormat } from "../../Maturities";
import LoadingIcon from "../../../Icons/loading";
import { formatNumber } from "../utils";



const CSRPieChart = ({start_date, end_date, query_param, title, isExtraLargeScreen, selectedCustomer}) => {

    const [{ loading: chartDataLoading, data: chartData }, refetch] = useAxios({
        url: url(`/aluminium_analytics/fetch_total_pnl/?start_date=${dateFormat.formatDate(start_date)}&end_date=${dateFormat.formatDate(end_date)}&attribute=${query_param}${selectedCustomer !== -1 ? '&customer=' + selectedCustomer : ''}`),
    });

    useEffect(()=>{
        if (!selectedCustomer && !query_param && !start_date && !end_date) return
        refetch()
        // eslint-disable-next-line
    }, [query_param, start_date, end_date, selectedCustomer])

    const BackgroundColors = [
        '#0017FA',
        '#003776',
        '#0059D1',
        '#0087FA'
    ]
    const TeamBackgroundColors = [
        '#003776',
        '#0059D1',
        '#0087FA'
    ]
    const data = {
        labels: chartDataLoading === false && chartData ? chartData.map(item => item.label): [],
        datasets: [
            chartDataLoading === false && chartData && {
                data: chartData.map(item => item.ttl_pnl),
                backgroundColor: query_param === 'sales_entity' ? BackgroundColors.slice(0, chartData.length) : TeamBackgroundColors.slice(0, chartData.length)
            }
        ]
    }
    
    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: false,
            tooltip: {
                titleFont: {
                  family: 'RT_VickermanW01'
                },
                bodyFont: {
                  family: 'RT_VickermanW01'
                },
                callbacks: {
                    label: function(context) {
                        return context.label + ': ' + formatNumber(context.formattedValue.replace(/,/g, ''), false, 0)
                    }
                }
            }
        }
    }

    return (
        <>
        {chartDataLoading === true ? (
            <div style={{ alignItems: "center", justifyContent: "center", height: '100%', display: 'flex'}}>
                <LoadingIcon />
                <p>Loading Data</p>
            </div>
        ) : (
            <Card elevation={1} style={{padding: '10px', minHeight: isExtraLargeScreen === true ? '260px' : '240px'}}>
                {chartData.length === 0 ? (
                    <div style={{ alignItems: "center", justifyContent: "center", minHeight: 'inherit', display: 'flex'}}>
                        <p>No Data available</p>
                    </div>
                ) : (
                    <>
                        <h1>{title}</h1>
                        <div style={{height: isExtraLargeScreen === true ? '282px' : '209px', marginTop: '10px'}}>
                          <Pie data={data} options={options}/>
                        </div>
                    </>
                    
                )}
            </Card>
        )}
        </>
    )
}
export default CSRPieChart