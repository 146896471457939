import {InputGroup} from "../../Form";
import React from "react";

const Select = ({name, label, data, items}) => <InputGroup
    title={label}
    defaultValue={data ? [data[name]] : []}
    required
    name={name}
    key={name}
    type="select"
    placeholder={label}
    items={items}
/>

export default Select;
