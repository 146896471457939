import {Icon} from "rt-design-system-backup";
import React from "react";
import {useDeleteSystemColumn} from "../../../Views/STPMappingSystemColumns/api";
import {useSTPMappingSystemColumnContext} from "../../STPMappingSystemColumnsContext";
import {errorToaster, successToaster} from "../../../../helpers";

const Actions = ({record, history}) => {
    const {reloadSystemColumns, reloadSystemColumnValues} = useSTPMappingSystemColumnContext()
    const {refetch: deleteColumn} = useDeleteSystemColumn(record.id)
    return <>
        <div style={{whiteSpace: "nowrap"}}>
            <Icon
                icon="eye-open"
                style={{cursor: "pointer"}}
                onClick={() => {
                    history.push(`/stp-mapping-console/system-columns/${record.id}/values`);
                }}
            />
            <Icon
                icon="edit"
                style={{cursor: "pointer"}}
                onClick={() => {
                    history.push(`/stp-mapping-console/system-columns/update/${record.id}`);
                }}
            />
            <Icon
                icon="delete"
                style={{cursor: "pointer"}}
                onClick={async () => {
                    await deleteColumn().then(() => successToaster('System Column deleted')).catch(()=> errorToaster('Failed to delete System Column'));
                    await reloadSystemColumns()
                    await reloadSystemColumnValues()
                }}
            />
        </div>
    </>;
}

const renderSystemColumnActions = (history) => (_, record) => <Actions record={record} history={history}/>;

export {renderSystemColumnActions}