import React, { useContext, useEffect, useState } from "react";
import useAxios from "axios-hooks";

import {
  Button,
  Icon,
  Spinner,
} from "rt-design-system-backup";

import { Input, Space, Table } from "antd";
import TradeDeleteDialog from "../../TradeDeleteDialog";

import {
  url,
  capitalize,
  formatSort,
  formatDate,
} from "../../../helpers";
import SocketContext from "../../SocketContext";
import SortContext from "../../SortContext";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {useHistory} from "react-router-dom";

const renderItem = (page, type, originalElement) => {
  if (type === "prev") {
    return <Button icon="arrow-left" intent="primary" />;
  } else if (type === "next") {
    return <Button icon="arrow-right" intent="primary" />;
  } else {
    return originalElement;
  }
};


const TableInterAction = (props) => {
  const [tradeDeleteView, setTradeDeleteView] = useState(false);

  const initial_filters = {}
  const filter_keys = ['trade', 'contract', 'tenor', 'book', 'bidOffer', 'broker', 'clearer', 'order']
    filter_keys.forEach(filter_key => {
      const value = localStorage.getItem(`trade_filter_${filter_key}`)
      if (value === '-') {
        delete initial_filters[filter_key]
      } else if (value) {
        initial_filters[filter_key] = value.split(',')
      }
    });

  const [filteredInfo, setFilterInfo] = useState(initial_filters);

  const history = useHistory();
  const sortContext = useContext(SortContext);

  let [{ data: trades, loading }, refetch] = useAxios({
    url: url("copper/copper_trades/" + formatSort(sortContext)),
  });

  const [searchText, setSearchText] = useState("initState");

  const handleChange = (pagination, filters, sorter) => {
    setFilterInfo(filters);

    Object.keys(filters).forEach(filter => {
      if (filters[filter]) {
        localStorage.setItem(`trade_filter_${filter}`, filters[filter].join())
      } else {
        localStorage.setItem(`trade_filter_${filter}`, '-')
      }
    })
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
      {
      title: "Order ID",
      dataIndex: "order",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ID`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 160, marginBottom: 8, display: "block" }}
          />
          <Space size={'large'}>
            <Button
              intent="secondary"
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ minHeight: '24px', minWidth: 60 }}
            >
              Reset
            </Button>
            <Button
              intent="primary"
              icon={<SearchOutlined />}
              onClick={() => handleSearch(selectedKeys, confirm)}
              size="small"
              style={{ minHeight: '24px', minWidth: 60 }}
            >
              Search
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? "#1890ff" : "#8e8e8e", fontSize: "16px" }}
        />
      ),
      onFilter: (value, record) =>
        record.order.toString().includes(value.toString()),
      filteredValue: filteredInfo.order || null,
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
      fixed: "left",
    },
    {
      title: "ID",
      dataIndex: "trade",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ID`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 160, marginBottom: 8, display: "block" }}
          />
          <Space size={'large'}>
            <Button
              intent="secondary"
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ minHeight: '24px', minWidth: 60 }}
            >
              Reset
            </Button>
            <Button
              intent="primary"
              icon={<SearchOutlined />}
              onClick={() => handleSearch(selectedKeys, confirm)}
              size="small"
              style={{ minHeight: '24px', minWidth: 60 }}
            >
              Search
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? "#1890ff" : "#8e8e8e", fontSize: "16px" }}
        />
      ),
      onFilter: (value, record) =>
        record.trade.toString().includes(value.toString()),
      filteredValue: filteredInfo.trade || null,
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
      fixed: "left",
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => {
        const fromDate = new Date(b.date);
        const toDate = new Date(a.date);

        return fromDate.getTime() - toDate.getTime();
      },
      render: (dateString) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>{formatDate(dateString)}</div>
        );
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Strategy",
      dataIndex: "strategy",
      filters: trades
        ? trades
          .map((item) => item.strategy)
          .filter(
            (elem, pos) =>
              elem &&
              trades.map((item) => item.strategy)
                .indexOf(elem) === pos
          )
          .map((value) => ({
            text: capitalize(value),
            value: capitalize(value),
          }))
        : [],
      filteredValue: filteredInfo.strategy || null,
      onFilter: (value, record) => record.strategy.includes(value),
    },
    {
      title: "Order Type",
      dataIndex: "order_type",
      filters: trades
        ? trades
          .map((item) => item.order_type)
          .filter(
            (elem, pos) =>
              elem &&
              trades.map((item) => item.order_type)
                .indexOf(elem) === pos
          )
          .map((value) => ({
            text: capitalize(value),
            value: capitalize(value),
          }))
        : [],
      filteredValue: filteredInfo.order_type || null,
      onFilter: (value, record) => record.order_type.includes(value),
    },
    {
      title: "Contract",
      dataIndex: "contract",
      filters: trades
        ? trades
          .map((item) => item.copper.contract.name)
          .filter(
            (elem, pos) =>
              elem &&
              trades.map((item) => item.copper.contract.name)
                .indexOf(elem) === pos
          )
          .map((value) => ({
            text: capitalize(value),
            value: capitalize(value),
          }))
        : [],
      filteredValue: filteredInfo.contract || null,
      onFilter: (value, record) => record.contract.includes(value),
    },
    {
      title: "Entity",
      dataIndex: "entity",
      filters: trades
        ? trades
          .map((item) => item.copper.entity.name)
          .filter(
            (elem, pos) =>
              elem &&
              trades.map((item) => item.copper.entity.name)
                .indexOf(elem) === pos
          )
          .map((value) => ({
            text: capitalize(value),
            value: capitalize(value),
          }))
        : [],
      filteredValue: filteredInfo.entity || null,
      onFilter: (value, record) => record.entity.includes(value),
    },
    {
      title: "Customer",
      dataIndex: "customer",
      filters: trades
        ? trades
          .map((item) => item.copper.customer.display_name)
          .filter(
            (elem, pos) =>
              elem &&
              trades.map((item) => item.copper.customer.display_name)
                .indexOf(elem) === pos
          )
          .map((value) => ({
            text: capitalize(value),
            value: capitalize(value),
          }))
        : [],
      filteredValue: filteredInfo.customer || null,
      onFilter: (value, record) => record.customer.includes(value),
    },
    {
      title: "Quota Month",
      dataIndex: "quota_month",
    },
    {
      title: "Pricing Date",
      dataIndex: "pricing_date",
      sorter: (a, b) => {
        const fromDate = new Date(b.pricing_date);
        const toDate = new Date(a.pricing_date);

        return fromDate.getTime() - toDate.getTime();
      },
      render: (dateString) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>{dateString ? dateString : ''}</div>
        );
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Buy/Sell",
      dataIndex: "action",
      filters: trades
        ? trades
          .map((item) => item.action)
          .filter(
            (elem, pos) =>
              elem &&
              trades.map((item) => item.action)
                .indexOf(elem) === pos
          )
          .map((value) => ({
            text: capitalize(value),
            value: capitalize(value),
          }))
        : [],
      filteredValue: filteredInfo.action || null,
      onFilter: (value, record) => record.action.includes(value),
    },
    {
      title: "Volume",
      dataIndex: "quantity",
    },
    {
      title: "Unit",
      dataIndex: "unit",
    },
    {
      title: "Maturity Month",
      dataIndex: "maturity",
      render: capitalize,

    },
    {
      title: "Requestor",
      dataIndex: "requestor",
      filters: trades
        ? trades
          .map((item) => {
            return item.requestor
          })
          .filter(
            (elem, pos) =>
              elem &&
              trades.map((item) => item.requestor)
                .indexOf(elem) === pos
          )
          .sort()
          .map((value) => ({
            text: value,
            value: value,
          }))
        : [],
      filteredValue: filteredInfo.requestor || null,
      onFilter: (value, record) => record.requestor.includes(value),
    },
    {
      title: "Requestor",
      dataIndex: "requestor",
      filters: trades
        ? trades
          .map((item) => item.trader)
          .filter(
            (elem, pos) =>
              elem &&
              trades.map((item) => item.trader)
                .indexOf(elem) === pos
          )
          .sort()
          .map((value) => ({
            text: value,
            value: value,
          }))
        : [],
      filteredValue: filteredInfo.trader || null,
      onFilter: (value, record) => record.trader.includes(value),
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "x",
      render: (_, record) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            <span style={{ cursor: 'pointer' }}>
              <Icon
                icon="eye-open"
                onClick={() => {
                  history.push(`/coppers/${record.order}`);
                }}
              />
            </span>
          </div>
        )
      }
    }
  ];

  // Sockets

  const socket = useContext(SocketContext);

  useEffect(() => {
    refetch();
    socket.addEventListener("trade_create", refetch);
    socket.addEventListener("trade_update", refetch);
    socket.addEventListener("order_update", refetch);

    return () => {
      socket.removeEventListener("trade_create", refetch);
      socket.removeEventListener("trade_update", refetch);
      socket.removeEventListener("order_update", refetch);
    };
  }, [socket, refetch]);

  return (
    <>
      <TradeDeleteDialog
        trade={
          !trades ? undefined : trades.find(({ id }) => id === tradeDeleteView)
        }
        onClose={() => setTradeDeleteView(false)}
        isOpen={tradeDeleteView !== false}
      />
      <Table
        tableLayout="auto"
        locale={{
          emptyText: "No trades to display",
        }}
        loading={
          loading
            ? {
              delay: 1500,
              indicator: <Spinner size={32} />,
            }
            : false
        }
        scroll={{ x: trades && trades.length > 0 }}
        size="small"
        expandIconColumnIndex={13}
        columns={columns}
        dataSource={
          trades
            ? trades
              .sort((a, b) => {
                return b.id - a.id;
              })
              .map((trade) => {
                return {
                  key: trade.id,
                  order: trade.copper.id,
                  trade: trade.id,
                  date: trade.created_at,
                  strategy: trade.copper.strategy,
                  order_type: trade.copper.order_type,
                  contract: trade.copper.contract.name,
                  entity: trade.copper.entity.name,
                  customer: trade.copper.customer.display_name,
                  quota_month: trade.copper.quota_month,
                  pricing_date: trade.copper.pricing_date_str || trade.copper.pricing_date,
                  action: trade.action,
                  unit: trade.copper.contract.units,
                  quantity: trade.quantity,
                  maturity: trade.maturity_str,
                  requestor: trade.copper.requestor.full_name,
                  trader: (trade.copper.trader && trade.copper.trader.full_name) || '',
                };
              })
            : []
        }
        pagination={{
          pageSize: 20,
          itemRender: renderItem,
          showSizeChanger: false,
        }}
        onChange={handleChange}
      />
    </>
  );
};

const CopperTradesTable = () => {
  return <TableInterAction />;
}

export default CopperTradesTable;
