import React, {useEffect, useState} from 'react';
import {Col, Row, Tooltip} from "antd";
import RequestTimer from "../RequestTimer";
import PricingTable from "../DashboardPricingTable";
import CardMaturities from "../DashboardMaturities";
import {Button, Icon} from "rt-design-system-backup";
import {
  AcceptQuote,
  CancelRequest, ResubmitQuote,
  useShowCancelRequest,
  useShowFillRequest, useShowResubmit
} from "../RequestOverview/RequestOverviewActions";
import FillIcon from "../Icons/fill";
import {Link} from "react-router-dom";
import RequestReconcileModal from "../RequestReconcileModal";
import ReconcileIcon from "../Icons/reconcile";
import {replaceOrderTypes} from "../../helpers";
import StatusTag from "../../core/Tags";


function DashboardCard({request, showReconcile = false}) {
  const [isRequestReconcileModalOpen, setIsRequestReconcileModalOpen] = useState(
    false
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);
  const showCancelRequest = useShowCancelRequest(request);
  const showResubmit = useShowResubmit(request);

  useEffect(() => {
    window.addEventListener("resize", () => setIsMobile(window.innerWidth <= 900));
  }, [])
  const showFillRequest = useShowFillRequest(request)

  return (
    <div style={{
      background: '#FFFFFF', padding: '16px', borderRadius: '8px', width: '378px',
      marginRight: 16, boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.13)'
    }}>
      <Row justify={'space-around'}>
        <Col span={24}>
          <StatusTag status={request.status}/>
          {request.status === "For correction" ?
            <Tooltip placement={isMobile ? "bottom" : "right"} title={request.correction_comments} color={'#00397A'} overlayInnerStyle={{borderRadius: 16, padding: 12}} zIndex={1}>
                  <Icon style={{marginLeft: 5, color: '#00397A'}} icon={'info-sign'}/>
            </Tooltip>
            : null
          }
          {(request.request_type === "Auto Quote") && (request.status === 'Generated') ?
            <RequestTimer validUntil={request.valid_until} validity={request.validity}
                          createdOn={request.created_at} status={request.status}/>
            : null
          }
          <span style={{float: 'right'}}>
              <Link to={"/aluminium/request/" + request.id + "/details"}>
                <Icon iconSize={20} icon="eye-open" color={'#7D7C7C'}/>
              </Link>
            </span>
        </Col>
        <Col span={24}>
          <h1 style={{
            fontSize: '20px',
            marginBottom: 5,
            marginTop: 15
          }}>{replaceOrderTypes(request.request_type)} #{request.id}</h1>
        </Col>
        <Col span={24}>
          <h3 style={{fontSize: '16px'}}>{(request.customer && (request.customer.display_name.length > 35 ? request.customer.display_name.substr(0,35) + '...' : request.customer.display_name)) || '-'}</h3>
        </Col>
      </Row>
      <Row style={{
        marginTop: 10, backgroundColor: '#F5F5F5',
        marginRight: -16, marginLeft: -16, paddingRight: 16, paddingLeft: 16, paddingBottom: 2, paddingTop: 3
      }}>
        <Col span={24}>
          <PricingTable request={request}/>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <CardMaturities request={request}/>
        </Col>
      </Row>
      {(request.request_type === 'Auto Quote' && request.status === 'Generated') || request.status === 'For correction' ?
        <Row style={{marginTop: 15}} justify="space-between">
          <Col style={{paddingRight: 5}} span={12}>
            <CancelRequest large={isMobile} show={showCancelRequest} requestId={request.id}
                           requestType={request.request_type} label={'Cancel'}
                           requestSalesEntity={request.sales_entity.name} requestCreatedOn={request.created_at}
                           fill={true}/>
          </Col>
          <Col span={11}>
            {
              request.status === "For correction" ?
                <a style={{textDecoration: 'none'}} href={"/aluminium/request/" + request.id + "/fill-edit/"}>
                  <Button
                    className={'cmd_button'}
                    icon={'edit'}
                    intent="primary"
                    text="Edit"
                    fill
                    large={isMobile ? true : null}/>
                </a> :
                <AcceptQuote className={'cmd_button'} primary={true} show={true}
                             fill={true} large={isMobile ? true : null} requestId={request.id}/>
            }
          </Col>
        </Row> :
        request.status === 'Open' && showFillRequest ?
          <Row style={{marginTop: 22}} justify="space-between">
            <Col span={24}>
              <a style={{textDecoration: 'none'}} href={"/aluminium/request/" + request.id + "/fill/"}>
                <Button
                  className={'cmd_button'}
                  large={isMobile ? true : null}
                  fill
                  icon={isMobile ? <FillIcon/> : <FillIcon height={'16'} width={'16'}/>}
                  intent={'Primary'}
                  onClick={() => {}}
                >
                  Fill request
                </Button>
              </a>
            </Col>
          </Row> : null
      }
      {showResubmit ?
          <Row style={{marginTop: 22}} justify="space-between">
            <Col span={24}>
              <ResubmitQuote show={true} fill={true} large={isMobile ? true : null} requestId={request.id}/>
            </Col>
          </Row> : null
      }
      {
        showReconcile && request.request_type !== 'Indic Quote' ?
          <Row style={{marginTop: 22}} justify="space-between">
            <Col span={24}>
              <>
                <RequestReconcileModal
                  isOpen={isRequestReconcileModalOpen}
                  onClose={() => setIsRequestReconcileModalOpen(false)}
                  requestId={request.id}
                  request={request}
                />
                <span
                  style={{cursor: "pointer"}}
                  onClick={() => setIsRequestReconcileModalOpen(true)}
                >
                  <Button
                    className={'cmd_button'}
                    icon={<ReconcileIcon/>}
                    intent="primary"
                    text="Reconcile request"
                    fill
                    large={isMobile ? true : null}
                  />
                </span>
              </>
            </Col>
          </Row> : null
      }
    </div>
  );
}

export default DashboardCard;
