import React, {useContext, useEffect, useRef, useState} from "react";
import { Row, Col } from "antd";
import BunkerTable from "./dataTable";
import MarineContext from "../../MarineContext";
import "./style.scss"
import Axios from "axios";
import {url} from "../../../helpers";
import HistoricalFFADashboard from "../HistoricalFFADashboard";
import WtiBrentChart from "./WtiBrentChart";
import SocketContext from '../../../App/SocketContext'
import WtiBrentComponent from "./WtiBrentComponent";

const BunkerPriceDashboardView = () => {
    const {setShowImage} = useContext(MarineContext);
    const [routes, setRoutes] = useState([]);
    const [bloombergRoutes, setBloombergRoutes] = useState([]);
    const [maturities, setMaturities] = useState([]);
    const [lastUpdatedAt, setLastUpdatedAt] = useState();

    const socket = useContext(SocketContext)
    const fetchDataTimeout = useRef(() => { })
    useEffect(() => {
      const fetchData = async () => {
        clearTimeout(fetchDataTimeout.current)
        try {
            const routes = await Axios.get(url('/route/?bunker_routes_only=true'))
            const bloomberg = await Axios.get(url('/route/?bloomberg_routes_only=true'))
            const maturities = await Axios.get(url('/maturity/?display_only&bunker_maturities=true'))
            const bunkerLastUpdatedAt = await Axios.get(url('/offer/get_bunker_latest_updated_at/'));

            setLastUpdatedAt(bunkerLastUpdatedAt.data);
            setRoutes(routes.data);
            for (let i = 0; i < bloomberg.data.length; i++) {
                if (bloomberg.data[i].route === 'COA Comdty') bloomberg.data[i].route = 'Brent'
                if (bloomberg.data[i].route === 'CLA Comdty') bloomberg.data[i].route = 'WTI'
            }
            setBloombergRoutes(bloomberg.data);
            setMaturities(maturities.data)
        } catch (err) {
            console.log(err)
            fetchDataTimeout.current = setTimeout(fetchData, 30000)
        }
      }

      fetchData()

      return () => {
          clearTimeout(fetchDataTimeout.current)
      }
    }, [])

    useEffect(() => {
        const updateLastUpdatedAt = (data) => {
            if (data.detail) {
                setLastUpdatedAt(data.detail);
            }
        }
        socket.addEventListener("bunker_last_updated_at", updateLastUpdatedAt);
        return () => socket.removeEventListener("bunker_last_updated_at", updateLastUpdatedAt)
    })
    useEffect(() => {
        setShowImage(true);
        return () => {
            setShowImage(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getRoute = (route_name) => {
        return routes.find((route) => route.route === route_name)
    }

    const formatDateStr = (lastUpdatedStr) => {
        const dateTimeObject = new Date(lastUpdatedStr);
        const dateStr = dateTimeObject.toLocaleDateString();
        const timeStr = dateTimeObject.toLocaleTimeString('en-US', {hour12:true, hour:'numeric', minute:'numeric'})
        return `${dateStr} ${timeStr}`
    }

    const parseUpdatedAtData = () => {
        if (lastUpdatedAt === undefined || lastUpdatedAt === null) return ''
        const data = lastUpdatedAt.split('\n')
        let dateStr = formatDateStr(data[0].split(' ')[0]) + ' ' + data[0].split(' ')[1] + ' ' + data[0].split(' ')[2]
        return dateStr
    }
    
    return (
      <MarineContext.Consumer>
        {({ isDarkModeOn}) => (
         routes.length !== 0 && maturities.length !== 0 && (
         <div style={{background: isDarkModeOn ? "black" : ''}}>
            <div style={{display: 'flex', justifyContent: 'flex-end', margin: '0px 16px 20px 0px', color: isDarkModeOn ? "white" : 'black', whiteSpace: 'pre-line', alignItems: 'center'}}>
                <div>{`Last Updated At: `}</div>
                <div style={{marginLeft: 10}}>{parseUpdatedAtData()}</div>
                
            </div>
            <Row justify="space-between">
                <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{paddingRight: 16}}>
                    <BunkerTable title="SING VLSFO 0.5%" route={getRoute('SING05')}
                        maturities={maturities} isDarkModeOn={isDarkModeOn} />
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{paddingRight: 16}}>
                    <BunkerTable title="ROTT VLSFO 0.5%" route={getRoute('ROTT05')}
                        maturities={maturities} isDarkModeOn={isDarkModeOn} />
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{paddingRight: 16}}>
                    <BunkerTable title="GASOIL" route={getRoute('MEDGO')}
                        maturities={maturities} isDarkModeOn={isDarkModeOn} />
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{paddingRight: 16}}>
                    <BunkerTable title="SING 380" route={getRoute('SING380')}
                        maturities={maturities} isDarkModeOn={isDarkModeOn} />
                </Col>
            </Row>
            <Row style={{marginTop: 20}}>
                <Col xs={24} sm={24} md={12} lg={8} xl={6} style={{paddingRight: 16}}>
                    <BunkerTable title="HI5 (VLSFO - 380)" route={getRoute('HI5')}
                        maturities={maturities} isDarkModeOn={isDarkModeOn} />
                </Col>
                <Col id={"BunkerGraphComponent"} xs={24} sm={24} md={12} lg={9} xl={9} style={{paddingRight: 16}}>
                    <HistoricalFFADashboard defaultWindowHeight={400} relativeComponentId={"BunkerGraphComponent"} defaultMaturity={maturities[1].string} 
                      defaultRoute={routes.find(o => o.route === 'SING05').id} defaultTime={7} defaultOfferType={'bid'}
                      parentComponent={"BunkerDashboard"}/>
                </Col>
                {bloombergRoutes.length !== 0 && (
                    <Col id={"BunkerBloombergGraphComponent"} xs={24} sm={24} md={12} lg={9} xl={9} style={{paddingRight: 16}}>
                        <Row>
                            <Col lg={12} xl={12} style={{padding: "0px 10px 10px 0px"}}>
                                <WtiBrentComponent route={bloombergRoutes.find(o => o.route === 'Brent').id} title={'Brent'}
                                    isDarkModeOn={isDarkModeOn}
                                />
                            </Col>
                            <Col lb={12} xl={12} style={{padding: "0px 0px 10px 0px"}}>
                                <WtiBrentComponent route={bloombergRoutes.find(o => o.route === 'WTI').id} title={'WTI'} 
                                    isDarkModeOn={isDarkModeOn}
                                />
                            </Col>
                        </Row>
                        <WtiBrentChart defaultWindowHeight={200} relativeComponentId={"BunkerBloombergGraphComponent"}  
                        defaultRoute={bloombergRoutes.find(o => o.route === 'Brent').id} defaultTime={1} defaultRoutes={bloombergRoutes} />
                    </Col>
                )}
            <p style={{color: isDarkModeOn ? "white" : 'black'}}> *Balance of month</p>
            </Row>
        </div>))}
      </MarineContext.Consumer>
    )
}

export default BunkerPriceDashboardView;