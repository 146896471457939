import React, {useEffect, useState} from "react";
import useAxios from "axios-hooks";
import Breadcrumb from "../../Breadcrumb";
import SimpleTable from "../../SimpleTable";
import {formatDate, url} from "../../../helpers";
import {useParams} from "react-router-dom";
import "./style.scss";

const STPAPILogDetails = ({action = "details"}) => {
    const {log_id} = useParams();
    const [{data, loading, error}] = useAxios({
        url: url(`/stp_logs?log_id=${log_id}`),
    });

    const [initialData, setInitialData] = useState("");
    const [updatedData, setUpdatedData] = useState("");
    const [id, setId] = useState("");


    useEffect(() => {
        if (data && data["Description"]) {
            const descriptionFormatted = formatDescription(data["Description"]);
            setInitialData(descriptionFormatted.initial);
            setUpdatedData(descriptionFormatted.updated);

            const formattedObject = Object.values(JSON.parse(data["Description"]))[0]
            const id = formattedObject?.["Rule ID"] ?? formattedObject?.["STP ID"]
            setId(id)
        }
    }, [data]);

    if (loading) return <>Loading...</>;
    if (error) return <>An error occurred: {error.message}</>;

    const formatDescription = (description) => {
        let descriptionObj;
        try {
            descriptionObj = JSON.parse(description);
        } catch (e) {
            console.error("Error parsing description:", description);
            return {initial: description, updated: ""};
        }

        const recursiveFormat = (obj) => {
            if (Array.isArray(obj)) {
                return obj.map(recursiveFormat).join(", ");
            } else if (obj && typeof obj === "object") {
                return Object.entries(obj)
                    .map(([key, value]) => {
                        const formattedValue = recursiveFormat(value);
                        return `${key}: ${formattedValue ? formattedValue : "-"}`;
                    })
                    .join(", ");
            }
            return obj === null || obj === undefined ? "" : obj.toString();
        };

        // Separate initial and updated data
        const initialData = descriptionObj.previous_history
            ? recursiveFormat(descriptionObj.previous_history)
            : descriptionObj.current_changes ? recursiveFormat(descriptionObj.current_changes) : "-";

        const updatedData = descriptionObj.current_changes && descriptionObj.previous_history
            ? recursiveFormat(descriptionObj.current_changes)
            : "-";

        return {initial: initialData, updated: updatedData};
    };

    const styles = {
        whiteSpace: "initial"
    }

    return (
        <div className="stp-details-container">
            <Breadcrumb text="STP Logs" link="/stp-logs"/>

            <div className="stp-api-main">
                <h1 className="stp-api">STP Log Details</h1>
            </div>

            <hr/>

            <div className="details-content">
                <h4 className="details-title">Log ID #{data["Log ID"]}</h4>
                <div className="details-table-container">
                    <SimpleTable className="details-table" style={{
                        marginBottom: "4rem"
                    }}>
                        <tbody>
                        <tr>
                            <td>Type:</td>
                            <td>{data["Type"]}</td>
                        </tr>
                        <tr>
                            <td>
                                {data["Type"] === "API Console" ? "STP ID:" : "Rule ID:"}
                            </td>
                            <td> {id ?? "-"}</td>
                        </tr>

                        <tr>
                            <td>Date & Time:</td>
                            <td>{formatDate(data["Date & Time"])}</td>
                        </tr>
                        <tr>
                            <td>User:</td>
                            <td>{data["User"]}</td>
                        </tr>
                        <tr>
                            <td>Activity Type:</td>
                            <td>{data["Activity Type"]}</td>
                        </tr>

                        <tr>
                            {/* <td>Description:</td>
                <td>
                    <pre>{formatDescription(data["Description"])}</pre>
                </td> */}
                        </tr>
                        </tbody>
                    </SimpleTable>
                    <table className="table">
                        <thead>
                        <tr>
                            <th className="table-header">Intitial Data</th>
                            <th className="table-header">Updated Data</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="table-bottom">
                                <pre style={styles}>{initialData}</pre>
                                {/* Render initial data here */}
                            </td>
                            <td className="table-bottom">
                                <pre style={styles}>{updatedData}</pre>
                                {/* Render updated data here */}
                            </td>
                        </tr>
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    );
};

export default STPAPILogDetails;
