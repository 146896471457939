import React from 'react';
import '../../assets/scss/core.scss';
import {
  Boundary,
  Breadcrumbs as BPBreadcrumbs,
  IBreadcrumbsProps as BPIBreadcrumbsProps,
  IBreadcrumbProps as BPIBreadcrumbProps,
} from '@blueprintjs/core';

export interface IBreadcrumbsProps extends BPIBreadcrumbsProps {
  collapseFrom?: Boundary;
  items: IBreadcrumbProps[];
}
export type IBreadcrumbProps = BPIBreadcrumbProps;

export const Breadcrumbs: React.FC<IBreadcrumbsProps> = (props) => {
  return <BPBreadcrumbs {...props} />;
};
