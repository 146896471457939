import { Alert as BPAlert, IAlertProps as BPIAlertProps } from '@blueprintjs/core';
import React from 'react';

export interface IAlertProps extends BPIAlertProps {
  canOutsideClickCancel?: boolean;
  confirmButtonText?: string;
}

export const Alert: React.FC<IAlertProps> = (props) => {
  return <BPAlert {...props} />;
};
