import React, {useState} from "react";
import Highlighter from "react-highlight-words";
import { MenuOutlined } from '@ant-design/icons';
import { Table } from "antd";

import { Draggable, Droppable } from 'react-beautiful-dnd';
import { SearchOutlined } from "@ant-design/icons";


import { css } from "@emotion/css";



import { renderItem } from "../../../../OrdersTable";
import { InputGroup } from "../../../../Form";
import { formatNumber, getFontColorDelta } from "../../../../../helpers";

const tableCSS = css({
    '.ant-table-content .bp3-icon svg': {
        width: '20px',
        height: '20px',
    }
});

const TierTable = ({tier, data}) => {
    const initial_search_text = localStorage.getItem(`${tier}_filter_id`);
    const initial_filters = {}

    
    const [filteredInfo, setFilterInfo] = useState(initial_filters);
    const [searchText, setSearchText] = useState(initial_search_text || "initState");
    
    const filter_keys = ['customer', 'delivery_months', 'id', 'requestor', 'sales_entity', 'status', 'type']

    if (filter_keys.some(x => localStorage.getItem(`${tier}_filter_${x}`) !== null)) {
        filter_keys.forEach(filter_key => {
        const value = localStorage.getItem(`${tier}_filter_${filter_key}`)
        if (value === '-') {
            delete initial_filters[filter_key]
        } else if (value) {
            initial_filters[filter_key] = value.split(',')
        }
        });
    }
    const customers = data.map((item) => {return {name: item.customer, id: item.customer_id}})

    const handleChange = (pagination, filters, sorter) => {
        setFilterInfo(filters);

        Object.keys(filters).forEach(filter => {
        if (filters[filter]) {
            localStorage.setItem(`${tier}_filter_${filter}`, filters[filter].join())
        } else {
            localStorage.setItem(`${tier}_filter_${filter}`, '-')
        }
        })
    };

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };
    
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    const columns = [
        {
            title: "Customer",
            dataIndex: "customer",
            sorter: Array.sort,
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => (
                <div style={{minWidth: '100%', width: '100%'}}>
                    <InputGroup style={{ padding: 8, minWidth: 800, zIndex: 3 }}
                        required
                        name="customer"
                        type="select"
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            if (e[0].name === 'CLEAR FILTER') {
                            handleReset(clearFilters)
                            setSelectedKeys('')
                            window.location.reload()
                            } else {
                            setSelectedKeys(e[0].name ? [e[0].name] : [])
                            handleSearch(e[0].name ? [e[0].name] : [], confirm)
                            }
                        }}
                        items={customers ? [{name: 'CLEAR FILTER', id: 0}, ...customers.map((item) => {
                            return {
                            name: item.name,
                            value: item.id,
                        }}).sort((a, b) => a.name.localeCompare(b.name))] : []}
                    />
                </div>
            ),
            filterIcon: (filtered) => (
                <SearchOutlined
                    style={{ color: filtered ? "#1890ff" : "#8e8e8e", fontSize: "16px" }}
                />
            ),
            onFilter: (value, record) => {console.log(value, record); return record.customer.toLowerCase().includes(value.toLowerCase())},
            filteredValue: filteredInfo.customer || null,
            render: (text) => (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text || ""}
                />
            ),
        },
        {
            title: "Acc Rate",
            dataIndex: "acceptance_ratio",
            sorter: (a, b) => {
              return parseFloat(a.acceptance_ratio.replace('%', '')) - parseFloat(b.acceptance_ratio.replace('%', ''));
            },
            sortDirections: ["descend", "ascend"],
            render: (value) => {
              return (
                <div style={{ whiteSpace: "nowrap" }}>
                  {formatNumber(value * 100, false, 2, false)}%
                </div>
              );
            },
        },
        {
            title: "TTL Pnl",
            dataIndex: "ttl_pnl",
            sorter: (a, b) => {
              return a.ttl_pnl - b.ttl_pnl;
            },
            sortDirections: ["descend", "ascend"],
            render: (value) => {
              return (
                <div style={{ whiteSpace: "nowrap", color: getFontColorDelta(value) }}>
                  {formatNumber(value, false, 2, false)}
                </div>
              );
            },
        },
        {
            key: "",
            render: () => (
                <MenuOutlined style={{ touchAction: 'none', cursor: 'move' }} />
            ),
        }
    ]
      
    return (
        <>
            <h5 style={{marginTop: '20px'}}>{tier}</h5>
            <Droppable droppableId={tier}>
                {(provided) => (
                <Table
                    className={tableCSS}
                    locale={{
                        emptyText: "No data to display",
                    }}
                    size="small"
                    columns={columns}
                    dataSource={data}
                    tableLayout="auto"
                    pagination={{
                        pageSize: 20,
                        itemRender: renderItem,
                        showSizeChanger: false,
                    }}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    rowKey={"key"}
                    handleChange={handleChange}
                    components={{
                        body: {
                          wrapper: (props) => (
                            <tbody ref={provided.innerRef} {...props} />
                          ),
                          row: ({ children, ...props }) => (
                            <Draggable draggableId={props['data-row-key']} index={props.index}>
                              {(dragProvided) => (
                                <tr
                                  ref={dragProvided.innerRef}
                                  {...props}
                                  {...dragProvided.draggableProps}
                                  {...dragProvided.dragHandleProps}
                                >
                                  {children}
                                </tr>
                              )}
                            </Draggable>
                          ),
                        },
                      }}
                />
            )}
            </Droppable>
        </>
    )
}
export default TierTable;
