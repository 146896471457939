import { Boundary, Breadcrumbs as BPBreadcrumbs } from '@blueprintjs/core';
import React from 'react';
import '../../assets/scss/core.scss';

export interface OverflowListProps<T> {
  collapseFrom?: Boundary;
  items?: T[];
}

export const OverflowList: React.FC<OverflowListProps<any>> = (props) => {
  return <BPBreadcrumbs {...props} />;
};
