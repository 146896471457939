import { captureException } from '@sentry/browser'
import { Component } from 'react'

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    captureException(error)
  }

  render() {
    if (this.state.hasError) {
      return <p>Something went wrong rendering this component.</p>;
    }

    return this.props.children;
  }
}
