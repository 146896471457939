import React, { useState, useEffect, useContext } from "react";
import { FormContext, InputGroup, FormFooter } from "../../Form";
import { Button } from "rt-design-system-backup";
import { useHistory, useLocation } from "react-router-dom";
import Toaster from "../../Toaster";
import useAxios from "axios-hooks";
import { url } from "../../../helpers";
import Page from "../../Page";
import { dropdownDataTransformer } from "../../STPMappingForm/helper";

import "./style.scss";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const EditSTPApiConsole = () => {
  const formContext = useContext(FormContext);
  const history = useHistory();
  const location = useLocation();
  const initialData = location.state;

  const { apiId } = useParams();

  // Initialize form state with initialData if available
  const [system, setSystem] = useState("");
  const [systemId, setSystemId] = useState("");
  const [status, setStatus] = useState("");
  const [updateInProgress, setUpdateInProgress] = useState(false);

  const [{ loading: updating }, executeUpdate] = useAxios(
    {
      url: url(`/trade_sync/${apiId}/`),
      method: "PUT",
    },
    { manual: true }
  );

  const [{ data }] = useAxios(
    {
      url: url(`/trade_sync/${apiId}/`),
      method: "GET",
    },
    {
      useCache: false,
    }
  );

  useEffect(() => {
    if (data) {
      setSystem(data.system || "Fusion");
      setSystemId(data.system_id || "");
      setStatus(data.status || "");
    }
  }, [data]);

  useEffect(() => {
    // Populate form with initial data
    if (initialData) {
      setSystem(initialData.system || "Fusion");
      setSystemId(initialData.fusionID || "");
      setStatus(initialData.status?.toString() || "");
    }
  }, [initialData]);

  const handleInputChange = (name, value) => {
    switch (name) {
      case "status":
        setStatus(value);
        break;
      case "system":
        setSystem(value);
        break;
      case "systemId":
        setSystemId(value);
        break;
      default:
        break;
    }
    // Update form context if you are using a context to manage form state
    if (formContext && formContext.setFormContext) {
      formContext.setFormContext((currentContext) => ({
        ...currentContext,
        data: { ...currentContext.data, [name]: value },
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formContext.valid && !updating && !updateInProgress) {
      setUpdateInProgress(true);

      // Prepare the payload as per the new API requirements
      const payload = {
        system_id: systemId,
        status: status[0].value,
      };

      try {
        const response = await executeUpdate({ data: payload });

        if (response.status === 200) {
          Toaster.show({
            message: "STP API updated Successfully.",
            intent: "success",
            icon: "tick",
          });
          history.goBack();
        }
      } catch (error) {
        console.error("Error updating the STP API:", error);
        Toaster.show({
          message: "An error occurred while updating the STP API.",
          intent: "danger",
          icon: "error",
        });
      } finally {
        setUpdateInProgress(false);
      }
    }
  };

  return (
    <div className="stp-api-console-container">
      <Page title="Update STP API Console" tabs={[]}>
        <hr />
        <div className="details-content">
          <h4 className="details-title">Edit STP API #{apiId}</h4>
        </div>
      </Page>

      <form onSubmit={handleSubmit} className="form-content">
        <InputGroup
          name="system"
          defaultValue={system}
          onChange={(value) => handleInputChange("system", value)}
          placeholder="Enter system"
          readOnly
          className="input-group"
        />
        <InputGroup
          name="systemId"
          defaultValue={systemId}
          onChange={(value) => handleInputChange("systemId", value)}
          placeholder="Enter system ID"
          readOnly
          className="input-group"
        />
        {status && (
          <InputGroup
            name="status"
            defaultValue={[status]}
            type="select"
            placeholder="Select Status"
            items={dropdownDataTransformer([
              ["Waiting", "Waiting"],
              ["In progress", "In progress"],
              ["Successful", "Successful"],
              ["Error", "Error"],
              ["Timeout", "Timeout"],
            ])}
            onChange={(selectedOption) =>
              handleInputChange("status", selectedOption)
            }
            className="custom-input-group"
          />
        )}

        <FormFooter>
          <Button
            onClick={() => history.goBack()}
            text="Cancel"
            minimal
            large
          />
          <Button
            disabled={!formContext.valid}
            large
            type="submit"
            intent="primary"
            text={"Update STP API"}
          />
        </FormFooter>
      </form>
    </div>
  );
};
export default EditSTPApiConsole;
