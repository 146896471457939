import React, {useEffect, useRef, useState} from "react";
import { Card } from "rt-design-system-backup"
import Axios from "axios";
import {url} from "../helpers";
import timeAgo from "./utils";
import "../style.scss";
import {MARINE_CARD_BACKGROUND_COLOR} from "../helpers/constants";

function FirstColumnP({text, isDarkModeOn}) {
    return (
        <p style={{fontSize: '13px', fontWeight: 400, lineHeight: '17px', padding: 0, color: isDarkModeOn? '#177ff8':'#00438F', whiteSpace: 'pre-wrap'}}>
            {text}
        </p>
    )
}

function TradeActivityTable({isDarkModeOn}) {
    const [messages, setMessages] = useState([]);
    const fetchMessagesTimeout = useRef(() => {});

    const trStyle = {
        borderBottomWidth: 'thin',
        borderBottom: '1px solid #EBEBEB',
        marginTop: '20px'
    };

    const tdStyle = {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '17px',
        alignItems: 'center',
        color: isDarkModeOn?  '#d2dada': '#636262',
        paddingLeft: '5px',
    }

    useEffect(() => {
    const fetchMessages = async () => {
      clearTimeout(fetchMessagesTimeout.current);
      try {
        const msgData = await Axios.get(
            url("/message/")
        );
        const messagesData = msgData.data.filter((msg, index, self) => index === self.findIndex(obj => obj.id === msg.id));
        setMessages(messagesData)

      } catch (err) {
        console.log(err);
      }
      fetchMessagesTimeout.current = setTimeout(fetchMessages, 30000);
    };

    fetchMessages();

    return () => {
      clearTimeout(fetchMessagesTimeout.current);
    };
  }, []);
    return (
        <Card className="trade-activity-table-card" elevation={2} style={{padding: '20px 20px 10px 20px', marginRight: 8, background: isDarkModeOn? MARINE_CARD_BACKGROUND_COLOR: '', color: isDarkModeOn? 'white': ''}}>
            <div className="trade-activity-table" style={{overflow: 'scroll'}}>
                <table style={{width: '100%'}}>
                    <thead>
                        <p style={{fontWeight: 700, paddingTop: 0, color: isDarkModeOn ? '#bcbecc': ''}}>
                            FFA trade activity (today)
                        </p>
                    </thead>
                    <tbody>
                    {messages.map((message)=> {
                        return (
                            <tr style={trStyle}>
                                <td style={{maxWidth: '200px', width: '200px'}}>
                                     <FirstColumnP isDarkModeOn={isDarkModeOn} text={message.content} />
                                </td>
                                <td style={tdStyle}>
                                Broker: <>{message.sender && <>{message.sender.broker && message.sender.broker.name ? message.sender.broker.name : "unregistered"}</>}</>
                                </td>
                                <td style={tdStyle}>
                                    {timeAgo(message.created_at)}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </Card>
    )
}
export default TradeActivityTable;
