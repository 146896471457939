import { useHistory } from "react-router-dom";
import { renderSystemColumnActions } from "./Actions/STPMappingSystemColumnsActions";
import { renderSystemColumnValueActions } from "./Actions/STPMappingSystemColumnValuesActions";

const useSTPMappingSystemColumns = () => {
  const history = useHistory();
  return [
    {
      title: "System",
      dataIndex: "system",
      render: (text) => (text ? text.toString() : "-"),
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text) => (text ? text.toString() : "-"),
    },
    {
      title: "API Field Name",
      dataIndex: "api_field",
      render: (text) => (text ? text.toString() : "-"),
    },
    {
      title: "Possible Values",
      dataIndex: "values",
      render: (text) => (text ? text.toString() : "-"),
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "x",
      render: renderSystemColumnActions(history),
    },
  ];
};

const useSTPMappingSystemColumnValues = () => {
  const history = useHistory();
  return [
    {
      title: "Value",
      dataIndex: "value",
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "x",
      render: renderSystemColumnValueActions(history),
    },
  ];
};

export { useSTPMappingSystemColumns, useSTPMappingSystemColumnValues };
