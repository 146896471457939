import React, { useState } from "react";
import useAxios from "axios-hooks";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Button, Checkbox, TextArea } from "rt-design-system-backup";
import Form, { InputGroup, FormFooter, defaultFormContext } from "../Form";
import { convertObjectTOCSV, downloadCSV, url } from "../../helpers";
import { mappingTableHeaders } from "../../helpers/constants";
import Toaster from "../Toaster";
import NexTradeForm from "./NexTradeForm";
import {
  FORM_TYPE,
  dropdownDataTransformer,
  extractAndTransformFinalNexTradeData,
  extractNexTradeData,
  fetchEnumSystemColumnNames,
  fetchSpecificSystemColumnValues,
  fetchSystemColumnNames,
  isNexTradeValue,
  isEnumSystemColumnName,
  sortArrayByStringKey,
} from "./helper";
import { useMappingValidation } from "../MappingValidation/MappingValidationContext";

const Column = styled.div`
  max-width: 450px;
`;

// STPMappingForm is a React component used for creating and updating mappings in the STP console.
// This component handles both the creation of new mappings and the updating of existing ones, depending on the mode.
const STPMappingForm = ({ data, NexTradeFieldsList, SystemList, DDPFieldList }) => {
  const EditMode = !!data;
  const history = useHistory();
  const validation = useMappingValidation();
  const initialFormType = !EditMode
    ? FORM_TYPE.DYNAMIC
    : isNexTradeValue(NexTradeFieldsList, data?.SystemColumnValue)
    ? FORM_TYPE.DYNAMIC
    : FORM_TYPE.STATIC;
  const initialCheckBoxStatus =
    EditMode &&
    isNexTradeValue(NexTradeFieldsList, data?.SystemColumnValue) &&
    extractNexTradeData(data).length > 0;
  const initialSystemColumnValues = [
    initialFormType === FORM_TYPE.STATIC && {
      name: "Specific",
      value: "Specific",
    },
    ...dropdownDataTransformer(NexTradeFieldsList),
  ];

  const [formContext, setFormContext] = useState(defaultFormContext);
  const [systemColumnName, setSystemColumnName] = useState([]);
  const [systemColumnValues, setSystemColumnValues] = useState(
    initialSystemColumnValues
  );
  const [specificSystemColumnValues, setSpecificSystemColumnValues] = useState(
    []
  );

  const statusInitialState = EditMode ? data.Status === "Enabled" : true;
  const [formType, setFormType] = useState(initialFormType);
  const [isChecked, setChecked] = useState(initialCheckBoxStatus);
  const [isStatusChecked, setStatusChecked] = useState(statusInitialState);

  const sortedSystemColumnNames = sortArrayByStringKey(
    systemColumnName,
    "name"
  );
  const sortedSystemColumnValues = sortArrayByStringKey(
    systemColumnValues,
    "name"
  );
  const sortedSpecificSystemColumnValues = sortArrayByStringKey(
    specificSystemColumnValues,
    "name"
  );
  const sortedNexTradeFieldsList = sortArrayByStringKey(
    DDPFieldList,
    "name"
  );

  const CommodityList = ["Aluminum"];
  const formattedCommodityList = CommodityList.map((item) => [item, item]);

  // Axios hooks for performing CRUD operations on mappings.
  // addMapping for creating a new mapping.
  const [, addMapping] = useAxios(
    {
      url: url("mapping_rules/"),
      method: "POST",
    },
    { manual: true }
  );

  // updateMapping for updating an existing one.
  const [, updateMapping] = useAxios(
    {
      url: url(`mapping_rules/${data?.RuleID ?? 0}/`),
      method: "PUT",
    },
    { manual: true }
  );

  // deleteMapping for deletion.
  // const [, deleteMapping] = useAxios(
  //   {
  //     url: url(`mapping_rules/${data?.RuleID ?? 0}/`),
  //     method: "DELETE",
  //   },
  //   { manual: true }
  // );

  // Handles form submission for both creating and updating mappings.
  const submitHandler = async (formData, formState) => {
    const { System, SystemColumnValue, SystemColumnName, status, ...restData } =
      formState.data;

    // console.log(restData);
    const newDDPData = extractAndTransformFinalNexTradeData(restData);

    const finalFormData = {
      enabled: formData?.status && formData.status === "on" ? true : false,
      system: System[0].value,
      system_column: {id: SystemColumnName[0].value, api_field: SystemColumnName[0].name, name: SystemColumnName[0].name},
      comments: formData?.comments,
      output: {
        ddp_field: null,
        specific_value: null,
      },
      conditions: [...newDDPData],
    };

    if (SystemColumnValue[0].value === "Specific") {
      finalFormData.output["specific_value"] =
          {id: restData.SystemSpecificColumnValue[0].value, value:restData.SystemSpecificColumnValue[0].value, column: SystemColumnName[0].value};
    } else {
      finalFormData.output["ddp_field"] = SystemColumnValue[0].value;
    }

    if (EditMode) {
      try {
        await updateMapping({ data: finalFormData });
        Toaster.show({
          message: "Mapping updated Successfully.",
          icon: "small-tick",
          intent: "success",
        });
        history.goBack();
        validation.validate();
      } catch (error) {
        Toaster.show({
          message: "An error occurred while updating the mapping. ",
          icon: "warning-sign",
          intent: "danger",
        });
      }
    } else {
      try {
        await addMapping({ data: finalFormData });
        Toaster.show({
          message: "Mapping created Successfully.",
          icon: "small-tick",
          intent: "success",
        });
        history.goBack();
        validation.validate();
      } catch (error) {
        if (error.message.includes("500")) {
          Toaster.show({
            message: `A duplicate mapping was found  `,
            icon: "warning-sign",
            intent: "danger",
          });
        } else {
          Toaster.show({
            message: `An error occurred while creating the mapping. ${error.message} `,
            icon: "warning-sign",
            intent: "danger",
          });
        }
      }
    }
  };
  // Function to download the mapping data as a CSV file.
  const downloadCSVData = (data) => {
    const tempData = { ...data, ...data.criteria };
    const csvData = convertObjectTOCSV(mappingTableHeaders, tempData);
    downloadCSV(csvData, "mapping.csv");
    Toaster.show({
      message: "Mapping downloaded successfully.",
      icon: "small-tick",
      intent: "success",
    });
  };

  // Function to handle deletion of a mapping.
  // const deleteMappingHandler = async (id) => {
  //   try {
  //     await deleteMapping(id);
  //     Toaster.show({
  //       message: "Mapping deleted successfully.",
  //       icon: "small-tick",
  //       intent: "success",
  //     });
  //     history.push("/stp-mapping-console");
  //     validation.validate();
  //   } catch (error) {
  //     Toaster.show({
  //       message: "An error occurred while deleting the order. ",
  //       icon: "warning-sign",
  //       intent: "danger",
  //     });
  //   }
  // };

  // Handles changes in the 'System' input field and fetches corresponding system column names.
  const handleSystemNameInputChange = async (d) => {
    if (d[0]?.value) {
      const systemData = await fetchSystemColumnNames(d[0].value);
      setSystemColumnName(systemData.map(row => ({name: row.name, value: row.id})));
    }
  };

  // Handles changes in the 'System Column Name' input field and sets up the system column values.
  const handleSystemColumnNameInputChange = async (inputValue) => {
    const { data } = formContext;
    if (data["System"] && inputValue[0]?.value) {
      const enumSystemColumnNames = await fetchEnumSystemColumnNames(
        data["System"][0].value
      );
      const isEnumVal = isEnumSystemColumnName(
        inputValue[0].value,
        enumSystemColumnNames
      );
      const systemColumnValues = [
        ...dropdownDataTransformer(NexTradeFieldsList),
      ];
      isEnumVal &&
        systemColumnValues.unshift({ name: "Specific", value: "Specific" });
      setSystemColumnValues(systemColumnValues);
    }
  };

  // Handles changes in the 'System Column Value' input field and toggles the form type (static or dynamic).
  const handleSystemColumnValueInputChange = async (inputValue) => {
    const val = inputValue[0]?.value;
    if (val === "Specific") {
      setFormType(FORM_TYPE.STATIC);
      const { data } = formContext;
      if (
        data["System"] &&
        data["System"][0] &&
        data["System"][0]?.value &&
        data["SystemColumnName"] &&
        data["SystemColumnName"][0] &&
        data["SystemColumnName"][0]?.value
      ) {
        const tempSpecificSystemColumnValues =
          await fetchSpecificSystemColumnValues(
            data["System"][0]?.value,
            data["SystemColumnName"][0]?.value
          );
        setSpecificSystemColumnValues(
          tempSpecificSystemColumnValues.map(row => ({name: row.value, value: row.id}))
        );
      }
    } else {
      setFormType(FORM_TYPE.DYNAMIC);
    }
  };

  // The render method of the component.
  return (
    <Form onChange={(d) => setFormContext(d)} onSubmit={submitHandler}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {EditMode ? (
          <h1>Update STP Mapping</h1>
        ) : (
          <h1>Create new STP Mapping</h1>
        )}
        {EditMode && (
          <Button
            key={"download-mapping"}
            large
            outlined
            intent="primary"
            text={"Download Mapping"}
            onClick={() => downloadCSVData(data)}
          />
        )}
      </div>
      <hr />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1>STP Mapping Details</h1>
        {/*{EditMode && (*/}
        {/*  <Button*/}
        {/*    large*/}
        {/*    key={"delete-mapping"}*/}
        {/*    outlined*/}
        {/*    intent="danger"*/}
        {/*    onClick={() => deleteMappingHandler(data.RuleID)}*/}
        {/*    text={"Delete This Mapping"}*/}
        {/*  />*/}
        {/*)}*/}
      </div>
      <Column>
        <InputGroup
          key={formattedCommodityList}
          title="Commodity"
          defaultValue={CommodityList}
          required
          name="Commodity"
          type="select"
          placeholder={"Select one"}
          items={dropdownDataTransformer(formattedCommodityList)}
          onChange={(data) => handleSystemNameInputChange(data)}
        />
        <InputGroup
          key={SystemList}
          title="System"
          defaultValue={EditMode && SystemList ? [data["System"]] : ["Fusion"]}
          required
          name="System"
          type="select"
          placeholder={"Select one"}
          items={dropdownDataTransformer(SystemList)}
          onChange={(data) => handleSystemNameInputChange(data)}
        />
        <InputGroup
          title="System Column Name"
          key={systemColumnName}
          defaultValue={
            EditMode && systemColumnName ? [data["SystemColumnId"]] : ""
          }
          required
          name="SystemColumnName"
          type="select"
          placeholder={"Select one"}
          items={sortedSystemColumnNames}
          onChange={(data) => handleSystemColumnNameInputChange(data)}
        />
        <InputGroup
          key={
            formContext?.data["SystemColumnName"] &&
            formContext?.data["SystemColumnName"][0] &&
            formContext?.data["SystemColumnName"][0]?.value &&
            systemColumnValues
          }
          title="System Column Value (Can either be a NexTrade Field or a specific value)"
          defaultValue={
            !EditMode
              ? ""
              : formType === FORM_TYPE.DYNAMIC
              ? [data["SystemColumnValue"]]
              : ["Specific"]
          }
          required
          name="SystemColumnValue"
          type="select"
          placeholder={"Select one"}
          items={sortedSystemColumnValues}
          onChange={(data) => handleSystemColumnValueInputChange(data)}
        />
        {formType === FORM_TYPE.STATIC ? (
          <>
            <InputGroup
              defaultValue={
                EditMode && specificSystemColumnValues
                  ? [data["SpecificSystemColumnValue"]]
                  : ""
              }
              key={
                formContext?.data["SystemColumnName"] &&
                formContext?.data["SystemColumnName"][0] &&
                formContext?.data["SystemColumnName"][0]?.value &&
                formContext?.data["SystemColumnValue"] &&
                formContext?.data["SystemColumnValue"][0] &&
                formContext?.data["SystemColumnValue"][0]?.value &&
                specificSystemColumnValues
              }
              required
              title="Input the specific value below"
              type="select"
              name="SystemSpecificColumnValue"
              items={sortedSpecificSystemColumnValues}
            />
            <NexTradeForm
              key="static"
              data={extractNexTradeData(data).data}
              NexTradeFields={sortedNexTradeFieldsList}
            />
          </>
        ) : (
          <>
            <Checkbox
              checked={isChecked}
              label="Add Criteria"
              onChange={() => setChecked((prev) => !prev)}
            />
            {isChecked && (
              <NexTradeForm
                key="dynamic"
                data={extractNexTradeData(data).data}
                NexTradeFields={sortedNexTradeFieldsList}
              />
            )}
          </>
        )}
      </Column>
      <hr />
      <Checkbox
        name="status"
        checked={isStatusChecked}
        label={"Enabled"}
        onChange={() => setStatusChecked((prev) => !prev)}
      />
      <h2>Additional Comments</h2>
      <TextArea
        name="comments"
        defaultValue={EditMode ? data.comments : ""}
        style={{ width: "655px" }}
        growVertically={true}
      ></TextArea>
      <FormFooter>
        <Button onClick={() => history.goBack()} text="Cancel" minimal large />
        <Button
          disabled={!formContext.valid}
          large
          type="submit"
          intent="primary"
          text={EditMode ? "Update Mapping" : "Create Mapping"}
        />
      </FormFooter>
    </Form>
  );
};

export default STPMappingForm;
