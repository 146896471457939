import { useContext, useEffect, useMemo } from "react";

import SocketContext from "../SocketContext";
import AppContext from "../AppContext";

import Toaster from "../Toaster/TopRight";

import { hasPerm, hasGroup } from "../../helpers";

const GlobalToaster = () => {
  const socket = useContext(SocketContext);
  const { user } = useContext(AppContext);

  const listeners = useMemo(() => {
    return {
      order_create: (e) => {
        if (
          e.detail.requestor.email !== user.email &&
          hasPerm(user, "rtcmdmodels.notified_marine_orders")
        ) {
          Toaster.show({
            message: "New order added by " + e.detail.requestor.full_name,
            intent: "primary",
          });
        }
      },
      trade_create: (e) => {
        if (
          e.detail.trader.email !== user.email &&
          e.detail.linked_order.requestor.email === user.email &&
          hasPerm(user, "rtcmdmodels.notified_marine_orders")
        ) {
          Toaster.show({
            message: "New trade added by " + e.detail.trader.full_name,
            intent: "primary",
          });
        }
      },
      order_update: (e) => {
        if (
          e.detail.requestor.email !== user.email &&
          hasPerm(user, "rtcmdmodels.notified_marine_orders")
        ) {
          Toaster.show({
            message:
              "Order " +
              e.detail.id +
              " cancelled by " +
              e.detail.requestor.full_name,
            intent: "primary",
          });
        }
      },
      trade_update: (e) => {
        if (
          e.detail.trader.email !== user.email &&
          e.detail.linked_order.requestor.email === user.email &&
          hasPerm(user, "rtcmdmodels.notified_marine_orders")
        ) {
          Toaster.show({
            message:
              "Trade " +
              e.detail.id +
              " cancelled by " +
              e.detail.trader.full_name,
            intent: "primary",
          });
        }
      },

      /* ALUMINIUM EVENTS */
      request_create: (e) => {
        if (
          e.detail.requestor.email !== user.email &&
          hasPerm(user, "rtcmdmodels.notified_request_open")
        ) {
          //ALU trader
          Toaster.show({
            message:
              "New " +
              e.detail.request_type +
              " #" +
              e.detail.id +
              " for " +
              (e.detail.customer && e.detail.customer.name) +
              " for " +
              e.detail.total_quantity +
              " MT",
            intent: "primary",
          });
        }
      },
      request_fill: (e) => {
        if (
          e.detail.trader.email !== user.email &&
          hasPerm(user, "rtcmdmodels.notified_request_filled")
        ) {
          //BO
          Toaster.show({
            message:
              "Request " +
              e.detail.id +
              " filled by " +
              e.detail.trader.full_name,
            intent: "primary",
          });
        }
      },
      request_update: (e) => {
        //ALU trader and BO
        if (
          e.detail.last_modified_by.email !== user.email &&
          hasPerm(user, "rtcmdmodels.notified_request_edit")
        ) {
          Toaster.show({
            message:
              e.detail.request_type +
              " #" +
              e.detail.id +
              " editted by " +
                e.detail.requestor.full_name,
            intent: "primary",
          });
        }
      },
      request_cancel: (e) => {
        //ALU trader
        if (
          e.detail.cancelled_by.email !== user.email &&
          hasPerm(user, "rtcmdmodels.notified_request_cancelled")
        )
          Toaster.show({
            message:
              e.detail.request_type +
              " #" +
              e.detail.id +
              " cancelled by " +
              e.detail.cancelled_by.full_name,
            intent: "primary",
          });
      },
      request_reconcilled: (e) => {
        if (hasPerm(user, "rtcmdmodels.notified_request_reconciled"))
          Toaster.show({
            message:
              e.detail.request_type + " #" + e.detail.id + " reconcilled",
            intent: "primary",
          });
      },
      ddp_alert_aluminium: (e) => {
        console.log(e)
        if (hasGroup(user, "DDP Alerts"))
          Toaster.show({
            message:
              e.detail,
            intent: "primary",
          });
      },
    };
  }, [user]);

  useEffect(() => {
    Object.keys(listeners).forEach((event) => {
      socket.addEventListener(event, listeners[event]);
    });

    return () => {
      Object.keys(listeners).forEach((event) => {
        socket.removeEventListener(event, listeners[event]);
      });
    };
  }, [listeners, socket]);

  return null;
};

export default GlobalToaster;
