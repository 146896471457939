import React from "react";
import { Link, matchPath, useLocation } from "react-router-dom";

import { Button, Tabs, Tab, Icon } from "rt-design-system-backup";

import { LayoutContext } from "../Layout";

import "./style.scss";

const Page = ({
  children,
  title,
  tabs,
  leftActions,
  rightActions,
  mobileActions,
  showFFADrawerButton,
  topActions = null,
  actionsMarginBottom = null,
}) => {
  const location = useLocation();

  let tabsMatch;

  tabs.forEach(({ to }, key) => {
    if (
      matchPath(location.pathname, {
        path: to,
        exact: true,
        strict: false,
      })
    ) {
      tabsMatch = to;
    }
  });

  return (
    <div className="rtcmd-page-wrapper">
      <div
        className="rtcmd-page-actions"
        style={
          actionsMarginBottom !== null
            ? { marginBottom: actionsMarginBottom }
            : {}
        }
      >
        {tabs ? (
          <div className="rtcmd-page-tabs">
            <Tabs large minimal selectedTabId={"" + tabsMatch}>
              {tabs.map(({ title, to }, key) => (
                <Tab key={key} id={to} title={<Link to={to}>{title}</Link>} />
              ))}
            </Tabs>
          </div>
        ) : null}
        <div>
          {showFFADrawerButton ? (
            <LayoutContext.Consumer>
              {({ setLayoutContext }) => {
                return (
                  <Button
                    onClick={() =>
                      setLayoutContext({
                        isFFADrawerOpen: true,
                      })
                    }
                    intent="primary"
                    minimal
                    rightIcon={<Icon icon="arrow-right" />}
                  >
                    View FFA Activity
                  </Button>
                );
              }}
            </LayoutContext.Consumer>
          ) : null}
          {topActions}
        </div>
      </div>
      <h1 className="rtcmd-page-header">
        <span>
          {title && <h3>{title}</h3>}
          {leftActions ? (
            <span key={title} style={{ display: "block", marginTop: "10px" }}>
              {leftActions}
            </span>
          ) : null}
        </span>
        <span className="rtcmd-page-header-spacer" />
        <span className="rtcmd-page-float-right">{rightActions}</span>
        <span className="rtcmd-page-mobile-actions">{mobileActions}</span>
      </h1>
      {children}
    </div>
  );
};

export default Page;
