import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAxios from "axios-hooks";
import { hasPerm, url } from "../../../helpers";
import RequestOverview from "../../RequestOverview";
import RequestMaturityPrices from "../../RequestMaturityPrices";
import { Divider } from "antd";
import RequestViewContext from "../../RequestContext";
import RequestVWP from "../../RequestVWP";
import RequestPricingInstructions from "../../RequestPricingInstructions";
import RequestOverviewActions from "../../RequestOverview/RequestOverviewActions";
import SocketContext from "../../SocketContext";
import Breadcrumb from "../../Breadcrumb";
import Page from "../../Page";
import { Button, Icon } from "rt-design-system-backup";
import AppContext from "../../AppContext";
import RequestAdditional from "../../RequestAdditional";
import RequestHistory from "../../RequestHistory";
import RequestPlantDetails from "../../RequestPlantDetails";
import RequestProductDetails from "../../RequestProductDetails";

export const EditRequestButton = ({
  href,
  showIcon = false,
  style = {},
  disabled,
}) => {
  return (
    !disabled && (
      <a style={{ textDecoration: "none" }} href={href}>
        {showIcon ? (
          <Icon icon={"edit"} iconSize={20} style={style} />
        ) : (
          <Button
            icon={"edit"}
            large
            outlined
            style={{ marginRight: 10 }}
          >
            Edit request
          </Button>
        )}
      </a>
    )
  );
};

const RequestDetail = ({ action = "details" }) => {
  const { requestId } = useParams();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  const [{ data: request, loading }, refetch] = useAxios({
    url: url("/request/" + requestId + "/"),
  });
  const { user } = useContext(AppContext);
  const socket = useContext(SocketContext);

  useEffect(() => {
    const refetcher = (req) => {
      if (!req || `${req.detail.id}` === requestId) {
        refetch();
      }
    };

    refetcher();
    window.addEventListener("resize", () =>
      setIsMobile(window.innerWidth <= 900)
    );
    socket.addEventListener("request_fill", refetcher);
    socket.addEventListener("request_update", refetcher);
    socket.addEventListener("request_cancel", refetcher);
    socket.addEventListener("request_reconcile", refetcher);
    socket.addEventListener("request_accept", refetch);
    socket.addEventListener("request_expire", refetch);
    socket.addEventListener("request_expire", refetcher);
    socket.addEventListener("request_for_correction", refetcher);
    socket.addEventListener("request_resubmit", refetcher);

    return () => {
      socket.removeEventListener("request_fill", refetcher);
      socket.removeEventListener("request_update", refetcher);
      socket.removeEventListener("request_cancel", refetcher);
      socket.removeEventListener("request_reconcile", refetcher);
      socket.removeEventListener("request_accept", refetcher);
      socket.removeEventListener("request_expire", refetcher);
      socket.removeEventListener("request_expire", refetcher);
      socket.removeEventListener("request_for_correction", refetcher);
      socket.removeEventListener("request_resubmit", refetcher);
    };
  }, [socket, refetch, requestId]);

  const additional = loading ? null : <RequestAdditional request={request} />;

  console.log(request);

  return loading || !request ? (
    "Loading..."
  ) : (
    <div style={{ paddingBottom: "32px" }}>
      {action === "details" ? (
        <Breadcrumb text="All requests" link={"/aluminium"} />
      ) : null}
      <Page
        title={action === "details" ? "Request details" : "Reconcile request"}
        tabs={[]}
        mobileActions={
          isMobile
            ? [
                action === "details" && (!loading || request) ? (
                  <RequestOverviewActions
                    request={request}
                    action={action}
                    additional={
                      ["Filled", "For correction"].includes(request.status) &&
                      hasPerm(user, "rtcmdmodels.fill_open_request") &&
                      request.request_type !== "Indic Quote" ? (
                        <EditRequestButton
                          disabled={request.is_accounting_period_closed}
                          href={
                            "/aluminium/request/" + requestId + "/fill-edit/"
                          }
                        />
                      ) : request.status === "Open" &&
                        ![
                          "Postponement Auto Quote",
                          "Anticipation Auto Quote",
                          "Auto Quote",
                          "Indic Quote",
                        ].includes(request.request_type) &&
                        hasPerm(user, "rtcmdmodels.change_request") &&
                        (request.requestor.email === user.email ||
                          (user.sales_entity &&
                            user.sales_entity.name ===
                              request.sales_entity.name)) ? (
                        <EditRequestButton
                          disabled={request.is_accounting_period_closed}
                          href={"/aluminium/request/" + requestId + "/update/"}
                        />
                      ) : null
                    }
                  />
                ) : null,
              ]
            : null
        }
        rightActions={
          !isMobile
            ? [
                request.request_type !== "Indic Quote" &&
                ["Filled", "For correction"].includes(request.status) &&
                hasPerm(user, "rtcmdmodels.fill_open_request") ? (
                  <EditRequestButton
                    disabled={request.is_accounting_period_closed}
                    href={"/aluminium/request/" + requestId + "/fill-edit/"}
                  />
                ) : null,
                request.status === "Open" &&
                ![
                  "Postponement Auto Quote",
                  "Anticipation Auto Quote",
                  "Auto Quote",
                  "Indic Quote",
                ].includes(request.request_type) &&
                hasPerm(user, "rtcmdmodels.change_request") &&
                (request.requestor.email === user.email ||
                  (user.sales_entity &&
                    user.sales_entity.name === request.sales_entity.name)) ? (
                  <EditRequestButton
                    disabled={request.is_accounting_period_closed}
                    href={"/aluminium/request/" + requestId + "/update/"}
                  />
                ) : null,
                action === "details" && (!loading || request) ? (
                  <RequestOverviewActions request={request} action={action} />
                ) : null,
                ["Open"].includes(request.status) &&
                request.request_type === "Auto Quote" ? (
                  <Button
                    large
                    outlined
                    style={{ marginRight: 10 }}
                    onClick={() =>
                      window.location.replace(
                        `/aluminium/request/${request.id}/allocate`
                      )
                    }
                  >
                    Allocation
                  </Button>
                ) : null,
              ]
            : null
        }
      >
        <hr />
        <RequestViewContext.Provider value={{ refetch, disableEditing: true }}>
          <RequestOverview request={request} showSpinner />
          <Divider />
          <RequestMaturityPrices request={request} />
          {request.plant_details.length !== 0 && (
            <>
              <Divider />
              <RequestPlantDetails request={request} />
            </>
          )}
          {request.product_details && request.product_details.length !==0 && (
              <>
                  <RequestProductDetails request={request}/>
              </>
          )}
          {request.status !== "At Market" ? (
            <RequestVWP request={request} editable={false} />
          ) : null}
          <Divider />
          <RequestPricingInstructions request={request} editable={false} />
          {additional ? (
            <>
              <Divider />
              {additional}
            </>
          ) : null}
          {action !== "details" ? (
            <RequestOverviewActions request={request} action={action} />
          ) : null}
          <RequestHistory request={request} />
        </RequestViewContext.Provider>
      </Page>
    </div>
  );
};
export default RequestDetail;
