import {
  TimePicker as BPTimePicker,
  ITimePickerProps as BPITimePickerProps,
  TimePrecision,
} from '@blueprintjs/datetime';
import React from 'react';
import '../../../assets/scss/core.scss';

export interface ITimePickerProps extends BPITimePickerProps {
  disabled?: boolean;
  precision?: TimePrecision;
  selectAllOnFocus?: boolean;
  showArrowButtons?: boolean;
  useAmPm?: boolean;
}

export const TimePicker: React.FC<ITimePickerProps> = (props) => {
  return <BPTimePicker {...props} />;
};
