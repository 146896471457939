import { ItemPredicate } from '@blueprintjs/select';

export interface ISelect {
  title: string;
  value: string | number;
  rank: number;
}

export const TOP_FILTER: ISelect[] = [
  { title: 'Option 1', value: 1 },
  { title: 'Option 2', value: 2 },
  { title: 'Option with really long conten...', value: 3 },
].map((m, index) => ({ ...m, rank: index + 1 }));

export const DATA: ISelect[] = [
  { title: 'Item 1', value: 1 },
  { title: 'Item 2', value: 2 },
  { title: 'Item 3', value: 3 },
  { title: 'Item 4', value: 4 },
  { title: 'Item 5', value: 5 },
].map((m, index) => ({ ...m, rank: index + 1 }));

export const DATA2: ISelect[] = [
  { title: 'Item 1', value: 1 },
  { title: 'Item 2', value: 2 },
  { title: 'Item 3', value: 3 },
  { title: 'Item 4', value: 4 },
  { title: 'Item 5', value: 5 },
  { title: 'Item 6', value: 6 },
  { title: 'Item 7', value: 7 },
  { title: 'Item 8', value: 8 },
  { title: 'Item 9', value: 9 },
  { title: 'Item 10', value: 10 },
  { title: 'Item 11', value: 11 },
  { title: 'Item 12', value: 12 },
].map((m, index) => ({ ...m, rank: index + 1 }));

export function areItemsEqual(itemA: ISelect, itemB: ISelect) {
  return itemA.title.toLowerCase() === itemB.title.toLowerCase();
}

export const arrayContainsItem = (items: ISelect[], itemToFind: ISelect): boolean => {
  return items.some((item: ISelect) => item.value === itemToFind.value);
};

export const addItemToArray = (items: ISelect[], itemToAdd: ISelect) => {
  return [...items, itemToAdd];
};

export const deleteItemFromArray = (items: ISelect[], itemToDelete: ISelect) => {
  return items.filter((item) => item !== itemToDelete);
};

export const maybeAddCreatedItemToArrays = (
  items: ISelect[],
  createdItems: ISelect[],
  item: ISelect,
): { createdItems: ISelect[]; items: ISelect[] } => {
  const isNewlyCreatedItem = !arrayContainsItem(items, item);
  return {
    createdItems: isNewlyCreatedItem ? addItemToArray(createdItems, item) : createdItems,
    items: isNewlyCreatedItem ? addItemToArray(items, item) : items,
  };
};

export const maybeDeleteCreatedItemFromArrays = (
  items: ISelect[],
  createdItems: ISelect[],
  item: ISelect,
): { createdItems: ISelect[]; items: ISelect[] } => {
  const wasItemCreatedByUser = arrayContainsItem(createdItems, item);

  return {
    createdItems: wasItemCreatedByUser ? deleteItemFromArray(createdItems, item) : createdItems,
    items: wasItemCreatedByUser ? deleteItemFromArray(items, item) : items,
  };
};

export const filterItem: ItemPredicate<ISelect> = (query, item, _index, exactMatch) => {
  const normalizedTitle = item.title.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedTitle === normalizedQuery;
  } else {
    return `${item.rank}. ${normalizedTitle} ${item.value}`.indexOf(normalizedQuery) >= 0;
  }
};
