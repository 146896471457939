import {InputGroup} from "../../Form";
import React from "react";

const TextInput = ({name, label, data, required}) => <InputGroup
    title={label}
    defaultValue={data ? [data[name]] : ""}
    {...required === false ? "": "required"}
    name={name}
    type="text"
    placeholder={label}
/>

export default TextInput;
