import {
  Classes as BPClasses,
  Intent as BPIntent,
  Position as BPPosition,
  Alignment as BPAlignment,
  NumberRange as BPNumberRange,
  Boundary as BPBoundary,
  PopoverInteractionKind as BPPopoverInteractionKind,
  PopoverPosition as BPPopoverPosition,
} from '@blueprintjs/core';
import { IDateFormatProps as BPIDateFormatProps, TimePrecision as BPTimePrecision } from '@blueprintjs/datetime';
import { IExampleProps as BPIExampleProps } from '@blueprintjs/docs-theme';
import { ItemRenderer as BPItemRenderer, ItemPredicate as BPItemPredicate } from '@blueprintjs/select';

export type IExampleProps<T> = BPIExampleProps<T>;
export const Classes = BPClasses;

export const Intent = BPIntent;
export type Intent = BPIntent;

export type Position = BPPosition;
export const Position = BPPosition;

export const Alignment = BPAlignment;
export type Alignment = BPAlignment;

export const Boundary = BPBoundary;
export type Boundary = BPBoundary;

export const PopoverInteractionKind = BPPopoverInteractionKind;
export type PopoverInteractionKind = BPPopoverInteractionKind;

export const PopoverPosition = BPPopoverPosition;
export type PopoverPosition = BPPopoverPosition;

export type ItemRenderer<T> = BPItemRenderer<T>;
export type NumberRange = BPNumberRange;

export type ItemPredicate<T> = BPItemPredicate<T>;

export const TimePrecision = BPTimePrecision;
export type TimePrecision = BPTimePrecision;

export type IDateFormatProps = BPIDateFormatProps;
