import React from "react";
import STPMappingSystemColumnsTable from "../../STPMappingSystemColumns/Table/STPMappingSystemColumnsTable";
import { useSTPMappingSystemColumnContext } from "../../STPMappingSystemColumns/STPMappingSystemColumnsContext";
import Back from "../../STPMappingSystemColumns/Nav/Back";
import Nav from "../../STPMappingSystemColumns/Nav/Nav";
import "./style.scss";
const STPMappingSystemColumnsList = () => {
  const { systemColumns, systemColumnsLoading } =
    useSTPMappingSystemColumnContext();
  return (
    <>
      <div className="edit-mapping">
        <Back to="/stp-mapping-console" title="All mappings " />
        <Nav
          to="/stp-mapping-console/system-columns/new"
          text="New System Column"
        />
      </div>
      <STPMappingSystemColumnsTable
        data={systemColumns}
        loading={systemColumnsLoading}
      />
    </>
  );
};

export default STPMappingSystemColumnsList;
