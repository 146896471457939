import React, {useContext, useEffect, useRef, useState} from "react";
import useAxios from "axios-hooks";
import axios from "axios";
import ReconcileIcon from "../../Icons/reconcile"

import {
  Button, Dialog, Icon,
  Spinner,
} from "rt-design-system-backup";
import SortContext from "../../SortContext";
import SocketContext from "../../SocketContext";

import {
  url,
  capitalize,
  formatSort,
  formatDate, hasPerm,
} from "../../../helpers";

import { Input, Space, Table } from 'antd';

import "./style.scss";
import { SearchOutlined } from "@ant-design/icons";

import Highlighter from "react-highlight-words";
import Toaster from "../../Toaster";
import AppContext from "../../AppContext";

export const renderItem = (page, type, originalElement) => {
  if (type === "prev") {
    return <Button icon="arrow-left" intent="primary" />;
  } else if (type === "next") {
    return <Button icon="arrow-right" intent="primary" />;
  } else {
    return originalElement;
  }
};

const TableInterAction = ({orders, loading}) => {
  const { user } = useContext(AppContext)


  const initial_filters = {}
  const filter_keys = ['order', 'contract', 'order_type', 'strategy', 'entity', 'customer', 'requestor', 'status']
    filter_keys.forEach(filter_key => {
      const value = localStorage.getItem(`copper_order_filter_${filter_key}`)
      if (value === '-') {
        delete initial_filters[filter_key]
      } else if (value) {
        initial_filters[filter_key] = value.split(',')
      }
    });

  const [filteredInfo, setFilterInfo] = useState(initial_filters);
  const [searchText, setSearchText] = useState("initState");

  const handleChange = (pagination, filters, sorter) => {
    setFilterInfo(filters);

    Object.keys(filters).forEach(filter => {
      if (filters[filter]) {
        localStorage.setItem(`copper_order_filter_${filter}`, filters[filter].join())
      } else {
        localStorage.setItem(`copper_order_filter_${filter}`, '-')
      }
    })
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "order",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ID`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 160, marginBottom: 8, display: "block" }}
          />
          <Space size={'large'}>
            <Button
              intent="secondary"
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ minHeight: '24px', minWidth: 60 }}
            >
              Reset
            </Button>
            <Button
              intent="primary"
              icon={<SearchOutlined />}
              onClick={() => handleSearch(selectedKeys, confirm)}
              size="small"
              style={{ minHeight: '24px', minWidth: 60 }}
            >
              Search
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? "#1890ff" : "#8e8e8e", fontSize: "16px" }}
        />
      ),
      onFilter: (value, record) =>
        record.order.toString().includes(value.toString()),
      filteredValue: filteredInfo.order || null,
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
      fixed: "left",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      sorter: (a, b) => {
        const fromDate = new Date(b.date);
        const toDate = new Date(a.date);

        return fromDate.getTime() - toDate.getTime();
      },
      render: (dateString) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>{formatDate(dateString)}</div>
        );
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Strategy",
      dataIndex: "strategy",
      filters: orders
        ? orders
          .map((item) => item.strategy)
          .filter(
            (elem, pos) =>
              elem &&
              orders.map((item) => item.strategy)
                .indexOf(elem) === pos
          )
          .sort()
          .map((value) => ({
            text: value,
            value: value,
          }))
        : [],
      filteredValue: filteredInfo.strategy || null,
      onFilter: (value, record) => record.strategy.includes(value),
    },
    {
      title: "Order Type",
      dataIndex: "order_type",
      filters: orders
        ? orders
          .map((item) => item.order_type)
          .filter(
            (elem, pos) =>
              elem &&
              orders.map((item) => item.order_type)
                .indexOf(elem) === pos
          )
          .sort()
          .map((value) => ({
            text: value,
            value: value,
          }))
        : [],
      filteredValue: filteredInfo.order_type || null,
      onFilter: (value, record) => record.order_type.includes(value),
    },
    {
      title: "Contract",
      dataIndex: "contract",
      filters: orders
        ? orders
          .map((item) => item.contract.name)
          .filter(
            (elem, pos) =>
              elem &&
              orders.map((item) => item.contract.name)
                .indexOf(elem) === pos
          )
          .sort()
          .map((value) => ({
            text: value,
            value: value,
          }))
        : [],
      filteredValue: filteredInfo.contract || null,
      onFilter: (value, record) => record.contract.includes(value),
    },
    {
      title: "Entity",
      dataIndex: "entity",
      filters: orders
        ? orders
          .map((item) => item.entity.name)
          .filter(
            (elem, pos) =>
              elem &&
              orders.map((item) => item.entity.name)
                .indexOf(elem) === pos
          )
          .sort()
          .map((value) => ({
            text: value,
            value: value,
          }))
        : [],
      filteredValue: filteredInfo.entity || null,
      onFilter: (value, record) => record.entity.includes(value),
    },
    {
      title: "Customer",
      dataIndex: "customer",
      filters: orders
        ? orders
          .map((item) => item.customer && item.customer.display_name)
          .filter(
            (elem, pos) =>
              elem &&
              orders.map((item) => item.customer && item.customer.display_name)
                .indexOf(elem) === pos
          )
          .sort()
          .map((value) => ({
            text: value,
            value: value,
          }))
        : [],
      filteredValue: filteredInfo.customer || null,
      onFilter: (value, record) => record.customer && record.customer.includes(value),
    },
    {
      title: "Quota Month",
      dataIndex: "quota_month",
      filters: orders
        ? orders
          .map((item) => item.quota_month)
          .filter(
            (elem, pos) =>
              elem &&
              orders.map((item) => item.quota_month)
                .indexOf(elem) === pos
          )
          .sort()
          .map((value) => ({
            text: value,
            value: value,
          }))
        : [],
      filteredValue: filteredInfo.quota_month || null,
      onFilter: (value, record) => record.quota_month.includes(value),
      sorter: (a, b) => {
        const dateA = a.quota_month || '';
        const dateB = b.quota_month || '';
        const fromDate = new Date('1/' + dateA.split(' ').join('/'));
        const toDate = new Date('1/' + dateB.split(' ').join('/'));

        return fromDate.getTime() - toDate.getTime();
      },
    },
    {
      title: "Pricing Date",
      dataIndex: "pricing_date",
      sorter: (a, b) => {
        const fromDate = new Date(b.pricing_date);
        const toDate = new Date(a.pricing_date);

        return fromDate.getTime() - toDate.getTime();
      },
      render: (dateString) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>{dateString ? dateString : ''}</div>
        );
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Buy/Sell",
      dataIndex: "buy_sell",
      filters: orders
        ? orders
          .map((item) => {
            return item.trades.map(trade => trade.action)[0]
          })
          .filter(
            (elem, pos) =>
              elem &&
              orders.map((item) => {
                  return item.trades.map(trade => trade.action)[0]
                })
                .indexOf(elem) === pos
          )
          .sort()
          .map((value) => ({
            text: value,
            value: value,
          }))
        : [],
      filteredValue: filteredInfo.buy_sell || null,
      onFilter: (value, record) => record.buy_sell.includes(value),
    },
    {
      title: "Volume",
      dataIndex: "quantity",
    },
    {
      title: "Unit",
      dataIndex: "unit",
    },
    {
      title: "Lots",
      dataIndex: "lots",
    },
    {
      title: "Maturity Month",
      dataIndex: "maturity",
      render: capitalize,

    },
    {
      title: "Requestor",
      dataIndex: "requestor",
      filters: orders
        ? orders
          .map((item) => item.requestor.full_name)
          .filter(
            (elem, pos) =>
              elem &&
              orders.map((item) => item.requestor.full_name)
                .indexOf(elem) === pos
          )
          .sort()
          .map((value) => ({
            text: value,
            value: value,
          }))
        : [],
      filteredValue: filteredInfo.requestor || null,
      onFilter: (value, record) => record.requestor.includes(value),
    },
    {
      title: "Status",
      dataIndex: "status",
      filters: orders
        ? orders
          .map((item) => item.status)
          .filter(
            (elem, pos) =>
              elem &&
              orders.map((item) => item.status)
                .indexOf(elem) === pos
          )
          .sort()
          .map((value) => ({
            text: value,
            value: value,
          }))
        : [],
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => record.status.includes(value),
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "x",
      render: (_, record) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            <span style={{ cursor: 'pointer' }}>
              <a href={`/coppers/${record.order}`} style={{color: 'black'}}>
                <Icon
                  icon="eye-open"
                />
              </a>
            </span>
            {hasPerm(user, "rtcmdmodels.change_copper") && ['Open'].includes(record.status)
                ? <span style={{cursor: 'pointer'}}>
                  <a href={`/coppers/${record.order}/update/`} style={{color: 'black'}}>
                    <Icon icon="edit"/>
                  </a>
            </span> : null}
            {hasPerm(user, "rtcmdmodels.duplicate_copper") && ['Open'].includes(record.status)
                ? <span style={{cursor: 'pointer'}}>
                  <a href={`/coppers/${record.order}/copy/`} style={{color: 'black'}}>
                    <Icon icon="duplicate"/>
                  </a>
            </span> : null}
            {hasPerm(user, "rtcmdmodels.fill_copper") && record.order_type !== 'FFP TBC' && ['Open', 'Partial'].includes(record.status)
                ? <span style={{cursor: 'pointer'}}>
                  <a href={`/coppers/${record.order}/fill/`} style={{color: 'black'}}>
                    <Icon icon="add"/>
                  </a>
            </span> : null}
            {hasPerm(user, "rtcmdmodels.reconcile_copper") && ['Filled'].includes(record.status) && false ? <span style={{cursor: 'pointer'}}
                 onClick={() => {
                   const res = axios.post(url(`copper/${record.order}/reconcile/`))
                   res.then(x =>
                       Toaster.show({
                         message: "Order successfully reconciled.",
                         icon: "small-tick",
                         intent: "success",
                       })
                   ).catch(x =>
                       Toaster.show({
                         message: "An error occurred while reconciling the order.",
                         icon: "warning-sign",
                         intent: "danger",
                       })
                   )
                 }}
            >
                <ReconcileIcon/>
            </span> : null}
            {hasPerm(user, "rtcmdmodels.delete_copper") && ['Open', 'Partial'].includes(record.status)
                ? <span style={{cursor: 'pointer'}}
                  onClick={() => {
                       const res = axios.post(url(`copper/${record.order}/cancel/`))
                       res.then(x =>
                           Toaster.show({
                             message: "Order successfully cancelled.",
                             icon: "small-tick",
                             intent: "success",
                           })
                       ).catch(x =>
                           Toaster.show({
                             message: "An error occurred while cancelling the order.",
                             icon: "warning-sign",
                             intent: "danger",
                           })
                       )
                     }}
              >
              <Icon
                  icon="cross"
              />
            </span> : null}
          </div>
        );
      },
    }
  ];

  return (
    <div className="rtcmd-orders-table">
      <Table
        locale={{
          emptyText: "No orders to display",
        }}
        loading={
          loading
            ? {
              delay: 1500,
              indicator: <Spinner size={32} />,
            }
            : false
        }
        scroll={{
          x: orders && orders.length > 0,
        }}
        size="small"
        expandIconColumnIndex={13}
        columns={columns}
        dataSource={
          orders
            ? orders
              .sort((a, b) => {
                return b.id - a.id;
              })
              .map((order) => {
                return {
                  key: order.id,
                  order: order.id,
                  created_at: order.created_at,
                  quota_month: order.quota_month,
                  pricing_date: order.pricing_date_str || order.pricing_date,
                  contract: order.contract.name,
                  order_type: order.order_type,
                  buy_sell: order.trades.map(trade => trade.action)[0],
                  maturity: order.trades.map(trade => trade.maturity_str)[0],
                  strategy: order.strategy,
                  entity: order.entity.name,
                  customer: order.customer && order.customer.display_name,
                  quantity: order.trades.map(trade => trade.quantity)[0],
                  unit: order.contract.units,
                  lots: order.trades.map(trade => trade.quantity)[0]/(order.contract.units.includes('lbs') ? 25000 : 25),
                  status: order.status,
                  requestor: order.requestor.full_name,
                  raw: order,
                };
              })
            : []
        }
        tableLayout="auto"
        pagination={{
          pageSize: 20,
          itemRender: renderItem,
          showSizeChanger: false,
        }}
        onChange={handleChange}
      />
    </div>
  );
};

const CoppersTable = ({get_pending_tbc=false}) => {
  const sortContext = useContext(SortContext);
  const [{ data: orders, loading }, refetch] = useAxios({
    url: url("copper/" + formatSort(sortContext)) + `&get_pending_tbc=${get_pending_tbc}`,
  });

  const firstRender = useRef(true);

  // Sockets

  const socket = useContext(SocketContext);

  useEffect(() => {
    refetch();
    socket.addEventListener("copper_order_create", refetch);
    socket.addEventListener("copper_order_reconcile", refetch);

    return () => {
      socket.removeEventListener("copper_order_create", refetch);
      socket.removeEventListener("copper_order_reconcile", refetch);
    };
  }, [socket, refetch]);

  // End of socket handling
  const [isTBCModelOpen, setTBCModelOpen] = useState(false)

   useEffect(() => {
     if (firstRender.current && get_pending_tbc && orders && orders.length) {
       setTBCModelOpen(true)
       firstRender.current = false
     }
  }, [orders, setTBCModelOpen, get_pending_tbc])

  return get_pending_tbc ?
      <Dialog
        isOpen={isTBCModelOpen}
        hasBackdrop={true}
        canEscapeKeyClose
        canOutsideClickClose
        onClose={() => {setTBCModelOpen(false)}}
        style={{width: '96%', padding: 20}}
      >
        <div>
          <h1 style={{fontSize: 22}}>FFP TBC Alert!</h1>
          <p style={{fontSize: 18, marginBottom: 40}}>
              These FFP TBC orders are for the upcoming maturity month, please input the pricing date or delete the order asap.
          </p>
          <TableInterAction orders={orders} loading={loading}/>
        </div>
      </Dialog>
      : <TableInterAction orders={orders} loading={loading}/>;
};

export default CoppersTable;
