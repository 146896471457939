import React, {useState} from "react";
import useAxios from "axios-hooks";
import {Button, Dialog, TextArea} from "rt-design-system-backup";
import Toaster from "../Toaster";
import {getCSRF, getErrorMessage, report, url} from "../../helpers";
import {FormFooter} from "../Form";

const SendForCorrectionModal = ({ isOpen, onClose, requestId}) => {
  const [comments, setComments] = useState('');

  const [{ loading }, commentOnRequest] = useAxios(
    {
      url: url("request/" + requestId + "/send_for_correction/"),
      method: "POST",
      headers: {
        "X-CSRFToken": getCSRF(),
      },
    },
    { manual: true }
  );

  const submit = async () => {
    try {
      await commentOnRequest({data: {'comments': comments},})
      report("request_sent_for_correction", {
        request_id: requestId,
      });

      Toaster.show({
        message: "The request has been sent back for correction",
        icon: "small-tick",
        intent: "success",
      });
    } catch (err) {
      if(err.message){
        report("request_cancel_failed");

        Toaster.show({
          message:
            "An error occurred while sending the request for correction. " +
            getErrorMessage(err),
          icon: "warning-sign",
          intent: "danger",
        });
      }else {
        report("request_sent_for_correction", {
        request_id: requestId,
        });

        Toaster.show({
          message: "The request has been sent back for correction",
          icon: "small-tick",
          intent: "success",
        });
      }
    }

    onClose && onClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      hasBackdrop={true}
      canEscapeKeyClose
      canOutsideClickClose
      onClose={onClose}
      title={"Send for correction"}
      style={{ maxWidth: "548px", width: "100%" }}
    >
      <div style={{ padding: "0 32px 0 32px" }}>
        <p
          style={{
            color: "#706F6F",
            fontSize: "16px",
            lineHeight: "32px",
            marginTop: "16px",
          }}
        >
          Details for correction(s):
        </p>
        <TextArea maxLength={500} style={{width: '655px'}} growVertically={true} onChange={(evt) => {
            setComments(evt.target.value)
          }} defaultValue="">
        </TextArea>
        <p style={{marginTop: 5, color: '#706F6F'}}>{comments.length}/500</p>
        <FormFooter>
          <Button onClick={onClose} text="Cancel" minimal />
          <Button
            disabled={comments.length === 0}
            loading={loading}
            onClick={submit}
            text="Submit"
            intent="primary"
          />
        </FormFooter>
      </div>
    </Dialog>
  );
};

export default SendForCorrectionModal;
