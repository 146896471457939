import React from 'react'
import STPMappingForm from '../../STPMappingForm';
import useAxios from 'axios-hooks';
import { url } from '../../../helpers';

// NewSTPMapping is a React component for creating a new STP mapping.
const NewSTPMapping = () => {
    const [{ data: SystemList, loading: systemLoading }] = useAxios({ url: url('systems/') })
    const [{ data: NexTradeFieldsList, loading: NexTradeFieldLoading }] = useAxios({ url: url('ddp_fields/') })
    // For NexTrade Fields enums will be used
    const [{ data: DDPFieldList, loading: DDPFieldLoading }] = useAxios({ url: url('ddp_enums/') })
    if (systemLoading || NexTradeFieldLoading || DDPFieldLoading) {
        return <div>Loading</div>
    }
    return <STPMappingForm SystemList={SystemList} NexTradeFieldsList={NexTradeFieldsList} DDPFieldList={DDPFieldList} />;
};
export default NewSTPMapping;
