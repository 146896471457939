import React from "react";
import { useParams } from "react-router-dom";
import STPMappingSystemColumnValuesTable from "../../STPMappingSystemColumns/Table/STPMappingSystemColumnValuesTable";
import Back from "../../STPMappingSystemColumns/Nav/Back";
import Nav from "../../STPMappingSystemColumns/Nav/Nav";
import { useSTPMappingSystemColumnContext } from "../../STPMappingSystemColumns/STPMappingSystemColumnsContext";
import "./style.scss";

const getSystemColumnValues = (
  systemColumnValuesLoading,
  systemColumnValues,
  systemColumnId
) =>
  !systemColumnValuesLoading &&
  systemColumnValues &&
  systemColumnValues.filter(
    (value) => value.column === parseInt(systemColumnId)
  );

const useSystemColumnValues = (systemColumnId) => {
  const { systemColumnValues, systemColumnValuesLoading } =
    useSTPMappingSystemColumnContext();

  return {
    systemColumnValuesLoading,
    systemColumnValues: getSystemColumnValues(
      systemColumnValuesLoading,
      systemColumnValues,
      systemColumnId
    ),
  };
};

const STPMappingSystemColumnValuesList = () => {
  const { systemColumnId } = useParams();
  const { systemColumnValuesLoading, systemColumnValues } =
    useSystemColumnValues(systemColumnId);
  return (
    <>
      <div className="edit-mapping">
        <Back
          to="/stp-mapping-console/system-columns"
          title="All system columns"
        />
        <Nav
          to="/stp-mapping-console/system-column-values/new"
          text="New Value"
        />
      </div>
      <STPMappingSystemColumnValuesTable
        data={systemColumnValues}
        loading={systemColumnValuesLoading}
      />
    </>
  );
};

export default STPMappingSystemColumnValuesList;
