import React, {useContext, useState} from "react";
import {Button, Icon} from "rt-design-system-backup";
import styled from "styled-components";
import {useHistory} from "react-router-dom";

import {getCSRF, getErrorMessage, hasGroup, hasPerm, report, url} from "../../helpers";

import RequestCancelModal from "../RequestCancelModal";
import RequestReconcileModal from "../RequestReconcileModal";
import AppContext from "../AppContext";
import FillIcon from "../Icons/fill";
import ReconcileIcon from "../Icons/reconcile";
import useAxios from "axios-hooks";
import Toaster from "../Toaster";
import axios from "axios";
import FileDownload from "js-file-download";
import SendForCorrectionModal from "../SendForCorrectionModal";
import {loadingModelCSS} from "../Views/NewRequest";
import LoadingIcon from "../Icons/loading";
import {Overlay} from "@blueprintjs/core";

export const CancelQuote = () => {
  // TODO: implement quote cancel modal
  return <Button large>Cancel quote</Button>;
};

export const AcceptQuote = ({ show, showIcon = false, label = 'Accept quote', fill = false,
  large, requestId, primary = false, className = '' }) => {
  const [, acceptRequest] = useAxios(
    {
      url: url("request/" + requestId + "/accept/"),
      method: "POST",
      headers: {
        "X-CSRFToken": getCSRF(),
      },
    },
    { manual: true }
  );

  const submit = async () => {
    try {
      setTimeout(async () => {
        await acceptRequest();
      }, 1000)

      report("request_accept_confirmed", {
        request_id: requestId,
      });

      Toaster.show({
        message: "Request successfully accepted. ",
        icon: "small-tick",
        intent: "success",
      });
    } catch (err) {
      report("request_accept_failed");

      Toaster.show({
        message:
          "An error occurred while accepting the request. " +
          getErrorMessage(err),
        icon: "warning-sign",
        intent: "danger",
      });
    }
  };

  return (show ?
    showIcon ? <Icon style={{ cursor: "pointer" }} icon={'tick'} onClick={submit} /> :
      <Button onClick={submit} large={large ? true : null} icon={'tick'} outlined={!primary} intent={primary ? 'primary' : null}
        fill={fill ? true : null} className={className}>{label}</Button>
    : null);
};

const downloadCsv = (event, requestId) => {
  event.preventDefault()
  report('requests_pdf')
  axios({
    url: url(`requestspdf/${requestId}/`),
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const fileName = response.headers['filename'];
    FileDownload(response.data, fileName);
  });
}

export const GenericButton = ({ show = false, requestId, text, icon }) => {
  return show ? <a style={{ textDecoration: 'none' }} href="#0" onClick={(event) => { downloadCsv(event, requestId) }} download>
    <Button
      large
      outlined
      onClick={() => {
      }}
    >
      <Icon icon={icon} iconSize={20} /> {text}
    </Button>
  </a> : null
}

export const ResubmitQuote = ({ show, showIcon = false, label = 'Resubmit', fill = false,
  large, requestId, primary = false, className = '' }) => {
  const history = useHistory();
  const [showLoadingModel, setShowLoadingModel] = useState(false);
  const [, resubmitRequest] = useAxios(
    {
      url: url("request/" + requestId + "/resubmit/"),
      method: "POST",
      headers: {
        "X-CSRFToken": getCSRF(),
      },
    },
    { manual: true }
  );

  const submit = async () => {
    try {
      const resubmittedRequest = await resubmitRequest();
      history.push(`/aluminium/request/${resubmittedRequest.data.id}/details`);

      Toaster.show({
        message: "Request successfully resubmitted. ",
        icon: "small-tick",
        intent: "success",
      });
    } catch (err) {
      setShowLoadingModel(false)
      Toaster.show({
        message:
          err.response.data.error || "An error occurred while resubmitting the request. "+
          getErrorMessage(err),
        icon: "warning-sign",
        intent: "danger",
      });
    }
  };

  return (show ?
    <>
      <Overlay
          isOpen={showLoadingModel}
          className={loadingModelCSS}
        >
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <LoadingIcon /><h4>Generating request</h4>
        </div>
      </Overlay>
      {showIcon ? <Icon style={{ cursor: "pointer"}} icon={'refresh'} onClick={() => {setShowLoadingModel(true); submit()}} /> :
      <Button onClick={() => {setShowLoadingModel(true); submit()}} large={large ? true : null} icon={'refresh'}
              outlined={!primary} intent={primary ? 'primary' : null} fill={fill ? true : null} className={className}>{label}</Button>}
    </>
    : null);
};

export const useShowCancelRequest = (request) => {
  const { user } = useContext(AppContext);
  return (
    request &&
    user &&
    request.request_type !== 'Indic Quote' &&
    request.status !== "Cancelled" && request.status !== "Expired" &&
    (
      (["Open"].includes(request.status) && hasPerm(user, "rtcmdmodels.cancel_own_open_request") &&  ((request.requestor.email === user.email) ||
        (user.sales_entity && user.sales_entity.name === request.sales_entity.name))) ||
      (["Filled", "For correction"].includes(request.status) && hasGroup(user, 'Alu Trader')) ||
      (["Generated"].includes(request.status) && hasGroup(user, 'Alu CSR'))
    )
  );
};

export const useShowPDFDownload = (request) => {
  return (
    request &&
    request.status === "Reconciled"
  )
}


export const useShowSendForCorrection = (request) => {
  const { user } = useContext(AppContext);

  return (
    request &&
    (hasPerm(user, "rtcmdmodels.reconcile_filled_request") && ["Filled", "Reconciled"].includes(request.status))
  )
}

export const useShowResubmit = (request) => {
  const { user } = useContext(AppContext);
  const date = new Date();

  // Disables temporarily this feature (awaiting for approval)
  return (
    request && ["Auto Quote", "Indic Quote"].includes(request.request_type) &&
    new Date(request.created_at) >= new Date(date.getFullYear(), date.getMonth(), 1)   &&
    (["Cancelled", "Expired"].includes(request.status) && hasGroup(user, 'Alu CSR'))
  )
}


export const CancelRequest = ({
  requestId,
  requestType,
  requestCreatedOn,
  requestSalesEntity,
  show,
  label = 'Cancel request',
  fill = false,
  large
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <RequestCancelModal
        requestId={requestId}
        requestType={requestType}
        requestCreatedOn={requestCreatedOn}
        requestSalesEntity={requestSalesEntity}
        onClose={() => {
          setIsOpen(false);
        }}
        isOpen={isOpen && show}
      />
      {show ? (
        <Button icon="cross" large={large ? true : null} fill={fill ? true : null} outlined onClick={() => setIsOpen(true)}>
          {label}
        </Button>
      ) : null}
    </>
  );
};

export const SendForCorrectionButton = ({ requestId, show }) => {
  const [isOpen, setIsOpen] = useState(false);

  return <>
    <SendForCorrectionModal
      requestId={requestId}
      onClose={() => {
        setIsOpen(false);
      }}
      isOpen={isOpen && show}
    />
    {show ? (
      <Button large outlined onClick={() => setIsOpen(true)}>
        Send for correction
      </Button>
    ) : null}
  </>
};

export const useShowFillRequest = (request) => {
  const { user } = useContext(AppContext);

  return (
    request &&
    user &&
    request.request_type !== 'Indic Quote' &&
    (request.status === "Open" &&
      hasPerm(user, "rtcmdmodels.fill_open_request"))
  );
};

export const FillRequest = ({ requestId, show }) => {
  const history = useHistory();

  return show ? (
    <Button
      icon={<FillIcon />}
      large
      outlined
      onClick={() => {
        history.push("/aluminium/request/" + requestId + "/fill/");
      }}
    >
      Fill request
    </Button>
  ) : null;
};

export const ViewAllRequests = () => {
  return <Button large>All requests</Button>;
};

export const useShowReconcileRequest = (request) => {
  const { user } = useContext(AppContext);

  return (
    request &&
    user &&
    request.status === "Filled" &&
    hasPerm(user, "rtcmdmodels.reconcile_filled_request")
  );
};


export const useShowAcceptRequest = (request) => {
  const { user } = useContext(AppContext);

  return (
    
    request && user &&
    ["Auto Quote", "Postponement Auto Quote", "Anticipation Auto Quote"].includes(request.request_type) && request.status === "Generated" &&
    ((request.requestor.email === user.email) || (user.sales_entity && user.sales_entity.name === request.sales_entity.name))
    && (hasGroup(user, "Alu CSR") )
    
  );
};

export const ReconcileRequest = ({ requestId, request, show }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <RequestReconcileModal
        requestId={requestId}
        request={request}
        onClose={() => {
          setIsOpen(false);
        }}
        isOpen={isOpen && show}
      />
      {show ? (
        <Button large icon={<ReconcileIcon />} outlined onClick={() => setIsOpen(true)}>
          Reconcile request
        </Button>
      ) : null}
    </>
  );
};

export const Cancel = ({ text = "Cancel" }) => {
  const history = useHistory();

  return (
    <Button
      minimal
      large
      onClick={() => {
        history.push("/aluminium");
      }}
    >
      {text}
    </Button>
  );
};

export const ActionsWrapper = styled.div`
  text-align: right;
  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;

export const RequestOverviewActions = ({ request, action, additional=null }) => {
  const showCancelRequest =
    useShowCancelRequest(request) && action !== "reconcile";
  const showReconcileRequest = useShowReconcileRequest(request);
  const showFillRequest = useShowFillRequest(request) && action !== "reconcile";
  const showPDFDownload = useShowPDFDownload(request);
  const showAcceptRequest = useShowAcceptRequest(request);
  const showSendForCorrection = useShowSendForCorrection(request);
  const showResubmit = useShowResubmit(request);

  return (
    <ActionsWrapper>
      {additional}
      <SendForCorrectionButton show={showSendForCorrection} requestId={request.id} />
      {action === "reconcile" || action === "fill" ? (
        <Cancel text={action === "reconcile" ? "Cancel" : "Back"} />
      ) : null}
      <CancelRequest show={showCancelRequest} requestId={request.id} requestType={request.request_type}
        requestSalesEntity={request.sales_entity.name} requestCreatedOn={request.created_at} large={true} />
      <ReconcileRequest show={showReconcileRequest} requestId={request.id} request={request} />
      <FillRequest show={showFillRequest} requestId={request.id} />
      <AcceptQuote show={showAcceptRequest} large={true} requestId={request.id} />
      <GenericButton show={showPDFDownload} requestId={request.id} text={"Download PDF"} icon={"download"} />
      <ResubmitQuote show={showResubmit} large={true} requestId={request.id} />
    </ActionsWrapper>
  );
};

export default RequestOverviewActions;
