import React, { useEffect, useState, useRef } from "react"
import Axios from "axios"

import Page from "../../Page"

import RoutePriceTable from "../../../PriceTable/Route"
import { url } from "../../../helpers";

import { Chart } from "../../Chart"

import { Row, Col } from "antd"

const Price = () => {
    const [routes, setRoutes] = useState([])
    const [maturities, setMaturities] = useState([])

    const fetchDataTimeout = useRef(() => { })

    useEffect(() => {
        const fetchData = async () => {
            clearTimeout(fetchDataTimeout.current)

            try {
                const routes = await Axios.get(url('/route/'))
                const maturities = await Axios.get(url('/maturity/?display_only'))
                setRoutes(routes.data)
                setMaturities(maturities.data)
            } catch (err) {
                console.log(err)
                fetchDataTimeout.current = setTimeout(fetchData, 2000)
            }
        }

        fetchData()

        return () => {
            clearTimeout(fetchDataTimeout.current)
        }
    }, [])

    return <Page showFFADrawerButton title="Freight prices" tabs={[
        {
            title: "Freight",
            to: "/prices/"
        },
        {
            title: "Brokers",
            to: "/brokers/"
        }
    ]}>
        <Row gutter={[32, 32]}>
            {
                routes.map((route) => {
                    return <Col sm={24} md={12} xl={6} key={route.route}>
                        <RoutePriceTable route={route} maturities={maturities} />
                    </Col>
                })
            }
        </Row>
        <h1 className="rtcmd-page-header">History</h1>
        <Row gutter={[32, 32]}>
            <Col span="24">
                <Chart />
            </Col>
        </Row>
    </Page>
}

export default Price