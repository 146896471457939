import React, { useEffect, useState } from "react";
import axios from "axios";
import { Icon, Popover, Position } from "rt-design-system-backup";
import TableInput from "../../../TableInput";
import ConfigurationForm from "../ConfigurationForm";
import { url, formatNumber, isValidNumber, getFontColorDelta } from "../../../../helpers";

const ValidityPremiumCurve = () => {
    const [editing, setEditing] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [marginData, setMarginData] = useState([]);
    const [lastUpdated, setLastUpdated] = useState()
    const [payload, setPayload] = useState({});
    const [refetch, setRefetch] = useState(false);
    const cellStyle = {
        'textAlign': 'right'
    }
    useEffect(() => {
        axios.all([
            axios.get(url('/validity_margin/')),
            axios.get(url('/validity_margin/?tier_customer=Tier 1')),
            axios.get(url('/validity_margin/?tier_customer=Tier 2')),
            axios.get(url('/validity_margin/?tier_customer=Tier 3'))
        ])
        .then(axios.spread((data, data1, data2, data3) => {
            console.log( data, data1, data2, data3)
            const tempMarginData = data['data']['data'].map((item) => {
                return {
                    validity: item['validity'],
                    margin: data['data']['data'].find((tempMargin) => tempMargin['validity'] === item['validity']).margin,
                    margin1: data1['data']['data'].find((tempMargin) => tempMargin['validity'] === item['validity']).margin,
                    margin2: data2['data']['data'].find((tempMargin) => tempMargin['validity'] === item['validity']).margin,
                    margin3: data3['data']['data'].find((tempMargin) => tempMargin['validity'] === item['validity']).margin
                }
            })

            setLastUpdated(data1['data']['last_updated'])
            setMarginData(tempMarginData);
        }));
    }, [refetch])

    const formatBasisMargin = (value, record) => {
        const cumulativeMargin = value.value + record.margin.value
        return formatNumber(cumulativeMargin, false, 2, false)
    }

    const formatContents = (value, additional) => {
        if (value > 0) return '+' + value
        else if (value < 0) return '-' + value
        else return null
     }

    const validityMarginCol = [
        {
          title: "Validity",
          dataIndex: "validity",
          render: x => <>
            <span style={{ fontSize: '16px' }}>{x}</span>
            {x === 'Past Cutoff' ? <span className="for-correction-tooltip">
              <Popover
                content={
                  <div style={{
                    backgroundColor: '#002C5F',
                    color: 'white',
                    padding: 10,
                    borderRadius: 10,
                    maxWidth: 235,
                    marginBottom: 8,
                  }}>
                    Margin to be applied if validity is past the daily cut-off time (London 19:00)
                  </div>
                }
                minimal
                position={Position.TOP}>
                <Icon style={{ color: '#706F6F' }} icon={'info-sign'} />
              </Popover>
            </span> : null}
          </>,
        },
        {
          title: "Margin ($)",
          dataIndex: "margin",
          render: value => {
            return <TableInput
              required
              value={formatNumber(value['value'], false, 2, false)}
              onBlurCall={(str) => setTimeout(() => setIsValid(true), 1000)}
              id={'margin'}
            />
          }
        },
        {
            title: "Margin Tier 1 ($)",
            dataIndex: "margin1",
            render: (value, record) => {
              return <div style={{"display": "flex", "alignItems": "baseline"}}>
                <p style={{"margin": 0, flexGrow: 1, color: 'black'}}>{formatBasisMargin(value, record)}</p>
                <TableInput
                    required
                    tabbable={editing}
                    enabled={editing}
                    value={formatNumber(value['value'], false, 1, false)}
                    isInvalid={(str) => {
                    const error = isValidNumber(str, true, true)
                    if (error) {
                        setIsValid(false)
                    }
                    return error
                    }}
                    formatContents={formatContents}
                    onBlurCall={(str) => setTimeout(() => setIsValid(true), 1000)}
                    style={cellStyle}
                    divStyle={{
                        ...cellStyle,
                        color: getFontColorDelta(value['value'])
                    }}
                    onChange={(x) => {
                    setPayload({
                        ...payload,
                        [value['id']]: {
                        ...(payload[value['id']] || { }),
                        'margin': x
                        }
                    })
                    setIsValid(true)
                    }}
                    id={'margin1'}
                />
              </div> 
            }
          },
          {
            title: "Margin Tier 2 ($)",
            dataIndex: "margin2",
            render: (value, record) => {
                return <div style={{"display": "flex", "alignItems": "baseline"}}>
                  <p style={{"margin": 0, flexGrow: 1, color: 'black'}}>{formatBasisMargin(value, record)}</p>
                  <TableInput
                      required
                      tabbable={editing}
                      enabled={editing}
                      value={formatNumber(value['value'], false, 1, false)}
                      isInvalid={(str) => {
                      const error = isValidNumber(str, true, true)
                      if (error) {
                          setIsValid(false)
                      }
                      return error
                      }}
                      formatContents={formatContents}
                      onBlurCall={(str) => setTimeout(() => setIsValid(true), 1000)}
                      style={cellStyle}
                      divStyle={{
                        ...cellStyle,
                        color: getFontColorDelta(value['value'])
                      }}
                      onChange={(x) => {
                      setPayload({
                          ...payload,
                          [value['id']]: {
                          ...(payload[value['id']] || { }),
                          'margin': x
                          }
                      })
                      setIsValid(true)
                      }}
                      id={'margin2'}
                  />
                </div> 
            }
          },
          {
            title: "Margin Tier 3($)",
            dataIndex: "margin3",
            render: (value, record) => {
                return <div style={{"display": "flex", "alignItems": "baseline"}}>
                  <p style={{"margin": 0, flexGrow: 1, color: 'black'}}>{formatBasisMargin(value, record)}</p>
                  <TableInput
                      required
                      tabbable={editing}
                      enabled={editing}
                      value={formatNumber(value['value'], false, 1, false)}
                      isInvalid={(str) => {
                      const error = isValidNumber(str, true, true)
                      if (error) {
                          setIsValid(false)
                      }
                      return error
                      }}
                      formatContents={formatContents}
                      onBlurCall={(str) => setTimeout(() => setIsValid(true), 1000)}
                      style={cellStyle}
                      divStyle={{
                        ...cellStyle,
                        color: getFontColorDelta(value['value'])
                      }}
                      onChange={(x) => {
                      setPayload({
                          ...payload,
                          [value['id']]: {
                          ...(payload[value['id']] || { }),
                          'margin': x
                          }
                      })
                      setIsValid(true)
                      }}
                      id={'margin3'}
                  />
                </div> 
            }
          },
      ]
    const refetchMargins = () => setRefetch(!refetch)
    return (
        <>
            <ConfigurationForm title={'Validity Premium Curve Configuration'} 
              dataSource={marginData} columns={validityMarginCol}
              endpoint={'/validity_margin/custom_update/'}
              lastUpdated={lastUpdated}
              editing={editing} setEditing={setEditing}
              data={payload} refetch={refetchMargins} isValid={isValid}
              divWidth={'80%'}
            />
        </>
    )
}
export default ValidityPremiumCurve;
