export const types = [
  {
    name: "Quote",
    value: "Quote",
    url: "/quote/",
  },
  {
    name: "Auto Quote",
    value: "Auto Quote",
    url: "/auto-quote/",
  },
  {
    name: "Indic Quote",
    value: "Indic Quote",
    url: "/auto-quote/",
  },
  {
    name: "Postponement Auto Quote",
    value: "Postponement Auto Quote",
    url: "/replacement-auto-quote/",
  },
  {
    name: "Anticipation Auto Quote",
    value: "Anticipation Auto Quote",
    url: "/replacement-auto-quote/",
  },
  {
    name: "Limit order",
    value: "Limit Order",
    url: "/limit_order/",
  },
  {
    name: "CSP",
    value: "CSP",
    url: "/csp/",
  },
  {
    name: "APC",
    value: "APC",
    url: "/apc/",
  },
  {
    name: "At market",
    value: "At Market",
    url: "/at_market/",
  },
];
