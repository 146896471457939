import React, {useEffect, useState} from "react";
import useAxios from "axios-hooks";

import { InputGroup } from "../../../../Form";
import { url } from "../../../../../helpers";
import { dateFormat } from "../../../Maturities";
import LoadingIcon from "../../../../Icons/loading";

const AcceptanceRow = ({data, monthType=null}) => {
    const calculateAcceptanceRatio = (data, key) => {
        if (data.accepted === 0 && data.rejected === 0) return 0.0
        const ratio = (data[key] / (data.accepted + data.rejected)) * 100
        return parseFloat(parseFloat(ratio).toFixed(2))
    }
    const dataStyle = {"fontSize": '19px', "padding": '10px 20px', "color": "white", "margin": "0px"}
    const lableStyle = {"padding": "0px 20px", "fontWeight": 600, "fontSize": '12px', "textAlign": "center"}
    return (
        <div style={{"marginBottom": '30px'}}>
            <p style={{fontWeight: 600, color: 'gray', paddingTop: 0, fill: '#002C5F', 'textAlign': 'center'}}>{monthType !== null && `${monthType}`} Acceptance Ratio</p>
            <div style={{"display": 'inline-flex', 'width': '100%', justifyContent: 'center'}}>
                <div>
                    <p style={{"background": '#0087fa', ...dataStyle}}>
                        {(calculateAcceptanceRatio(data, 'accepted'))}%
                    </p>
                    <p style={{...lableStyle}}>
                        Accepted
                    </p>
                </div>
                <div>
                    <p style={{"background": 'rgb(192 220 244)', ...dataStyle}}>
                        {(calculateAcceptanceRatio(data, 'rejected'))}%
                    </p>
                    <p style={{...lableStyle}}>
                        Rejected
                    </p>
                </div>
            </div>
        </div> 
    )
}

const PieChart = ({customer_id, start_date, end_date}) => {
    const [acceptanceType, setAcceptanceType] = useState('USD')
    const [{ loading: chartDataLoading, data: chartData }, refetch] = useAxios({
        url: url(`aluminium_analytics/acceptance_ratio/?start_date=${dateFormat.formatDate(start_date)}&end_date=${dateFormat.formatDate(end_date)}${customer_id !== -1 ? '&customer=' + customer_id : ''}&type=${acceptanceType}`),
    });
    const [{ loading: spotDataLoading, data: spotData }, spotRefetch] = useAxios({
        url: url(`aluminium_analytics/acceptance_ratio/?start_date=${dateFormat.formatDate(start_date)}&end_date=${dateFormat.formatDate(end_date)}${customer_id !== -1 ? '&customer=' + customer_id : ''}&type=${acceptanceType}&month_type=spot`),
    });
    const [{ loading: futureDataLoading, data: futureData }, futureRefetch] = useAxios({
        url: url(`aluminium_analytics/acceptance_ratio/?start_date=${dateFormat.formatDate(start_date)}&end_date=${dateFormat.formatDate(end_date)}${customer_id !== -1 ? '&customer=' + customer_id : ''}&type=${acceptanceType}&month_type=future`),
    });

    useEffect(()=>{
        if (!acceptanceType || !customer_id || !start_date || !end_date) return 
        refetch()
        spotRefetch()
        futureRefetch()

        // eslint-disable-next-line
    }, [acceptanceType, customer_id, start_date, end_date])

    return (
        <>
        {chartDataLoading === true || spotDataLoading === true || futureDataLoading === true? (
            <div style={{ alignItems: "center", justifyContent: "center", height: '100%', display: 'flex'}}>
                <LoadingIcon />
                <p>Loading Data</p>
            </div>
        ) : (
            <>
                <div style={{"display": 'flex', "width": '100%', "justifyContent": "flex-end"}}>
                  <InputGroup
                    required
                    name="acceptance type"
                    type="select"
                    formStyle={{margin: 0, marginBottom: 20, }}
                    items={[{name: ' Total Count', value:'USD'}, {name: 'Total Qt (MT)', value:'MT'}].map((item) => {
                    return {
                        name: item.name,
                        value: item.value,
                    };
                    })}
                    onChange={(e) => {setAcceptanceType(e[0].value)}}
                    placeholder={'Select Type'}
                    defaultValue={[acceptanceType || 'USD']}
                  />
                </div>
                <div style={{height: '431px'}}>
                    <AcceptanceRow data={chartData} monthType={'Global'}/>
                    <AcceptanceRow data={futureData} monthType={'Future'}/>
                    <AcceptanceRow data={spotData} monthType={'Spot'} />    
                </div>
            </>
        )}
        </>
    )
}
export default PieChart