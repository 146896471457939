import { report } from "./analytics";
import api from "./api";
import LoadingIcon from "../App/Icons/loading";
import jwt_decode from "jwt-decode";
import Toaster from "../App/Toaster";

const capitalize = (str) => {
  return str.substring(0, 1).toUpperCase() + str.substring(1);
};

const formatNumber = (x, showDash, showDecimals, useGrouping = true) => {
  if ((x === 0 || x === null) && showDash) {
    return "-";
  }

  const digits =
    typeof showDecimals === "number"
      ? showDecimals
      : showDecimals
      ? 2
      : parseFloat(x) >= 1000
      ? 0
      : 2;

  return typeof x === "number" && x.toLocaleString
    ? x.toLocaleString("en-US", {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
        useGrouping: useGrouping,
      })
    : showDash
    ? "-"
    : x;
};

const renameInstrument = (instrumentName) => {
  if (instrumentName.includes("CME")) return instrumentName;
  return instrumentName.replace(/(MWP|MJP|EDP)/g, "CME $1");
};

const replaceOrderTypes = (orderType) => {
  const order_types_mapping = {
    csp: "Official Price",
    "auto quote": "Auto Quote",
    quote: "Manual Quote",
  };
  if (order_types_mapping[orderType.toLowerCase()]) {
    return order_types_mapping[orderType.toLowerCase()];
  }
  return orderType;
};

const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

const formatSort = (sortContext) => {
  let qs = "?";
  Object.keys(sortContext).forEach((key) => {
    let parsedContent;
    let content = sortContext[key];

    if (content === undefined || content === "undefined") return;

    if (content instanceof Date) {
      parsedContent = content.toISOString();
    } else {
      parsedContent = content;
    }

    qs += camelToSnakeCase(key) + "=" + parsedContent + "&";
  });
  return qs.substring(0, qs.length - 1);
};

const getEnvironment = () => {
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    return "local";
  }
  if (window.location.hostname.indexOf("dev.") >= 0) {
    return "dev";
  } else if (window.location.hostname.indexOf("staging.") >= 0) {
    return "staging";
  } else if (window.location.hostname.indexOf("rtnextrade.net") >= 0) {
    return "prod";
  } else {
    return "local";
  }
};

const url = (url) => {
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    return (
      `http://localhost:8000` + (url.substring(0, 1) === "/" ? "" : "/") + url
    );
  }
  if (window.location.hostname.indexOf("rtnextrade.net") >= 0) {
    return (
      `https://api.${window.location.hostname
        .replace("api.", "")
        .replace("www.", "")}` +
      (url.substring(0, 1) === "/" ? "" : "/") +
      url
    );
  } else if (
    window.location.hostname.indexOf("commercialmarketsdesk.net") >= 0
  ) {
    return (
      `https://api.rtnextrade.net` +
      (url.substring(0, 1) === "/" ? "" : "/") +
      url
    );
  } else {
    return (
      `http://localhost:8000` + (url.substring(0, 1) === "/" ? "" : "/") + url
    );
  }
};

const formatDate = (date, hideHours, utc, showTimezone) => {
  return new Date(date).toLocaleString(navigator.language, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: hideHours ? undefined : "numeric",
    minute: hideHours ? undefined : "2-digit",
    timeZone: utc ? "UTC" : undefined,
    timeZoneName: showTimezone ? "long" : undefined,
  });
};

const parseDateWithoutTimezone = (str) => {
  var date = new Date(str);
  var userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + userTimezoneOffset);
};

// if current date = 1 AM SGT, 8 June 2024
// return     date = 1 AM UTC, 8 June 2024
// so that we can extract the current date the user is located
const parseDateInTheTimezone = (str) => {
  var date = new Date(str);
  var userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() - userTimezoneOffset);
};

const resetToMidnight = (input_date) => {
  var dateMidnight = new Date(input_date);
  dateMidnight = dateMidnight.setUTCHours(0, 0, 0, 0);
  return new Date(dateMidnight);
};

const hasPerm = (user, permission) => {
  if (!user) return false;
  if (user.permissions.indexOf(permission) >= 0) {
    return true;
  }
  return false;
};

const has_no_permissions = (user) => {
  if (!user) return true;
  return user.permissions.length === 0;
};

// const getNextPathBasedOnPermission = (user, permission) => {
//   if (!user) return '/login';
//   if(user.permissions.length === 0){
//     return '/no-permissions'
//   }
//   if (user.permissions.indexOf(permission) >= 0) {
//     return '/aluminium'
//   }
//   else{
//     return "/orders"
//   }
// };

const hasGroup = (user, group, only_that = false) => {
  if (only_that) {
    only_that = user.groups.length === 1;
  } else {
    only_that = true;
  }

  return (
    only_that &&
    user.groups.map((group) => group.name).filter((name) => name === group)
      .length
  );
};

const getErrorMessage = (axiosError) => {
  console.log("------------------------");
  console.log(axiosError);
  console.log("------------------------");
  if (axiosError.response.data) {
    return axiosError.response.data.error;
  } else if (axiosError.response) {
    return (
      "The server responded with code: " + axiosError.response.status + "."
    );
  } else if (axiosError.request) {
    return axiosError.request.status === 0
      ? "Could not connect to server."
      : "The server did not respond.";
  } else {
    return "Could not create the request.";
  }
};

const isValidNumber = (rawStr, above0, allow0, max) => {
  const decimalRegex = /(\.?0*$)$/gm;
  const commaRegex = /,/gm;

  let str = rawStr
    .toLowerCase()
    .trim()
    .replace(decimalRegex, "")
    .replace(commaRegex, "");

  if (parseFloat(rawStr) < 0 && above0) {
    return "Positive value needed";
  } else if (rawStr.trim() === "0" && !allow0) {
    return "This value can't be null";
  } else if (rawStr.trim() === "0" && allow0) {
    return;
  } else if ("" + parseFloat(str) !== str) {
    return "Numeric value needed";
  } else if (max && parseFloat(rawStr) > max) {
    return `Value greater than ${max}`;
  }
};

const getCookie = (name) => {
  var cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

const getCSRF = () => {
  return getCookie("csrftoken");
};

const isTokenExpired = (token) => {
  if (!token) {
    return true;
  }
  const { exp } = jwt_decode(token);
  const expirationTime = exp * 1000 - 60000;
  return Date.now() >= expirationTime;
};

const getTooltip = (offer) => {
  return !offer
    ? ""
    : offer.from_message?.sender?.broker?.short +
        " at " +
        new Date(offer.received_at).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        });
};

const getTime = (offer) => {
  return !offer
    ? ""
    : new Date(offer.created_at).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
};

const calculateDelta = (bid, offer, close) => {
  if (bid !== "-" && offer !== "-" && close !== "-") {
    return (parseFloat(bid) + parseFloat(offer)) / 2 - parseFloat(close);
  } else if (bid !== "-" && offer === "-" && close !== "-") {
    return parseFloat(bid) - parseFloat(close);
  } else if (bid === "-" && offer !== "-" && close !== "-") {
    return parseFloat(offer) - parseFloat(close);
  }
  return "-";
};

const getFontColorDelta = (delta) => {
  if (parseFloat(delta) === 0) return "#f08439";
  else if (delta === "-" || isNaN(delta)) return null;
  else if (parseFloat(delta) > 0) return "#49d087";
  else return "#ae002f";
};

const getObjectWithMaxDate = (dateArray) => {
  const maxDateObject = dateArray.reduce((maxObject, currentObject) => {
    if (!maxObject || currentObject.date > maxObject.date) {
      return currentObject;
    }
    return maxObject;
  }, null);
  console.log(dateArray);
  console.log("MAx date", maxDateObject);
  return maxDateObject;
};

const isValidPrice = (vDate) => {
  const currTime = new Date();
  const validityDate = new Date(vDate);
  return currTime.getTime() < validityDate.getTime();
};

const sortOffersByValue = (offers, type) => {
  if (type === "bid") {
    offers.sort((a, b) => {
      return parseInt(b.value) - parseInt(a.value);
    });
  } else if (type === "offer") {
    offers.sort((a, b) => {
      return parseInt(a.value) - parseInt(b.value);
    });
  } else {
    offers.sort((a, b) => {
      return parseInt(b.id) - parseInt(a.id);
    });
  }
};

const getBestOffer = (offers, maturity, type) => {
  const relevantOffers = offers.filter(
    (offer) =>
      offer.contract.maturity.string === maturity.string && offer.type === type
  );
  const validOffers = relevantOffers.filter((offer) =>
    isValidPrice(offer.valid_until)
  );
  if (validOffers.length) {
    sortOffersByValue(validOffers, type);
    return validOffers[0];
  } else {
    return relevantOffers[0];
  }
};

const getLocalTime = (time) => {
  const d = new Date(time);
  console.log(
    Date.UTC(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      d.getHours(),
      d.getMinutes(),
      d.getSeconds(),
      d.getMilliseconds()
    ) / 1000
  );
  return (
    Date.UTC(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      d.getHours(),
      d.getMinutes(),
      d.getSeconds(),
      d.getMilliseconds()
    ) / 1000
  );
};

const validatePostponementTrades = (trades, type) => {
  let isValid = false;
  let error = null;
  const buyTrade = trades.find((trade) => trade.data.action[0].name === "Buy");
  const sellTrade = trades.find(
    (trade) => trade.data.action[0].name === "Sell"
  );
  if (buyTrade !== undefined && sellTrade !== undefined) {
    if (buyTrade.data.quantity === sellTrade.data.quantity) {
      if (
        type === "Postponement Auto Quote" &&
        buyTrade.data.maturity[0].value > sellTrade.data.maturity[0].value
      )
        isValid = true;
      if (
        type === "Anticipation Auto Quote" &&
        sellTrade.data.maturity[0].value > buyTrade.data.maturity[0].value
      )
        isValid = true;
    } else {
      error = "Quantities must be equal";
    }
  }
  return { isValid: isValid, error: error };
};

const getQuoteValidities = (quoteValidities, type) => {
  if (["Postponement Auto Quote", "Anticipation Auto Quote"].includes(type))
    return quoteValidities.slice(-1);
  return quoteValidities;
};

const getComponentHeight = (componentId) => {
  const elem = document.getElementById(componentId);
  return elem ? elem.clientHeight : 0;
};

const divLoader = (windowHeight, deductHeight, isDarkModeOn) => {
  return (
    <div
      style={{
        justifyContent: "center",
        paddingTop: `${(windowHeight - deductHeight) / 2 - 30}px`,
        display: "flex",
      }}
    >
      <LoadingIcon />
      <h4 style={{ color: isDarkModeOn ? "white" : "black" }}>Loading Data</h4>
    </div>
  );
};

const today = new Date();
const makeDate = (action) => {
  const returnVal = new Date();
  action(returnVal);
  returnVal.setDate(returnVal.getDate() + 1);
  return returnVal;
};

const tomorrow = makeDate((d) => d.setDate(d.getDate()));
const ytd = new Date(new Date().getFullYear(), 0, 1);
const yesterday = makeDate((d) => d.setDate(d.getDate() - 2));
const oneWeekAgo = makeDate((d) => d.setDate(d.getDate() - 7));
const oneMonthAgo = makeDate((d) => d.setMonth(d.getMonth() - 1));
const threeMonthsAgo = makeDate((d) => d.setMonth(d.getMonth() - 3));
const sixMonthsAgo = makeDate((d) => d.setMonth(d.getMonth() - 6));
const oneYearAgo = makeDate((d) => d.setFullYear(d.getFullYear() - 1));
const twoYearsAgo = makeDate((d) => d.setFullYear(d.getFullYear() - 2));
const tenYearsAgo = makeDate((d) => d.setFullYear(d.getFullYear() - 10));

function createShortcut(label, dateRange) {
  return { dateRange, label };
}

const getCustomShortcuts = (isCustomerDashboard = false) => {
  let shortcuts = [
    createShortcut("Today", [today, today]),
    createShortcut("Yesterday", [yesterday, yesterday]),
    createShortcut("Past week", [oneWeekAgo, today]),
    createShortcut("Past month", [oneMonthAgo, today]),
    createShortcut("Past 3 months", [threeMonthsAgo, today]),
    createShortcut("Past 6 months", [sixMonthsAgo, today]),
    createShortcut("Past year", [oneYearAgo, today]),
  ];
  if (isCustomerDashboard === true) {
    return [
      ...shortcuts,
      createShortcut("YTD", [ytd, today]),
      createShortcut("Past 2 years", [twoYearsAgo, today]),
    ];
  } else {
    return [
      ...shortcuts,
      createShortcut("Past 2 years", [twoYearsAgo, today]),
      createShortcut("Past 10 years", [tenYearsAgo, today]),
    ];
  }
};

const convertObjectTOCSV = (staticHeaders, obj) => {
  const headers = staticHeaders.map((d) => d.title).join(",");
  const row = staticHeaders
    .map((headerVal) =>
      obj[headerVal.dataIndex]
        ? `"${obj[headerVal.dataIndex].toString().replace(/"/g, '""')}"`
        : null
    )
    .join(",");
  return [headers, row].join("\n");
};

function convertArrayToCSV(staticHeaders, arr) {
  // Extract headers
  const headers = staticHeaders.map((d) => d.title).join(",");

  // Map each object to a CSV row
  const rows = arr.map((obj) =>
    staticHeaders
      .map((headerVal) =>
        obj[headerVal.dataIndex]
          ? `"${obj[headerVal.dataIndex].toString().replace(/"/g, '""')}"`
          : null
      )
      .join(",")
  );
  // Combine headers and rows
  return [headers, ...rows].join("\n");
}

function downloadCSV(csvString, filename = "data.csv") {
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", filename);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const deepClone = (data) => JSON.parse(JSON.stringify(data));

const successToaster = (message) =>
  Toaster.show({
    message: message,
    icon: "small-tick",
    intent: "success",
  });

const errorToaster = (message) =>
  Toaster.show({
    message: message,
    icon: "warning-sign",
    intent: "danger",
  });

const parseFusionResponse = (fusionResponse) => {
  try {
    let response = fusionResponse || "{}";
    response = response
      .replace(/'/g, '"')
      .replace(/\b(True|False|None)\b/g, (match) => {
        switch (match) {
          case "True":
            return "true";
          case "False":
            return "false";
          case "None":
            return "null";
          default:
            return match;
        }
      });

    return JSON.stringify(JSON.parse(response), null, 2);
  } catch (error) {
    console.error("Error parsing fusion response:", error);
    return JSON.stringify({ error: "Failed to parse fusion response" });
  }
};

const formatDateReplaceDash = (dateString) => {
  if (!dateString) {
    return ""; // Return an empty string
  }
  return dateString.replace(/-/g, "/");
};

const getExcelRow = (parsedData, fusionID, tradeID, externalReference) => {
  return {
    "Trade Sr No": externalReference,
    "Trade Number": fusionID,
    "Import Status": "",
    "External Reference": externalReference || "",
    "Trade Status": parsedData.Trade_Status || "",
    "Trade Date": formatDateReplaceDash(parsedData.Trade_Date) || "",
    "Buy / Sell": parsedData.Buy_Sell || "",
    Commodity: parsedData.Commodity || "",
    "Trade Type": parsedData.Trade_Type || "",
    Cleared: parsedData.Cleared ? "True" : "False",
    Quote: parsedData.Quote || "",
    Strategy: parsedData.Strategy || "",
    Counterpart: parsedData.Counterpart_Ref || "",
    "Internal Company": parsedData.Internal_Company || "",
    Quantity: parsedData.Quantity || "",
    "Qty UOM": parsedData.Qty_UOM || "",
    Price: parsedData.Price || "",
    "Price UOM": parsedData.Price_UOM || "",
    "Price Ccy": parsedData.Price_Ccy || "",
    "Pricing Term": parsedData.Pricing_Term || "",
    "From Date": formatDateReplaceDash(parsedData.From_Date) || "",
    "To Date": formatDateReplaceDash(parsedData.To_Date) || "",
    "Settlement Ccy": parsedData.Settlement_Ccy || "",
    "Settlement UOM": parsedData.Settlement_UOM || "",
    "Payment Due": parsedData.Payment_Due || "",
    "Payment Due Date":
      formatDateReplaceDash(parsedData.Payment_Due_Date) || "",
    "Internal Strategy": parsedData.Internal_Strategy || "",
    "Inter Company": parsedData.Inter_Company || "",
    Trader: parsedData.Trader || "",
    Note: parsedData.Note || "",
    "Internal Reference": parsedData.Internal_Reference || "",
    "Counterpart Ref": parsedData.Counterpart_Ref || "",
    "Contact Person": parsedData.Contact_Person || "",
    "Standard Formula Code": parsedData.Standard_Formula || "",
    Periodicity: parsedData.Periodicity || "",
    Schedule: parsedData.Schedule || "",
    NexTrade: "TRUE",
    "Business Unit": parsedData.Business_Unit || "",
    "Exchange Id": parsedData.Exchange_Id || "",
    "Broker Ref": parsedData.Broker_Ref || "",
    "Deal Classification": parsedData.Deal_Classification || "",
    Venture: parsedData.Venture || "",
    Lot: parsedData.Lot || "",
    "Fixed Price": parsedData.Fixed_Price || "",
    Location: parsedData.Location || "",
    Broker: parsedData.Broker || "",
    Type: parsedData.Type || "",
    Fee: parsedData.Fee || "",
    "Fee Ccy": parsedData.Fee_Ccy || "",
    "Fee UOM": parsedData.Fee_UOM || "",
    Account: parsedData.Account || "",
    "Cost Matrix": parsedData.Cost_Matrix || "",
    "Execution Broker": parsedData.Execution_Broker || "",
    "Market Exchange": parsedData.Market_Exchange || "",
    "Rate Type": parsedData.Rate_Type || "",
    "Fixed Rate": parsedData.Fixed_Rate || "",
    "FX Quoting Rule": parsedData.FX_Quoting_Rule || "",
    "Internal Ref 1": parsedData.Internal_Ref_1 || "",
    "Contract Size": parsedData.Contract_Size || "",
    "Payment Term": parsedData.Payment_Term || "",
    "Cash Broker": parsedData.Cash_Broker || "",
    "Cash Broker Type": parsedData.Cash_Broker_Type || "",
    "Cash Broker Account": parsedData.Cash_Broker_Account || "",
    "Cash Broker Price": parsedData.Cash_Broker_Price || "",
    "Cash Broker Currency": parsedData.Cash_Broker_Currency || "",
    "Cash Broker UOM": parsedData.Cash_Broker_UOM || "",
    "Internal Business Unit": parsedData.Internal_Business_Unit || "",
    "Trade Sub Status": parsedData.Trade_Sub_Status || "",
    Agreement: parsedData.Agreement || "",
    "STP Trade": parsedData.STP_Trade ? "Yes" : "No",
    Contract: parsedData.Contract || "",
    "Fx Rate Set": parsedData.Fx_Rate_Set || "",
    "Fx Rate Term": parsedData.Fx_Rate_Term || "",
    "Fx Start Date": formatDateReplaceDash(parsedData.Fx_Start_Date) || "",
    "Fx End Date": formatDateReplaceDash(parsedData.Fx_End_Date) || "",
    "Pre Output Precision": parsedData.Pre_Output_Precision || "",
    "Pre Output Treatment": parsedData.Pre_Output_Treatment || "",
    "Output Treatment": parsedData.Output_Treatment || "",
    "Output Precision": parsedData.Output_Precision || "",
    "Interest Rate Curve": parsedData.Interest_Rate_Curve || "",
    TAS: parsedData.TAS || "",
  };
};

export {
  successToaster,
  errorToaster,
  capitalize,
  formatNumber,
  url,
  getCSRF,
  formatSort,
  hasPerm,
  has_no_permissions,
  hasGroup,
  isValidNumber,
  report,
  formatDate,
  getEnvironment,
  getErrorMessage,
  replaceOrderTypes,
  api,
  isTokenExpired,
  getTime,
  getTooltip,
  calculateDelta,
  getFontColorDelta,
  isValidPrice,
  getBestOffer,
  getLocalTime,
  getComponentHeight,
  parseDateWithoutTimezone,
  parseDateInTheTimezone,
  resetToMidnight,
  divLoader,
  getCustomShortcuts,
  getObjectWithMaxDate,
  ytd,
  tomorrow,
  renameInstrument,
  convertArrayToCSV,
  convertObjectTOCSV,
  downloadCSV,
  deepClone,
  validatePostponementTrades,
  getQuoteValidities,
  parseFusionResponse,
  getExcelRow,
};
