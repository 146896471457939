import { Link, useLocation } from "react-router-dom";

const NavLink = ({ to = [], subLink = '', children }) => {
  const location = useLocation();
  const active = location.pathname.includes(subLink)

  return (
    <li>
      <Link
        to={to}
        style={{
          textDecoration: "none",
          borderBottom: active ? "4px solid #e60d2e" : "4px solid transparent",
          paddingTop: "21px",
          paddingBottom: "21px",
          margin: 10,
        }}
      >
        {children}
      </Link>
    </li>
  );
};

export default NavLink;
