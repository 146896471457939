import {
  DatePicker as BDatePicker,
  IDatePickerProps as BPIDatePickerProps,
  IDatePickerShortcut,
} from '@blueprintjs/datetime';
import React from 'react';
import '../../../assets/scss/core.scss';

export interface IDatePickerProps extends BPIDatePickerProps {
  showActionsBar?: boolean;
  todayButtonText?: string;
  clearButtonText?: string;
  highlightCurrentDay?: boolean;
  shortcuts?: boolean | IDatePickerShortcut[];
}

export const DatePicker: React.FC<IDatePickerProps> = (props) => {
  const dayPickerProp = {
    fixedWeeks: true,
  };
  return <BDatePicker dayPickerProps={dayPickerProp} {...props} />;
};
