import React, {useState, useEffect} from 'react';
import {Progress} from "antd";
import { ClockCircleFilled } from '@ant-design/icons';

function RequestTimer({validUntil, validity, createdOn, showProgress=false, status}) {
  let expiryDate = new Date(createdOn);
  if(validity === '15 min') {
    expiryDate.setMinutes( expiryDate.getMinutes() + 15 );
  }else if(validity === '30 min') {
    expiryDate.setMinutes( expiryDate.getMinutes() + 30 );
  }else if(validity === '1 hr'){
    expiryDate.setHours( expiryDate.getHours() + 1 );
  }else if(validity === '3 hr'){
    expiryDate.setHours( expiryDate.getHours() + 3 );
  }else {
    expiryDate = new Date(validUntil)
  }

  const calculateTimeLeft = () => {
    const difference = expiryDate - new Date();

    if (difference > 0) {
      return {
        hours: Math.floor((difference / (1000 * 60 * 60))),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }
  }
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

  useEffect(() => {
    if(expiryDate > new Date()){
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  return status === "Generated" ?
    <span style={{textAlign: 'center'}}>
      {showProgress ? <Progress type="circle" percent={expiryDate > new Date() ? (expiryDate-new Date())/(expiryDate-new Date(createdOn))*100 : 0.1}
                strokeColor={'#0091B5'} width={100}
                format={() => <div style={{fontSize: '16px'}}>
                  {expiryDate > new Date() ? [timeLeft.hours, timeLeft.minutes, timeLeft.seconds].map(number=> number<10?'0'+number:number).join(':') : '00:00:00'}s
                </div>}
      /> :
        <><ClockCircleFilled /> <span>{timeLeft ? [timeLeft.hours, timeLeft.minutes, timeLeft.seconds].map(number=> number<10?'0'+number:number).join(':') : '00:00:00'}s</span></>}
    </span> : null
}

export default RequestTimer;