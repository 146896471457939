import React from "react";
import GeneralTable from "./GeneralTable";
import {useSTPMappingSystemColumnValues} from "./tableColumns";

const STPMappingSystemColumnValuesTable = ({data, loading}) => {
    const columns = useSTPMappingSystemColumnValues()
    return <GeneralTable
        emptyText="No Values for this Column"
        columns={columns}
        loading={loading}
        data={data}
    />

}
export default STPMappingSystemColumnValuesTable;