import useAxios from "axios-hooks";
import {Button, Dialog, TextArea} from "rt-design-system-backup";
import Toaster from "../Toaster";
import {formatDate, getCSRF, getErrorMessage, replaceOrderTypes, report, url} from "../../helpers";
import {FormFooter} from "../Form";
import SimpleTable from "../SimpleTable";
import {useState} from "react";

const RequestCancelModal = ({ isOpen, onClose, requestId, requestType='', requestSalesEntity='', requestCreatedOn=''}) => {
  const [confirmation, setConfirmation] = useState(false);
  const [comments, setComments] = useState('');

  const [{ loading }, cancelRequest] = useAxios(
    {
      url: url("request/" + requestId + "/"),
      method: "DELETE",
      headers: {
        "X-CSRFToken": getCSRF(),
      },
    },
    { manual: true }
  );

  const submit = async () => {
    try {
      await cancelRequest({data: {reason: comments}})

      report("request_cancel_confirmed", {
        request_id: requestId,
      });

      Toaster.show({
        message: "Request successfully cancelled. ",
        icon: "small-tick",
        intent: "success",
      });
    } catch (err) {
      if(err.message){
        report("request_cancel_failed");

        Toaster.show({
          message:
            "An error occurred while cancelling the request. " +
            getErrorMessage(err),
          icon: "warning-sign",
          intent: "danger",
        });
      }else{
        report("request_cancel_confirmed", {
        request_id: requestId,
        });

        Toaster.show({
          message: "Request successfully cancelled. ",
          icon: "small-tick",
          intent: "success",
        });
      }
    }

    onClose && onClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      hasBackdrop={true}
      canEscapeKeyClose
      canOutsideClickClose
      onClose={onClose}
      onClosing={() => {setConfirmation(false); setComments('')}}
      title={"Cancel request #" + requestId}
      style={{ maxWidth: "548px", width: "100%" }}
    >
      <div style={{ padding: "0 32px 0 32px" }}>
        {confirmation ?
          <p
            style={{
              color: "#706F6F",
              fontSize: "16px",
              lineHeight: "32px",
              marginTop: "16px",
            }}
          >
            Please input reason for cancellation:
          </p> :
            <p
            style={{
              color: "#706F6F",
              fontSize: "16px",
              lineHeight: "32px",
              marginTop: "16px",
            }}
          >
            Are you sure you want to cancel this request?
          </p>
        }
        <br />
        {confirmation ?
            <TextArea name="comments" style={{width: '655px'}} growVertically={true}
                      onChange={(e) => setComments(e.target.value)}
                      defaultValue={comments} />:
            <SimpleTable style={{ flex: "0 0 auto", fontSize: '16px' }}>
            <tr>
              <td>Type:</td>
              <td><b>{replaceOrderTypes(requestType)}</b></td>
            </tr>
            <tr>
              <td>Sales entity:</td>
              <td><b>{requestSalesEntity}</b></td>
            </tr>
            <tr>
              <td>Created on:</td>
              <td><b>{formatDate(requestCreatedOn)}</b></td>
            </tr>
          </SimpleTable>
        }
        <FormFooter>
          <Button onClick={onClose} text="Keep request" minimal />
          {confirmation ?
            <Button
              loading={loading}
              onClick={submit}
              disabled={comments.length === 0}
              text="Cancel request"
              intent="danger"
            />:
              <Button
              loading={loading}
              onClick={() => setConfirmation(true)}
              text="Yes, input reason"
              intent="danger"
            />
          }
        </FormFooter>
      </div>
    </Dialog>
  );
};

export default RequestCancelModal;
