import React, {useState, useContext, useEffect} from "react"

import {Button, Tooltip, Icon, DateRangeInput, Dialog } from "rt-design-system-backup"
import Page from "../../Page"
import SortContext, { defaultSortContext } from "../../SortContext"
import AppContext from "../../AppContext"

import {formatSort, url, hasPerm, report, hasGroup} from "../../../helpers"
import axios from "axios";
import FileDownload from "js-file-download";
import {Link, useHistory} from "react-router-dom";
import CoppersTable from "./CoppersTable";
import Toaster from "../../Toaster";

const Coppers = () => {
    const history = useHistory();
    const { user } = useContext(AppContext)

    const sortContext = { ...defaultSortContext, startDate: defaultSortContext.startDate }

    const [sortState, setSortState] = useState(sortContext)
    const [appliedSortState, setAppliedSortState] = useState(sortContext)
    const [isOpen, setIsOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const downloadCsv = (e) => {
        e.preventDefault()
        report('orders_download')
        axios({
            url: url("/ordercsv/" + formatSort(appliedSortState)),
            method: 'GET',
            responseType: 'blob', // Important
        }).then((response) => {
            FileDownload(response.data, 'orders.csv');
        });
    }

    const handleNewRange = ([startDate, endDate]) => {
        setSortState({
            ...sortState,
            startDate,
            endDate
        })
    }

    useEffect(() => {
        setAppliedSortState(sortState)
    }, [sortState]);

    const handleClose = () => {setIsOpen(false)}

    const handleFileChange = (event) => {
        setFile(event.target.files[0])
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        if (file === null) {
            Toaster.show({
                message: "Please select a file first",
                icon: "warning-sign",
                intent: "danger",
            });
            return;
        }
        const formData = new FormData();
        formData.append("file", file);
        setLoading(true);
        try {
          await axios({
            method: "post",
            url: url("/copper/bulk_upload_data/"),
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          });
          setLoading(false);
          Toaster.show({
            message: "File uploaded successfully",
            icon: "small-tick",
            intent: "success",
          });
          setIsOpen(false);
          //window.location.reload();
        } catch(error) {
          console.log(error)
          setLoading(false);
          Toaster.show({
            message: error.response.data.detail,
            icon: "warning-sign",
            intent: "danger",
          });
        }
    }

    return <SortContext.Provider value={appliedSortState}>
        <Page title="Orders" tabs={[]}
            leftActions={
                hasPerm(user, 'rtcmdmodels.add_copper') ? [
                    <Button onClick={() => history.push('/copper/new/')} intent="primary" text="New order" />
                ] : []
            }

            rightActions={
                [
                    <span><DateRangeInput
                        singleMonthOnly
                        allowSingleDayRange {...{
                            formatDate: (date) => (date == null ? '' : new Date(new Date(new Date(date.setHours(1)).setMinutes(0)).setSeconds(0)).toLocaleDateString(navigator.language)),
                            parseDate: (str) => new Date(Date.parse(str)),
                        }} popoverProps={{
                            minimal: true
                        }} value={[sortState.startDate, sortState.endDate]} onChange={handleNewRange} /></span>,
                    <Button intent="primary" text="Upload orders" onClick={() => setIsOpen(true)}/>,
                    <Tooltip intent="primary" content="Download Orders">
                        {true ? null : <a onClick={downloadCsv} href="#0" download>
                            <span className="rtcmd-page-action-icon">
                                <Icon icon="arrow-down"/>
                            </span>
                        </a>}
                    </Tooltip>,
                    <Link to={"/coppers/trades/"}>
                        <span style={{ fontSize: '16px', fontWeight: 'normal', marginLeft: 12 }}><u>View trades</u></span>
                    </Link>
                ]
            }
        >
            <CoppersTable />
            {hasGroup(user, "RT Copper Desk") ? <CoppersTable get_pending_tbc={true}/> : null}
            <Dialog
              isOpen={isOpen}
              onClose={handleClose}
              hasBackdrop={true}
              canEscapeKeyClose
              canOutsideClickClose
              title={"Upload orders"}
              style={{ maxWidth: "548px", width: "100%" }}
            >
                <div style={{ padding: "0 32px 0 32px" }}>
                    <input type="file" required={true} accept=".csv" multiple={false} onChange={handleFileChange} style={{marginTop: 20, marginBottom: 10}}/>
                    <div style={{justifyContent: 'flex-end', display: 'flex'}}>
                      <Button intent="danger" onClick={handleClose}>Cancel</Button>
                      <Button intent="success" onClick={handleSubmit} loading={loading} style={{marginLeft: 10}}>Upload</Button>
                    </div>
                </div>
            </Dialog>
        </Page>
    </SortContext.Provider>
}

export default Coppers
