import React, { useState, useContext, useEffect } from "react";
import useAxios from "axios-hooks";
import { Link } from "react-router-dom";


import Highlighter from "react-highlight-words";
import { Button, Spinner, Icon } from "rt-design-system-backup";

import { SearchOutlined } from "@ant-design/icons";
import { Input, Space, Table } from "antd";
import { InputGroup } from "../../../../Form";


import { renderItem } from "../../../../OrdersTable";
import { css } from "@emotion/css";
import { hasPerm, formatDate, url, getFontColorDelta } from "../../../../../helpers";
import { dateFormat } from "../../../Maturities";
import AppContext from "../../../../AppContext";
import { formatNumber } from "../../utils";
//import { PriceTable } from "../../../../../PriceTable/Base";


const tableCSS = css({
    '.ant-table-content .bp3-icon svg': {
        width: '20px',
        height: '20px',
    }
});

const ActionsRenderer = ({ value }) => {
  return (
    <div style={{ whiteSpace: "nowrap" }}>
      <Link to={"/aluminium/request/" + value + "/details"} target="_blank">
        <Icon iconSize={20} icon="eye-open" />
      </Link>
    </div>
  )
}

const CustomerTable = ({start_date, end_date, customer_id, customers, monthType}) => {
    const [{ data: requests, loading }, refetch] = useAxios({
      url: url(`auto-quote/?start_date=${dateFormat.formatDate(start_date)}&end_date=${dateFormat.formatDate(end_date)}${customer_id !== -1 ? '&customer=' + customer_id : ''}&month_type=${monthType}`),
  });
    const { user } = useContext(AppContext);
    const initial_search_text = localStorage.getItem('filter_id');
    const initial_filters = {}
    if (user.sales_entity && hasPerm(user, "rtcmdmodels.add_request")) {
        initial_filters['sales_entity'] = [user.sales_entity.name]
    }

    if (hasPerm(user, "rtcmdmodels.reconcile_filled_request")) {
        initial_filters['status'] = ['Filled']
    }

    if (hasPerm(user, "rtcmdmodels.fill_open_request")) {
        initial_filters['status'] = ['Filled', 'Open', 'For correction']
    }
    
    const [filteredInfo, setFilterInfo] = useState(initial_filters);
    const [searchText, setSearchText] = useState(initial_search_text || "initState");
    
    const filter_keys = ['customer', 'delivery_months', 'id', 'requestor', 'sales_entity', 'status', 'type']

    if (filter_keys.some(x => localStorage.getItem(`filter_${x}`) !== null)) {
        filter_keys.forEach(filter_key => {
        const value = localStorage.getItem(`filter_${filter_key}`)
        if (value === '-') {
            delete initial_filters[filter_key]
        } else if (value) {
            initial_filters[filter_key] = value.split(',')
        }
        });
    }

    useEffect(()=>{
      if (!monthType || !customer_id || !start_date || !end_date) return 
      refetch()
      // eslint-disable-next-line
    }, [start_date, end_date, customer_id, monthType])

    const handleChange = (pagination, filters, sorter) => {
        setFilterInfo(filters);

        Object.keys(filters).forEach(filter => {
        if (filters[filter]) {
            localStorage.setItem(`filter_${filter}`, filters[filter].join())
        } else {
            localStorage.setItem(`filter_${filter}`, '-')
        }
        })
    };

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };
    
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    function parseTuple(t) {

      if( t != null){
        if (typeof(t) === "string"){
          const parsedtuple = JSON.parse("[" + t.replace(/\(/g, "[").replace(/\)/g, "]") + "]");
          return parsedtuple[0]
        }
        else{
          return t
        }
      }else{
        return (0,0)
      }
    }
    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div style={{ padding: 8 }}>
                <Input
                  placeholder={`Search ID`}
                  value={selectedKeys[0]}
                  onChange={(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  onPressEnter={() => handleSearch(selectedKeys, confirm)}
                  style={{ width: 160, marginBottom: 8, display: "block" }}
                />
                <Space size={"large"}>
                  <Button
                    intent="secondary"
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ minHeight: "24px", minWidth: 60 }}
                  >
                    Reset
                  </Button>
                  <Button
                    intent="primary"
                    icon={<SearchOutlined />}
                    onClick={() => handleSearch(selectedKeys, confirm)}
                    size="small"
                    style={{ minHeight: "24px", minWidth: 60 }}
                  >
                    Search
                  </Button>
                </Space>
              </div>
            ),
            filterIcon: (filtered) => (
              <SearchOutlined
                style={{ color: filtered ? "#1890ff" : "#8e8e8e", fontSize: "16px" }}
              />
            ),
            onFilter: (value, record) =>
              record.id.toString().includes(value.toString()),
            filteredValue: filteredInfo.id || null,
            render: (text) => (
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ""}
              />
            ),
            fixed: "left",
        },
        {
            title: "Created on",
            dataIndex: "date",
            sorter: (a, b) => {
              const fromDate = new Date(b.date);
              const toDate = new Date(a.date);
      
              return fromDate.getTime() - toDate.getTime();
            },
            render: (dateString) => {
              return (
                <div style={{ whiteSpace: "nowrap" }}>{formatDate(dateString)}</div>
              );
            },
            sortDirections: ["descend", "ascend"],
        },
        {
            title: "Sales Entity",
            dataIndex: "sales_entity",
            sorter: Array.sort,
            filters: requests
              ? requests
                .map((item) => item.sales_entity.name)
                .filter(
                  (elem, pos) =>
                    elem &&
                    requests.map((item) => item.sales_entity.name).indexOf(elem) ===
                    pos
                )
                .sort()
                .map((value) => ({
                  text: value,
                  value: value,
                }))
              : [],
            onFilter: (value, record) => {
              return record.sales_entity === value;
            },
            filteredValue: filteredInfo.sales_entity || null,
            render: (value) => {
              return <div style={{ whiteSpace: "nowrap" }}>{value}</div>;
            },
        },
        {
            title: "Customer/Supplier",
            dataIndex: "customer",
            sorter: Array.sort,
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div style={{minWidth: '100%', width: '100%'}}>
                <InputGroup style={{ padding: 8, minWidth: 800, zIndex: 3 }}
                  required
                  name="customer"
                  type="select"
      
                  value={selectedKeys[0]}
                  onChange={(e) => {
                    if (e[0].name === 'CLEAR FILTER') {
                      handleReset(clearFilters)
                      setSelectedKeys('')
                      window.location.reload()
                    } else {
                      setSelectedKeys(e[0].name ? [e[0].name] : [])
                      handleSearch(selectedKeys, confirm)
                    }
                  }}
      
                  items={customers ? [{name: 'CLEAR FILTER', id: 0}, ...customers.map((item) => {
                    return {
                      name: item.display_name,
                      value: item.id,
                    };
                  }).sort((a, b) => a.name.localeCompare(b.name))] : []}
                />
              </div>
            ),
            filterIcon: (filtered) => (
              <SearchOutlined
                style={{ color: filtered ? "#1890ff" : "#8e8e8e", fontSize: "16px" }}
              />
            ),
            onFilter: (value, record) => record.customer.toLowerCase().includes(value.toLowerCase()),
            filteredValue: filteredInfo.customer || null,
            render: (text) => (
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text || ""}
              />
            ),
        },
        {
          title: "Delivery",
          dataIndex: "delivery_months",
          sorter: Array.sort,
          filters: requests
            ? requests
              .map((item) => item.delivery_months)
              .filter(
                (elem, pos) =>
                  elem &&
                  requests.map((item) => item.delivery_months).indexOf(elem) ===
                  pos
              )
              .sort()
              .map((value) => ({
                text: value,
                value: value,
              }))
            : [],
          onFilter: (value, record) => record.delivery_months === value,
          filteredValue: filteredInfo.delivery_months || null,
          render: (value) => {
            return <div style={{ whiteSpace: "nowrap" }}>{value}</div>;
          },
        },
        {
            title: "Total qty (mt)",
            dataIndex: "total_quantity",
            sorter: (a, b) => {
              return a.total_quantity - b.total_quantity;
            },
            sortDirections: ["descend", "ascend"],
            render: (value) => {
              return (
                <div style={{ whiteSpace: "nowrap" }}>
                  {value}
                </div>
              );
            },
        },
        {
            title: "Requestor",
            dataIndex: "requestor",
            sorter: Array.sort,
            filters: requests
              ? requests
                .map((item) => item.requestor.full_name)
                .filter(
                  (elem, pos) =>
                    elem &&
                    requests
                      .map((item) => item.requestor.full_name)
                      .indexOf(elem) === pos
                )
                .sort()
                .map((value) => ({
                  text: value,
                  value: value,
                }))
              : [],
            onFilter: (value, record) => record.requestor === value,
            filteredValue: filteredInfo.requestor || null,
            render: (value) => {
              return <div style={{ whiteSpace: "nowrap" }}>{value}</div>;
            },
        },
        {
            title: "LME Basis 3M ($/mt)",
            dataIndex: "lme_basis",
            sorter: (a, b) => {
              return a.lme_basis - b.lme_basis;
            },
            sortDirections: ["descend", "ascend"],
            render: (value) => {
              return (
                <div style={{ whiteSpace: "nowrap" }}>
                  {value ? formatNumber(value, true, 2) : "-"}
                </div>
              );
            },
        },
        {
            title: "LME Basis 3M END ($/mt)",
            dataIndex: "lme_basis_end",
            sorter: (a, b) => {
              return a.lme_basis_end - b.lme_basis_end;
            },
            sortDirections: ["descend", "ascend"],
            render: (value) => {
              return (
                <div style={{ whiteSpace: "nowrap" }}>
                  {value ? formatNumber(value, false, 2) : "-"}
                </div>
              );
            },
        },
        {
          title: "Market Pnl",
          dataIndex: "market_pnl",
          sorter: (a, b) => {
            return a.market_pnl - b.market_pnl;
          },
          sortDirections: ["descend", "ascend"],
          render: (value) => {
            return (
              <div style={{ whiteSpace: "nowrap", color: getFontColorDelta(value) }}>
                {value ? '$ ' + formatNumber(value, false, 0) : "-"}
              </div>
            );
          },
      },
        {
            title: "Premium Pnl",
            dataIndex: "premium_pnl",
            sorter: (a, b) => {
              return parseTuple(a.premium_pnl)[1] - parseTuple(b.premium_pnl)[1] ;
            },
            sortDirections: ["descend", "ascend"],
            render: (value) => {
              return (
                <div style={{ whiteSpace: "nowrap", display: "flex" }}>
                  <span style={{minWidth: '40%', textAlign: 'right'}}>{value ? '$ ' + formatNumber(parseTuple(value)[0], true, 0) : "-"}&nbsp;&nbsp;</span>
                  <span style={{color: getFontColorDelta(parseTuple(value)[1]), minWidth: '60%', textAlign: 'right'}}>{value && parseTuple(value)[1] && parseTuple(value)[1] !== 0 ? '$ ' + formatNumber(parseTuple(value)[1], true, 0) : "-"}</span>
                </div>
              );
            },
        },
        {
            title: "LME Alu Tier Pnl",
            dataIndex: "alu_tier_customer_pnl",
            sorter: (a, b) => {
              return parseTuple(a.alu_tier_customer_pnl)[1] - parseTuple(b.alu_tier_customer_pnl)[1];
            },
            sortDirections: ["descend", "ascend"],
            align: 'right',
            render: (value) => {
              return (
                <div style={{ whiteSpace: "nowrap" }}>
                  <span style={{minWidth: '40%', textAlign: 'right'}}>{value && parseTuple(value)[0] !== 0 ? ('$ ' + formatNumber(parseTuple(value)[0], true, 0)) : "-"}&nbsp;&nbsp;</span>
                  <span style={{color: getFontColorDelta(parseTuple(value)[1])}}>{value && parseTuple(value)[1] && parseTuple(value)[1] !== 0  ? '$ ' + formatNumber(parseTuple(value)[1], true, 0) : "-"}</span>
                </div>
              );
            },
        },
        {
            title: "MWP Tier Pnl",
            dataIndex: "tier_customer_market_premium_pnl",
            sorter: (a, b) => {
              return parseTuple(a.tier_customer_market_premium_pnl)[1] - parseTuple(b.tier_customer_market_premium_pnl)[1];
            },
            sortDirections: ["descend", "ascend"],
            align: 'right',
            render: (value) => {
              return (
                <div style={{ whiteSpace: "nowrap" }}>
                  <span>{value && parseTuple(value)[0] !== 0 ? ('$ ' + formatNumber(parseTuple(value)[0], true, 0)) : "-"}&nbsp;&nbsp;</span>
                  <span style={{color: getFontColorDelta(parseTuple(value)[1])}}>{value && parseTuple(value)[1] && parseTuple(value)[1] !== 0  ? '$ ' + formatNumber(parseTuple(value)[1], true, 0) : "-"}</span>
                </div>
              );
            },
        },
        {
            title: "TTL Pnl",
            dataIndex: "ttl_pnl",
            sorter: (a, b) => {
              return a.ttl_pnl - b.ttl_pnl;
            },
            sortDirections: ["descend", "ascend"],
            render: (value) => {
              return (
                <div style={{ whiteSpace: "nowrap", color: getFontColorDelta(value) }}>
                  {value ? '$ ' + formatNumber(value, false, 0) : "-"}
                </div>
              );
            },
        },
        {
          title: "Actions",
          dataIndex: "id",
          sorter: Array.sort,
          render: (value) => {
            return <ActionsRenderer value={value} />;
          },
        },
    ]
    
    return (
      <Table
        className={tableCSS}
        locale={{
          emptyText: "No requests to display",
        }}
        loading={
          loading && !requests
            ? {
              delay: 1500,
              indicator: <Spinner size={32} />,
            }
            : false
        }
        scroll={{
          x: requests && requests.length > 0,
        }}
        size="small"
        expandIconColumnIndex={13}
        columns={columns}
        dataSource={
          requests
            ? requests
              .sort((a, b) => {
                return b.id - a.id;
              })
              .map((request) => {
                return {
                  ...request,
                  key: request.id,
                  type: request.request_type,
                  date: request.created_at,
                  sales_entity: request.sales_entity.name,
                  customer: request.customer ? request.customer.display_name : '',
                  delivery_months: request.delivery_months,
                  lme_basis: request.lme_basis,
                  lme_basis_end: request.lme_basis_end,
                  requested_price: request.requested_price,
                  requestor: request.requestor
                    ? request.requestor.full_name
                    : "",
                  rawRequest: request,
                  ttl_pnl: request.total_pnl,
                  volatility_pnl: request.volatility_delay_pnl,
                  tier_pnl: request.tier_customer_pnl,
                  premium_pnl: request.validity_premium_pnl,

                };
              })
            : []
        }
        tableLayout="auto"
        pagination={{
          pageSize: 20,
          itemRender: renderItem,
          showSizeChanger: false,
        }}
        onChange={handleChange}
     />
    )
}
export default CustomerTable;
