import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Icon } from "rt-design-system-backup";
import useAxios from "axios-hooks";
import Page from "../../Page";
import MappingOverview from "./MappingOverview";
import { singleMappingTransformer } from "../STPMapping";
import { url } from "../../../helpers";
import RuleHistory from "./RuleHistory";

// EditRequestButton is a functional component to create an edit button.
// It accepts a URL 'href', an optional 'showIcon' flag, and a 'style' object.
export const EditRequestButton = ({ href, showIcon = false, style = {} }) => {
  return (
    <a style={{ textDecoration: "none" }} href={href}>
      {showIcon ? (
        <Icon icon={"edit"} iconSize={20} style={style} />
      ) : (
        <Button icon={"edit"} large outlined style={{ marginRight: 10 }}>
          Edit request
        </Button>
      )}
    </a>
  );
};

// MappingDetails is the main component to display details of a specific mapping.
// It fetches the mapping details based on the mapping ID obtained from the URL parameters.
// The component also fetches necessary data for the system list and NextTrade Fields list to populate form dropdowns.
// The component also renders an edit button to redirect to the edit page.
const MappingDetails = () => {
  const { mappingId } = useParams();
  const history = useHistory();
  const [{ data, loading }, refetch] = useAxios({
    url: url("mapping_rules/" + mappingId + "/"),
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapping = data && !loading && singleMappingTransformer(data);
  if (mapping && mapping["SystemColumnId"]) {
    delete mapping["SystemColumnId"];
  }
  if (mapping && mapping["SpecificSystemColumnValue"]) {
    delete mapping["SpecificSystemColumnValue"];
  }

  if (loading) {
    return <div>Loading</div>;
  }

  return (
    <div style={{ paddingBottom: "32px" }}>
      <Button
        icon="chevron-left"
        minimal
        style={{ fontSize: "16px", lineHeight: "24px" }}
        onClick={() => {
          history.goBack();
        }}
      >
        All Mappings
      </Button>
      <Page
        title={"Mapping details"}
        tabs={[]}
        rightActions={
          <Button
            icon={"edit"}
            large
            outlined
            onClick={() =>
              history.push(`/stp-mapping-console/update/${mapping.RuleID}`)
            }
          >
            Edit Mapping
          </Button>
        }
        mobileActions={[]}
      >
        <hr />
        <MappingOverview data={mapping} />
        <hr />
        {data?.history && <RuleHistory request={data} />}
      </Page>
    </div>
  );
};

export default MappingDetails;
