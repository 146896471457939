import { useState, useRef, useEffect, useContext } from "react";
import { Tooltip } from "rt-design-system-backup";

import { FillRequestContext } from "../Views/FillRequest";

import "./style.scss";

const TableInput = ({
  isInvalid,
  enabled,
  value,
  onChange,
  formatContents,
  tabbable,
  id,
  key,
  onBlurCall,
  required,
  style={},
  disabled=false,
  focused=false,
  divStyle={},
}) => {
  const {
    enabled: fillRequestContextEnabled,
    invalidate,
    deregister,
    validate,
  } = useContext(FillRequestContext);

  const [currentValue, setCurrentValue] = useState(value || "");

  // Whether to show the HTML input or a replacement DIV
  const [isEditing, setIsEditing] = useState(focused);

  // Field invalidity status
  const [isFieldInvalid, setIsFieldInvalid] = useState(false);

  // Create ref for the actual input html element
  const inputRef = useRef();

  // Focus the input when the isEditing state changes
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, isEditing]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    return () => {
      deregister(id);
    };
  }, []);

  useEffect(() => {
    if (fillRequestContextEnabled && enabled) {
      if (isFieldInvalid || (required && currentValue.length <= 0)) {
        invalidate && invalidate(id);
      } else {
        validate && validate(id);
      }
    } else if (fillRequestContextEnabled) {
      validate && validate(id);
    }
  }, [
    enabled,
    validate,
    invalidate,
    isFieldInvalid,
    fillRequestContextEnabled,
    id,
    required,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <div style={{ display: "inline-block" }}>
      <Tooltip
        intent="danger"
        content={isFieldInvalid}
        isOpen={isFieldInvalid && isEditing}
        position="bottom"
        openOnTargetFocus={false}
      >
        <div>
          <input
            style={{
              display: isEditing || (tabbable && enabled) ? "block" : "none",
              ...style
            }}
            disabled={disabled}
            key={key}
            ref={inputRef}
            defaultValue={value}
            className={
              "rtcmd-table-input" +
              (isFieldInvalid ? " invalid" : "") +
              (enabled ? " enabled" : "") +
              (disabled ? " disabled" : "")
            }
            onBlur={async (evt) => {
              const invalidReason = isInvalid(evt.target.value);

              if (invalidReason) {
                evt.target.value = evt.target.defaultValue;
              } else {
                onChange(evt.target.value);
              }

              setIsFieldInvalid(false);
              setIsEditing(false);
              if (onBlurCall) {
                onBlurCall(evt.target.value)
              }
            }}
            onFocus={() => {
              setIsEditing(true);
            }}
            onKeyUp={async (evt) => {
              setCurrentValue(evt.target.value);
              if (evt.key === "Tab") return;

              const invalidReason = isInvalid(evt.target.value);

              if (invalidReason) {
                setIsFieldInvalid(invalidReason);
                return;
              } else {
                setIsFieldInvalid(false);
              }

              if (evt.key === "Enter") {
                evt.target.blur();

                if (enabled) setIsEditing(false);
              }
            }}
          />
          <div
            style={{
              display: (!isEditing && !tabbable) || !enabled ? "block" : "none",
              ...divStyle
            }}
            className={"rtcmd-table-input" + (enabled ? " enabled" : "")}
            onClick={(e) => {
              e.preventDefault();

              if (enabled) setIsEditing(true);
            }}
          >
            {formatContents ? formatContents(value, true) : value}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};
export default TableInput;
