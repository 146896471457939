import { Table } from "rt-design-system-backup";

import { css } from '@emotion/css'
import React from "react";
import { numericRenderBuilder } from "../RequestMaturityPrices/columns";
import { capitalize } from "../../helpers";

const tableCSS = css({
  'thead > tr > th': {
    verticalAlign: 'top',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#000000',
  },
  'thead > tr > th:nth-child(5), thead > tr > th:nth-child(6)': {
    verticalAlign: 'top',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#000000',
    backgroundColor: '#b2b2b2',
  },
  'tbody > tr > td': {
    fontSize: '14px',
    lineHeight: '22px',
    color: '#000000',
  },
  'tbody > tr > td:nth-child(5), tbody > tr > td:nth-child(6)': {
    fontSize: '14px',
    lineHeight: '22px',
    color: '#000000',
    backgroundColor: '#eaeaea',
  }
});

const RequestPlantDetails = ({ request }) => {
  const columns = [
    {
      title: "Source Plant",
      dataIndex: "plant_details",
      render: capitalize,
    },
    {
      title: (
        <>
          Value
          <br />
          (mt)
        </>
      ),
      dataIndex: "value",
      render: numericRenderBuilder(2),
    },
  ];
  

  return (
    <>
      <h4>Source Plant</h4>
      <div style={{ position: "relative" }}>
        <div style={{ display: "inline-block", maxWidth: "100%" }}>
          <Table
            pagination={false}
            dataSource={request.plant_details}
            className={tableCSS}
            scroll={{
              x: true,
            }}
            size="small"
            columns={columns}
          />
        </div>
      </div>
    </>
  );
};

export default RequestPlantDetails;
