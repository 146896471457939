import React, { useState } from "react";
import useAxios from "axios-hooks";
import {
  capitalize,
  formatDate,
  formatNumber,
  report,
  url,
  renameInstrument,
} from "../../../helpers";
import { Input, Space, Table } from "antd";
import {
  Button,
  DateRangeInput,
  Icon,
  Spinner,
  Tooltip,
} from "rt-design-system-backup";
import Page from "../../Page";
import { renderItem } from "../../OrdersTable";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import axios from "axios";
import FileDownload from "js-file-download";

const parseValue = (value) => {
  return value !== 0 ? value || "-" : value;
};

export const dateFormat = {
  formatDate: (date) => {
    if (date === "null") return "";

    const y = "" + date.getFullYear();
    const m = "" + (date.getMonth() + 1);
    const d = "" + date.getDate();

    return d.padStart(2, "0") + "/" + m.padStart(2, "0") + "/" + y;
  },
  parseDate: (str) => {
    const [d, m, y] = str.split(",")[0].split("/");
    return new Date(`${m}/${d}/${y}`);
  }, //Date class always expects date in this format we need to adjust it.
};

function Maturities() {
  const today = new Date();
  const last_week = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 7,
  );
  const [startDate, setStartDate] = useState(last_week);
  const [endDate, setEndDate] = useState(new Date());

  const [{ data: trades, loading }] = useAxios(
    {
      url: url(
        `alu_trade/?start_date=${dateFormat.formatDate(
          startDate,
        )}&end_date=${dateFormat.formatDate(
          endDate,
        )}&filter_open_request=${1}&filter_empty_qt_id=${1}`,
      ),
    },
    [startDate, endDate],
  );

  const [filteredInfo, setFilterInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [csvDownloading, setCsvDownloading] = useState(false);

  const handleChange = (pagination, filters) => {
    setFilterInfo(filters);
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleDateChange = (data) => {
    if (data[0]) {
      setStartDate(data[0]);
    } else {
      setEndDate(new Date());
    }

    if (data[1]) {
      setEndDate(data[1]);
    } else {
      setEndDate(new Date());
    }
  };

  const downloadCsv = (e) => {
    e.preventDefault();
    setCsvDownloading(true);
    report("trades_download");
    axios({
      url: url(
        `alu_tradecsv/?start_date=${dateFormat.formatDate(
          startDate,
        )}&end_date=${dateFormat.formatDate(
          endDate,
        )}&filter_open_request=${1}&filter_empty_qt_id=${1}`,
      ),
      method: "GET",
      responseType: "blob", // Important
    }).then((response) => {
      setCsvDownloading(false);
      FileDownload(response.data, "alu_trades.csv");
    });
  };

  const columns = [
    {
      title: "Fusion ID",
      dataIndex: "qt_id",
      key: "qt_id",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ID`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 160, marginBottom: 8, display: "block" }}
          />
          <Space size={"large"}>
            <Button
              intent="secondary"
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ minHeight: "24px", minWidth: 60 }}
            >
              Reset
            </Button>
            <Button
              intent="primary"
              icon={<SearchOutlined />}
              onClick={() => handleSearch(selectedKeys, confirm)}
              size="small"
              style={{ minHeight: "24px", minWidth: 60 }}
            >
              Search
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? "#1890ff" : "#8e8e8e", fontSize: "16px" }}
        />
      ),
      onFilter: (value, record) =>
        record.qt_id.toString().includes(value.toString()),
      filteredValue: filteredInfo.qt_id || null,
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
      fixed: "left",
    },
    {
      title: "Created on",
      dataIndex: "created_on",
      key: "created_on",
      sorter: (a, b) => {
        const fromDate = new Date(b.created_on);
        const toDate = new Date(a.created_on);
        return fromDate.getTime() - toDate.getTime();
      },
      sortDirections: ["descend", "ascend"],
      render: (dateString) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>{formatDate(dateString)}</div>
        );
      },
    },
    {
      title: "Sales Entity",
      dataIndex: "sales_entity",
      key: "sales_entity",
      filters: trades
        ? trades
            .map((item) => item.sales_entity)
            .filter(
              (elem, pos) =>
                elem &&
                trades.map((item) => item.sales_entity).indexOf(elem) === pos,
            )
            .map((value) => ({
              text: value,
              value: value,
            }))
        : [],
      filteredValue: filteredInfo.sales_entity || null,
      onFilter: (value, record) => {
        return record.sales_entity === value;
      },
    },
    {
      title: "Customer/Supplier",
      dataIndex: "customer",
      key: "customer",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Customer/Supplier`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 160, marginBottom: 8, display: "block" }}
          />
          <Space size={"large"}>
            <Button
              intent="secondary"
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ minHeight: "24px", minWidth: 60 }}
            >
              Reset
            </Button>
            <Button
              intent="primary"
              icon={<SearchOutlined />}
              onClick={() => handleSearch(selectedKeys, confirm)}
              size="small"
              style={{ minHeight: "24px", minWidth: 60 }}
            >
              Search
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? "#1890ff" : "#8e8e8e", fontSize: "16px" }}
        />
      ),
      onFilter: (value, record) =>
        record.customer.toLowerCase().includes(value.toLowerCase()),
      filteredValue: filteredInfo.customer || null,
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text || ""}
        />
      ),
    },
    {
      title: "Instrument",
      dataIndex: "instrument",
      key: "instrument",
      filters: trades
        ? trades
            .map((item) => (item.instrument ? item.instrument.name : ""))
            .filter(
              (elem, pos) =>
                elem &&
                trades
                  .map((item) => (item.instrument ? item.instrument.name : ""))
                  .indexOf(elem) === pos,
            )
            .map((value) => ({
              text: renameInstrument(value),
              value: value,
            }))
        : [],
      filteredValue: filteredInfo.instrument || null,
      onFilter: (value, record) => {
        return record.instrument === value;
      },
      render: (value) => renameInstrument(value),
    },
    {
      title: "Maturity",
      dataIndex: "maturity",
      key: "maturity",
      filters: trades
        ? trades
            .sort(
              (a, b) => new Date(a.maturity_start) - new Date(b.maturity_start),
            )
            .map((item) => item.maturity)
            .filter(
              (elem, pos) =>
                elem &&
                trades.map((item) => item.maturity).indexOf(elem) === pos,
            )
            .map((value) => ({
              text: capitalize(value),
              value: capitalize(value),
            }))
        : [],
      filteredValue: filteredInfo.maturity || null,
      onFilter: (value, record) => {
        return record.maturity === value;
      },
    },
    {
      title: "Buy/Sell",
      dataIndex: "buy_sell",
      key: "buy_sell",
    },
    {
      title: "Price ($/mt)",
      dataIndex: "price",
      key: "price",
      render: (value) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {value !== 0 ? formatNumber(value, true, 2) : 0}
          </div>
        );
      },
    },
    {
      title: "Quantity (mt)",
      dataIndex: "quantity",
      key: "quantity",
      render: (value) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {value ? formatNumber(value, true, 2) : "-"}
          </div>
        );
      },
    },
    {
      title: "Ccy",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Exch. Rate",
      dataIndex: "exchange_rate",
      key: "exchange_rate",
      render: (value) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {value ? formatNumber(value, true, 4) : "-"}
          </div>
        );
      },
    },
    {
      title: "Price (Ccy)",
      dataIndex: "price_currency",
      key: "price_currency",
      render: (value) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {value ? formatNumber(value, true, 2) : "-"}
          </div>
        );
      },
    },
    {
      title: "Request ID",
      dataIndex: "request_id",
      key: "id",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Request ID`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 160, marginBottom: 8, display: "block" }}
          />
          <Space size={"large"}>
            <Button
              intent="secondary"
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ minHeight: "24px", minWidth: 60 }}
            >
              Reset
            </Button>
            <Button
              intent="primary"
              icon={<SearchOutlined />}
              onClick={() => handleSearch(selectedKeys, confirm)}
              size="small"
              style={{ minHeight: "24px", minWidth: 60 }}
            >
              Search
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? "#1890ff" : "#8e8e8e", fontSize: "16px" }}
        />
      ),
      onFilter: (value, record) =>
        record.id.toString().includes(value.toString()),
      filteredValue: filteredInfo.id || null,
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (value) => {
        return (
          <Link to={"/aluminium/request/" + value + "/details"}>
            <Icon size={20} icon="eye-open" />
          </Link>
        );
      },
    },
  ];

  return (
    <Page
      title="Trades"
      rightActions={[
        <span style={{ fontSize: "18px", fontWeight: "normal" }}>
          Viewing:
        </span>,
        <DateRangeInput
          singleMonthOnly
          allowSingleDayRange
          {...dateFormat}
          popoverProps={{ minimal: true }}
          onChange={handleDateChange}
          value={[startDate, endDate]}
        />,
        !csvDownloading ? (
          <Tooltip intent="primary" content="Download Trades">
            <a onClick={downloadCsv} href="#0" download>
              <span className="rtcmd-page-action-icon">
                <Icon icon="arrow-down" />
              </span>
            </a>
          </Tooltip>
        ) : null,
        csvDownloading ? (
          <span>
            <Spinner size={24} />
          </span>
        ) : null,
        <Link to={"/aluminium"}>
          <span
            style={{ fontSize: "16px", fontWeight: "normal", marginLeft: 12 }}
          >
            <u>View requests</u>
          </span>
        </Link>,
      ]}
      tabs={[]}
    >
      <Table
        locale={{
          emptyText: "No trades to display",
        }}
        loading={
          loading && !trades
            ? {
                delay: 1500,
                indicator: <Spinner size={32} />,
              }
            : false
        }
        columns={columns}
        size={"small"}
        scroll={{
          x: trades && trades.length > 0,
        }}
        dataSource={
          trades
            ? trades
                .sort((a, b) => {
                  const fromDate = new Date(b.created_at);
                  const toDate = new Date(a.created_at);
                  return fromDate.getTime() - toDate.getTime();
                })
                .map((trade) => {
                  return {
                    id: trade.id,
                    qt_id: parseValue(trade.qt_id),
                    created_on: trade.created_at,
                    maturity: parseValue(
                      trade.maturity ? capitalize(trade.maturity) : "",
                    ),
                    sales_entity: parseValue(trade.sales_entity),
                    customer: parseValue(trade.customer),
                    instrument: parseValue(
                      trade.instrument ? trade.instrument.name : "",
                    ),
                    buy_sell: parseValue(trade.action),
                    price: parseValue(trade.price),
                    quantity: parseValue(trade.quantity),
                    currency: parseValue(trade.currency),
                    exchange_rate: parseValue(trade.forward_rate),
                    price_currency: parseValue(trade.price_currency),
                    request_id: parseValue(trade.request),
                    action: trade.request,
                  };
                })
            : []
        }
        tableLayout="auto"
        pagination={{
          pageSize: 20,
          itemRender: renderItem,
          showSizeChanger: false,
        }}
        onChange={handleChange}
      />
    </Page>
  );
}

export default Maturities;
