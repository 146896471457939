import useAxios from "axios-hooks";
import {url} from "../../../helpers";

const useSystemColumns = () => {
    const [{data, error, loading}, refetch] = useAxios({
        url: url("/mapping_system_columns/"),
    })
    const columns = data && data.map(row => ({...row, values: row['values'].length || ''}));
    return {data: columns, error, loading, refetch};
};

const useSystemColumnValues = (columnId) => {
    const [{data, error, loading}, refetch] = useAxios({
        url: url("/mapping_system_column_values/"),
    })
    return {data, error, loading, refetch};
};

const useSystemColumn = (columnId) => {
    const [{data, error, loading}, refetch] = useAxios({
        url: url(`/mapping_system_columns/${columnId}/`),
    })
    return {data, error, loading, refetch};
};

const useSystemColumnValue = (columnValueId) => {
    const [{data, error, loading}, refetch] = useAxios({
        url: url(`/mapping_system_column_values/${columnValueId}/`),
    })
    return {data, error, loading, refetch};
};

const useDeleteSystemColumn = (columnId) => {
    const [{data, error, loading}, refetch] = useAxios(
        {
            url: url(`/mapping_system_columns/${columnId}/`),
            method: "DELETE",
        },
        {
            manual: true
        }
    )
    return {data, error, loading, refetch};
};

const useDeleteSystemColumnValue = (columnId) => {
    const [{data, error, loading}, refetch] = useAxios(
        {
            url: url(`/mapping_system_column_values/${columnId}/`),
            method: "DELETE",
        },
        {
            manual: true
        }
    )
    return {data, error, loading, refetch};
};

const useAddSystemColumn = () => {
    const [{data, error, loading}, refetch] = useAxios(
        {
            url: url(`/mapping_system_columns/`),
            method: "POST",
        },
        {
            manual: true
        }
    )
    return {data, error, loading, refetch};
};

const useUpdateSystemColumn = (columnId) => {
    const [{data, error, loading}, refetch] = useAxios(
        {
            url: url(`/mapping_system_columns/${columnId}/`),
            method: "PATCH",
        },
        {
            manual: true
        }
    )
    return {data, error, loading, refetch};
};

const useAddSystemColumnValue = () => {
    const [{data, error, loading}, refetch] = useAxios(
        {
            url: url(`/mapping_system_column_values/`),
            method: "POST",
        },
        {
            manual: true
        }
    )
    return {data, error, loading, refetch};
};

const useUpdateSystemColumnValue = (columnValueId) => {
    const [{data, error, loading}, refetch] = useAxios(
        {
            url: url(`/mapping_system_column_values/${columnValueId}/`),
            method: "PATCH",
        },
        {
            manual: true
        }
    )
    return {data, error, loading, refetch};
};

export {
    useSystemColumns,
    useSystemColumnValues,
    useSystemColumn,
    useSystemColumnValue,
    useAddSystemColumn,
    useUpdateSystemColumn,
    useDeleteSystemColumn,
    useDeleteSystemColumnValue,
    useAddSystemColumnValue,
    useUpdateSystemColumnValue
};
