import { useContext } from "react";

import { capitalize, formatNumber, isValidNumber } from "../../helpers";

import TableInput from "../TableInput";
import RequestViewContext from "../RequestContext";


const EditableCell = ({ tradeIds, field, value, render, allowNegativeOrNull, focused=false, max=false }) => {
  const { update, disableEditing } = useContext(RequestViewContext);

  const onChange = (value) => {
    tradeIds.forEach((tradeId) => {
      update(value, tradeId);
    });
  };

  return (
    <>
      <TableInput
        required
        tabbable={!disableEditing}
        enabled={!disableEditing}
        value={value}
        key={tradeIds + value}
        focused={focused}
        isInvalid={(str) => isValidNumber(str, !allowNegativeOrNull, allowNegativeOrNull || false, max)}
        onChange={async (value) => {
          onChange({
            [field]: `${value}`,
          });
          return;
        }}
        id={tradeIds.join(",") + "." + field}
        formatContents={(value) => {
          return render ? render(value) : value || "-";
        }}
      />
    </>
  );
};

const editable = (types, field, render, allowNegativeOrNull, noHighlight=true, focused=false, max=false) => {
  return (value, data) => {
    return {
      props: noHighlight
        ? {}
        : {
          style: {
            backgroundColor: "rgba(191, 202, 215, 0.2)",
          },
        },
      children: (
        <EditableCell
          tradeIds={[data.trade_id]}
          value={value}
          field={field}
          render={render}
          focused={focused}
          allowNegativeOrNull={allowNegativeOrNull}
          max={max ? parseFloat(data.quantity) : false}
        />
      ),
    };
  };
};

const numericRender = (value, decimals) => {
  return formatNumber(value, true, decimals);
};

export const numericRenderBuilder = (decimals) => {
  return (value) => numericRender(value, decimals);
};

const integerRender = (value) => {
  return value;
};

const columns = (request, isEditable=false) => {
    return [
    {
      title: "Maturities",
      fixed: "left",
      dataIndex: "maturity",
      render: capitalize,
    },
    {
      title: "Buy/Sell",
      dataIndex: "action",
    },
    {
      title: (
        <>
          Quantity
          <br />
          ({request.contract.units})
        </>
      ),
      dataIndex: "quantity",
      render: isEditable && ['Limit Order', 'FFP Spot', 'FFP TBC'].includes(request.order_type) ?
        editable([request.constructor.name.toLowerCase()], "quantity", integerRender, false, true, true, true) :  numericRenderBuilder(2),
    },
    {
      title: (
        <>
          Price
          <br />
          ($/{request.contract.units})
        </>
      ),
      dataIndex: "price",
      render: isEditable ? editable([request.constructor.name.toLowerCase()], "price", integerRender) :  numericRenderBuilder(2),
    },
    {
      title: (
        <>
          QT ID
        </>
      ),
      dataIndex: "qt_id",
      render: isEditable ? editable([request.constructor.name.toLowerCase()], "qt_id", integerRender) : (value) => value,
    },
  ];
};

export default columns;
