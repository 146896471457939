import { formatDate, capitalize } from "../../helpers";

import SimpleTable from "../SimpleTable";
import React from "react";


const MarineOverview = ({ request }) => {
  return (
    <div>
      <h4 style={{ fontSize: '24px', lineHeight: '32px' }}>Order #{request.id}</h4>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <SimpleTable style={{ flex: "0 0 auto", fontSize: '14px', lineHeight: '22px' }}>
          <tr>
            <td>Status:</td>
            <td>{request.status}</td>
          </tr>


          <tr>
            <td>Tenor:</td>
            <td>
              {[...new Set(request.order_contracts.map((m) => {
                return capitalize(m.maturity_shortened_string);
              }))].join(", ") || request.maturities_to_display.map((m) => { return capitalize(m) }).join(', ')}
            </td>
          </tr>
          <tr>
            <td>
              Contract:
            </td>
            <td>
              {request.order_contracts[0]?.contract?.route?.route}
            </td>
          </tr>

          <tr>
            <td>Book:</td>
            <td>{request.book}</td>
          </tr>
          <tr>
            <td>Order Creation Date:</td>
            <td>
              {formatDate(request.created_at, true, true)}
            </td>
          </tr>
          <tr>
            <td>Requestor:</td>
            <td>{request.requestor.full_name}</td>
          </tr>
          <tr>
            <td>Order Type:</td>
            <td>{request.order_type}</td>
          </tr>
          <tr>
            <td>Price:</td>
            <td>{request.value}</td>
          </tr>
          <tr>
            <td>Qty:</td>
            <td>{request.quantity}</td>
          </tr>
          <tr>
            <td>VWAP:</td>
            <td>{request.vwap}</td>
          </tr>
        </SimpleTable>
      </div>
    </div>
  );
};

export default MarineOverview;
