import {
  IHTMLSelectProps as BPIHTMLSelectProps,
  HTMLSelect as BPHTMLSelect,
  IRef,
  IIconProps,
  IOptionProps,
} from '@blueprintjs/core';
import React from 'react';
import '../../../assets/scss/core.scss';

export interface IHTMLSelectProps extends BPIHTMLSelectProps {
  disabled?: boolean;
  elementRef?: IRef<HTMLSelectElement>;
  fill?: boolean;
  iconProps?: Partial<IIconProps>;
  large?: boolean;
  minimal?: boolean;
  options?: Array<string | number | IOptionProps>;
  value?: string | number;
}

export const HTMLSelect: React.FC<IHTMLSelectProps> = (props) => {
  return <BPHTMLSelect {...props} />;
};
