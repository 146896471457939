import React, { useEffect, useState } from "react";
import useAxios from "axios-hooks";

import { FilledCircle } from "../circleIcon";
import { url, getFontColorDelta } from "../../../../../helpers";
import { dateFormat } from "../../../Maturities";
import LoadingIcon from "../../../../Icons/loading";
import DoughnutGraph from "./chart";
import "./style.scss";
import { formatNumber } from "../../utils";


const PremiumRow = ({circleColor, label, percentage, hrMarginTop = '10px', value1 = null, value2 = null}) => {
  return (
    <>
      <hr style={{marginTop: hrMarginTop, marginBottom: '10px'}}/>
      <div className="premiumRow">
        <FilledCircle color={circleColor} />
        <p className="DoughnutChartLable">{label}</p>
        <p className="DoughnutChartPremium">
          {value1 !== null && (<span>{'$ ' + formatNumber(value1, false, 0)}&nbsp;&nbsp;</span>)}
        </p>
        <p className="DoughnutChartPnl" style={{color: getFontColorDelta(value2)}}>
          {'$ ' + formatNumber(value2, false, 0)}
        </p>
        <p className="DoughnutChartPercentage">
          {percentage}%
        </p>
      </div>
    </>
  )
}

const calculatePercentage = (value1, ttl_pnl) => {
  if (ttl_pnl && value1 > 0) return (parseFloat((Math.abs(value1)/ttl_pnl) * 100).toFixed(0))
  else return 0
}

const DoughnutChart = ({start_date, end_date, customer_id, pnlType}) => {
    const  [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [{ loading: chartDataLoading, data: chartData }, refetch] = useAxios({
      url: url(`aluminium_analytics/pnl_overview_splitted/?start_date=${dateFormat.formatDate(start_date)}&end_date=${dateFormat.formatDate(end_date)}${customer_id !== -1 ? '&customer=' + customer_id : ''}`),
    });
    
    useEffect(() => {
      if (!chartData) return
      setData(chartData)
    }, [chartData])

    useEffect(() => {
      setLoading(chartDataLoading)
    }, [chartDataLoading])

    useEffect(()=>{
      if(!start_date || !end_date || !customer_id) return
      refetch()
      // eslint-disable-next-line
    }, [start_date, end_date, customer_id])
    
    return (
      <>
      {loading === true ? (
            <div style={{ alignItems: "center", justifyContent: "center", height: '100%', display: 'flex'}}>
                <LoadingIcon />
                <p>Loading Data</p>
            </div>
        ) : (
        <>
          <DoughnutGraph chartData={data} chartDataLoading={chartDataLoading} pnlType={pnlType}/>
          <div className="premiumRow" style={{ marginTop: '30px'}}>
            <FilledCircle color={'#ffffff'} />
            <p className="DoughnutChartLable">&nbsp;&nbsp;</p>
            <p className="DoughnutChartPremium" style={{fontWeight: 600}}>
              Value
            </p>
            <p className="DoughnutChartPnl" style={{fontWeight: 600}}>
              {'Cum. Pnl'}
            </p>
            <p style={{textAlign: 'center', borderRadius: '3px', padding: '2px 5px 2px 5px', margin: 0, fontSize: '10px', minWidth: '8%'}}>
            &nbsp;&nbsp;
            </p>
          </div>
          <PremiumRow 
            circleColor={'#0017FA'} 
            label={'Market PnL'}
            value2={chartData[pnlType].market_pnl}
            value1={chartData[pnlType].market_pnl}
            percentage={calculatePercentage(chartData[pnlType].market_pnl, chartData[pnlType].total)}
          />
          <PremiumRow 
            circleColor={'#003776'} 
            label={'Premium PnL'} 
            value2={chartData[pnlType].premium_pnl[1]} 
            value1={chartData[pnlType].premium_pnl[0]}
            percentage={calculatePercentage(chartData[pnlType].market_pnl < 0 ? chartData[pnlType].premium_pnl[1] : chartData[pnlType].premium_pnl[0], chartData[pnlType].total)}
          />
          <PremiumRow 
            circleColor={'#0059D1'} 
            label={'Tier customer ALU PnL'}
            value2={chartData[pnlType].tier_customer_alu_pnl[1]} 
            value1={chartData[pnlType].tier_customer_alu_pnl[0]}
            percentage={calculatePercentage(chartData[pnlType].tier_customer_alu_pnl[0], chartData[pnlType].total)}
          />

          <PremiumRow 
            circleColor={'#0087FA'} 
            label={'Tier customer MWP PnL'}
            value2={chartData[pnlType].tier_customer_mwp_pnl[1]} 
            value1={chartData[pnlType].tier_customer_mwp_pnl[0]}
            percentage={calculatePercentage(chartData[pnlType].tier_customer_mwp_pnl[0], chartData[pnlType].total)}
          />
          <PremiumRow 
            circleColor={'#Ffffff'} 
            label={'Total'}
            value1={chartData[pnlType].total}
            value2={chartData[pnlType].total}
            percentage={calculatePercentage(chartData[pnlType].total, chartData[pnlType].total)}
            isTotalPnl={true}
          />
        </>
        )}
      </>
      
        
    )
}

export default DoughnutChart;
