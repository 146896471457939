import React, { useRef, useEffect } from "react"
import * as Plottable from "plottable"

import { Card, MenuItem, Select, Button } from "rt-design-system-backup"

import "plottable/plottable.css"

import "./style.scss"

export const Chart = () => {
    const container = useRef()
    const plot = useRef(false)
    const pointerHandler = useRef(false)

    useEffect(() => {
        if (!container.current) return

        var xScale = new Plottable.Scales.Time();
        var yScale = new Plottable.Scales.Linear();
        var data = [
            {
                x: new Date("2020-12-03"),
                y: 12000
            },
            {
                x: new Date("2020-12-04"),
                y: 12400
            },
            {
                x: new Date("2020-12-05"),
                y: 13000
            },
            {
                x: new Date("2020-12-06"),
                y: 13100
            },
            {
                x: new Date("2020-12-07"),
                y: 12900
            },
            {
                x: new Date("2020-12-08"),
                y: 13000
            },
        ];

        const dataLine = new Plottable.Plots.Line()
            .addDataset(new Plottable.Dataset(data))
            .x(function (d) { return d.x; }, xScale)
            .y(function (d) { return d.y; }, yScale)

        var dataPlot = new Plottable.Plots.Scatter()
            .addDataset(new Plottable.Dataset(data))
            .x(function (d) { return d.x; }, xScale)
            .y(function (d) { return d.y; }, yScale)

        const pointer = new Plottable.Interactions.Pointer();

        pointer.onPointerMove(function (p) {
            dataPlot.entities().forEach(function (entity) {
                entity.selection.attr("fill", "#5279C7");
            });

            const nearestEntity = dataPlot.entityNearest(p)
            nearestEntity.selection.attr('fill', 'red')
        });

        pointer.onPointerExit(function () {
            console.log("Out")
        });

        pointer.attachTo(dataPlot);

        pointerHandler.current = pointer

        const cs = new Plottable.Scales.Color();
        cs.range(["#bbbbbb", "#888888", "#DE4406"]);
        cs.domain(["Pmax 4TC", "Cape 5TC", "Smax 10TC"]);
        const legend = new Plottable.Components.Legend(cs);
        legend.maxEntriesPerRow(4)

        const xAxis = new Plottable.Axes.Numeric(xScale, "bottom")
        const yAxis = new Plottable.Axes.Numeric(yScale, "right")

        const grid = new Plottable.Components.Gridlines(xScale, yScale)

        const plots = new Plottable.Components.Group([dataPlot, dataLine, grid])

        plot.current = new Plottable.Components.Table([[plots, yAxis], [xAxis, null], [legend, null]])
            .renderTo(container.current)

        return () => {
            pointerHandler.current.detachFrom(pointerHandler.current._componentAttachedTo)
            plot.current.destroy()
        }
    }, [container])

    useEffect(() => {
        const observer = new ResizeObserver(() => {
            if (plot && plot.current) plot.current.redraw()
        })

        observer.observe(container.current)

        return observer.disconnect
    }, [container, plot])

    return <Card elevation={2}>
        <div className="rtcmd-chart-controls">
            <div>
                <Select items={[
                    {
                        size: "Cape"
                    }
                ]}
                    filterable={false}
                    placeholder="Select sizes"
                    intent="primary"
                    itemRenderer={(item, { handleClick }) => {
                        return <MenuItem text={item.size} onClick={handleClick} />
                    }} />
            </div>
            <div>
                <Select items={[
                    {
                        size: "Nov 20"
                    }
                ]}
                    filterable={false}
                    placeholder="Tenor"
                    intent="primary"
                    itemRenderer={(item, { handleClick }) => {
                        return <MenuItem text={item.size} onClick={handleClick} />
                    }} />
            </div>
            <div className="rtcmd-chart-controls-filler"></div>
            <div>
                <Select items={[
                    {
                        size: "Intraday"
                    }
                ]}
                    filterable={false}
                    placeholder="Display"
                    intent="primary"
                    itemRenderer={(item, { handleClick }) => {
                        return <MenuItem text={item.size} onClick={handleClick} />
                    }} />
            </div>
            <div className="rtcmd-chart-shortcuts">
                <Button intent="primary"
                    alignText="center" text="1D" />
                <Button
                    alignText="center" text="1W" />
                <Button
                    alignText="center" text="1M" />
                <Button
                    alignText="center" text="3M" />
                <Button
                    alignText="center" text="1Y" />
                <Button
                    alignText="center" text="All" />
            </div>
        </div>
        <div ref={container} className="rtcmd-chart" />
    </Card>
}