import React, { useContext } from "react";

import { Redirect, Route, Switch } from "react-router-dom";

import Prices from "../Views/Prices";
import Orders from "../Views/Orders";
import Trades from "../Views/Trades";
import Aluminium from "./aluminium";
import AppContext from "../AppContext";

import { has_no_permissions, hasPerm } from "../../helpers";
import { NoPermissions } from "../Login/no-permissions";
import NewOrder from "../Views/NewOrder";
import UpdateOrder from "../Views/UpdateOrder/UpdateOrder";
import NewOrderCracks from "../Views/NewOrder/Cracks";
import UpdateCracks from "../Views/UpdateCracks/UpdateCracks";
import NewCopperOrder from "../Views/NewCopperOrder";
import Coppers from "../Views/Coppers";
import CopperDetail from "../Views/CopperDetails";
import MarineDetail from "../Views/MarineDetails";
import FillCopper from "../Views/FillCopper";
import CopperTradesPage from "../Views/CopperTradesPage";
import UpdateCopperOrder from "../Views/UpdateCopperOrder";
import MarineDashboard from "../Views/MarineDashboard";
import BrokerPriceDashboard from "../Views/BrokerPriceDashboard";
import BunkerPriceDashboard from "../Views/BunkerPriceDashboard";
import HistoricalFFADashboard from "../Views/HistoricalFFADashboard";
import MetricDashboard from "../Views/MetricDashboard";
import STPMapping from "../Views/STPMapping";
import NewSTPMapping from "../Views/NewSTPMapping";
import UpdateSTPMapping from "../Views/UpdateSTPMapping";
import MappingDetails from "../Views/MappingDetails";
import STPAPIConsole from "../Views/STPAPIConsole";
import STPAPIConsoleDetails from "../Views/STPAPIConsoleDetails";
import EditSTPApiConsole from "../Views/EditSTPApiConsole";
import STPApiLogs from "../STPApiLogs";
import STPAPILogDetails from "../Views/STPAPILogDetails";
import STPMappingSystemColumnsList from "../Views/STPMappingSystemColumns/list";
import STPMappingSystemColumnValuesList from "../Views/STPMappingSystemColumns/valuesList";
import STPMappingSystemColumnForm from "../Views/STPMappingSystemColumns/form";
import STPMappingSystemColumnValueForm from "../Views/STPMappingSystemColumns/valueForm";

const Routes = () => {
  const { user, loggedIn } = useContext(AppContext);

  return (
    <Switch>
      <Route path="/prices/">
        <Prices />
      </Route>
      <Route path="/orders/trades/:trade?">
        <Trades />
      </Route>
      <Route path="/ffa/dashboard">
        <MarineDashboard />
      </Route>
      <Route path="/bunker">
        <BunkerPriceDashboard />
      </Route>
      <Route path="/ffa/historical_dashboard">
        <HistoricalFFADashboard />
      </Route>
      <Route path="/ffa/metric-dashboard">
        <MetricDashboard />
      </Route>
      <Route path="/ffa/broker/:routeId/dashboard/">
        <BrokerPriceDashboard />
      </Route>
      <Route path="/orders/:orderId/">
        <MarineDetail />
      </Route>
      <Route path="/orders/:order?">
        <Orders />
      </Route>
      <Route path="/coppers/trades/">
        <CopperTradesPage />
      </Route>
      <Route path="/coppers/:requestId/fill/">
        <FillCopper />
      </Route>
      <Route path="/coppers/:copper/copy/">
        <UpdateCopperOrder duplicating={true} />
      </Route>
      <Route path="/coppers/:copper/update/">
        <UpdateCopperOrder />
      </Route>
      <Route path="/coppers/:copperId/">
        <CopperDetail />
      </Route>
      <Route path="/coppers/">
        <Coppers />
      </Route>
      <Route exact={true} path="/order/:oderId/update/">
        <UpdateOrder updating={true} />
      </Route>
      <Route exact={true} path="/order-cracks/:oderId/update/">
        <UpdateCracks updating={true} />
      </Route>
      <Route path="/order/new">
        <NewOrder />
      </Route>
      <Route path="/copper/new/">
        <NewCopperOrder />
      </Route>
      <Route path="/order-cracks/new">
        <NewOrderCracks />
      </Route>
      <Route path="/aluminium/">
        <Aluminium />
      </Route>

      {/* STP Mapping */}
      <Route exact path="/stp-mapping-console">
        <STPMapping />
      </Route>
      <Route exact path="/stp-mapping-console/new">
        <NewSTPMapping />
      </Route>
      <Route exact path="/stp-mapping-console/system-columns/:systemColumnId/values">
        <STPMappingSystemColumnValuesList/>
      </Route>
      <Route exact path="/stp-mapping-console/system-columns/new">
          <STPMappingSystemColumnForm/>
      </Route>
      <Route exact path="/stp-mapping-console/system-columns/update/:systemColumnId/">
          <STPMappingSystemColumnForm/>
      </Route>
      <Route exact path="/stp-mapping-console/system-column-values/update/:systemColumnValueId/">
          <STPMappingSystemColumnValueForm/>
      </Route>
      <Route exact path="/stp-mapping-console/system-column-values/new">
          <STPMappingSystemColumnValueForm/>
      </Route>
      <Route exact path="/stp-mapping-console/system-columns">
        <STPMappingSystemColumnsList/>
      </Route>
      <Route exact path="/stp-mapping-console/:mappingId/">
        <MappingDetails />
      </Route>
      <Route exact path="/stp-mapping-console/update/:mappingId/">
        <UpdateSTPMapping />
      </Route>

      {/* STP API Console */}
      <Route exact path="/stp-api-console">
        <STPAPIConsole />
      </Route>
      <Route exact path="/stp-api-console/:apiId/">
        <STPAPIConsoleDetails />
      </Route>
      <Route exact path="/stp-api-console/update/:apiId">
        <EditSTPApiConsole />
      </Route>

      {/* STP API Console */}
      <Route exact path="/stp-logs">
        <STPApiLogs />
      </Route>
      <Route exact path="/stp-logs/:log_id">
        <STPAPILogDetails />
      </Route>

      <Route path="/no-permissions">
        <NoPermissions />
      </Route>
      <Route path="/">
        <Redirect
          to={
            !user && !loggedIn
              ? "/login"
              : has_no_permissions(user)
              ? "/no-permissions"
              : hasPerm(user, "rtcmdmodels.view_request")
              ? "/aluminium"
              : "/orders"
          }
        />
      </Route>
    </Switch>
  );
};

export default Routes;
