/* eslint-disable */
window.dataLayer = window.dataLayer || [];
function gtag() { dataLayer.push(arguments); }
gtag('js', new Date());

gtag('config', 'G-3F2YQRF702');

const report = (eventName, data) => {
    gtag('event', eventName, data)
}

export default gtag
export { report }