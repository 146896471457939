import React, {useCallback} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useAddSystemColumn, useSystemColumn, useUpdateSystemColumn} from "./api";
import SystemColumnEditOrUpdateForm from "../../STPMappingSystemColumns/Form/SystemColumnEditOrUpdateForm";
import {useSTPMappingSystemColumnContext} from "../../STPMappingSystemColumns/STPMappingSystemColumnsContext";
import Back from "../../STPMappingSystemColumns/Nav/Back";
import {errorToaster, successToaster} from "../../../helpers";

const EditForm = ({systemColumnId}) => {
    const {reloadSystemColumns, reloadSystemColumnValues} = useSTPMappingSystemColumnContext()
    const {refetch: updateSystemColumn} = useUpdateSystemColumn(systemColumnId)
    const history = useHistory();
    const {data, refetch: reloadSystemColumn} = useSystemColumn(systemColumnId);
    const onSubmit = useCallback((data) => {
        const submit = async () => {
            updateSystemColumn({data}).then(() => successToaster('System Column updated')).catch(()=> errorToaster('Failed to update System Column'));
            await reloadSystemColumn();
            await reloadSystemColumns();
            await reloadSystemColumnValues();
            history.goBack();
        }
        submit();
    }, [history, updateSystemColumn, reloadSystemColumn, reloadSystemColumns, reloadSystemColumnValues])
    return <SystemColumnEditOrUpdateForm data={data} onSubmit={onSubmit}/>
};

const AddForm = () => {
    const {reloadSystemColumns, reloadSystemColumnValues} = useSTPMappingSystemColumnContext()
    const history = useHistory();
    const {refetch: addSystemColumn} = useAddSystemColumn()
    const onSubmit = useCallback((data) => {
        const submit = async () => {
            addSystemColumn({data}).then(() => successToaster('System Column created')).catch(()=> errorToaster('Failed to create System Column'));
            await reloadSystemColumns();
            await reloadSystemColumnValues();
            history.goBack();
        }
        submit();
    }, [history, addSystemColumn, reloadSystemColumns, reloadSystemColumnValues])
    return <SystemColumnEditOrUpdateForm data={null} onSubmit={onSubmit}/>
};

const STPMappingSystemColumnForm = () => {
    const {systemColumnId} = useParams();
    return <>
        <Back to='/stp-mapping-console/system-columns' title='All system columns'/>
        {systemColumnId ? <EditForm systemColumnId={systemColumnId}/> : <AddForm/>}
    </>
};

export default STPMappingSystemColumnForm;
