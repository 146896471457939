import React, { useContext, useEffect, useState } from "react";
import useAxios from "axios-hooks";

import {
  Button,
  Icon,
  Spinner,
} from "rt-design-system-backup";

import { Input, Space, Table } from "antd";
import TradeDeleteDialog from "../TradeDeleteDialog";

import {
  url,
  capitalize,
  formatSort,
  formatNumber,
  hasPerm,
  formatDate,
} from "../../helpers";
import SocketContext from "../SocketContext";
import SortContext from "../SortContext";
import AppContext from "../AppContext";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const renderItem = (page, type, originalElement) => {
  if (type === "prev") {
    return <Button icon="arrow-left" intent="primary" />;
  } else if (type === "next") {
    return <Button icon="arrow-right" intent="primary" />;
  } else {
    return originalElement;
  }
};

const TableInterAction = (props) => {
  const [tradeDeleteView, setTradeDeleteView] = useState(false);

  const initial_filters = {}
  const filter_keys = ['trade', 'contract', 'tenor', 'book', 'bidOffer', 'broker', 'clearer', 'order']
    filter_keys.forEach(filter_key => {
      const value = localStorage.getItem(`trade_filter_${filter_key}`)
      if (value === '-') {
        delete initial_filters[filter_key]
      } else if (value) {
        initial_filters[filter_key] = value.split(',')
      }
    });

  const [filteredInfo, setFilterInfo] = useState(initial_filters);

  const { user } = useContext(AppContext);
  const sortContext = useContext(SortContext);

  let [{ data: trades = [], loading }, refetch] = useAxios({
    url: url("trade/" + formatSort(sortContext)),
  });

  const [searchText, setSearchText] = useState("initState");

  const handleChange = (pagination, filters, sorter) => {
    setFilterInfo(filters);

    Object.keys(filters).forEach(filter => {
      if (filters[filter]) {
        localStorage.setItem(`trade_filter_${filter}`, filters[filter].join())
      } else {
        localStorage.setItem(`trade_filter_${filter}`, '-')
      }
    })
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "trade",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ID`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 160, marginBottom: 8, display: "block" }}
          />
          <Space size={'large'}>
            <Button
              intent="secondary"
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ minHeight: '24px', minWidth: 60 }}
            >
              Reset
            </Button>
            <Button
              intent="primary"
              icon={<SearchOutlined />}
              onClick={() => handleSearch(selectedKeys, confirm)}
              size="small"
              style={{ minHeight: '24px', minWidth: 60 }}
            >
              Search
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? "#1890ff" : "#8e8e8e", fontSize: "16px" }}
        />
      ),
      onFilter: (value, record) =>
        record.trade.toString().includes(value.toString()),
      filteredValue: filteredInfo.trade || null,
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
      fixed: "left",
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => {
        const fromDate = new Date(b.date);
        const toDate = new Date(a.date);

        return fromDate.getTime() - toDate.getTime();
      },
      render: (dateString) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>{formatDate(dateString)}</div>
        );
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Contract",
      dataIndex: "contract",
      filters: (() => {
          const filtered_trades = trades.filter((item)=>item && item.order_contracts && item.order_contracts.length > 0
                                                        && item.order_contracts[0].contract !== undefined)
          return filtered_trades.map((item) => item.order_contracts[0].contract.route.route)
          .filter(
            (elem, pos) =>
              elem &&
              filtered_trades.map((item) => item.order_contracts[0].contract.route.route,).indexOf(elem) === pos
          )
          .sort()
          .map((value) => ({
            text: value,
            value: value,
          }))})(),
      filteredValue: filteredInfo.contract || null,
      onFilter: (value, record) => record.contract.includes(value),
    },
    {
      title: "Tenor",
      dataIndex: "tenor",
      filters: trades
        ? trades
          .map((item) => item.maturities_to_display.map((m) => {
            return capitalize(m);
          }).join(", "))
          .filter(
            (elem, pos) =>
              elem &&
              trades.map((item) => item.maturities_to_display.map((m) => {
                return capitalize(m);
              }).join(", "))
                .indexOf(elem) === pos
          )
          .sort()
          .map((value) => ({
            text: value,
            value: value,
          }))
        : [],
      filteredValue: filteredInfo.tenor || null,
      onFilter: (value, record) => record.tenor.includes(value),
      render: (tenors) => {
        return <div style={{ whiteSpace: "nowrap" }}>{tenors}</div>;
      },
    },
    {
      title: "Book",
      dataIndex: "book",
      filters: trades
        ? trades
          .map((item) => item.linked_order.book)
          .filter(
            (elem, pos) =>
              elem &&
              trades.map((item) => item.linked_order.book)
                .indexOf(elem) === pos
          )
          .sort()
          .map((value) => ({
            text: value,
            value: value,
          }))
        : [],
      filteredValue: filteredInfo.book || null,
      onFilter: (value, record) => record.book.includes(value),
    },
    {
      title: "Bid/Offer",
      dataIndex: "bidOffer",
      filters: trades
        ? trades
          .map((item) => item.linked_order.type)
          .filter(
            (elem, pos) =>
              elem &&
              trades.map((item) => item.linked_order.type)
                .indexOf(elem) === pos
          )
          .map((value) => ({
            text: capitalize(value),
            value: capitalize(value),
          }))
        : [],
      filteredValue: filteredInfo.bidOffer || null,
      onFilter: (value, record) => record.bidOffer.includes(value),
    },
    {
      title: "Price",
      dataIndex: "price",
    },
    {
      title: "Qty/mth",
      dataIndex: "qty",
      render: (qty) => {
        return <b>{qty}</b>;
      },
    },
    {
      title: "Total quantity",
      dataIndex: "total_qty",
      render: (total_qty) => {
        return <b>{total_qty}</b>;
      },
    },
    {
      title: "Broker",
      dataIndex: "broker",
      filters: trades
        ? trades
          .map((item) => item.broker.short)
          .filter(
            (elem, pos) =>
              elem &&
              trades.map((item) => item.broker.short)
                .indexOf(elem) === pos
          )
          .sort()
          .map((value) => ({
            text: value,
            value: value,
          }))
        : [],
      filteredValue: filteredInfo.broker || null,
      onFilter: (value, record) => record.broker.includes(value),
    },
    {
      title: "Clearer",
      dataIndex: "clearer",
      filters: trades
        ? trades
          .map((item) => item.clearer)
          .filter(
            (elem, pos) =>
              elem &&
              trades.map((item) => item.clearer)
                .indexOf(elem) === pos
          )
          .sort()
          .map((value) => ({
            text: value,
            value: value,
          }))
        : [],
      filteredValue: filteredInfo.clearer || null,
      onFilter: (value, record) => record.clearer.includes(value),
    },
    {
      title: "Trader",
      dataIndex: "trader",
    },
    {
      title: "Order ID",
      dataIndex: "order",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.order - b.order,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ID`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 160, marginBottom: 8, display: "block" }}
          />
          <Space size={'large'}>
            <Button
              intent="secondary"
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ minHeight: '24px', minWidth: 60 }}
            >
              Reset
            </Button>
            <Button
              intent="primary"
              icon={<SearchOutlined />}
              onClick={() => handleSearch(selectedKeys, confirm)}
              size="small"
              style={{ minHeight: '24px', minWidth: 60 }}
            >
              Search
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? "#1890ff" : "#8e8e8e", fontSize: "16px" }}
        />
      ),
      onFilter: (value, record) =>
        record.order.toString().includes(value.toString()),
      filteredValue: filteredInfo.order || null,
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },
  ];

  if (hasPerm(user, "rtcmdmodels.delete_trade")) {
    columns.push({
      title: "Actions",
      dataIndex: "",
      key: "x",
      render: (text, record) => {
        const isWithinLast24hrs =
          new Date() - new Date(record.raw.linked_order.created_at) <=
          24 * 60 * 60 * 1000;
        const isMine = record.raw.trader.email === user.email;
        return (
          <>
            {hasPerm(user, "rtcmdmodels.delete_trade") &&
              isWithinLast24hrs &&
              isMine ? (
              <Icon
                icon="trash"
                onClick={() => {
                  setTradeDeleteView(record.key);
                }}
              />
            ) : (
              ""
            )}
          </>
        );
      },
    });
  }

  // Sockets

  const socket = useContext(SocketContext);

  useEffect(() => {
    refetch();
    socket.addEventListener("trade_create", refetch);
    socket.addEventListener("trade_update", refetch);
    socket.addEventListener("order_update", refetch);

    return () => {
      socket.removeEventListener("trade_create", refetch);
      socket.removeEventListener("trade_update", refetch);
      socket.removeEventListener("order_update", refetch);
    };
  }, [socket, refetch]);

  return (
    <>
      <TradeDeleteDialog
        trade={
          !trades ? undefined : trades.find(({ id }) => id === tradeDeleteView)
        }
        onClose={() => setTradeDeleteView(false)}
        isOpen={tradeDeleteView !== false}
      />
      <Table
        tableLayout="auto"
        locale={{
          emptyText: "No trades to display",
        }}
        loading={
          loading
            ? {
              delay: 1500,
              indicator: <Spinner size={32} />,
            }
            : false
        }
        scroll={{ x: trades && trades.length > 0 }}
        size="small"
        expandIconColumnIndex={13}
        columns={columns}
        dataSource={
          trades
            ? trades
              .sort((a, b) => {
                return b.id - a.id;
              })
              .filter((trade) => !trade.is_deleted)
              .map((trade) => {
                return {
                  key: trade.id,
                  trade: trade.id,
                  date: trade.created_at,
                  contract: capitalize(
                    trade.order_contracts.length > 0 ? trade.order_contracts[0].contract.route.route : '-'
                  ),
                  tenor: trade.maturities_to_display
                    .map((m) => {
                      return capitalize(m);
                    })
                    .join(", "),
                  book: trade.linked_order.book,
                  bidOffer: capitalize(trade.linked_order.type),
                  price: formatNumber(trade.value),
                  qty: formatNumber(trade.quantity, false, /^-?\d+$/.test(trade.quantity) ? 0 : 2),
                  total_qty: trade.maturities_to_display.map(m => {
                    const multiplier = m.includes('Cal') ? 12 : m.includes('Q') ? 3 : 1
                    const qty = trade.quantity * multiplier
                    return formatNumber(qty, false, /^-?\d+$/.test(qty) ? 0 : 2)
                  }).join('/'),
                  clearer: trade.clearer,
                  broker: trade.broker.short,
                  trader: trade.trader ? trade.trader.full_name : "",
                  order: trade.linked_order.id,
                  raw: trade,
                };
              })
            : []
        }
        pagination={{
          pageSize: 20,
          itemRender: renderItem,
          showSizeChanger: false,
        }}
        onChange={handleChange}
      />
    </>
  );
};

const TradesTable = () => {
  return <TableInterAction />;
};

export default TradesTable;
