import React, { useContext, useEffect, useState } from "react";
import { useParams,useHistory } from "react-router-dom";
import useAxios from "axios-hooks";


import {
  Button,
  Icon,
  Spinner,
} from "rt-design-system-backup";
import OrderCancelDialog from "../OrderCancelDialog";
import TradeCreateDialog from "../TradeCreateDialog";
import OrderCreateDialog from "../OrderCreateDialog";
import SortContext from "../SortContext";
import SocketContext from "../SocketContext";
import AppContext from "../AppContext";
import MarketBidOfferInput from "../MarketBidOfferInput";

import {
  url,
  capitalize,
  formatNumber,
  formatSort,
  hasPerm,
  formatDate,
} from "../../helpers";

import { Input, Space, Table } from 'antd';

import "./style.scss";
import { SearchOutlined } from "@ant-design/icons";

import Highlighter from "react-highlight-words";

export const renderItem = (page, type, originalElement) => {
  if (type === "prev") {
    return <Button icon="arrow-left" intent="primary" />;
  } else if (type === "next") {
    return <Button icon="arrow-right" intent="primary" />;
  } else {
    return originalElement;
  }
};

const TableInterAction = (props) => {
  const history = useHistory();

  const [orderView, setOrderView] = useState(false);
  const [tradeCreateView, setTradeCreateView] = useState(false);
  const [orderCancelView, setOrderCancelView] = useState(false);
  const [orderCopyView, setOrderCopyView] = useState(false);

  const { user } = useContext(AppContext);
  const sortContext = useContext(SortContext);

  const [{ data: orders = [], loading }, refetch] = useAxios({
    url: url("order/" + formatSort(sortContext)),
  });

  const initial_filters = {}
  const filter_keys = ['order', 'contract', 'tenor', 'book', 'bidOffer', 'status', 'charterer']
    filter_keys.forEach(filter_key => {
      const value = localStorage.getItem(`order_filter_${filter_key}`)
      if (value === '-') {
        delete initial_filters[filter_key]
      } else if (value) {
        initial_filters[filter_key] = value.split(',')
      }
    });

  const [filteredInfo, setFilterInfo] = useState(initial_filters);
  const [searchText, setSearchText] = useState("initState");

  const handleChange = (pagination, filters, sorter) => {
    setFilterInfo(filters);

    Object.keys(filters).forEach(filter => {
      if (filters[filter]) {
        localStorage.setItem(`order_filter_${filter}`, filters[filter].join())
      } else {
        localStorage.setItem(`order_filter_${filter}`, '-')
      }
    })
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "order",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ID`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 160, marginBottom: 8, display: "block" }}
          />
          <Space size={'large'}>
            <Button
              intent="secondary"
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ minHeight: '24px', minWidth: 60 }}
            >
              Reset
            </Button>
            <Button
              intent="primary"
              icon={<SearchOutlined />}
              onClick={() => handleSearch(selectedKeys, confirm)}
              size="small"
              style={{ minHeight: '24px', minWidth: 60 }}
            >
              Search
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? "#1890ff" : "#8e8e8e", fontSize: "16px" }}
        />
      ),
      onFilter: (value, record) =>
        record.order.toString().includes(value.toString()),
      filteredValue: filteredInfo.order || null,
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
      fixed: "left",
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => {
        const fromDate = new Date(b.date);
        const toDate = new Date(a.date);

        return fromDate.getTime() - toDate.getTime();
      },
      render: (dateString) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>{formatDate(dateString)}</div>
        );
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Contract",
      dataIndex: "contract",
      filters: (() => {
          const filtered_orders = orders.filter((item)=>item && item.order_contracts && item.order_contracts.length > 0
                                                        && item.order_contracts[0].contract !== undefined)
          return filtered_orders.map((item) => item.order_contracts[0].contract.route.route)
          .filter(
            (elem, pos) =>
              elem &&
              filtered_orders.map((item) => item.order_contracts[0].contract.route.route,).indexOf(elem) === pos
          )
          .sort()
          .map((value) => ({
            text: value,
            value: value,
          }))})()
        ,
      filteredValue: filteredInfo.contract || null,
      onFilter: (value, record) => record.contract.includes(value),
    },
    {
      title: "Tenor",
      dataIndex: "tenor",
      filters: orders
        ? orders
          .map((item) => item.order_contracts.map((m) => {
            return capitalize(m.maturity_shortened_string);
          }).join(", "))
          .filter(
            (elem, pos) =>
              elem &&
              orders.map((item) => item.order_contracts.map((m) => {
                return capitalize(m.maturity_shortened_string);
              }).join(", "))
                .indexOf(elem) === pos
          )
          .sort()
          .map((value) => ({
            text: value,
            value: value,
          }))
        : [],
      filteredValue: filteredInfo.tenor || null,
      onFilter: (value, record) => record.tenor.includes(value),
      render: (tenors) => {
        return <div style={{ whiteSpace: "nowrap" }}>{tenors}</div>;
      },
    },
    {
      title: "Book",
      dataIndex: "book",
      filters: orders
        ? orders
          .map((item) => item.book)
          .filter(
            (elem, pos) =>
              elem &&
              orders.map((item) => item.book)
                .indexOf(elem) === pos
          )
          .sort()
          .map((value) => ({
            text: value,
            value: value,
          }))
        : [],
      filteredValue: filteredInfo.book || null,
      onFilter: (value, record) => record.book.includes(value),
    },
    {
      title: "Bid/Offer",
      dataIndex: "bidOffer",
      filters: orders
        ? orders
          .map((item) => item.type)
          .filter(
            (elem, pos) =>
              elem &&
              orders.map((item) => item.type)
                .indexOf(elem) === pos
          )
          .sort()
          .map((value) => ({
            text: capitalize(value),
            value: capitalize(value),
          }))
        : [],
      filteredValue: filteredInfo.bidOffer || null,
      onFilter: (value, record) => record.bidOffer.includes(value),
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (price, record) => {
        if (record.raw.order_type === "market") {
          return "At best";
        } else {
          return price;
        }
      },
    },
    {
      title: "Qty",
      dataIndex: "qty",
      render: (qty) => {
        return <b>{qty}</b>;
      },
    },
    {
      title: "VWAP",
      dataIndex: "vwap"
    },
  ];

  if (hasPerm(user, "rtcmdmodels.add_trade")) {
    columns.push(
      {
        title: "Market bid",
        render: (_, r) => {
          const isWithinLast24hrs =
            new Date() - new Date(r.raw.created_at) <= 24 * 60 * 60 * 1000;
          return (
            <MarketBidOfferInput
              enabled={isWithinLast24hrs}
              type="bid"
              order={r.raw}
            />
          );
        },
      },
      {
        title: "Market offer",
        render: (_, r) => {
          const isWithinLast24hrs =
            new Date() - new Date(r.raw.created_at) <= 24 * 60 * 60 * 1000;
          return (
            <MarketBidOfferInput
              enabled={isWithinLast24hrs}
              type="offer"
              order={r.raw}
            />
          );
        },
      }
    );
  }

  columns.push(
    {
      title: "Status",
      dataIndex: "status",
      filters: orders
        ? orders
          .map((item) => item.status)
          .filter(
            (elem, pos) =>
              elem &&
              orders.map((item) => item.status)
                .indexOf(elem) === pos
          )
          .sort()
          .map((value) => ({
            text: value,
            value: value,
          }))
        : [],
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => record.status.includes(value),
    },
    {
      title: "Charterer",
      dataIndex: "charterer",
      filters: orders
        ? orders
          .map((item) => item.requestor ? item.requestor.full_name : "")
          .filter(
            (elem, pos) =>
              elem &&
              orders.map((item) => item.requestor ? item.requestor.full_name : "")
                .indexOf(elem) === pos
          )
          .sort()
          .map((value) => ({
            text: value,
            value: value,
          }))
        : [],
      filteredValue: filteredInfo.charterer || null,
      onFilter: (value, record) => record.charterer.includes(value),
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "x",
      render: (_, record) => {
        const isWithinLast24hrs =
          new Date() - new Date(record.raw.created_at) <= 24 * 60 * 60 * 1000;
        const isMine = record.raw.requestor.email === user.email;
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            <Icon
              icon="eye-open"
              onClick={() => {
                history.push(`/orders/${record.key}`);
              }}
            />
            {hasPerm(user, "rtcmdmodels.change_order") && ['Open'].includes(record.status)
                ? <span style={{cursor: 'pointer'}}>
                  <a href={`/order/${record.key}/update/`} style={{color: 'black'}}>
                    <Icon icon="edit"/>
                  </a>
            </span> : null}
            {hasPerm(user, "rtcmdmodels.duplicate_order") ? (
              <Icon
                size={20}
                icon="duplicate"
                onClick={() => {
                  setOrderCopyView(record.key);
                }}
              />
            ) : null}
            {hasPerm(user, "rtcmdmodels.add_trade") &&
              isWithinLast24hrs && ["Open", "Partial", "Locked"].includes(record.status) ? (
              <Icon
                size={20}
                icon="add"
                onClick={() => {
                  setTradeCreateView(record.key);
                }}
              />
            ) : null}
            {hasPerm(user, "rtcmdmodels.delete_order") &&
              isMine &&
              isWithinLast24hrs &&
              (record.status === "Open" || record.status === "Partial") ? (
              <Icon
                size={20}
                icon="cross"
                onClick={() => {
                  setOrderCancelView(record.key);
                }}
              />
            ) : null}
            {/*{["Open"].includes(record.status) && isMine ? (record.raw.is_cracks ?*/}
            {/*  <EditRequestButton style={{color: 'black'}} href={"/order-cracks/" + record.order + "/update/"} showIcon /> :*/}
            {/*    <EditRequestButton style={{color: 'black'}} href={"/order/" + record.order + "/update/"} showIcon />) : null*/}
            {/*}*/}
          </div>
        );
      },
    }
  );

  // Automatic popup opening

  const { order } = useParams();

  const [firstRender, setFirstRender] = useState(true);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!orders || !orders.length || !firstRender) return;

    if (
      orders.find((e) => {
        return `${e.id}` === `${order}`;
      })
    ) {
      if (orderView) return;

      setOrderView(parseInt(order));
    }

    setFirstRender(false);
  }, [orders, order]);
  /* eslint-enable react-hooks/exhaustive-deps */

  // Sockets

  const socket = useContext(SocketContext);

  useEffect(() => {
    refetch();
    socket.addEventListener("order_create", refetch);
    socket.addEventListener("order_update", refetch);
    socket.addEventListener("trade_create", refetch);
    socket.addEventListener("trade_update", refetch);
    socket.addEventListener("order_update_market", refetch);

    return () => {
      socket.removeEventListener("order_create", refetch);
      socket.removeEventListener("order_update", refetch);
      socket.removeEventListener("trade_create", refetch);
      socket.removeEventListener("trade_update", refetch);
      socket.removeEventListener("order_update_market", refetch);
    };
  }, [socket, refetch]);

  // End of socket handling

  return (
    <div className="rtcmd-orders-table">
      <OrderCreateDialog
        prefill={
          !orders ? undefined : orders.find(({ id }) => id === orderCopyView)
        }
        onClose={() => setOrderCopyView(false)}
        isOpen={orderCopyView !== false}
      />
      <TradeCreateDialog
        order={
          !orders ? undefined : orders.find(({ id }) => id === tradeCreateView)
        }
        onClose={() => setTradeCreateView(false)}
        isOpen={tradeCreateView !== false}
      />
      {/* <OrderViewDialog
        order={!orders ? undefined : orders.find(({ id }) => id === orderView)}
        onClose={() => setOrderView(false)}
        isOpen={orderView !== false}
      /> */}
      <OrderCancelDialog
        order={
          !orders ? undefined : orders.find(({ id }) => id === orderCancelView)
        }
        onClose={() => setOrderCancelView(false)}
        isOpen={orderCancelView !== false}
      />
      <Table
        locale={{
          emptyText: "No orders to display",
        }}
        loading={
          loading
            ? {
              delay: 1500,
              indicator: <Spinner size={32} />,
            }
            : false
        }
        scroll={{
          x: orders && orders.length > 0,
        }}
        size="small"
        expandIconColumnIndex={13}
        columns={columns}
        dataSource={
          orders
            ? orders
              .sort((a, b) => {
                return b.id - a.id;
              })
              .map((order) => {
                const bidOffer = order.order_contracts.filter(c => c.type).map(c => capitalize(c.type))
                return {
                  key: order.id,
                  order: order.id,
                  date: order.created_at,
                  contract: [ ...new Set(order.order_contracts.map(c => c.contract.route.route))].join('/'),
                  tenor: [...new Set(order.order_contracts.map((m) => {
                      return capitalize(m.maturity_shortened_string);
                    }))].join(", ") || order.maturities_to_display.map((m) => {return capitalize(m)}).join(', '),
                  book: order.book,
                  bidOffer: (bidOffer.length ? [ ...new Set(bidOffer) ] : [capitalize(order.type)]).join('/'),
                  vwap: order.vwap ? parseFloat(parseFloat(order.vwap).toFixed(2)) : '',
                  price: formatNumber(order.value),
                  qty:
                    formatNumber(order.is_spread || order.is_cracks ? order.quantity_traded/2 : order.quantity_traded , false, /^-?\d+$/.test(order.quantity_traded) ? 0 : 2) +
                    "/" +
                    formatNumber(order.is_spread || order.is_cracks ? order.total_quantity/2 : order.total_quantity , false, /^-?\d+$/.test(order.total_quantity) ? 0 : 2),
                  status: order.status,
                  charterer: order.requestor ? order.requestor.full_name : "",
                  raw: order,
                };
              })
            : []
        }
        tableLayout="auto"
        pagination={{
          pageSize: 20,
          itemRender: renderItem,
          showSizeChanger: false,
        }}
        onChange={handleChange}
      />
    </div>
  );
};

const OrdersTable = () => {
  return <TableInterAction />;
};

export default OrdersTable;
