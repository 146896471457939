import React, { useEffect, useState } from "react";
import useAxios from "axios-hooks";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import moment from "moment"
import { Bar } from 'react-chartjs-2';
import { Card } from "rt-design-system-backup"
import { InputGroup } from "../../../Form";

import { url } from "../../../../helpers";
import { dateFormat } from "../../Maturities";
import LoadingIcon from "../../../Icons/loading";
import { formatNumber } from "../utils";


const CSRMixedChart = ({customer_id, start_date, end_date, isExtraLargeScreen}) => {
    ChartJS.register(
      CategoryScale,
      LinearScale,
      BarElement,
      PointElement,
      LineElement,
      LineController,
      Title,
      Tooltip,
      Legend
    );
    const [unit, setUnit] = useState('Monthly')
    const [{ loading: chartDataLoading, data: chartData }, refetch] = useAxios({
        url: url(`aluminium_analytics/get_data_for_csr_mixed_chart/?start_date=${dateFormat.formatDate(start_date)}&end_date=${dateFormat.formatDate(end_date)}${customer_id !== -1 ? '&customer=' + customer_id : ''}&unit=${unit}`),
    });

    const getGraphUnits = (start_date, end_date) => {
      let units = []
      let days = 0
      for (let i=moment(start_date); i <= moment(end_date); ) {
          if (![6,7].includes(i.isoWeekday())) {
              days += 1
          }
          i = i.add(1, 'days')
      }
      const differenceInSec = days * 24 * 60 * 60
      const isHourValid = differenceInSec === 0 || (differenceInSec / (60 * 60)) <= 48
      const is12HourValid = (differenceInSec / (12 * 60 * 60)) > 4 && (differenceInSec / (12 * 60 * 60)) <= 24
      const isDailyValid = (differenceInSec / (24 * 60 * 60)) > 4 && (differenceInSec / (24 * 60 * 60)) <= 24
      const isWeeklyValid = (differenceInSec / (7 * 24 * 60 * 60)) > 3 && (differenceInSec / (7 * 24 * 60 * 60)) <= 24
      const isMonthlyValid = (differenceInSec / (30 * 24 * 60 * 60)) > 4 && (differenceInSec / (30 * 24 * 60 * 60)) <= 24

      if (isHourValid) units.push('Hourly')
      if (is12HourValid) units.push('12 Hour')
      if (isDailyValid) units.push('Daily')
      if (isWeeklyValid) units.push('Weekly')
      if (isMonthlyValid) units.push('Monthly')
      if (units.length === 0) units.push('Monthly')
      return units
    }

    useEffect(()=>{
      if(!unit || !customer_id) return
      refetch()
      console.log(unit)
      // eslint-disable-next-line
    }, [unit, customer_id])

    useEffect(()=> {
      if(!start_date || !end_date) return
      const units = getGraphUnits(start_date, end_date)
      setUnit(units[0])
    }, [start_date, end_date])

    const options = {
        maintainAspectRatio: false,
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
              font: {
                family: 'RT_VickermanW01',
              }
            }
          },
          y: {
              ticks: {
                font: {
                  size: 14,
                  family: 'RT_VickermanW01',
                }
              }
          },
        },
        plugins: {
          legend: {
              display: false,
          },
          title: {
            display: false,
          },
          tooltip: {
            titleFont: {
              family: 'RT_VickermanW01'
            },
            bodyFont: {
              family: 'RT_VickermanW01'
            },
            callbacks: {
              label: function(context) {
                  return "Total Pnl " + formatNumber(context.formattedValue.replace(/,/g, ''), false, 0)
              }
            }
          }
        },
    };
    const dataset = {
        labels: chartData && chartData.labels && chartData.labels.length ? chartData.labels : [],
        datasets: [
        chartDataLoading === false && chartData && {
            label: 'Total Pnl',
            data: chartData && chartData.labels && chartData.labels.length ? chartData.bar_chart_data : [],
            backgroundColor: '#0087FA',
          },
        chartDataLoading === false && chartData && {
            type: 'line',
            label: 'Total Pnl',
            data: chartData && chartData.labels && chartData.labels.length ? chartData.line_chart_data : [],
            backgroundColor: '#0087FA',
          }
        ]
    };
    
    return (
        <Card elevation={1}
          style={{padding: '10px 14px 10px 18px', borderRadius: '8px', minHeight: isExtraLargeScreen === true ? '440px' : '320px',
        }}>
            <div style={{"display": "inline-flex", "width": "100%"}}>
              <h1 style={{"flexGrow": 1}}>Cumulative PnL</h1>
              <InputGroup
                required
                name="unit"
                type="select"
                key={`${unit}_CSR GRAPH UNIT"`}
                formStyle={{margin: 0, marginRight: 20, }}
                items={getGraphUnits(start_date, end_date).map((item) => {
                return {
                    name: item,
                    value: item,
                };
                })}
                onChange={(e) => {setUnit(e[0].value)}}
                placeholder={'Select Unit'}
                defaultValue={[getGraphUnits(start_date, end_date).includes(unit) ? unit : getGraphUnits(start_date, end_date)[0]]}
              />
            </div>
            {chartDataLoading === true ? (
            <div style={{ alignItems: "center", justifyContent: "center", height: '100%', display: 'flex'}}>
                <LoadingIcon />
                <p>Loading Data</p>
            </div>
            ) : (
            <>
                <div style={isExtraLargeScreen === true ? {height:  "410px", marginTop: '20px'} : {height:  "290px", marginTop: '10px'}}>
                  <Bar data={dataset} options={options} />
                </div>
            </>
            )}  
        </Card>
    );
}
export default CSRMixedChart;
