import { Suggest as BPSuggest, ISuggestProps as BPISuggestProps } from '@blueprintjs/select';
import React, { useEffect, useRef, useState } from 'react';
import '../../../assets/scss/core.scss';
import styled from 'styled-components';
import { Button } from 'index';

const SelectContainer = styled.div`
  display: inline-block;
  font-size: 0;
  width: 100%;
`;

export type ISelectProps<T> = BPISuggestProps<T>;

export const Select: React.FC<ISelectProps<any>> = (props) => {
  const { popoverProps, disabled, inputProps, ...rest } = props;
  const { popoverClassName, ...res } = popoverProps || {};
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);
  const inputRef = useRef<any>(null);
  const [popoverRef, setPopoverRef] = useState<HTMLElement | null>(null);

  const handlePopover = (ref: HTMLElement | null) => {
    setPopoverRef(ref);
  };

  useEffect(() => {
    if (inputRef.current && popoverRef) {
      const elementWidth = inputRef?.current?.offsetWidth || 0;
      const width = elementWidth ? elementWidth - 0.3 : 0;

      const element = popoverRef.querySelector('.bp3-select-popover .bp3-menu');
      if (element) {
        element.setAttribute('style', `width: ${width ? `${width}px` : 'auto'}`);
      }
    }
  }, [inputRef.current, popoverRef]);

  const controlPopover = () => {
    setPopoverIsOpen(true);
  };

  const handleInteraction = (nextOpenState: boolean) => {
    setPopoverIsOpen(nextOpenState);
  };

  const overProps = {
    ...res,
    popoverClassName: `${popoverClassName || ''} ${inputProps?.large && 'bp3-large'}`,
    isOpen: popoverIsOpen,
    popoverRef: props.popoverProps?.popoverRef || handlePopover,
    onInteraction: handleInteraction,
  };

  const rightElement = (
    <Button
      key='icon'
      icon={popoverIsOpen && !disabled ? 'caret-up' : 'caret-down'}
      disabled={disabled}
      minimal
      onClick={controlPopover}
    />
  );

  const inputPropsConvert = {
    ...inputProps,
    rightElement: rightElement,
  };

  return (
    <SelectContainer ref={inputRef}>
      <BPSuggest inputProps={inputPropsConvert} popoverProps={overProps} {...rest} />
    </SelectContainer>
  );
};
