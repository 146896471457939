import React, {useContext, useEffect, useState} from "react";
import { Row, Col } from "antd"
import useAxios from "axios-hooks";
import {url} from "../../../helpers";
import MetricBarChart from "./MetricBarChart";
import { Card } from "rt-design-system-backup"

import "./style.scss";
import {loadingModelCSS} from "../NewRequest";
import {InputGroup} from "../../../App/Form";
import {Overlay} from "@blueprintjs/core";
import LoadingIcon from "../../Icons/loading";
import MarineContext from "../../MarineContext";
import { SortIcon } from "./sortIcon";

const MetricDashboard = () => {
    const [routes, setRoutes] = useState([]);
    const [route, setRoute] = useState();

    const [totalOffers, setTotalOffers] = useState([]);
    const [bidsOffers, setBidsOffers] = useState([]);
    const [lasts, setLasts] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortField, setSortField] = useState();
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const [{ data: routesData = null }, routeRefetch] = useAxios({
      url: url('/route/?freight_routes_only=true'),
    });

    const [{ data: chartData = null }, chartRefetch] = useAxios({
      url: url(`offer/metric_graphs/`),
    });

    const [{ data: tData = null, loading }, tableRefetch] = useAxios({
      url: url(`offer/metric_table/?route=${route}`),
    });

    useEffect(() => {
      if (routeRefetch) {
        chartRefetch();
        tableRefetch();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, routeRefetch]);

    useEffect(()=> {
        if (!chartData) return;
        setTotalOffers(chartData['total_offers']);
        setBidsOffers(chartData['bids_offers']);
        setLasts(chartData['lasts_only']);
    },[chartData]);

    useEffect(() => {
        const interval = setInterval(chartRefetch, 30000);
        const tableInterval = setInterval(tableRefetch, 30000);
        return () => {
            clearInterval(interval);
            clearInterval(tableInterval)
        }
    })

    useEffect(() => {
        if (!tData) return;
        if (sortField) handleSorting(sortField, sortOrder, tData);
        else setTableData(tData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tData]);

    useEffect(() => {
      if (!routesData) return;
      setRoutes(routesData);
      setRoute(routesData[0].route);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routesData]);

    const {setShowImage, setMainPadding} = useContext(MarineContext);

    const handleWindowResize = () => {
      setWindowHeight(window.innerHeight);
    }

    useEffect(()=> {
      setShowImage(true);
      if (windowHeight < 800) {
          setMainPadding('16px 16px 10px 16px');
      } else {
          setMainPadding('32px 32px 12px 32px');
      }
      const resizeEvent = window.addEventListener('resize', handleWindowResize)
      return () => {
          setShowImage(false);
          setMainPadding('');
          window.removeEventListener('resize', resizeEvent);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleSorting = (sortField, sOrder, data=tableData) => {
      if (sortField) {
       const sorted = [...data].sort((a, b) => {
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;
        if (a[sortField] === null && b[sortField] === null) return 0;
        return (
         a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
          numeric: true,
         }) * (sOrder === "asc" ? 1 : -1)
        );
       });
       setTableData(sorted);
      }
     };

    const handleSortingChange = (key) => {
      let sOrder = 'asc';
      if (key === sortField && sortOrder === "asc") {
        if (sortOrder === 'asc') sOrder = 'desc';
        else sOrder = 'asc';
      }
      setSortField(key);
      setSortOrder(sOrder);
      handleSorting(key, sOrder);
    };

    const getSortColor = (key, order) => {
      if (key === sortField) {
        if (sortOrder === order) return 'blue'
        else return 'grey'
      }
      return 'grey'
    }

     const MetricTableCell = ({dataIndex, title, padding='2%'}) => {
      return (
        <th onClick={() => handleSortingChange(dataIndex)} style={{fontWeight: 700}}>
          <React.Fragment style={{display: 'flex'}} >
            <>{title}</>
            <SortIcon upArrowColor={getSortColor(dataIndex, 'asc')} downArrowColor={getSortColor(dataIndex, 'desc')} padding={padding}/>
          </React.Fragment>
        </th>
      )
    }

    const getTableHeight = () => {
      let graphHeight = window.document.getElementById("CAPE_DTD_READ_GRAPH");
      let windowHeight = window.innerHeight;
      if (graphHeight) graphHeight = graphHeight.clientHeight;
      else return windowHeight - 370 - 200
    
      return windowHeight - graphHeight - 200;
    }

    return (
    <MarineContext.Consumer>
      {({isDarkModeOn}) => (
        chartData !== null && tData !== null && routes.length !== 0 ? (
        <div style={{background: isDarkModeOn ? "black" : ''}}>
            <Row>
                <Col id="CAPE_DTD_READ_GRAPH" sm={24} md={12} lg={8} xl={8} style={{marginBottom: 8, paddingLeft: 12}}>
                    <p className="chart_title" style={{color: isDarkModeOn? 'white': ''}}>
                        CAPE DTD READ (Broker house)
                    </p>
                    <MetricBarChart data={totalOffers} isDarkModeOn={isDarkModeOn} showCustomToolTip={true}/>
                </Col>
                <Col sm={24} md={12} lg={8} xl={8} style={{marginBottom: 8, paddingLeft: 12}}>
                    <p className="chart_title" style={{color: isDarkModeOn? 'white': ''}}>
                        CAPE DTD READ (Broker name)
                    </p>
                    <MetricBarChart data={bidsOffers} isDarkModeOn={isDarkModeOn}/>
                </Col>
                <Col sm={24} md={12} lg={8} xl={8} style={{marginBottom: 8, paddingRight: 24, paddingLeft: 12}}>
                    <p className="chart_title" style={{color: isDarkModeOn? 'white': ''}}>
                        CAPE DTD TRADED (Broker name)
                    </p>
                    <MetricBarChart data={lasts} BackgroundColor={"#FFC000"} isDarkModeOn={isDarkModeOn}/>
                </Col>
            </Row>
            <Row style={{marginTop: 12, background: isDarkModeOn ? "black" : ''}} align={"center"}>
              <Col sm={24} md={24} lg={24} xl={24} style={{ paddingRight: 24, paddingLeft: 12}}>
                <Card elevation={1} className={isDarkModeOn ? "metric_dashboard_card_dark": "metric_dashboard_card"}>
                  <div style={{display: 'flex'}}>
                    <InputGroup
                        required
                        name="route"
                        type="select"
                        formStyle={{margin: 0, marginRight: 20, }}
                        items={(routes.length ? routes : []).map((route) => {
                          return {
                            name: route.route,
                            value: route.route,
                          };
                        })}
                        onChange={(e) => {setRoute(e[0].value)}}
                        placeholder={'Select Route'}
                        defaultValue={[routes[0].route]}
                        className={isDarkModeOn? 'chart-input-group' : ''}
                    />
                  </div>
                  {tableData.length !== 0 ?
                    <div className={isDarkModeOn ? "metric_dashboard_table_dark": "metric_dashboard_table"} style={{height: getTableHeight()}}>
                      <table>
                        <thead className={isDarkModeOn ? "tablehead_dark": "tablehead"}>
                          <MetricTableCell dataIndex={'name'} title={'Broker Name'}/>
                          <MetricTableCell dataIndex={'shop'} title={'Broker Shop'}/>
                          <MetricTableCell dataIndex={'ttl_dtd_received'} title={'TTL DTD Received'}/>
                          <MetricTableCell dataIndex={'ttl_dtd'} title={'TTL DTD Read'} />
                          <MetricTableCell dataIndex={'dtd'} title={`${route.split(" ")[0]} DTD Read`} />
                          <MetricTableCell dataIndex={'wtd'} title={`${route.split(" ")[0]} WTD Read`} />
                          <MetricTableCell dataIndex={'mtd'} title={`${route.split(" ")[0]} MTD Read`} />
                          <MetricTableCell dataIndex={'ytd'} title={`${route.split(" ")[0]} YTD Read`} />
                        </thead>
                          <tbody>
                              {tableData.map((row, index) => (
                              <tr align={"center"} className={isDarkModeOn ? "metric_dashboard_row_dark": "metric_dashboard_row"}>
                                <td>{row.name}</td>
                                <td>{row.shop}</td>
                                <td>{row.ttl_dtd_received}</td>
                                <td>{row.ttl_dtd}</td>
                                <td>{row.dtd}</td>
                                <td>{row.wtd}</td>
                                <td>{row.mtd}</td>
                                <td style={{justifyContent: 'center'}}>{row.ytd}</td>
                              </tr>
                              ))}
                          </tbody>
                      </table>
                    </div>
                  : tableData.length === 0 && loading === false ? <p>Nothing to show</p>: <></>}
                </Card>
              </Col> 
            </Row>
        </div>
        ):(
          <Overlay
            isOpen={!chartData || !tData}
            className={loadingModelCSS}
            >
            <div style={{ top: '50%', left: '45%', position: 'fixed', display: 'flex'}}>
              <LoadingIcon />
              <h4>Loading Data</h4>
            </div>
          </Overlay>
        )
    )}
    </MarineContext.Consumer>
)};
export default MetricDashboard;
