import { Dialog as BPDialog, IDialogProps as BPIDialogProps } from '@blueprintjs/core';
import React from 'react';

export type IDialogProps = BPIDialogProps;
export const Dialog: React.FC<IDialogProps> = (props) => {
  let newProps = props;
  if (!props.hasBackdrop) {
    newProps = {
      ...newProps,
      backdropClassName: `${props.backdropClassName || ''} hasBackdrop`,
    };
  }
  return <BPDialog {...newProps} />;
};
