import React from "react";
import SimpleTable from "../../SimpleTable";
import { formatDate } from "../../../helpers";

// Functional component dataOverview for displaying data overview.
// It takes 'data' object as a prop.
const dataOverview = ({ data }) => {
  const dataKeys = Object.keys(data);
  return (
    <div>
      <h4 style={{ fontSize: "24px", lineHeight: "32px" }}>
        Rule #{data.RuleID}
      </h4>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <SimpleTable
          style={{ flex: "0 0 auto", fontSize: "14px", lineHeight: "22px" }}
        >
          {dataKeys
            .filter((d) => d !== "RuleID")
            .map((d) => (
              <tr>
                <td>{d}:</td>
                <td>
                  {d === "LastUpdated" ? (
                    formatDate(data[d])
                  ) : // Format the date here
                  typeof data[d] === "string" ? (
                    data[d] || "-"
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                      }}
                    >
                      {data[d] ? (
                        Object.values(data[d]).map((tag) => <span>{tag}</span>)
                      ) : (
                        <span>-</span>
                      )}
                    </div>
                  )}
                </td>
              </tr>
            ))}
        </SimpleTable>
      </div>
    </div>
  );
};

export default dataOverview;
