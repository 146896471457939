import React from "react";
import GeneralTable from "./GeneralTable";
import {useSTPMappingSystemColumns} from "./tableColumns";


const STPMappingSystemColumnsTable = ({data, loading}) => {
    const columns = useSTPMappingSystemColumns()
    return <GeneralTable
        emptyText="No Columns to display"
        columns={columns}
        loading={loading}
        data={data}
    />

}
export default STPMappingSystemColumnsTable;