import React, {useState, useEffect} from "react";
import { Row, Col } from "antd";
import useAxios from "axios-hooks";


import { DragDropContext } from "react-beautiful-dnd";
import { url, getCSRF } from "../../../../../helpers";
import Toaster from "../../../../Toaster";
import TierTable from "./TierTable";
import axios from "axios";

const TierCustomerGroupComponent = () => {
    const [isExtraLargeScreen, setIsExtraLargeScreen] = useState(window.innerWidth > 1600)
    const [customerData, setCustomerData] = useState([]);
    const [customerT1Data, setCustomerT1Data] = useState([]);
    const [customerT2Data, setCustomerT2Data] = useState([]);
    const [customerT3Data, setCustomerT3Data] = useState([]);
    
    const [{data: tempCustomerData}, refetch] = useAxios({
        url: url('/customer/get_customers_by_tier/')
    })
    const [{data: tempCustomerT1Data}, refetch1] = useAxios({
        url: url('/customer/get_customers_by_tier/?tier_customer=Tier 1')
    })
    const [{data: tempCustomerT2Data}, refetch2] = useAxios({
        url: url('/customer/get_customers_by_tier/?tier_customer=Tier 2')
    })
    const [{data: tempCustomerT3Data}, refetch3] = useAxios({
        url: url('/customer/get_customers_by_tier/?tier_customer=Tier 3')
    })
    
    useEffect(() => {
        window.addEventListener("resize", () => setIsExtraLargeScreen(window.innerWidth > 1700));
    }, [])

    useEffect(() => {
        if (!tempCustomerData) return
        setCustomerData(tempCustomerData.map((item, index)=> ({...item, key: 'data_' + index})))
    }, [tempCustomerData])

    useEffect(() => {
        if (!tempCustomerT1Data) return
        setCustomerT1Data(tempCustomerT1Data.map((item, index)=> ({...item, key: 'data_1' + index})))
    }, [tempCustomerT1Data])

    useEffect(() => {
        if (!tempCustomerT2Data) return
        setCustomerT2Data(tempCustomerT2Data.map((item, index)=> ({...item, key: 'data_2' + index})))
    }, [tempCustomerT2Data])

    useEffect(() => {
        if (!tempCustomerT3Data) return
        setCustomerT3Data(tempCustomerT3Data.map((item, index)=> ({...item, key: 'data_3' + index})))
    }, [tempCustomerT3Data])

    const dataSource = [
        {
            key: 'Tier',
            tData: customerData,
            tSetter: setCustomerData
        },
        {
            key: 'Tier 1',
            tData: customerT1Data,
            tSetter: setCustomerT1Data
        },
        {
            key: 'Tier 2',
            tData: customerT2Data,
            tSetter: setCustomerT2Data
        },
        {
            key: 'Tier 3',
            tData: customerT3Data,
            tSetter: setCustomerT3Data
        }
    ]

    const onDragEnd = (result) => {
        if(!result.destination) return;
        if (result.source.droppableId === result.destination.droppableId) return;

        const source = dataSource.find((item) => item.key === result.source.droppableId)
        const destination = dataSource.find((item) => item.key === result.destination.droppableId)
        const draggedId = result.draggableId
        const draggedObject = source.tData.find((item) => item.key === draggedId)
        source.tSetter(source.tData.filter((item) => item.key !== draggedId))
        destination.tSetter([...destination.tData, draggedObject])
        axios({
            method: 'POST',
            url: url(`/customer/${draggedObject.customer_id}/update_customer_tier/`),
            data: {
                'tier': result.destination.droppableId === 'Tier' ? 'Basis' : result.destination.droppableId
            },
            headers: {
                "X-CSRFToken": getCSRF(),
            },
          }).then((response) => {
            Toaster.show({
                message: `${draggedObject.customer}'s Tier has been updated to ${result.destination.droppableId === 'Tier' ? 'Basis' : result.destination.droppableId}. `,
                icon: "small-tick",
                intent: "success",
              });
          }).catch((err) => {
            refetch()
            refetch1()
            refetch2()
            refetch3()
            Toaster.show({
                message: err.response.data.error || "An error occurred while updating customer tier. ",
                icon: "warning-sign",
                intent: "danger",
              })
          })
        
    };
    return (
        <div style={{'clear': 'both'}}>
            <h5 style={{fontSize: '20px', display: 'inline'}}>Tier Customer Groups</h5>
            <DragDropContext onDragEnd={onDragEnd}>
            {isExtraLargeScreen ? (
                <Row justify="space-between">
                    <Col xs={24} sm={24} md={8} lg={8} xl={6} style={{paddingRight: 16}}>
                        <TierTable tier={'Tier'} data={customerData}/>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={6} style={{paddingRight: 16}}>
                        <TierTable tier={'Tier 1'} data={customerT1Data}/>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={6} style={{paddingRight: 16}}>
                        <TierTable tier={'Tier 2'} data={customerT2Data}/>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={6} style={{paddingRight: 16}}>
                        <TierTable tier={'Tier 3'} data={customerT3Data}/>
                    </Col>
                </Row>
            ) : (
                <>
                <Row>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} style={{paddingRight: 16}}>
                        <TierTable tier={'Tier'} data={customerData}/>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} style={{paddingRight: 16}}>
                        <TierTable tier={'Tier 1'} data={customerT1Data}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} style={{paddingRight: 16}}>
                        <TierTable tier={'Tier 2'} data={customerT2Data}/>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} style={{paddingRight: 16}}>
                        <TierTable tier={'Tier 3'} data={customerT3Data}/>
                    </Col>
                </Row>
                </>
            )}
            
            </DragDropContext>
        </div>
    )
}
export default TierCustomerGroupComponent;
