import { EditableText as BPEditableText, IEditableTextProps as BPIEditableTextProps, Intent } from '@blueprintjs/core';
import React from 'react';
import '../../assets/scss/core.scss';

export interface IEditableTextProps extends BPIEditableTextProps {
  alwaysRenderInput?: boolean;
  className?: string;
  confirmOnEnterKey?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  intent?: Intent;
  isEditing?: boolean;
  maxLength?: number;
  maxLines?: number;
  minLines?: number;
  minWidth?: number;
  multiline?: boolean;
  placeholder?: string;
  selectAllOnFocus?: boolean;
  type?: string;
  value?: string;
}
export const EditableText: React.FC<IEditableTextProps> = (props) => {
  return <BPEditableText {...props} />;
};
