import { ITextProps as BPITextProps, Text as BPText } from '@blueprintjs/core';
import '../../assets/scss/core.scss';

export interface ITextProps extends BPITextProps {
  className?: string;
  ellipsize?: boolean;
  tagName?: keyof JSX.IntrinsicElements;
  title?: string;
}
export class Text extends BPText {}
