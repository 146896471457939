import React, {useEffect, useState} from "react"
import useAxios from "axios-hooks"
import {Link, Route, Switch, useRouteMatch} from "react-router-dom"
import {Button, Card, FormGroup, InputGroup} from "rt-design-system-backup"
import {Col, Row} from "antd"
import SVG from 'react-inlinesvg'
import RioLogo from "../../../assets/RioLogo.svg"
import NexTradeLogo from "../../../assets/NexTrade_Colour_logo_Vector_LIGHT_BG.png"
import NexTradeTextLogo from "../../../assets/NT_word_navy.png"
import NexTradeLogoSVG from "../../../assets/NexTrade_Colour_logo_Vector_LIGHT_BG.svg"
import NexTradeTextSVG from "../../../assets/NexTrade_WORD_logo_Vector_DARKBLUE.svg"

import {getEnvironment, report, url as urlHelper} from "../../helpers"

import "./style.scss"

export const LoginForm = () => {
    let {path, url} = useRouteMatch();

    const [{error}, fetch] = useAxios(
        {
            url: urlHelper('/token/'),
            method: 'POST'
        },
        {manual: true}
    );

    const [shouldAllowEmailLogin, setEmailLogin] = useState(false);

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let shouldShowSSOParam = params.get('allowEmailLogin');
        if (shouldShowSSOParam) {
            setEmailLogin(state => ({...state, shouldAllowEmailLogin: true}))
        }
    }, [shouldAllowEmailLogin]);

    const send = async (e) => {
        e.preventDefault();

        try {
            const res = await fetch({
                data: new FormData(e.target)
            });
            localStorage.clear();
            let {access, refresh} = res.data;
            localStorage.setItem("accessToken", access);
            localStorage.setItem("refreshToken", refresh);
            report('login');
            window.location.reload()
        } catch (err) {
            report('login_failed')
        }
    };

    const handleSSOSignIn = () => {
        window.location.replace(urlHelper('/saml/login'));
    };

    const SSOContainer = ({classes,outline,buttonIntent}) => {
        return(
                <div className="rt-login-form-input">
                    <FormGroup>
                        <Button onClick={handleSSOSignIn} large outlined={outline} className={classes} intent={buttonIntent}
                            style={{display: "block", 
                                marginLeft: "auto", 
                                marginRight: "auto",
                                width: "fit-content",
                                borderRadius: "100px",
                                lineHeight: "0px"
                            }}
                        >
                            Sign in with Rio Tinto SSO</Button>
                    </FormGroup>
                </div>
        )
    }

    return (
        <form style={{display: "block", marginTop: "36px"}} onSubmit={send}>
            <img src={NexTradeLogo}     width={75}  style={{display: "block", marginLeft: "auto", marginRight: "auto", marginBottom: "20px"}}></img>
            <SVG width={150} src={NexTradeTextSVG} style={{display: "block", marginLeft: "auto", marginRight: "auto", marginBottom: "30px"}}/>
            <Switch>
                <Route path={`${path}/forgot-password`}>
                    <h4 style={{fontSize: "24px", fontWeight: 700}}>Forgot password</h4>
                    <p style={{marginBottom: "52px", marginTop: "52px"}}>
                        To issue a new password, please contact quoteboard@riotinto.com
                    </p>
                    <Link to={`${url}`} className="bp3-button bp3-large bp3-intent-primary">
                        Back to login
                    </Link>
                </Route>
                <Route path={`${path}/`}>
                    {
                        error ? <p style={{color: "#E60D2E"}}>
                            Invalid email or password.
                        </p> : null
                    }
                    <div>
                        {getEnvironment() === 'prod' && !shouldAllowEmailLogin ? (
                            <SSOContainer classes="outline-btn darkBtn" outline={false} buttonIntent="primary"/>
                        ): getEnvironment() !== 'prod' || (getEnvironment() === 'prod' && shouldAllowEmailLogin)?(
                            <div>
                                <SSOContainer classes="outline-btn" outline={true} buttonIntent="secondary"/>
                                <div className="rt-login-form-input">
                                    <FormGroup
                                        className=""
                                        contentClassName=""
                                        helperText=""
                                        intent="none"
                                        label="Email"
                                        labelFor="email"
                                        labelInfo=""
                                    >
                                        <InputGroup name="email" large type="email"
                                                    placeholder="Enter email address"/>
                                    </FormGroup>
                                </div>
                                <div className="rt-login-form-input">
                                    <FormGroup
                                        className=""
                                        contentClassName=""
                                        helperText=""
                                        intent="none"
                                        label="Password"
                                        labelFor="password"
                                        labelInfo=""
                                    >
                                        <InputGroup name="password" large type="password"
                                                    placeholder="Enter password"/>
                                    </FormGroup>
                                </div>
                                <div className="rt-login-form-input">
                                    <FormGroup>
                                        <Button type="submit" large className="login-btn" intent='primary'
                                        style={{display: "block", 
                                            marginLeft: "auto", 
                                            marginRight: "auto",
                                            width: "fit-content",
                                            borderRadius: "100px",
                                            lineHeight: "0px"
                                        }}
                                        >
                                            Sign in
                                        </Button>
                                    </FormGroup>
                                </div>
                                </div>
                            ):<div/>
                        }
                                <p style={{marginBottom: "52px", marginTop: "52px", textAlign: "center"}}>
                                    Trouble signing in? Contact<br></br>
                                    <a href="mailto:CommercialDigitalNexTradeTeam@riotinto.com">CommercialDigitalNexTradeTeam@riotinto.com</a>
                                </p>
                    </div>
                </Route>
            </Switch>
        </form>
    )
};

export const LoginContainer = () => {
    return <div className="rt-login" style={{padding: "40px"}}>
        <Row align="middle" className="rt-login-row">
            <Col span={6} xs={{span: 24, offset: 0}} lg={{span: 10, offset: 7}}>
                <div className="rt-login-container">
                    <div className="rt-login-logo">
                        <SVG src={RioLogo} style={{padding: "20px"}}/>
                    </div>
                    <Card elevation={3} style={{padding: "64px", paddingTop: "32px"}}>
                        <LoginForm/>
                    </Card>
                </div>
            </Col>
        </Row>
    </div>
};


export const Login = () => {
    return <LoginContainer/>
};

