import { Collapse as BPCollapse, ICollapseProps as BPICollapseProps } from '@blueprintjs/core';
import React from 'react';
import '../../assets/scss/core.scss';

export interface ICollapseProps extends BPICollapseProps {
  keepChildrenMounted?: boolean;
}

export const Collapse: React.FC<ICollapseProps> = (props) => {
  return <BPCollapse {...props} />;
};
