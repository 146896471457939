import React from "react"
import { Router as ReactRouter } from "react-router-dom"
import { createBrowserHistory } from "history"

const history = createBrowserHistory()

const Router = ({ children }) => {
    return <ReactRouter history={history}>
        {children}
    </ReactRouter>
};

export default Router
