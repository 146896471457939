import React, { useEffect } from 'react'
import useAxios from 'axios-hooks';
import { useHistory, useParams } from 'react-router-dom';
import STPMappingForm from '../../STPMappingForm';
import { singleMappingTransformer } from '../STPMapping';
import Toaster from '../../Toaster';
import { url } from '../../../helpers';


// UpdateSTPMapping is a React component for updating an existing STP mapping.
// It fetches the details of a specific mapping based on the mapping ID obtained from the URL parameters.
// The component also fetches necessary data for the system list and NexTrade Fields list to populate form dropdowns.
const UpdateSTPMapping = () => {
    const { mappingId } = useParams();
    const history = useHistory()

    // Axios hooks for fetching data.
    // Fetches the specific mapping based on the mapping ID.
    const [{ data, loading, error }, refetch] = useAxios({ url: url("mapping_rules/" + mappingId + "/") });
    const [{ data: SystemList, loading: systemLoading }] = useAxios({ url: url('systems/') })
    const [{ data: NexTradeFieldsList, loading: NexTradeFieldLoading }] = useAxios({ url: url('ddp_fields/') })
    const [{ data: DDPFieldList, loading: DDPFieldLoading }] = useAxios({ url: url('ddp_enums/') })
    const mapping = data && singleMappingTransformer(data)

    // useEffect hook to refetch the mapping data on component mount.
    useEffect(() => {
        refetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // useEffect hook to handle errors or the absence of data.
    useEffect(() => {
        if (!loading) {
            if (!data) {
                Toaster.show({
                    message:
                        "No mapping found ",
                    icon: "warning-sign",
                    intent: "danger",
                });
                history.goBack()
            } else if (error) {
                Toaster.show({
                    message:
                        `An error occurred while getting the mapping.`,
                    icon: "warning-sign",
                    intent: "danger",
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, data, history]);

    if (loading || systemLoading || NexTradeFieldLoading || DDPFieldLoading) {
        return <div>Loading</div>
    }

    return <STPMappingForm data={mapping} SystemList={SystemList} NexTradeFieldsList={NexTradeFieldsList} DDPFieldList={DDPFieldList}/>;
};
export default UpdateSTPMapping;
