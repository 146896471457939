import {
  Slider as BPSlider,
  ISliderProps as BPISliderProps,
  RangeSlider as BPRangeSlider,
  IRangeSliderProps as BPIRangeSliderProps,
  MultiSlider as BPMultiSlider,
  IMultiSliderProps as BPIMultiSliderProps,
} from '@blueprintjs/core';
import '../../../assets/scss/core.scss';

export interface ISliderProps extends BPISliderProps {
  disabled?: boolean;
}
export type IRangeSliderProps = BPIRangeSliderProps;
export type IMultiSliderProps = BPIMultiSliderProps;

export const Slider = BPSlider;
export const RangeSlider: typeof BPRangeSlider = BPRangeSlider;
export const MultiSlider: typeof BPMultiSlider = BPMultiSlider;
