import React, { useState, useRef, useEffect } from "react";

import "./style.scss";

const ButtonSelect = ({
  items,
  multiple,
  name,
  required,
  large,
  onChange,
  inputRef,
  defaultValue,
}) => {
  const [selected, setSelected] = useState(defaultValue || []);

  const input = useRef(false);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (onChange && defaultValue) onChange(defaultValue);
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (inputRef && typeof inputRef === "function") inputRef(input);
  }, [input, inputRef]);

  return (
    <div className="rtcmd-button-select">
      {items.map((item, key) => {
        return (
          <div className="rtcmd-button-select-button" key={key}>
            <button
              type="button"
              className={selected.indexOf(item.value) >= 0 ? "selected" : ""}
              tabIndex="0"
              onClick={() => {
                setSelected((selected) => {
                  const result = (() => {
                    if (selected.indexOf(item.value) >= 0) {
                      if (!multiple) return [];
                      return [...[...selected].filter((e) => e !== item.value)];
                    } else {
                      if (!multiple) return [item.value];
                      return [...selected, item.value];
                    }
                  })();

                  onChange(result);

                  return result;
                });
              }}
            >
              {item.name}
            </button>
          </div>
        );
      })}
      <input
        ref={input}
        style={{ display: "none" }}
        onChange={onChange}
        required={required || undefined}
        name={name}
        value={selected.join(",")}
      />
    </div>
  );
};

export default ButtonSelect;
