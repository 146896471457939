import { SearchOutlined } from "@ant-design/icons";
import { Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { Link, useHistory } from "react-router-dom";
import { Icon, Tooltip } from "rt-design-system-backup";
import * as XLSX from "xlsx";
import { formatDate, url } from "../../helpers";
import { LogColumns } from "../../helpers/constants";
import { useQueryParams } from "../../hooks/useQueryParams";
import Page from "../Page";
import { FilterDropDown } from "../STPAPIConsoleTable/FilterDropDown";

const STPApiLogs = () => {
  const [searchText, setSearchText] = useState("");
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const queryParams = useQueryParams();

  const uniqueUserNames = Array.from(
    new Set(logs.map((log) => log.user)),
  ).sort();
  const uniqueModule = Array.from(
    new Set(logs.map((log) => log.module)),
  ).sort();
  const uniqueActivityType = Array.from(
    new Set(logs.map((log) => log.activityType)),
  ).sort();

  const fetchDataAndUpdateUI = async () => {
    try {
      setLoading(true);
      const response = await axios.get(url("/stp_logs/"));
      const formattedData = response.data.map((item) => ({
        logID: item["Log ID"],
        date: item["Date & Time"],
        user: item.User,
        module: item["Type"] ? item["Type"] : "",
        activityType: item["Activity Type"],
        description: item.Description,
      }));
      setLogs(formattedData);
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataAndUpdateUI();
  }, []);

  const formatDescriptionForXLSX = (description) => {
    let descriptionObj;
    try {
      descriptionObj = JSON.parse(description);
    } catch (e) {
      return description;
    }

    const recursiveFormat = (obj, isNested = false) => {
      if (Array.isArray(obj)) {
        return obj.map((item) => recursiveFormat(item, true)).join(", ");
      } else if (obj && typeof obj === "object") {
        return Object.entries(obj)
          .map(([key, value]) => {
            const formattedValue = recursiveFormat(value, true);
            return `${key}: ${formattedValue}`;
          })
          .join(", ");
      }
      return obj === null || obj === undefined ? "" : obj.toString();
    };

    const hasPreviousHistory = !!descriptionObj.previous_history;
    const hasCurrentChanges = !!descriptionObj.current_changes;

    const formattedPreviousHistory = hasPreviousHistory
      ? recursiveFormat(descriptionObj.previous_history)
      : "";
    const formattedCurrentChanges = hasCurrentChanges
      ? recursiveFormat(descriptionObj.current_changes)
      : "";

    return hasPreviousHistory && hasCurrentChanges
      ? `${formattedPreviousHistory} TO ${formattedCurrentChanges}`
      : `${formattedPreviousHistory}${formattedCurrentChanges}`;
  };

  const downloadXlsx = (dataToDownload) => {
    const data = Array.isArray(dataToDownload)
      ? dataToDownload
      : [dataToDownload];
    const formattedData = data.map((item) => ({
      ...item,
      description: formatDescriptionForXLSX(item.description),
    }));

    const headers = LogColumns.filter((header) => typeof header === "string");

    const ws = XLSX.utils.json_to_sheet(formattedData, { header: headers });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, "log.xlsx");
  };

  const formatDescription = (description) => {
    let descriptionObj;
    try {
      descriptionObj = JSON.parse(description);
    } catch (e) {
      return description;
    }

    const recursiveFormat = (obj, isNested = false) => {
      if (Array.isArray(obj)) {
        return obj.map((item) => recursiveFormat(item, true)).join(", ");
      } else if (obj && typeof obj === "object") {
        return Object.entries(obj)
          .map(([key, value]) => {
            if (isNested) {
              return `${key}: ${recursiveFormat(value, true)}`;
            } else {
              const previousHistoryValue =
                descriptionObj.previous_history?.[key];
              const isValueChanged =
                previousHistoryValue !== undefined &&
                previousHistoryValue !== value;

              if (isValueChanged) {
                const formattedValue = `<strong>${recursiveFormat(value)}</strong>`;
                return `${key}: ${formattedValue}`;
              } else {
                return `${key}: ${recursiveFormat(value)}`;
              }
            }
          })
          .join(", ");
      }
      return obj === null || obj === undefined ? "" : obj.toString();
    };

    const hasPreviousHistory = !!descriptionObj.previous_history;
    const hasCurrentChanges = !!descriptionObj.current_changes;

    const formattedPreviousHistory = hasPreviousHistory
      ? recursiveFormat(descriptionObj.previous_history)
      : "";
    const formattedCurrentChanges = hasCurrentChanges
      ? recursiveFormat(descriptionObj.current_changes)
      : "";

    const combinedString =
      hasPreviousHistory && hasCurrentChanges
        ? `${formattedPreviousHistory} <b>TO</b> ${formattedCurrentChanges}`
        : `${formattedPreviousHistory}${formattedCurrentChanges}`;

    return <div dangerouslySetInnerHTML={{ __html: combinedString }} />;
  };

  const EnabledIcon = ({ icon, linkTo, onClick }) => {
    const history = useHistory();

    const handleClick = (e) => {
      e.preventDefault();
      if (onClick) {
        onClick(e);
      }
      if (linkTo) {
        history.push(linkTo);
      }
    };

    return (
      <a href={linkTo || "#"} onClick={handleClick}>
        <Icon icon={icon} style={{ cursor: "pointer" }} />
      </a>
    );
  };

  const columns = [
    {
      title: "Log ID",
      dataIndex: "logID",
      key: "logID",
      sorter: (a, b) => a.logID - b.logID,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <FilterDropDown
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          handleReset={() => {
            clearFilters();
            setSearchText("");
          }}
          handleSearch={(selectedKeys, confirm) => {
            setSearchText(selectedKeys[0]);
            confirm();
          }}
          clearFilters={clearFilters}
          confirm={confirm}
        />
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? "#1890ff" : "#8e8e8e", fontSize: "16px" }}
        />
      ),
      onFilter: (value, record) =>
        record.logID.toString().includes(value.toString()),
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
      fixed: "left",
    },
    {
      title: "Date & Time (UTC)",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      sortDirections: ["descend", "ascend"],
      onFilter: (value, record) => formatDate(record.date).includes(value),
      render: (text) => <div>{text ? formatDate(text) : "-"}</div>,
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      filters: uniqueUserNames.map((name) => ({ text: name, value: name })),
      onFilter: (value, record) => record.user.includes(value),
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },
    {
      title: "Module",
      dataIndex: "module",
      key: "module",
      filters: uniqueModule.map((name) => ({ text: name, value: name })),
      onFilter: (value, record) => record.module.includes(value),
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },
    {
      title: "Activity Type",
      dataIndex: "activityType",
      key: "activityType",
      filters: uniqueActivityType.map((name) => ({ text: name, value: name })),
      onFilter: (value, record) => record.activityType.includes(value),
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 500,
      render: (text) => <div>{formatDescription(text)}</div>,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div style={{ whiteSpace: "nowrap" }}>
          <EnabledIcon
            icon="eye-open"
            linkTo={`/stp-logs/${record.logID}`}
            onClick={() => console.log("Icon clicked!")}
          />

          <Link>
            <EnabledIcon
              icon="download"
              onClick={() => {
                downloadXlsx(record);
              }}
            />
          </Link>
        </div>
      ),
    },
  ];

  return (
    <Page
      title="Logs"
      tabs={[]}
      rightActions={
        <div className="rtcmd-stp-api-console-table">
          <Tooltip intent="primary" content="Download ALL STP Logs">
            <button
              onClick={() => downloadXlsx(logs)}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                background: "none",
                border: "none",
                padding: 0,
                color: "inherit",
                font: "inherit",
              }}
            >
              <Icon icon="download" />
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "normal",
                  marginLeft: 8,
                  textDecoration: "underline",
                }}
              >
                Download All
              </span>
            </button>
          </Tooltip>
        </div>
      }
    >
      <Table
        locale={{ emptyText: "No logs to display" }}
        loading={loading}
        columns={columns}
        dataSource={logs}
        pagination={{
          current: Number(queryParams.get("page")) || 1,
          pageSize: 20,
        }}
        onChange={(pagination) => {
          history.push({
            pathname: window.location.pathname,
            search: `?page=${pagination.current}`,
          });
        }}
      />
    </Page>
  );
};

export default STPApiLogs;
