import {Icon} from "rt-design-system-backup";
import React from "react";
import {useDeleteSystemColumnValue} from "../../../Views/STPMappingSystemColumns/api";
import {useSTPMappingSystemColumnContext} from "../../STPMappingSystemColumnsContext";
import {errorToaster, successToaster} from "../../../../helpers";

const Actions = ({record, history}) => {
    const {reloadSystemColumns, reloadSystemColumnValues} = useSTPMappingSystemColumnContext()
    const {refetch: deleteColumnValue} = useDeleteSystemColumnValue(record.id)
    return <>
        <div style={{whiteSpace: "nowrap"}}>
            <Icon
                icon="edit"
                style={{cursor: "pointer"}}
                onClick={() => {
                    history.push(`/stp-mapping-console/system-column-values/update/${record.id}`);
                }}
            />
            <Icon
                icon="delete"
                style={{cursor: "pointer"}}
                onClick={async () => {
                    deleteColumnValue().then(() => successToaster('System Column Value deleted')).catch(()=> errorToaster('Failed to delete System Column Value'));
                    await reloadSystemColumns()
                    await reloadSystemColumnValues()
                }}
            />
        </div>
    </>;
}

const renderSystemColumnValueActions = (history) => (_, record) => <Actions record={record} history={history}/>;

export {renderSystemColumnValueActions}