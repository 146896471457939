import React, {useContext, useEffect, useState} from "react";
import useAxios from "axios-hooks";
import styled from "styled-components";
import {useHistory, useParams} from "react-router-dom";
import Toaster from "../../Toaster";

import {Button, DateInput, Icon, TextArea,} from "rt-design-system-backup";

import Form, {FormContext, FormFooter, InputGroup} from "../../Form";
import {capitalize, getCSRF, getErrorMessage, isValidNumber, report, url,} from "../../../helpers";

import useLogin from '../../useLogin'

import "./style.scss";
import {css} from "@emotion/css";
import {FormGroup} from "rt-design-system-backup";
import {dateFormat} from "../Maturities";

const Column = styled.div`
  max-width: 450px;
`;


export const loadingModelCSS = css({
  '.bp3-overlay-content': {
    borderRadius: '5px',
    backgroundColor: '#BFCAD7',
    left: '45%',
    top: '10%',
    fontSize: '18px',
    padding: 10,
  },
  '.bp3-overlay-content h4': {
    margin: 5,
  }
});

const TradeInputWrapper = styled.div`
  & > div {
    display: flex;
    flex-direction: row;

    .bp3-form-group {
      margin: 4px;
      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        margin-left: 0;
      }
    }

    .rtcmd-button-select {
      flex-wrap: nowrap;
    }
  }
`;

const TradeInput = ({ report, remove, id, maturities, unit, lastAction, showQuantity, data={} }) => {
  const max_volume_limit = unit === 'lbs' ? 4000000 : 10000000
  const valid_maturity = data.maturity && maturities.map(maturity => maturity.value).includes(data.maturity.value)
  return (
    <TradeInputWrapper>
      <Form tag="div" onChange={report}>
        <InputGroup
          defaultValue={data.action ? [data.action.value] : ["Buy"]}
          required
          name="action"
          type="select"
          items={[
            {
              name: "Buy",
              value: "Buy",
            },
            {
              name: "Sell",
              value: "Sell",
            },
          ]}
        />
        <InputGroup
            required
            name="maturity"
            placeholder={"Month"}
            type="select"
            defaultValue={ valid_maturity ? [data.maturity.value] : undefined }
            items={maturities}
        />
        <InputGroup
          isInvalid={(value) => {
            return isValidNumber(value, true);
          }}
          rightElement={<span style={{ color: '#002C5F' }}>{unit}</span>}
          required
          placeholder="Quantity"
          defaultValue={data.quantity ? data.quantity : undefined}
          addWarning={(value) => { return value >= max_volume_limit ? `The volume input is greater than or equal to ${max_volume_limit} ${unit}` : false }}
          name="quantity"
          type="number"
        />
        <div className="bp3-form-group rtcmd-request-form-icon" data-key={id}>
          <Icon onClick={id > 0 ? remove : void 0} icon="remove" />
        </div>
      </Form>
    </TradeInputWrapper>
  );
};

const TypeAwareNewRequestForm = ({type, unit, maturities, request, duplicating }) => {
  const initialTrades = duplicating ? [false] : request.trades.map((trade) => {
    return {
      data: {
        action: { name: trade.action, value: trade.action },
        maturity: { name: capitalize(trade.maturity_str), value: trade.maturity },
        quantity: trade.quantity,
        maturity_id: trade.maturity,
      },
      valid: true,
      invalid: [],
    };
  });

  const [trades, setTrades] = useState(initialTrades);
  const actions = trades.filter(trade => trade && trade.data && trade.data.action).map(trade => {
      return trade.data.action.value
  })

  const { invalid, setFormContext, ...formContext } = useContext(FormContext);
  const lastAction = actions && actions[actions.length-1]

  const filterExistingMaturities = (maturities) => {
    const alreadySelectedMaturities =
      formContext.data &&
        formContext.data.trades &&
        formContext.data.trades.length
        ? formContext.data.trades
          .map((trade) => {
            if (trade && trade.maturity && trade.maturity.length)
              return trade.maturity[0].value;
            return false;
          })
          .filter((item) => item)
        : [];
    return maturities.filter((maturity) => {
      return alreadySelectedMaturities.indexOf(maturity.value) < 0;
    });
  };

  const updateTradesStatus = () => {
    let valid = true;
    let data = [];

    trades.forEach((trade) => {
      if (trade.valid !== true) {
        valid = false;
      }

      data.push(trade.data);
    });

    setFormContext((formContext) => {
      let invalid = [...formContext.invalid]

      if (!valid && formContext.invalid.indexOf("trades") < 0) {
          invalid = [...invalid, "trades"]
      } else if (valid && formContext.invalid.indexOf("trades" >= 0)) {
           invalid = invalid.filter((eName) => eName !== "trades")
      }
      return {invalid: invalid};
    });
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    updateTradesStatus();

    setFormContext({
      data: {
        trades: trades.map((trade) => trade.data),
      },
    });
  }, [trades]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const report = (key) => {
    return (data) => {
      const tradesCopy = [...trades];
      tradesCopy[key] = data;

      if (JSON.stringify(data) !== JSON.stringify(trades[key])) {
        setTrades(tradesCopy);
      }
    };
  };

  const remove = (key) => {
    return () => {
      const tradesCopy = [...trades].filter((_, itemKey) => itemKey !== key);
      setTrades(tradesCopy);
    };
  };

  const requestValidities = [
    {
      name: 'Good until cancelled',
      value: 'GTC',
    },
    {
      name: 'Good until specific date',
      value: 'GTD',
    }
  ]
  const today = new Date();

  return (
    <>
      {type && type[0].value === "Limit Order" ? (
        <>
          <FormGroup label={'Target Price'}>
            <InputGroup
              placeholder="Price"
              name="target_price"
              defaultValue={[request.target_price]}
              type="text"
            />
          </FormGroup>
          <FormGroup label={'Validity'}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputGroup
                required
                type="select"
                name="validity"
                key="validity"
                defaultValue={['GTC']}
                items={requestValidities}
              />
              <div style={{ margin: '10px' }}></div>
              <FormContext.Consumer>
                {(ctx) =>
                  ctx.data.validity &&
                    ctx.data.validity.length &&
                    ctx.data.validity[0].value === "GTD" ? (
                      <DateInput
                        inputProps={{ name: "valid_until" }}
                        {...dateFormat}
                        minDate={today}
                        maxDate={new Date(today.getFullYear()+2, today.getMonth(), today.getDate())}
                        required
                        key="valid_until"
                        placeholder={'Date'}
                      />
                  ) : null
                }
              </FormContext.Consumer>
            </div>
          </FormGroup>
        </>
      ) : null}
      <p>Maturities</p>
      <FormContext.Consumer>
        {(ctx) => {
          return (
            <>
              {trades.map((_, key) => {
                return (
                  <TradeInput
                    key={key}
                    id={key}
                    remove={remove(key)}
                    report={report(key)}
                    unit={unit}
                    data={initialTrades[key] ? initialTrades[key].data : {}}
                    showQuantity={key ? trades[0].data.quantity : null}
                    lastAction={lastAction}
                    maturities={filterExistingMaturities(
                      maturities.map((maturity) => {
                        return {
                          value: maturity.maturity.id,
                          name: capitalize(maturity.maturity.shortened_string),
                        };
                      })
                    )}
                  />
                );
              })}
              {type && ["Limit Order", "At Market", "LME Official Price", "Internal Transfer", "MOC/TAS"].includes(type[0].value) ?
                <Button
                  onClick={() => {
                    setTrades([...trades, false]);
                  }}
                  key={type || 'key'}
                  icon="add"
                  minimal
                >
                  Add maturity
                </Button> : null
              }
            </>
          );
        }}
      </FormContext.Consumer>
    </>
  );
};

const NewOrderForm = ({duplicating}) => {
  useLogin(true)

  const today = new Date();
  const { copper } = useParams();

   const [{ data: request, loadingCopper }, refetch] = useAxios({
    url: url("/copper/" + copper+"/"),
  });

  const [{data: copperEntity, loading: copperEntityLoading}, ] = useAxios({
    url: url("/copper/entity/"),
  });

  const [{ loading: customersLoading, data: customers }] = useAxios({
    url: url("/customer/?group=CO"),
  });

  const [{ loading: contractLoading, data: contracts }] = useAxios({
    url: url("/copper/contract/"),
  });
  const [{ loading: maturitiesLoading, data: rawMaturities }] = useAxios({
    url: url("maturity/?display_only_copper"),
  });

  const score = ["year", "quarter", "month"];

  const maturities = (rawMaturities || [])
    .map((maturity) => {
      return {
        name:
          maturity.shortened_string.substring(0, 1).toUpperCase() +
          maturity.shortened_string.substring(1),
        value: "" + maturity.shortened_string,
        maturity: maturity,
      };
    })
    .sort((a, b) => {
      return (
        new Date(a.maturity.start) -
        new Date(b.maturity.start) +
        (score.indexOf(a.maturity.type) - score.indexOf(b.maturity.type))
      );
    });

  const strategies = [
    {
      name: "FFP",
      value: "FFP",
    },
    {
      name: "BU Trades",
      value: "BU Trades",
    },
    {
      name: "KUC",
      value: "KUC",
    },
    {
      name: "KUC Hedging",
      value: "KUC Hedging",
    },
  ];

  const order_types = {
    'FFP': [
      {
        name: "FFP Spot",
        value: "FFP Spot",
      },
      {
        name: "FFP TBC",
        value: "FFP TBC",
      },
      {
        name: "Limit Order",
        value: "Limit Order",
      },
      {
        name: "At Market",
        value: "At Market",
      },
      {
        name: "LME Official Price",
        value: "LME Official Price",
      },
      {
        name: "MOC/TAS",
        value: "MOC/TAS",
      },
      {
        name: "Internal Transfer",
        value: "Internal Transfer",
      },
    ],
    'BU Trades': [
      {
        name: "Limit Order",
        value: "Limit Order",
      },
      {
        name: "At Market",
        value: "At Market",
      },
      {
        name: "LME Official Price",
        value: "LME Official Price",
      },
      {
        name: "MOC/TAS",
        value: "MOC/TAS",
      },
      {
        name: "Internal Transfer",
        value: "Internal Transfer",
      },
    ],
  };

  const history = useHistory();

  const [{ loading: updateLoading }, updateCopperOrder] = useAxios(
    {
      url: url("/copper/" + (request && request.id) + "/"),
      method: "PUT",
      headers: {
        "X-CSRFToken": getCSRF(),
      },
    },
    { manual: true }
  );

  const [{ loading: createLoading }, createCopperOrder] = useAxios(
    {
      url: url("copper/"),
      method: "POST",
      headers: {
        "X-CSRFToken": getCSRF(),
      },
    },
    { manual: true }
  );

  const send = async (formValues, formState) => {
    const trades = (formState.data.trades || []).map((trade) => {
      return {
        action: trade.action[0].value,
        maturity: trade.maturity[0].value,
        quantity: trade.quantity,
      };
    });

    const formData = {
      order_type: formValues.order_type,
      strategy: formValues.strategy,
      contract: formValues.contract,
      entity: formValues.entity,
      trades: trades,
      customer: formValues.customer,
      pricing_date: formValues.pricing_date,
      quota_month: formValues.quota_month,
      comments: formValues.comments,
      target_price: formValues.target_price,
      is_edit: true,
    };

    try {
      report("copper_order_submitted");
      if (duplicating) {
        await createCopperOrder({data: formData});
      } else {
        await updateCopperOrder({data: formData});
      }

      Toaster.show({
        message: duplicating ? "Copper Order created." : "Copper Order updated.",
        icon: "small-tick",
        intent: "success",
      });
      history.push('/coppers')

    } catch (err) {
      report("copper_order_failed");

      Toaster.show({
        message:
          "An error occurred. " + getErrorMessage(err),
        icon: "warning-sign",
        intent: "danger",
      });
    }
    refetch()
  };

  const loading = contractLoading || maturitiesLoading || copperEntityLoading || customersLoading || loadingCopper;

  const contractMapping = {
    'Limit Order': {contracts: ['LME Copper', 'CME Copper HGS', 'CME Copper HG','ARB CME-LME'], default: 'LME Copper'},
    'At Market': {contracts: ['LME Copper', 'CME Copper HGS', 'CME Copper HG','ARB CME-LME'], default: 'LME Copper'},
    'LME Official Price': {contracts: ['LME Copper'], default: 'LME Copper'},
    'Internal Transfer': {contracts: ['LME Copper', 'CME Copper','ARB CME-LME'], default: 'LME Copper'},
    'MOC/TAS': {contracts: ["CME Copper HG", "CME Copper HGS" ,"LME Copper"], default: 'LME Copper'},
  }

  const getContract = (type) => {
    const contract = contractMapping[type]
    return contract ? contract : {contracts: ['LME Copper', 'CME Copper HGS', 'CME Copper HG'], default: 'CME Copper HGS'}
  }

  const getPricingDate = (dateStr) => {
    let d = new Date();
    d.setMonth(parseInt(dateStr.split('-')[1] - 1))
    d.setFullYear(dateStr.split('-')[0])
    d.setDate(dateStr.split('-')[2])
    return d;
  }

  return loading || !request || !customers || !copperEntity || !maturities  ? (
    "Loading..."
  ) : (
    <Form onSubmit={send}>
      {duplicating ? <h1>Create new order</h1> : <h1>Edit Order# {copper}</h1>}
      <hr />
      <Column>
        <InputGroup
          title="Strategy"
          required
          defaultValue={[request.strategy]}
          name="strategy"
          type="select"
          items={strategies.filter(s => s.value === request.strategy)}
          placeholder={'Select one'}
        />
        <FormContext.Consumer>
          {
            ({data, setFormContext}) => {
              const order_strategy = (data.strategy && data.strategy[0].name) || request.strategy
              const order_type = (data.order_type && data.order_type[0].name) || request.order_type
              return (<>
                <InputGroup
                  title="Order Type"
                  key={order_strategy}
                  required
                  name="order_type"
                  type="select"
                  defaultValue={[order_type]}
                  items={order_types[order_strategy] || order_types['BU Trades']}
                  placeholder={'Select one'}
                />
                <InputGroup
                  title={['Internal Transfer'].includes(order_type) ? 'Entity From' : "Entity"}
                  required
                  name="entity"
                  type="select"
                  defaultValue={[request.entity.id]}
                  items={copperEntity.map(entity => {return {name: entity.name, value: entity.id}})}
                  placeholder={'Select one'}
                />
                {order_strategy === 'FFP' ?
                  <InputGroup
                    title={['Internal Transfer'].includes(order_type) ? 'Entity To' : "Customer"}
                    required
                    name="customer"
                    type="select"
                    defaultValue={[request.entity_to ? request.entity_to.id : request.customer.id]}
                    items={(['Internal Transfer'].includes(order_type) ? copperEntity : customers).map((item) => {
                      return {
                        name: ['Internal Transfer'].includes(order_type) ? item.name : item.display_name,
                        value: item.id,
                      };
                    }).sort((a, b) => a.name.localeCompare(b.name))}
                    placeholder={'Select one'}
                  /> : null
                }
                <br/>
                <h2>Pricing instructions</h2>
                <InputGroup
                  title="Contract"
                  required
                  name="contract"
                  key={getContract(order_type).default}
                  type="select"
                  defaultValue={duplicating ? null : [request.contract.id]}
                  items={contracts.filter(
                    contract => {
                      if (order_type) {
                        return getContract(order_type).contracts.includes(contract.name)
                      }
                      return true
                    }
                  ).map(contract => {return {name: contract.name, value: contract.id}})}
                  placeholder={'Select one'}
                />
                { order_strategy === 'FFP' ?
                  <InputGroup
                    title="Quota Month"
                    required name="quota_month"
                    placeholder={'Select one'}
                    defaultValue={[maturities[0].value]}
                    type="select" items={maturities}
                  /> : null
                }
              </>)
            }
          }
        </FormContext.Consumer>
        <FormContext.Consumer>
          {({ data }) => {
            return data.order_type && !['FFP TBC', 'Limit Order', 'At Market', 'Internal Transfer'].includes(data.order_type[0].name) ? (
              <FormGroup label="Pricing Date">
                <DateInput
                  inputProps={{ name: "pricing_date" }}
                  {...dateFormat}
                  defaultValue={duplicating ? null : request.pricing_date ? getPricingDate(request.pricing_date) : null}
                  maxDate={new Date(today.getFullYear()+2, today.getMonth(), today.getDate())}
                  required
                  key="pricing_date"
                  placeholder={'Date'}
                />
              </FormGroup>
            ) : null;
          }}
        </FormContext.Consumer>
        <FormContext.Consumer>
          {({data}) => {
            const contract = data.contract && data.contract.length ? contracts.find((i) => {
                  return i.id === data.contract[0].value; }) : null;
            return (
              <>
                <TypeAwareNewRequestForm type={data.order_type} unit={contract && contract.units} maturities={maturities} request={request} duplicating={duplicating}/>
              </>);
          }}
        </FormContext.Consumer>
      </Column>
      <br/>
      <hr />
      <Column>
        <h2>Additional Comments</h2>
        <FormGroup label="Comments">
          <TextArea style={{ width: '655px' }} growVertically={true} name="comments"></TextArea>
        </FormGroup>
      </Column>
      <FormFooter>
        <Button
          onClick={() => {
            history.push("/coppers/");
          }}
          text="Cancel"
          minimal
          large
        />
        <FormContext.Consumer>
          {({ valid, data }) => {
            return (
              <Button
                large
                loading={createLoading || updateLoading}
                disabled={!valid}
                type="submit"
                intent="primary"
                text={duplicating ? "Save" : "Edit order"}
              />);
          }}
        </FormContext.Consumer>
      </FormFooter>
      <div style={{ display: "block", height: "32px" }}></div>
    </Form>
  );
};

const UpdateCopperOrder = ({duplicating=false}) => {
  return <NewOrderForm duplicating={duplicating} />;
};

export default UpdateCopperOrder;
