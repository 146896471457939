import React, {useState, useEffect, useContext} from "react";
import Axios from "axios";

import { Icon } from "rt-design-system-backup";

import {formatNumber, url, getTime, getTooltip, calculateDelta, getFontColorDelta, getBestOffer} from "../../helpers";

import {
  PriceTable,
  PriceTableHeader,
  PriceTableRow,
  PriceTableCell,
} from "../Base";
import {useHistory} from "react-router-dom";
import SocketContext from "../../App/SocketContext";

const getCell = (val, isDelta = false, isC5Delta = false) => {
  if (!val) return "-";
  if (isDelta) {
    if (isC5Delta) return formatNumber(val, true, 2)
    return formatNumber(val, true, 0)
  }
  return formatNumber(val);
};

const RoutePriceTable = ({ route, maturities, isDarkModeOn }) => {
  const [offers, setOffers] = useState([]);
  const [lasts, setLasts] = useState([]);
  const [closes, setCloses] = useState([]);
  const [headerInfo, setHeaderInfo] = useState({});
  // eslint-disable-next-line
  const [recalculateOffers, setRecalculateOffers] = useState(Math.random());
  const [enableTransition, setEnableTransition] = useState(false);
  const history = useHistory();

  const socket = useContext(SocketContext);

  useEffect(() => {
    const fetchContracts = async () => {
      try {
        const loadHeaderData = async () => {
          const headerData = await Axios.get(
              url("/spot/?route="+route.id)
          );
          setHeaderInfo(headerData.data)
        }

        const loadOffers = async () => {
          const res = await Axios.get(
            url("/offer/?route=" + route.id)
          );
          setOffers(res.data);
        }

        const loadLasts = async () => {
          const lastResponse = await Axios.get(
            url("/last/?route=" + route.id)
          );
          setLasts(lastResponse.data.data)
        }
        const loadClose = async () => {
          const closeResponse = await Axios.get(
            url("/close/?route=" + route.id)
          );
          setCloses(closeResponse.data);
        }

        await Promise.all([
          loadHeaderData(),
          loadOffers(),
          loadLasts(),
          loadClose()
        ])

        setEnableTransition(true);
      } catch (err) {
        console.log(err);
      }
    };

    fetchContracts();

  }, [route]);

  useEffect(() => {
    const updateOffers = (data) => {
      const offer = data.detail.tableData;
      const routeID = offer.contract.route.id;
      if (route.id === routeID) {
        if (offer.type === 'bid' || offer.type === 'offer') {
          setOffers([offer, ...offers]);
        } else if(offer.type === 'last') {
          setLasts([offer, ...lasts]);
        }
      }
    }
    socket.addEventListener("offer_update", updateOffers);
    return () => socket.removeEventListener("offer_update", updateOffers)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offers, lasts])

  const rows = maturities.map((maturity) => {
    return {
      maturity: maturity,
      bid: getBestOffer(offers, maturity, "bid"),
      offer: getBestOffer(offers, maturity, "offer"),
      close: closes.find((close) => {
        return (
            close.contract.maturity.string === maturity.string
        )
      }),
      last: lasts.find((last) => {
        return (
            last.contract.maturity.string === maturity.string
        )
      }),
    };
  });
  const onBrokerClick = () => {
    history.push(`/ffa/broker/${route.id}/dashboard`)
  }
  return (
    <PriceTable
      header={
        <PriceTableHeader
          spot=  { route.route !== "c5" ?  headerInfo.value : "-"}
          mtd={ route.route !== "c5" ?  headerInfo.mtd : "-"}
          change={ route.route !== "c5" ?  headerInfo.change : "-"}
          icon={<Icon iconSize={18} icon="arrow-right" />}
          onClick={onBrokerClick}
          isDarkModeOn={isDarkModeOn}
        >
          {route.route === 'c5' ? 'C5': route.route}
        </PriceTableHeader>
      }
      tableHeader={
        <>
          <PriceTableCell isHeader={true}>Tenor</PriceTableCell>
          <PriceTableCell textAlign={"center"} isHeader={true}>Bid</PriceTableCell>
          <PriceTableCell textAlign={"center"} isHeader={true}>Offer</PriceTableCell>
          <PriceTableCell textAlign={"center"} isHeader={true}>Delta</PriceTableCell>
          <PriceTableCell textAlign={"center"} isHeader={true}>Close</PriceTableCell>
          <PriceTableCell textAlign={"center"} isHeader={true}>Last</PriceTableCell>
        </>
      }
      isDarkModeOn={isDarkModeOn}
    >
      {rows.map(({ maturity, bid, offer, close, last }, index) => {
        return (
          <PriceTableRow isDarkModeOn={isDarkModeOn}>
            <PriceTableCell key={`${index}Maturity`}>
              {maturity.shortened_string.toUpperCase()}
            </PriceTableCell>
            <PriceTableCell tooltip={getTooltip(bid)} created_at={getTime(bid)} valid_until={bid?.valid_until} textAlign={"center"}
                            key={`${index}bid`} enableTransition={enableTransition} setRecalculateOffers={setRecalculateOffers}>
              {getCell(bid?.value)}
            </PriceTableCell>
            <PriceTableCell tooltip={getTooltip(offer)} created_at={getTime(offer)} valid_until={offer?.valid_until} textAlign={"center"}
                            key={`${index}offer`} enableTransition={enableTransition} setRecalculateOffers={setRecalculateOffers}>
              {getCell(offer?.value)}
            </PriceTableCell>
            <PriceTableCell key={`${index}delta`} textAlign={"center"}
                            fontColor={getFontColorDelta(calculateDelta(bid?.value, offer?.value, close?.value))}>
              {getCell(calculateDelta(bid?.value, offer?.value, close?.value), true, route.route === 'c5')}
            </PriceTableCell>
            <PriceTableCell key={`${index}close`} textAlign={"center"}>
              {getCell(close?.value)}
            </PriceTableCell>
            <PriceTableCell tooltip={getTooltip(last)} created_at={getTime(last)} key={`${index}last`} textAlign={"center"}
                            enableTransition={enableTransition} setRecalculateOffers={setRecalculateOffers}>
              {getCell(last?.value)}
            </PriceTableCell>
          </PriceTableRow>
        );
      })}
    </PriceTable>
  );
};


export default RoutePriceTable;
