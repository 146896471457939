import {Table as AntdTable} from "antd";
import {Spinner} from "rt-design-system-backup";
import {renderItem} from "../../STPMappingTable";
import React from "react";

const GeneralTable = ({emptyText, columns, loading, data}) => <AntdTable
    locale={{emptyText}}
    columns={columns}
    loading={
        loading
            ? {
                delay: 1500,
                indicator: <Spinner size={32}/>,
            }
            : false
    }
    scroll={{
        x: data && data.length > 0,
    }}
    size="small"
    expandIconColumnIndex={13}
    dataSource={data ? data.sort() : []}
    tableLayout="auto"
    pagination={{
        pageSize: 20,
        itemRender: renderItem,
        showSizeChanger: false,
    }}
/>
export default GeneralTable;