import {
  IActionProps,
  IconName,
  ILinkProps,
  Intent,
  MaybeElement,
  Toast as BPToast,
  IToastProps as BPIToastProps,
} from '@blueprintjs/core';
import React from 'react';
import '../../assets/scss/core.scss';

export interface IToastProps extends BPIToastProps {
  action?: IActionProps & ILinkProps;
  className?: string;
  icon?: IconName | MaybeElement;
  intent?: Intent;
  message: React.ReactNode;
  onDismiss?: (didTimeoutExpire: boolean) => void;
  timeout?: number;
}

export class Toast extends BPToast {}
