import useAxios from "axios-hooks";
import { Dialog, Button } from "rt-design-system-backup";
import Toaster from "../Toaster";
import { getErrorMessage, url, getCSRF, report } from "../../helpers";
import { FormFooter } from "../Form";

const RequestCancelModal = ({ isOpen, onClose, requestId, request }) => {
  const [{ loading }, cancelRequest] = useAxios(
    {
      url: url("request/" + requestId + "/reconcile/"),
      method: "POST",
      headers: {
        "X-CSRFToken": getCSRF(),
      },
    },
    { manual: true }
  );

  const submit = async () => {
    try {
      await cancelRequest();

      report("request_reconcile_confirmed", {
        request_id: requestId,
      });

      Toaster.show({
        message: "Request successfully reconciled. ",
        icon: "small-tick",
        intent: "success",
      });
    } catch (err) {
      report("request_reconcile_failed");
      if (err.__CANCEL__){
        Toaster.show({
          message: "Request successfully reconciled. ",
          icon: "small-tick",
          intent: "success",
        });
      } else{
       Toaster.show({
          message:
            "An error occurred while reconciling the request. " +
            getErrorMessage(err),
          icon: "warning-sign",
          intent: "danger",
        });
      }
    }

    onClose && onClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      hasBackdrop={true}
      canEscapeKeyClose
      canOutsideClickClose
      onClose={onClose}
      title={"Reconcile request #" + requestId + ' - ' + request.request_type}
      style={{ maxWidth: "548px", width: "100%" }}
    >
      <div style={{ padding: "0 32px 0 32px" }}>
        <p
          style={{
            color: "#706F6F",
            fontSize: "16px",
            lineHeight: "32px",
            marginTop: "16px",
          }}
        >
          The request will be confirmed and no more changes can be made. The requestor will be automatically notified.
        </p>
        <hr />
        <FormFooter>
          <Button onClick={onClose} text="Cancel" minimal />
          <Button
            loading={loading}
            onClick={submit}
            text="Reconcile request"
            intent="primary"
          />
        </FormFooter>
      </div>
    </Dialog>
  );
};

export default RequestCancelModal;
