import React from "react";
import {formatDate} from "../../../helpers";
import "./style.css"

const CopperHistory = ({ request }) => {

    const requestHistories = request.history
    const maturityHistoryTypes = (requestHistory) => {
        if (requestHistory.history_change_reason && requestHistory.history_change_reason.type === 'status'){
            return 'Economics Filled'
        }
        if (requestHistory.history_type === '+'){
            return 'Maturity Added'
        }
        if (requestHistory.history_type === '~'){
            return 'Maturity Edited'
        }
        if (requestHistory.history_type === '-'){
            return 'Maturity Deleted'
        }
    }

    const tradeMaps = {
        'qt_id': 'Fusion ID',
        'price': 'Price',
        'quantity': 'Volume'
    }

    const requestHistoryTypes = (requestHistory) => {
        if (requestHistory.status === 'Open' && requestHistory.history_type === '~'){
            if(requestHistory.changes && requestHistory.changes[0].old === 'Generated'){
                return 'Request Accepted'
            }
            return 'Request Edited'
        }
        if (requestHistory.status === 'Open' && requestHistory.history_type === '+'){
            return 'Request Created'
        }
        if (requestHistory.status === 'Generated'){
            return 'Request Generated'
        }
        if (requestHistory.status === 'Filled'){
            return 'Request Filled'
        }
        if (requestHistory.status === 'Partial'){
            return 'Request Partially Filled'
        }
        if (requestHistory.status === 'Reconciled'){
            return 'Request Reconciled'
        }
        if (requestHistory.status === 'For correction'){
            return 'Request Send For Correction'
        }
        if (requestHistory.status === 'Cancelled'){
            return 'Request Cancelled'
        }
    }

    const noReflectChanges = ['log', 'last_modified_by']
    const dateFormats = ['accepted_at']
    const disableHistories = ['request_added']

    const listItems = requestHistories.map((item, index) => {
        if (!item.history_change_reason ||
            (item.history_change_reason && !disableHistories.includes(item.history_change_reason.type))){
            return (
                <div className="requests" key={index}>
                    <h1 key={index + 'parent'}>
                        {item.status ? requestHistoryTypes(item) : maturityHistoryTypes(item)}
                    </h1>
                    {item.changes ?
                        item.changes.map((subitem, i) => {
                            if (!noReflectChanges.includes(subitem.field)) {
                                if (item.request_maturity_id) {
                                    return (
                                        <h3 key={i + 'child'}>{tradeMaps[subitem.field]} for {item.instrument_name} {item.maturity} updated
                                            from {subitem.old ? subitem.old : 'NULL'} to {subitem.new}</h3>
                                    )
                                } else {
                                    return (
                                        <h3 key={i + 'child'}>{subitem.field} updated
                                            from {subitem.old ? subitem.old : 'NULL'} to {dateFormats.includes(subitem.field) ? formatDate(subitem.new) : subitem.new}
                                        </h3>
                                    )
                                }
                            }
                            return null
                        }) : null
                    }
                    <p>by {item.history_user_name}, {formatDate(item.history_date)}</p>
                </div>
            )
        }
        return null
    })

    return (
        <>
            <h4>History</h4>
            <div style={{ position: "relative" }}>
                <div style={{ display: "inline-block", maxWidth: "100%" }}>
                    <div className="history_txt">
                        {listItems}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CopperHistory;
