import { IOptionProps, IRadioGroupProps as BPIRadioGroupProps, RadioGroup as BPRadioGroup } from '@blueprintjs/core';
import React, { FormEvent } from 'react';
import '../../../assets/scss/core.scss';

export interface IRadioGroupProps extends BPIRadioGroupProps {
  className?: string | undefined;
  disabled?: boolean | undefined;
  label?: React.ReactNode;
  name?: string | undefined;
  onChange: (event: FormEvent<HTMLInputElement>) => void;
  options?: IOptionProps[];
  selectedValue?: string | number;
}

export const RadioGroup: React.FC<IRadioGroupProps> = (props) => {
  return <BPRadioGroup {...props} />;
};