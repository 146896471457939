import React from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

export const useQueryParams = () => {
  const { search } = useLocation();

  return React.useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);
};
