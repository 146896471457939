import useAxios from "axios-hooks";
import { Dialog, Button } from "rt-design-system-backup";
import { useEffect } from "react";

import { FormFooter } from "../Form";
import Toaster from "../Toaster";

import { getCSRF, url, report, getErrorMessage } from "../../helpers";

import "./style.scss";

const TradeDeleteDialog = ({ isOpen, onClose, trade }) => {
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isOpen) {
      report("trade_delete", {
        trade_id: trade.id,
      });
    }
  }, [isOpen]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const [{ loading }, cancelOrder] = useAxios(
    {
      url: url("trade/" + (trade ? trade.id : "")+"/"),
      method: "DELETE",
      headers: {
        "X-CSRFToken": getCSRF(),
      },
    },
    { manual: true }
  );

  const submit = async () => {
    try {
      report("trade_delete_confirmed", {
        trade_id: trade.id,
      });

      await cancelOrder();
      report("trade_delete_confirmed", {
        trade_id: trade.id,
      });

      Toaster.show({
        message: "Trade successfully deleted. ",
        icon: "small-tick",
        intent: "success",
      });

      onClose();
    } catch (err) {
      report("trade_delete_failed", {
        trade_id: trade.id,
      });

      Toaster.show({
        message:
          "Something went wrong and we were unable to cancel your trade. " +
          getErrorMessage(err),
        icon: "warning-sign",
        intent: "danger",
      });
    }
  };

  return trade !== undefined ? (
    <Dialog
      isOpen={isOpen}
      hasBackdrop={true}
      canEscapeKeyClose
      canOutsideClickClose
      onClose={onClose}
      title={"Delete trade #" + trade.id}
      style={{ maxWidth: "548px", width: "100%" }}
    >
      <div style={{ padding: "0 32px 0 32px" }}>
        <div className="rtcmd--view-order--header"></div>
        <p>This trade will be permanently deleted.</p>
        <FormFooter>
          <Button onClick={onClose} text="Cancel" minimal />
          <Button
            loading={loading}
            onClick={submit}
            text="Delete trade"
            intent="danger"
          />
        </FormFooter>
      </div>
    </Dialog>
  ) : null;
};

export default TradeDeleteDialog;
