import { Callout as BPCallout, ICalloutProps as BPICalloutProps, Intent } from '@blueprintjs/core';
import React from 'react';
import '../../assets/scss/core.scss';
export interface ICalloutProps extends BPICalloutProps {
  children?: string;
  intent?: Intent;
  title?: string;
}

export const Callout: React.FC<ICalloutProps> = (props) => {
  const { title, className, ...res } = props;

  const newProps = {
    ...res,
    title: title,
    className: title ? `${className || ''}title` : className,
  };
  return <BPCallout {...newProps} />;
};
