import React from "react";
import Page from "../../Page";
import CustomerDashboard from "./CustomerDashboard";
import CSRDashboard from "./CSRDashboard";

const AnalyticsDashboard = ({tab = 'main' }) => {
    return (
        <Page
            title=""
            tabs={[
            { title: 'Main', to: '/aluminium/analytics-dashboard/main' },
            { title: 'Customers', to: '/aluminium/analytics-dashboard/customers' },
            ]}
            actionsMarginBottom={'5px'}
        >
            {tab === "main" ?
            <>
                <CustomerDashboard />
            </>
            : 
            <>
                <CSRDashboard />
            </> 
            }

        </Page>
    )
}

export default AnalyticsDashboard;
