import React, {useEffect} from "react";
import useAxios from "axios-hooks";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Card } from "rt-design-system-backup"

import { url } from "../../../../../helpers";
import { dateFormat } from "../../../Maturities";
import LoadingIcon from "../../../../Icons/loading";


const BarChart = ({customer_id, start_date, end_date, monthType}) => {
    ChartJS.register(
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      Tooltip,
      Legend
    );

    const [{ loading: chartDataLoading, data: chartData }, refetch] = useAxios({
        url: url(`aluminium_analytics/acceptance_ratio/?start_date=${dateFormat.formatDate(start_date)}&end_date=${dateFormat.formatDate(end_date)}${customer_id !== -1 ? '&customer=' + customer_id : ''}&per_validity=true&month_type=${monthType}`),
    });
    
    useEffect(()=>{
      if (!monthType || !customer_id || !start_date || !end_date) return 
      refetch()
      // eslint-disable-next-line
    }, [start_date, end_date, customer_id, monthType])

    const options = {
        maintainAspectRatio: false,
        scales: {
          x: {
            beginAtZero: true,
            stacked: true,
            ticks: {
              font: {
                family: 'RT_VickermanW01',
              }
            }
          },
          y: {
              stacked: true,
              ticks: {
                font: {
                  size: 14,
                  family: 'RT_VickermanW01',
                }
              }
          },
        },
        plugins: {
          legend: {
              display: true,
              position: 'bottom',
              labels: {
                font: {
                  family: 'RT_VickermanW01'
                }
              }
          },
          title: {
            display: false,
          },
          tooltip: {
            titleFont: {
              family: 'RT_VickermanW01'
            },
            bodyFont: {
              family: 'RT_VickermanW01'
            },
            callbacks: {
              label: function(context) {
                let messageString = "quote";
                if (context.raw > 1) {
                  messageString = "quotes"
                }
                if(context.datasetIndex === 1){
                  return context.formattedValue + " rejected " + messageString;
                } else {
                  return context.formattedValue + " accepted " + messageString;
                }
              }
            }
          }
        },
    };
    const dataset = {
        labels: ['Live', '5min', '15min', '30min', '1h', '3h', 'EOD', 'Past Cutoff'],
        datasets: [
        chartDataLoading === false && chartData && {
            label: 'Accepted',
            data: [
                chartData?.Live?.accepted, chartData?.Five_Min?.accepted,
                chartData?.Fifteen_Min?.accepted, chartData?.Thirty_Min?.accepted, 
                chartData?.One_H?.accepted, chartData?.Three_H?.accepted, 
                chartData?.End_Of_Day?.accepted, chartData?.Past_Cutoff?.accepted
            ],
            backgroundColor: '#0087FA',
          },
          chartDataLoading === false && chartData && {
            label: 'Rejected',
            data: [
                chartData?.Live?.rejected, chartData?.Five_Min?.rejected,
                chartData?.Fifteen_Min?.rejected, chartData?.Thirty_Min?.rejected, 
                chartData?.One_H?.rejected, chartData?.Three_H?.rejected, 
                chartData?.End_Of_Day?.rejected, chartData?.Past_Cutoff?.rejected
            ],
            backgroundColor: '#a0caee'
          },
        ]
    };

    return (
        <Card elevation={1}
          style={{padding: '10px 14px 50px 18px', borderRadius: '8px', height: '548px',
        }}>
            {chartDataLoading === true ? (
            <div style={{ alignItems: "center", justifyContent: "center", height: '100%', display: 'flex'}}>
                <LoadingIcon />
                <p>Loading Data</p>
            </div>
            ) : (
            <>
                <h1 style={{color: '#002C5F', fontSize: '20px'}}>Acceptance Ratio per validity</h1>
                <Bar data={dataset} options={options} />
            </>
            )}  
        </Card>
    );
}
export default BarChart;
