import React from "react";
import { formatDate } from "../../../helpers";
import "./style.css";
const MarineHistory = ({ request }) => {
  const requestHistories = request.history;
  const processHistoryItem = (timestamp, historyDetails) => {
    const changes = historyDetails["Added"] || historyDetails["Change"] || [];
    const user =
      historyDetails["created_by"] || historyDetails["last_updated_by"];
    return {
      timestamp,
      changes,
      user,
    };
  };
  const historyItems = requestHistories
    .flatMap((history) => {
      return Object.entries(history).map(([timestamp, details]) => {
        return processHistoryItem(timestamp, details);
      });
    })
    .reverse();
  const listItems = historyItems.map((item, index) => {
    const action =
      item.changes.length &&
      item.changes[0].includes("Created STP Mapping Rule")
        ? "Rule Created"
        : "Rule Updated";
    return (
      <div className="requests" key={index}>
        <h1 key={index + "parent"}>{action}</h1>
        {item.changes.map((change, i) => (
          <h3 key={`${index}-${i}`}>{change}</h3>
        ))}
        <p>
          by {item.user},{" "}
          {formatDate(new Date(item.timestamp), undefined, undefined, true)}
        </p>
      </div>
    );
  });
  return (
    <>
      <h4>History</h4>
      <div style={{ position: "relative" }}>
        <div style={{ display: "inline-block", maxWidth: "100%" }}>
          <div className="history_txt rule">{listItems}</div>
        </div>
      </div>
    </>
  );
};
export default MarineHistory;
