import { useState, useRef, useEffect } from "react";
import useAxios from "axios-hooks";
import { Tooltip } from "rt-design-system-backup";

import "./style.scss";

import { url, formatNumber, getCSRF, isValidNumber } from "../../helpers";
import { useLogin } from '../useLogin'

const MarketBidOfferInput = ({ order, type, enabled }) => {
  useLogin(true)

  const [isEditing, setIsEditing] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [temporary, setTemporary] = useState(false);

  const price = order.order_contracts.length > 0 ? order.order_contracts[0]["market_" + type] : 0;

  const inputRef = useRef();

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, isEditing]);

  const isCurrentlyEditing = isEditing && enabled;

  const [{ error }, send] = useAxios(
    {
      url: url("order_contract/"),
      method: "POST",
      headers: {
        "X-CSRFToken": getCSRF(),
      },
    },
    { manual: true }
  );

  const update = async (value) => {
    setTemporary(parseFloat(value));

    try {
      await send({
        data: {
          order: order.id,
          ["market_" + type]: parseFloat(value),
        },
      });

      setTimeout(() => {
        setTemporary(false);
      }, 2000);
    } catch (err) {
      if (error) {
        setTemporary(false);
        throw err;
      } else {
        setTimeout(() => {
          setTemporary(false);
        }, 2000);
      }
    }
  };

  useEffect(() => {
    if (error) {
      setTemporary(false);
    }
  }, [error]);

  return (
    <div style={{ display: "inline-block" }}>
      <Tooltip
        intent="danger"
        content={isInvalid}
        isOpen={isInvalid && isEditing}
        position="bottom"
      >
        <div>
          <input
            style={{ display: isCurrentlyEditing ? "block" : "none" }}
            ref={inputRef}
            defaultValue={price}
            className={
              "rtcmd-mbo-input" +
              (isInvalid ? " invalid" : "") +
              (enabled ? " enabled" : "")
            }
            onBlur={async (evt) => {
              const invalidReason = isValidNumber(evt.target.value, true);

              if (invalidReason) {
                evt.target.value = evt.target.defaultValue;
              } else {
                await update(evt.target.value);
              }

              setIsInvalid(false);

              if (enabled) setIsEditing(false);
            }}
            onKeyUp={async (evt) => {
              const invalidReason = isValidNumber(evt.target.value, true);

              if (invalidReason) {
                setIsInvalid(invalidReason);
                return;
              } else {
                setIsInvalid(false);
              }

              if (evt.key === "Enter") {
                evt.target.blur();
                await update(evt.target.value);

                if (enabled) setIsEditing(false);
              }
            }}
          />
          <div
            style={{ display: !isCurrentlyEditing ? "block" : "none" }}
            className={"rtcmd-mbo-input" + (enabled ? " enabled" : "")}
            onClick={(e) => {
              e.preventDefault();

              if (enabled) setIsEditing(true);
            }}
          >
            {formatNumber(temporary || price, true)}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default MarketBidOfferInput;
