import React from "react";
import { capitalize } from "../../helpers";
import TitledTable from "../TitledTable"; 

const RequestProductDetails = ({ request }) => {
  const columns = [
    {
      title: "Product Details",
      dataIndex: "product_details",
      render: capitalize,
    }
  ];

  return (
    <>
      <TitledTable
        data={request.product_details}
        title="Product Details"
        mappings={columns}
      />
    </>
  );
};

export default RequestProductDetails;
