import {
  IconName,
  INonIdealStateProps as BPINonIdealStateProps,
  MaybeElement,
  NonIdealState as BPNonIdealState,
} from '@blueprintjs/core';
import React from 'react';
import '../../assets/scss/core.scss';

export interface INonIdealStateProps extends BPINonIdealStateProps {
  description?: React.ReactChild;
  icon?: IconName | MaybeElement;
  title?: React.ReactNode;
}

export const NonIdealState: React.FC<INonIdealStateProps> = (props) => {
  return <BPNonIdealState {...props} />;
};
