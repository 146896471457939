import React from 'react';
import '../../assets/scss/core.scss';
import { Position, Drawer as BPDrawer, IDrawerProps as BPIDrawerProps, IconName } from '@blueprintjs/core';

export interface IDrawerProps extends BPIDrawerProps {
  backdropClassName?: string;
  backdropProps?: React.HTMLProps<HTMLDivElement> | undefined;
  canEscapeKeyClose?: boolean;
  canOutsideClickClose?: boolean;
  className?: string;
  enforceFocus?: boolean;
  hasBackdrop?: boolean;
  icon?: IconName | undefined;
  isCloseButtonShown?: boolean;
  isOpen: boolean;
  lazy?: boolean;
  portalClassName?: string;
  portalContainer?: HTMLElement | undefined;
  position?: Position;
  size?: number | string;
  style?: React.CSSProperties;
  title?: React.ReactNode;
  transitionDuration?: number;
  transitionName?: string;
  usePortal?: boolean;
  vertical?: boolean;
  content?: string;
}

export const Drawer: React.FC<IDrawerProps> = (props) => {
  return <BPDrawer {...props} />;
};
