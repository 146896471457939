import React, {createContext, useContext} from "react"
import {useSystemColumns, useSystemColumnValues} from "../Views/STPMappingSystemColumns/api";

const STPMappingSystemColumnContext = createContext(
);


const STPMappingSystemColumnContextProvider = ({children}) => {
    const {data: systemColumns, loading: systemColumnsLoading, refetch: reloadSystemColumns} = useSystemColumns()
    const {
        data: systemColumnValues,
        loading: systemColumnValuesLoading,
        refetch: reloadSystemColumnValues
    } = useSystemColumnValues()
    const contextValue = {
        systemColumns, systemColumnsLoading, reloadSystemColumns,
        systemColumnValues, systemColumnValuesLoading, reloadSystemColumnValues,
    }
    return <STPMappingSystemColumnContext.Provider
        value={contextValue}>{children}</STPMappingSystemColumnContext.Provider>
}
const useSTPMappingSystemColumnContext = () => {
    const context = useContext(STPMappingSystemColumnContext)
    if (context === undefined) {
        throw new Error('useSTPMappingSystemColumnContext must be used within an STPMappingSystemColumnContextProvider')
    }
    return context
}

export {STPMappingSystemColumnContextProvider, useSTPMappingSystemColumnContext}
