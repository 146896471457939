import React from "react";

function Icon() {
  return (
    <span className="bp3-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          fill="currentColor"
          d="M15 5.833l-1.175-1.175-5.283 5.284 1.175 1.175L15 5.833zm3.534-1.175l-8.817 8.817L6.233 10l-1.175 1.175 4.659 4.658 10-10-1.183-1.175zM.341 11.175L5 15.833l1.175-1.175L1.525 10 .342 11.175z"
        ></path>
      </svg></span>
  );
}

export default Icon;
