import {
  Classes,
  Utils,
  Intent,
  ITagProps as BPITagProps,
  Icon,
  IconSize,
  Text,
  IconName,
  MaybeElement,
  AbstractPureComponent2,
} from '@blueprintjs/core';
import React from 'react';
import '../../assets/scss/core.scss';
import { isReactNodeEmpty } from '@blueprintjs/core/lib/esm/common/utils';
import join from 'lodash/join';

export interface ITagProps extends BPITagProps {
  intent?: Intent;
  interactive?: boolean;
  large?: boolean;
  extraLarge?: boolean;
  minimal?: boolean;
  round?: boolean;
  tags?: string[];
  icon?: IconName | MaybeElement;
}

class RTTag extends AbstractPureComponent2<ITagProps> {
  public render() {
    const {
      active,
      large,
      minimal,
      round,
      className,
      icon,
      rightIcon,
      intent,
      multiline,
      htmlTitle,
      children,
      interactive,
      onRemove,
      tabIndex = 0,
      elementRef,
      extraLarge,
      ...htmlProps
    } = this.props;
    const isRemovable = Utils.isFunction(onRemove);
    const listClass = [
      active ? Classes.ACTIVE : '',
      interactive ? Classes.INTERACTIVE : '',
      large ? Classes.LARGE : '',
      extraLarge ? 'bp3-extraLarge' : '',
      minimal ? Classes.MINIMAL : '',
      round ? Classes.ROUND : '',
      multiline ? 'bp3-multiple' : '',
      isRemovable ? 'bp3-remove' : '',
      Classes.TAG || '',
      Classes.intentClass(intent) || '',
      className || '',
    ].filter((el) => !!el);
    const tagClasses = join(listClass, ' ');

    const isLarge = large || extraLarge || tagClasses.indexOf(Classes.LARGE) >= 0;

    const onRemoveClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      this.props.onRemove?.(e, this.props);
    };

    const removeButton = isRemovable ? (
      <button
        type='button'
        className={Classes.TAG_REMOVE}
        onClick={onRemoveClick}
        tabIndex={interactive ? tabIndex : undefined}
      >
        <Icon icon='small-cross' iconSize={isLarge ? IconSize.LARGE : IconSize.STANDARD} />
      </button>
    ) : null;
    return (
      <span {...htmlProps} className={tagClasses} ref={elementRef} tabIndex={interactive ? tabIndex : undefined}>
        <Icon icon={icon} />
        {!isReactNodeEmpty(children) && (
          <Text className={Classes.FILL} ellipsize={!multiline} tagName='span' title={htmlTitle}>
            {children}
          </Text>
        )}
        <Icon icon={rightIcon} />
        {removeButton}
      </span>
    );
  }
}

export const Tag: React.FC<ITagProps> = (props) => {
  return <RTTag {...props} />;
};
