import { IInputGroupProps as BPIInputGroupProps, InputGroup as BPInputGroup, Intent } from '@blueprintjs/core';
import React from 'react';
import '../../../assets/scss/core.scss';

export interface IInputGroupProps extends BPIInputGroupProps {
  disabled?: boolean;
  fill?: boolean;
  intent?: Intent;
  large?: boolean;
  placeholder?: string;
  round?: boolean;
}
export const InputGroup: React.FC<IInputGroupProps> = (props) => {
  return <BPInputGroup {...props} />;
};
