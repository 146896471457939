import React, { useEffect } from "react";
import {useHistory, useParams} from "react-router-dom";
import useAxios from "axios-hooks";
import { url } from "../../../helpers";
import { Divider } from "antd";
import RequestViewContext from "../../RequestContext";
import Page from "../../Page";
import { Button, Icon } from "rt-design-system-backup";
import RequestAdditional from "../../RequestAdditional";
import CopperOverview from "../../CopperOverview";
import CopperPricingInstructions from "../../CopperOverview/CopperPricingInstructions";
import CopperTrades from "../../CopperOverview/CopperTrades";
import CopperHistory from "./CopperHistory";


export const EditRequestButton = ({ href, showIcon = false , style={}}) => {
  return <a style={{ textDecoration: 'none' }} href={href}>
    {showIcon ? <Icon icon={'edit'} iconSize={20} style={style} /> :
      <Button icon={'edit'} large outlined style={{marginRight: 10}}>
        Edit request
      </Button>}
  </a>
}

const CopperDetail = ({action = 'details'}) => {
  const { copperId } = useParams();
  const history = useHistory();

  const [{ data: request, loading }, refetch] = useAxios({
    url: url("/copper/" + copperId+"/"),
  });

  useEffect(() => {
    const refetcher = (req) => {
      if (!req || `${req.detail.id}` === copperId) {
        refetch();
      }
    };
    refetcher();
  }, [refetch, copperId]);

  const additional = loading ? <p>something</p> : <RequestAdditional request={request} />

  return loading || !request ? 'Loading...' : (
    <div style={{ paddingBottom: "32px" }}>
      <Button
        icon="chevron-left"
        minimal
        style={{fontSize: '16px', lineHeight: '24px'}}
        onClick={() => {
          history.push("/coppers/");
        }}
      >
        All Orders
      </Button>
      <Page
        title={"Order details"}
        tabs={[]}
        rightActions={
          request.status === 'Open' ?
          [
            <Button icon={'edit'} large outlined onClick={() => history.push(`/coppers/${request.id}/update/`)}>
              Edit order
            </Button>
          ] : []
        }
        mobileActions={[]}
      >
        <hr />
        <RequestViewContext.Provider
          value={{ refetch, disableEditing: true }}
        >
          <CopperOverview request={request} showSpinner />
          <Divider />
          <CopperPricingInstructions request={request} editable={false} />
          <Divider />
          <CopperTrades request={request} />
          <Divider />
          {additional}
          <CopperHistory request={request}/>
        </RequestViewContext.Provider>
      </Page>
    </div>)
};

export default CopperDetail;
