import { IControlGroupProps as BPIControlGroupProps, ControlGroup as BPControlGroup } from '@blueprintjs/core';
import React from 'react';
import '../../../assets/scss/core.scss';

export interface IControlGroupProps extends BPIControlGroupProps {
  fill?: boolean;
  vertical?: boolean;
}

export const ControlGroup: React.FC<IControlGroupProps> = (props) => {
  return <BPControlGroup {...props} />;
};
