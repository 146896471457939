import React from "react";
import { Button } from "rt-design-system-backup";
import {useHistory} from "react-router-dom";

const Back = ({to, title}) => {
    const history = useHistory();
    return <Button
        icon="chevron-left"
        minimal
        style={{fontSize: "16px", lineHeight: "24px"}}
        onClick={() => to ? history.push(to) : history.goBack()}
    >
        {title}
    </Button>;
}

export default Back;