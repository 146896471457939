import {
  DateRangeInput as BPDateRangeInput,
  IDateRangeInputProps as BPIDateRangeInputProps,
  IDateRangeShortcut,
} from '@blueprintjs/datetime';
import React from 'react';
import '../../../assets/scss/core.scss';
import styled from 'styled-components';
import { Button } from '../button';

export interface IDateRangeInputProps extends BPIDateRangeInputProps {
  allowSingleDayRange?: boolean;
  closeOnSelection?: boolean;
  contiguousCalendarMonths?: boolean;
  disabled?: boolean;
  selectAllOnFocus?: boolean;
  shortcuts?: boolean | IDateRangeShortcut[];
  singleMonthOnly?: boolean;
  reverseMonthAndYearMenus?: boolean;
  highlightCurrentDay?: boolean;
}

export const DateRangeInput: React.FC<IDateRangeInputProps> = (props) => {
  const ButtonContainer = styled(Button)`
    .bp3-icon svg {
      fill: #002c5f;
    }
  }
  `;

  const rightIcon = <ButtonContainer minimal icon='calendar' />;

  return (
    <BPDateRangeInput
      startInputProps={{ rightElement: rightIcon }}
      endInputProps={{ rightElement: rightIcon }}
      {...props}
    />
  );
};
