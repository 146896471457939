import { Card as BPCard, Elevation as BPElevation, ICardProps as BPICardProps } from '@blueprintjs/core';
import React from 'react';
import '../../assets/scss/core.scss';

export type Elevation = BPElevation;
export interface ICardProps extends BPICardProps {
  elevation?: Elevation;
  interactive?: boolean;
}

export const Card: React.FC<ICardProps> = (props) => {
  return <BPCard {...props} />;
};
