import { NumericInput as BPNumericInput, INumericInputProps as BPINumericInputProps } from '@blueprintjs/core';
import React from 'react';
import '../../../assets/scss/core.scss';

export interface INumericInputProps extends BPINumericInputProps {
  disabled?: boolean;
  fill?: boolean;
  large?: boolean;
  max?: number;
  min?: number;
  value?: number | string;
}
export const NumericInput: React.FC<INumericInputProps> = (props) => {
  return <BPNumericInput {...props} />;
};
