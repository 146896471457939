import React, {useContext, useEffect, useState} from "react";
import SocketContext from "../../SocketContext";
import useAxios from "axios-hooks";
import {url} from "../../../helpers";
import {Card} from "rt-design-system-backup";
import {InputGroup} from "../../Form";
import TradingViewHistoricalChart from "../HistoricalFFADashboard/HistoricalChart";
import MarineContext from "../../MarineContext";
import {MARINE_CARD_BACKGROUND_COLOR, LAST_COLORS} from "../../../helpers/constants";
import { getComponentHeight, divLoader } from "../../../helpers";

const WtiBrentChart = ({
  defaultWindowHeight = null, relativeComponentId = 'FFAGraphComponent', 
  defaultRoute = null, defaultRoutes = null, defaultTime = 7 }) => {
    // eslint-disable-next-line
    const [routes, setRoutes] = useState(defaultRoutes != null ? defaultRoutes : [])
    const [route, setRoute] = useState(defaultRoute !== null ? defaultRoute : null);
    const [time, setTime] = useState(defaultTime !== null ? defaultTime : 1);
    const [offers, setOffers] = useState([]);
    const [windowHeight, setWindowHeight] = useState(defaultWindowHeight !== null ? defaultWindowHeight : window.innerHeight);

    const timeData = [
        {text: '1 Day', value: 1},
        {text: '1 Week', value: 7},
        {text: 'All', value: -1}
    ]
    const socket = useContext(SocketContext);


    const [{ data: chartData = null, loading }, refetch] = useAxios({
      url: url(`maturity/get_visualization_data/?maturity=WTIBRENT&route=${route}&time_interval=${time}`),
    });

    useEffect(() => {
        if (refetch) refetch();
    }, [route, refetch, time]);

    const getLocalTime = (time) => {
        const d = new Date(time);
        console.log(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds()) / 1000)
        return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds()) / 1000;
    }
    useEffect(() => {
        if(!chartData) return;
        setOffers(chartData['offers'].map(obj => ({...obj, time: getLocalTime(obj.time)})));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartData])

  useEffect(()=> {
    const bunkerOfferUpdate = (data) => {
        const routeID = data.detail.route
        const tempoffers = data.detail.offers;
        if (routeID.includes(route) && time !== -1) {
            const chartOffers = tempoffers.map(({ chartData }) => chartData);
            let offersArray = []
            chartOffers.map((offer)=> {
                if (offer.route === route) {
                    offer.time = getLocalTime(offer.time);
                    offersArray.push(offer)
                }
                return offer;
            })
            setOffers(
                [...offers, ...offersArray]
                .filter((obj, index, self) =>
                    index === self.findIndex((o) => (
                        o.time === obj.time
                    ))
                )
            );
        }
    }
    socket.addEventListener("bunker_offer_update", bunkerOfferUpdate);
    return () => socket.removeEventListener("bunker_offer_update", bunkerOfferUpdate)
  })

  const {setShowImage, setMainPadding} = useContext(MarineContext);
  const handleWindowResize = () => {
    console.log('Component height',getComponentHeight(relativeComponentId))
    setWindowHeight(defaultWindowHeight !== null ? getComponentHeight(relativeComponentId) - 90 : window.innerHeight);
  }

  useEffect(()=> {
      handleWindowResize()
      setShowImage(true);
      setMainPadding('8px');
      const resizeEvent = window.addEventListener('resize', handleWindowResize)
      return () => {
          setShowImage(false);
          setMainPadding('');
          window.removeEventListener('resize', resizeEvent);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const deductHeight = defaultWindowHeight !== null ? 1 : 91
  console.log(deductHeight, windowHeight)

    return (
      <MarineContext.Consumer>
      {({isDarkModeOn}) => (
        routes.length !== 0 && (
       <div style={{height: `${windowHeight - deductHeight}px`}}>
       <Card elevation={1} style={{height: `${windowHeight - deductHeight}px`, background: isDarkModeOn ? MARINE_CARD_BACKGROUND_COLOR : ''}}>
         {loading === true ? divLoader(windowHeight, deductHeight, isDarkModeOn) : (
          <div style={{paddingBottom: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-end'}}>
                <InputGroup
                    required
                    name="route"
                    type="select"
                    formStyle={{margin: 0, marginRight: 20, }}
                    items={(routes.length ? routes : []).map((route) => {
                      return {
                        name: route.route,
                        value: route.id,
                      };
                    })}
                    onChange={(e) => {setRoute(e[0].value)}}
                    placeholder={'Select Route'}
                    defaultValue={defaultRoute !== null ? route !== null ? [route] : [defaultRoute] : [routes[0].id]}
                />
                <InputGroup
                    required
                    name="time"
                    type="select"
                    formStyle={{margin: 0, marginRight: 20}}
                    items={timeData.map((row) => {
                      return {
                        name: row.text,
                        value: row.value,
                      };
                    })}
                    onChange={(e) => {setTime(e[0].value)}}
                    placeholder={'Select Time'}
                    defaultValue={defaultTime !== null ? time !== null ? [time] : [defaultTime] : [1]}
                />
            </div>
            <TradingViewHistoricalChart offers={offers} isDarkModeOn={isDarkModeOn} chartColors={LAST_COLORS}
                                        windowHeight={windowHeight} defaultWindowHeight={defaultWindowHeight} />
         </div>
         )}
         
       </Card>
       </div>
      ))}
    </MarineContext.Consumer>
    );
}

export default WtiBrentChart;