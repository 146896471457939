import React from "react";
import FormWrapper from "./FormWrapper";
import Form from "../../Form";
import TextInput from "./TextInput";
import Footer from "./Footer";
import Select from "./Select";

const SystemColumnValueEditOrUpdateForm = ({data, onSubmit, columns}) => <Form onSubmit={onSubmit}>
    <FormWrapper>
        <TextInput label="Value" name="value" data={data} required={false}/>
        {columns && <Select label="Column" name="column" data={data} items={columns.map(column => ({value: column.id, name:column.name}))}/>}
        <Footer text={data ? "Update Column Value" : "Add Column Value"}/>
    </FormWrapper>
</Form>;

export default SystemColumnValueEditOrUpdateForm;