import React from "react";

function Icon({width= '20', height='20'}) {
  return (
    <span className="bp3-icon"><svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16.327 4.301a1.54 1.54 0 011.079-.535 1.58 1.58 0 011.29 2.617L17.48 7.764l-.002-.002-5.548 6.311-2.368-2.082L15.19 5.59l.002.002 1.134-1.29zM8.393 15.697l3.386-1.473-2.35-2.051-1.036 3.524z"
        clipRule="evenodd"
      ></path>
      <path stroke="currentColor" d="M4.5 6h4M4.5 8h6"></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1 .5h14V4l-1.5 1.773V2h-11v15.5h11v-3.444L15 12.5V19H1V.5z"
        clipRule="evenodd"
      ></path>
    </svg></span>
  );
}

export default Icon;
