import React, { useContext, useEffect, useState } from "react";
import useAxios from "axios-hooks";
import { Link, useHistory } from "react-router-dom";

import {
  Button,
  DateRangeInput,
  Icon,
  Spinner,
  Tooltip,
} from "rt-design-system-backup";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Space, Table } from "antd";
import { InputGroup } from "../../Form";

import {
  AcceptQuote,
  ResubmitQuote,
  useShowAcceptRequest,
  useShowCancelRequest,
  useShowFillRequest,
  useShowReconcileRequest,
  useShowResubmit,
} from "../../RequestOverview/RequestOverviewActions";

import { renderItem } from "../../OrdersTable";
import {
  hasGroup,
  formatDate,
  formatNumber,
  hasPerm,
  replaceOrderTypes,
  report,
  url,
} from "../../../helpers";
import SocketContext from "../../SocketContext";
import AppContext from "../../AppContext";
import Page from "../../Page";

import FillIcon from "../../Icons/fill";
import ReconcileIcon from "../../Icons/reconcile";

import RequestCancelModal from "../../RequestCancelModal";
import RequestReconcileModal from "../../RequestReconcileModal";
import { dateFormat } from "../Maturities";
import { EditRequestButton } from "../RequestDetail";
import { css } from "@emotion/css";
import FileDownload from "js-file-download";
import axios from "axios";

const tableCSS = css({
  ".ant-table-content .bp3-icon svg": {
    width: "20px",
    height: "20px",
  },
});

const overrideMarkStyle = css`
  .ant-table-content mark {
    background-color: transparent !important;
    padding: 0 !important;
  }
`;
// const dropdownZIndexOverride = css`
//   .ant-table-filter-dropdown .ant-dropdown {
//     z-index: 0 !important;
//   }
// `;
const ActionsRenderer = ({ value, request }) => {
  const showFillRequest = useShowFillRequest(request.rawRequest);
  const showCancelRequest = useShowCancelRequest(request.rawRequest);
  const showReconcileRequest = useShowReconcileRequest(request.rawRequest);
  const showAcceptRequest = useShowAcceptRequest(request.rawRequest);
  const showResubmit = useShowResubmit(request.rawRequest);

  const { user } = useContext(AppContext);

  const [isRequestCancelModalOpen, setIsRequestCancelModalOpen] =
    useState(false);
  const [isRequestReconcileModalOpen, setIsRequestReconcileModalOpen] =
    useState(false);

  return (
    <div style={{ whiteSpace: "nowrap" }}>
      <Link to={"/aluminium/request/" + value + "/details"}>
        <Icon iconSize={20} icon="eye-open" />
      </Link>
      {["Filled", "For correction"].includes(request.status) &&
      hasPerm(user, "rtcmdmodels.fill_open_request")
        ? !request.accountingPeriod && (
            <EditRequestButton
              href={"/aluminium/request/" + request.id + "/fill-edit/"}
              showIcon
            />
          )
        : null}
      {request.status === "Open" &&
      ![
        "Postponement Auto Quote",
        "Anticipation Auto Quote",
        "Auto Quote",
        "Indic Quote",
      ].includes(request.request_type) &&
      hasPerm(user, "rtcmdmodels.change_request") &&
      (request.rawRequest.requestor.email === user.email ||
        (user.sales_entity && user.sales_entity.name === request.sales_entity))
        ? !request.accountingPeriod && (
            <EditRequestButton
              href={"/aluminium/request/" + request.id + "/update/"}
              showIcon
            />
          )
        : null}
      {showFillRequest && hasPerm(user, "rtcmdmodels.fill_open_request") ? (
        <Link to={"/aluminium/request/" + value + "/fill"}>
          <FillIcon />
        </Link>
      ) : null}
      {showCancelRequest ? (
        <>
          <RequestCancelModal
            isOpen={isRequestCancelModalOpen}
            onClose={() => setIsRequestCancelModalOpen(false)}
            requestId={value}
            requestType={request.request_type}
            requestSalesEntity={request.sales_entity}
            requestCreatedOn={request.created_at}
          />
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setIsRequestCancelModalOpen(true)}
          >
            <Icon size={20} icon="cross" />
          </span>
        </>
      ) : null}
      <AcceptQuote
        show={showAcceptRequest}
        showIcon={true}
        requestId={request.id}
      />
      <ResubmitQuote
        show={showResubmit}
        showIcon={true}
        requestId={request.id}
      />
      {showReconcileRequest &&
      hasPerm(user, "rtcmdmodels.reconcile_filled_request") ? (
        <>
          <RequestReconcileModal
            isOpen={isRequestReconcileModalOpen}
            onClose={() => setIsRequestReconcileModalOpen(false)}
            requestId={value}
            request={request}
          />
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setIsRequestReconcileModalOpen(true)}
          >
            <ReconcileIcon />
          </span>
        </>
      ) : null}
    </div>
  );
};

const Requests = () => {
  const today = new Date();
  const last_week = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    today.getDate(),
  );
  const initial_start_date = localStorage.getItem("filter_start_date");
  const [startDate, setStartDate] = useState(
    (initial_start_date && new Date(initial_start_date)) || last_week,
  );
  const initial_end_date = localStorage.getItem("filter_end_date");
  const [endDate, setEndDate] = useState(
    (initial_end_date && new Date(initial_end_date)) || new Date(),
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  const [csvDownloading, setCsvDownloading] = useState(false);

  const [{ data: requests, loading }, refetch] = useAxios({
    url: url(
      `request/?start_date=${dateFormat.formatDate(
        startDate,
      )}&end_date=${dateFormat.formatDate(endDate)}`,
    ),
  });
  const [{ data: customers }] = useAxios({
    url: url("/customer/"),
  });
  const socket = useContext(SocketContext);
  const { user } = useContext(AppContext);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    refetch();
    window.addEventListener("resize", () =>
      setIsMobile(window.innerWidth <= 900),
    );
    socket.addEventListener("request_create", refetch);
    socket.addEventListener("request_fill", refetch);
    socket.addEventListener("request_update", refetch);
    socket.addEventListener("request_cancel", refetch);
    socket.addEventListener("request_reconcile", refetch);
    socket.addEventListener("request_accept", refetch);
    socket.addEventListener("request_expire", refetch);
    socket.addEventListener("request_expire", refetch);
    socket.addEventListener("request_resubmit", refetch);

    return () => {
      socket.removeEventListener("request_create", refetch);
      socket.removeEventListener("request_fill", refetch);
      socket.removeEventListener("request_update", refetch);
      socket.removeEventListener("request_cancel", refetch);
      socket.removeEventListener("request_reconcile", refetch);
      socket.removeEventListener("request_accept", refetch);
      socket.removeEventListener("request_expire", refetch);
      socket.removeEventListener("request_expire", refetch);
      socket.removeEventListener("request_resubmit", refetch);
    };
  }, [socket]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const initial_filters = {};
  if (user.sales_entity && hasPerm(user, "rtcmdmodels.add_request")) {
    initial_filters["sales_entity"] = [user.sales_entity.name];
  }

  if (hasPerm(user, "rtcmdmodels.reconcile_filled_request")) {
    initial_filters["status"] = ["Filled"];
  }

  if (hasPerm(user, "rtcmdmodels.fill_open_request")) {
    initial_filters["status"] = ["Filled", "Open", "For correction"];
  }

  const downloadCsv = (e) => {
    e.preventDefault();
    report("requests_download");
    setCsvDownloading(true);
    axios({
      url: url("requestscsv/"),
      params: {
        start_date: dateFormat.formatDate(startDate),
        end_date: dateFormat.formatDate(endDate),
        filters: JSON.stringify(filteredInfo)
      },
      method: "GET",
      responseType: "blob", // Important
    }).then((response) => {
      setCsvDownloading(false);
      FileDownload(response.data, "requests.csv");
    });
  };

  const filter_keys = [
    "customer",
    "delivery_months",
    "id",
    "requestor",
    "sales_entity",
    "status",
    "type",
  ];

  if (filter_keys.some((x) => localStorage.getItem(`filter_${x}`) !== null)) {
    filter_keys.forEach((filter_key) => {
      const value = localStorage.getItem(`filter_${filter_key}`);
      if (value === "-") {
        delete initial_filters[filter_key];
      } else if (value) {
        initial_filters[filter_key] = value.split(",");
      }
    });
  }

  const initial_search_text = localStorage.getItem("filter_id");
  const history = useHistory();
  const [filteredInfo, setFilterInfo] = useState(initial_filters);
  const [searchText, setSearchText] = useState(
    initial_search_text || "initState",
  );

  const handleChange = (pagination, filters, sorter) => {
    setFilterInfo(filters);

    Object.keys(filters).forEach((filter) => {
      if (filters[filter]) {
        localStorage.setItem(`filter_${filter}`, filters[filter].join());
      } else {
        localStorage.setItem(`filter_${filter}`, "-");
      }
    });
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    localStorage.removeItem('filter_customer'); 
  };

  const handleDateChange = (data) => {
    const start = data[0] || new Date();
    const end = data[1] || new Date();

    setStartDate(start);
    setEndDate(end);

    localStorage.setItem(`filter_start_date`, start);
    localStorage.setItem(`filter_end_date`, end);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ID`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 160, marginBottom: 8, display: "block" }}
          />
          <Space size={"large"}>
            <Button
              intent="secondary"
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ minHeight: "24px", minWidth: 60 }}
            >
              Reset
            </Button>
            <Button
              intent="primary"
              icon={<SearchOutlined />}
              onClick={() => handleSearch(selectedKeys, confirm)}
              size="small"
              style={{ minHeight: "24px", minWidth: 60 }}
            >
              Search
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? "#1890ff" : "#8e8e8e", fontSize: "16px" }}
        />
      ),
      onFilter: (value, record) =>
        record.id.toString().includes(value.toString()),
      filteredValue: filteredInfo.id || null,
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
      fixed: "left",
    },
    {
      title: "Created on",
      dataIndex: "date",
      sorter: (a, b) => {
        const fromDate = new Date(b.date);
        const toDate = new Date(a.date);

        return fromDate.getTime() - toDate.getTime();
      },
      render: (dateString) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>{formatDate(dateString)}</div>
        );
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: Array.sort,
      filters: requests
        ? requests
            .map((item) => item.request_type)
            .filter(
              (elem, pos) =>
                elem &&
                requests.map((item) => item.request_type).indexOf(elem) === pos,
            )
            .sort()
            .map((value) => ({
              text: replaceOrderTypes(value),
              value: value,
            }))
        : [],
      filteredValue: filteredInfo.type || null,
      onFilter: (value, record) => record.type === value,
      render: (value) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>{replaceOrderTypes(value)}</div>
        );
      },
    },
    {
      title: "Sales office",
      dataIndex: "sales_entity",
      sorter: Array.sort,
      filters: requests
        ? requests
            .map((item) => item.sales_entity.name)
            .filter(
              (elem, pos) =>
                elem &&
                requests.map((item) => item.sales_entity.name).indexOf(elem) ===
                  pos,
            )
            .sort()
            .map((value) => ({
              text: value,
              value: value,
            }))
        : [],
      onFilter: (value, record) => {
        return record.sales_entity === value;
      },
      filteredValue: filteredInfo.sales_entity || null,
      render: (value) => {
        return <div style={{ whiteSpace: "nowrap" }}>{value}</div>;
      },
    },
    {
      title: "Customer/Supplier",
      dataIndex: "customer",
      sorter: Array.sort,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ minWidth: "100%", width: "100%" }}>
          <InputGroup
            style={{ padding: 8, minWidth: 800, zIndex: 3 }}
            required
            name="customer"
            type="select"
            value={selectedKeys[0]}
            onChange={(e) => {
              if (e[0].name === "CLEAR FILTER") {
                handleReset(clearFilters);
                setSelectedKeys("");
                // TODO: Remove reload window
                window.location.reload();
              } else {
                setSelectedKeys(e[0].name ? [e[0].name] : []);
                handleSearch(selectedKeys, confirm);
              }
            }}
            items={
              customers
                ? [
                    { name: "CLEAR FILTER", id: 0 },
                    ...customers
                      .map((item) => {
                        return {
                          name: item.display_name,
                          value: item.id.toString(),
                        };
                      })
                      .sort((a, b) => a.name.localeCompare(b.name)),
                  ]
                : []
            }
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? "#1890ff" : "#8e8e8e", fontSize: "16px" }}
        />
      ),
      onFilter: (value, record) =>
        record.customer.toLowerCase().includes(value.toLowerCase()),
      filteredValue: filteredInfo.customer || null,
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text || ""}
        />
      ),
    },
    {
      title: "Delivery",
      dataIndex: "delivery_months",
      sorter: Array.sort,
      filters: requests
        ? requests
            .map((item) => item.delivery_months)
            .filter(
              (elem, pos) =>
                elem &&
                requests.map((item) => item.delivery_months).indexOf(elem) ===
                  pos,
            )
            .sort()
            .map((value) => ({
              text: value,
              value: value,
            }))
        : [],
      onFilter: (value, record) => record.delivery_months === value,
      filteredValue: filteredInfo.delivery_months || null,
      render: (value) => {
        return <div style={{ whiteSpace: "nowrap" }}>{value || "------"}</div>;
      },
    },
    {
      title: "Total qty (mt)",
      dataIndex: "total_quantity",
      sorter: (a, b) => {
        return a.total_quantity - b.total_quantity;
      },
      sortDirections: ["descend", "ascend"],
      render: (value) => {
        return <div style={{ whiteSpace: "nowrap" }}>{value}</div>;
      },
    },
    ...[
      hasGroup(user, "Alu Trader")
        ? {
            title: "Pricing basis",
            dataIndex: "benchmark",
            sorter: Array.sort,
            render: (value) => {
              return <div style={{ whiteSpace: "nowrap" }}>{value}</div>;
            },
          }
        : {},
    ],
    {
      title: "Target price",
      dataIndex: "requested_price",
      sorter: (a, b) => {
        return a.requested_price - b.requested_price;
      },
      sortDirections: ["descend", "ascend"],
      render: (value) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {value ? formatNumber(value, true, 2) : "-"}
          </div>
        );
      },
    },
    {
      title: "LME Basis 3M",
      dataIndex: "lme_basis",
      sorter: (a, b) => {
        return a.lme_basis - b.lme_basis;
      },
      sortDirections: ["descend", "ascend"],
      render: (value) => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {value ? formatNumber(value, true, 2) : "-"}
          </div>
        );
      },
    },
    {
      title: "Requestor",
      dataIndex: "requestor",
      sorter: Array.sort,
      filters: requests
        ? requests
            .map((item) => item.requestor.full_name)
            .filter(
              (elem, pos) =>
                elem &&
                requests
                  .map((item) => item.requestor.full_name)
                  .indexOf(elem) === pos,
            )
            .sort()
            .map((value) => ({
              text: value,
              value: value,
            }))
        : [],
      onFilter: (value, record) => record.requestor === value,
      filteredValue: filteredInfo.requestor || null,
      render: (value) => {
        return <div style={{ whiteSpace: "nowrap" }}>{value}</div>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: Array.sort,
      filters: requests
        ? requests
            .map((item) => item.status)
            .filter(
              (elem, pos) =>
                elem &&
                requests.map((item) => item.status).indexOf(elem) === pos,
            )
            .sort()
            .map((value) => ({
              text: value,
              value: value,
            }))
        : [],
      onFilter: (value, record) => record.status === value,
      filteredValue: filteredInfo.status || null,
      render: (value) => {
        return <div style={{ whiteSpace: "nowrap" }}>{value}</div>;
      },
    },
    {
      title: "Actions",
      dataIndex: "id",
      sorter: Array.sort,
      render: (value, request) => {
        return <ActionsRenderer value={value} request={request} />;
      },
    },
  ];

  return (
    <Page
      title="Requests"
      leftActions={[
        hasPerm(user, "rtcmdmodels.add_request") ? (
          <Button
            intent="primary"
            className={"cmd_button"}
            large={isMobile ? true : null}
            onClick={() => {
              history.push("/aluminium/request/new");
            }}
            text="New request"
          />
        ) : null,
      ]}
      rightActions={[
        <span style={{ fontSize: "18px", fontWeight: "normal" }}>
          Viewing:
        </span>,
        <DateRangeInput
          singleMonthOnly
          allowSingleDayRange
          {...dateFormat}
          popoverProps={{ minimal: true }}
          value={[startDate, endDate]}
          onChange={handleDateChange}
        />,
        !csvDownloading ? (
          <Tooltip intent="primary" content="Download Requests">
            <a onClick={downloadCsv} href="#0" download>
              <span className="rtcmd-page-action-icon">
                <Icon icon="arrow-down" />
              </span>
            </a>
          </Tooltip>
        ) : null,
        csvDownloading ? (
          <span>
            <Spinner size={24} />
          </span>
        ) : null,
        <Link to={"/aluminium/trades"}>
          <span
            style={{ fontSize: "16px", fontWeight: "normal", marginLeft: 12 }}
          >
            <u>View trades</u>
          </span>
        </Link>,
      ]}
      tabs={[]}
    >
      <Table
        className={`${tableCSS} ${overrideMarkStyle}`}
        locale={{
          emptyText: "No requests to display",
        }}
        loading={loading ? { indicator: <Spinner size={32} /> } : false}
        scroll={{
          x: requests && requests.length > 0,
        }}
        size="small"
        expandIconColumnIndex={13}
        columns={columns}
        dataSource={
          requests
            ? requests
                .sort((a, b) => {
                  return b.id - a.id;
                })
                .map((request) => {
                  return {
                    ...request,
                    key: request.id,
                    type: request.request_type,
                    date: request.created_at,
                    sales_entity: request.sales_entity.name,
                    customer: request.customer
                      ? request.customer.display_name
                      : "",
                    delivery_months: request.delivery_months,
                    lme_basis: request.lme_basis,
                    requested_price: request.requested_price,
                    requestor: request.requestor
                      ? request.requestor.full_name
                      : "",
                    rawRequest: request,
                    accountingPeriod: request.is_accounting_period_closed,
                  };
                })
            : []
        }
        tableLayout="auto"
        pagination={{
          pageSize: 20,
          itemRender: renderItem,
          showSizeChanger: false,
        }}
        onChange={handleChange}
      />
    </Page>
  );
};

export default Requests;
