import { ISwitchProps as BPISwitchProps, Switch as BPSwitch } from '@blueprintjs/core';
import React from 'react';
import '../../../assets/scss/core.scss';

export interface ISwitchProps extends BPISwitchProps {
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  large?: boolean;
}

export const Switch: React.FC<ISwitchProps> = (props) => {
  return <BPSwitch {...props} />;
};
