import { Button } from "rt-design-system-backup";
import { Space, Input } from "antd";

// Import Ant Design and custom icon
import { SearchOutlined } from "@ant-design/icons";

export const FilterDropDown = ({
  selectedKeys,
  setSelectedKeys,
  handleReset,
  handleSearch,
  clearFilters,
  confirm,
  placeholder = "Search ID"
}) => {
  return (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={placeholder}
        value={selectedKeys[0] ?? ""}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch(selectedKeys, confirm)}
        style={{ width: 160, marginBottom: 8, display: "block" }}
      />
      <Space size={"large"}>
        <Button
          intent="secondary"
          onClick={() => {
            handleReset(clearFilters);
          }}
          size="small"
          style={{ minHeight: "24px", minWidth: 60 }}
        >
          Reset
        </Button>
        <Button
          intent="primary"
          icon={<SearchOutlined />}
          onClick={() => handleSearch(selectedKeys, confirm)}
          size="small"
          style={{ minHeight: "24px", minWidth: 60 }}
        >
          Search
        </Button>
      </Space>
    </div>
  );
};
